import { get, post } from "../helpers/api_helper"

export async function getChatRoomList(userId) {
    try {
        return await get(`/common/users/talk-rooms?offset=&limit=`)

    } catch (err) {
        console.error(err)
        throw err
    }
}
export async function getMyRooms(body,offset,limit, value) {
    try {
        return await post(`/common/users/talk-rooms?offset=${offset}&limit=${limit}&keyword=${value}`, body, {}, true)

    } catch (err) {
        // console.error(err)
        return err
    }
}
