import React, {memo, useState, useRef, useEffect} from 'react';
import { useInterval, usePrevious } from 'utils/customHooks';
import ChatUI from '../../components/ChatUI';
import AppMessageComp from './AppMessageComp';
import SignMessageComp, {SignMessageAgain} from './SignMessageComp';
import FormModalContainer from './FormModalContainer';
import {SignUpForm, LoginForm} from './SignForms';
import { isMobile, checkMobileOS } from 'utils/checkMobile';
import i18n from '../../i18n';

const introductionDummy = [
    {
        "msgType": "TEXT",
        "message": "안녕하세요? 체셔고양이의 답변톡방을 찾아주셔서 감사합니다.",
    }
]

const delay = async (ms) => {
    return await new Promise(resolve => setTimeout(resolve, ms));
}


const Intro = (props) => {
    const {ownerId, ownerPath} = props;
    const setUser = props.setUser ? props.setUser : () => {console.log('Intro.js에 setUser prop이 전달되지 않았습니다.')};

    const [isRunning, setIsRunning] = useState(false);
    const [dummy, setDummy] = useState([]);
    const [messages, setMessages] = useState([]);
    const [chatDelay, setChatDelay] = useState(1200);
    const [intro, setIntro] = useState([]);
    const [customs, setCustoms] = useState([]);

    const [isThink, setIsThink] = useState(true);
    const [signFlag, setSignFlag] = useState(null);
    const prevSignFlag = usePrevious(signFlag);
    const [fade, setFade] = useState(false);

    const addMessage = () => {
        const dummyMessages = dummy;
        const originalMessages = messages.slice();

        console.log('addMessage Called!', {
            dl: dummyMessages.length,
            ol: originalMessages.length,
        });

        if (dummyMessages.length <= originalMessages.length) {
            setIsRunning(false);
            return false;
        }

        setIsThink(false);
        originalMessages.push(dummyMessages[originalMessages.length]);

        setMessages(originalMessages);
    }

    useEffect(() => {
        const dynamicUrl = props?.pageProfile?.dynamicTalkLink;

        setCustoms(isMobile() ? [
            {
                msgType: 'CUSTOM',
                messages: 'custom 1',
                dom: <AppMessageComp os={checkMobileOS()} url={dynamicUrl} />
            },
            {
                msgType: 'CUSTOM',
                messages: 'custom 2',
                dom: <SignMessageComp
                    loginAction={() => setSignFlag('login')}
                    signUpAction={() => setSignFlag('signUp')} />
            },
        ] : [
            {
                msgType: 'CUSTOM',
                messages: 'custom 2',
                dom: <SignMessageComp
                    loginAction={() => setSignFlag('login')}
                    signUpAction={() => setSignFlag('signUp')} />
            },
        ]);

        const loadIntroduction = async () => {
            //await delay(1500);
            const userName = props?.pageProfile?.userName;
            const introductionData = props?.pageProfile?.roomIntroduction || [];
            if (introductionData.length === 0) {
                introductionData.push({
                    msgType: 'TEXT',
                    message: userName ? `안녕하세요? ${userName}의 답변톡방을 찾아주셔서 감사합니다.` : '안녕하세요? 답변톡에 오신 것을 환영합니다.',
                })
            }
            const activeTimeData = props?.pageProfile?.activeTime;

            console.log('introductionData', introductionData);
            console.log('activeTimeData', activeTimeData);

            const activeTimeMessages = activeTimeData ? [
                {
                    msgType: 'CUSTOM',
                    message: '답변 가능 시간입니다.',
                    dom: (
                        <div>
                            <div className="font-weight-bold">답변 가능 시간 안내</div>
                            <ul className="mt-1" style={{
                                listStyle: 'inside',
                                listStyleType: 'disc'
                            }}>
                                <li>{
                                    activeTimeData.days.map((item, index) => (
                                        index === activeTimeData.days.length - 1
                                            ? (<span key={index}>{i18n.t(item)}</span>)
                                            : (<span key={index}>{i18n.t(item)}, </span>)
                                    ))
                                }</li>
                                <li>{`${activeTimeData.from} ~ ${activeTimeData.to}`}</li>
                                <li>{activeTimeData.exceptHoliday === 'on' ? '공휴일 답변 가능' : '공휴일 답변 불가'}</li>
                            </ul>
                        </div>
                    )
                }
            ] : []

            const presetMessages = introductionData.concat(activeTimeMessages);
            setIntro(presetMessages);
        }

        loadIntroduction().then(async r => r);
    }, [props.pageProfile]);

    useEffect(() => {
        const i = intro.slice();
        if (i.length <=  0) return;

        setDummy(i.concat(customs));
        setIsRunning(true);
    }, [intro])

    useEffect(() => {
        const signProcessor = async (flag) => {
            setIsRunning(false);

            const originalMessages = messages;
            const newMessages = messages.slice();

            const comment = flag === 'login' ? '로그인 모듈을 시작합니다.' :
                '회원가입 절차를 실행합니다.';

            setChatDelay(0);
            newMessages.push({
                msgType: 'TEXT',
                message: comment,
            });

            setMessages(newMessages);


            if (!fade) { // 이미 sign 처리 관련 modal이 활성화 된 경우, 지연효과 제거
                await delay(500);
                setIsThink(true);

                await delay(1500);
                setFade(true);
            } else {
                setIsThink(true);
            }

        }

        const canceledProcessor = async (prevSignFlag) => {
            const originalMessages = messages;
            const newMessages = messages.slice();

            let comment;
            if (prevSignFlag) {
                comment = `${prevSignFlag === 'login' ? '로그인' : '회원가입'}이 도중에 취소되었습니다. 다시 시도할까요?`
            }

            setChatDelay(1000);

            newMessages.push({
                msgType: 'CUSTOM',
                message: '취소되었습니다. 다시 시도 요청합니다.',
                dom: <SignMessageAgain
                    comment={comment ? comment : '회원인증이 도중에 취소되었습니다. 다시 시도할까요?'}
                    loginAction={() => setSignFlag('login')}
                    signUpAction={() => setSignFlag('signUp')} />
            });

            setMessages(newMessages);
        }

        if (signFlag && (signFlag === 'login' || signFlag === 'signUp')) {
            signProcessor(signFlag).then(r => r);
        }

        if (signFlag === false && prevSignFlag !== signFlag) {
            setIsThink(false);

            canceledProcessor(prevSignFlag).then(r => r);
        }
    }, [signFlag])

    useInterval(() => {
        addMessage();
    }, isRunning ? chatDelay : null);

    return (
        <div className="landing-wrapper" style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: 'red',
        }}>
            <ChatUI {...props} ownerId={ownerId}
                    isAnonymousMode={props.isAnonymousMode || false}
                    ownerName={props?.pageProfile?.userName}
                    ownerImage={props?.pageProfile?.userImg}
                    messages={messages}
                    chatDelay={chatDelay}
                    isThinking={isThink} >
                <FormModalContainer fade={fade}
                                    onClosed={() => {setSignFlag(false)}}
                                    close={() => {setFade(false)}}>
                    {signFlag === 'signUp' ?
                        (<SignUpForm
                            setSignFlag={setSignFlag}
                            ownerId={ownerId}
                            roomId={props.roomId}
                            setUser={setUser}
                            changeUserInfo={props.changeUserInfo}
                        />) :
                        (<LoginForm
                            setSignFlag={setSignFlag}
                            ownerId={ownerId}
                            roomId={props.roomId}
                            setUser={setUser}
                            changeUserInfo={props.changeUserInfo}
                        />)}
                </FormModalContainer>
            </ChatUI>
        </div>
    )
}

export default memo(Intro);