import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import {
  Button,
  Container,
  Row,
  Col,
  CardBody,
  Collapse,
  Card,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import "../../assets/css/myStyleSlick.css"
import replyimg1 from "../../assets/imageshtml/replyImg01.jpg"
import replyimg2 from "../../assets/imageshtml/replyImg02.jpg"
import replyimg3 from "../../assets/imageshtml/replyImg03.jpg"
import replyimg4 from "../../assets/imageshtml/replyImg04.jpg"
import replyimg5 from "../../assets/imageshtml/replyImg05.jpg"
import replyimg6 from "../../assets/imageshtml/replyImg06.jpg"
import replytagImg1 from "../../assets/imageshtml/replytagImg01.jpg"
import replytagImg2 from "../../assets/imageshtml/replytagImg02.jpg"
import replytagImg3 from "../../assets/imageshtml/replytagImg03.jpg"
import replytagImg4 from "../../assets/imageshtml/replytagImg04.jpg"
import replyTagUser from "../../assets/images/replyTagImgUser.png"
import productImg from "../../assets/images/productImg.png"
import pcGuide01 from "../../assets/images/imgPcGuide01.png"
import pcGuide02 from "../../assets/images/imgPcGuide02.png"
import pcGuide03 from "../../assets/images/imgPcGuide03.png"
import pcGuide04 from "../../assets/images/imgPcGuide04.png"
import pcGuide05 from "../../assets/images/imgPcGuide05new.png"
import pcGuide06 from "../../assets/images/imgPcGuide06new.png"
import pcGuide07 from "../../assets/images/imgPcGuide07.png"
import pcGuide08 from "../../assets/images/imgPcGuide08.png"
import pcGuide09 from "../../assets/images/imgPcGuide09.png"
import appGuide01 from "../../assets/images/imgAppGuide01.png"
import appGuide02 from "../../assets/images/imgAppGuide02.png"
import appGuide03 from "../../assets/images/imgAppGuide03.png"
import appGuide04 from "../../assets/images/imgAppGuide04.png"
import appGuide05 from "../../assets/images/imgAppGuide05.png"
import { checkPath } from "../../services/checkProfilePath.service"
import Slider from "react-slick"

import DialogModal from '../../components/DialogModal/DialogModal.js';

const ReplyGuide = (args, props) => {
  const [openEl, setOpenEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [path, setPath] = useState("")
  const [isPathChecked, setIsPathChecked] = useState(false)
  const [pathValid, setPathValid] = useState(false)
  const [pathModal, setPathModal] = useState(false)
  const inputRef = useRef()
  const console_url = process.env.REACT_APP_CONSOLE_URL

  const modalToggle = () => {
    setPathModal((prev)=>!prev)
  }

  const checkPathFunc = async () => {
    console.log(path)
    setIsPathChecked(true)
    const regExp = /^[a-z0-9-/_]{4,20}$/g;
    // const regExp = /^[a-z-/_]{4,20}$/g;
    if (path !== "" && path.trim() !== "") {
      if(path.match(regExp)) {
        try {
          const result = await checkPath({ path })
          console.log(result)
          if (result.message === "OK") {
            setPathModal(true)
            setPathValid(true)
            // toast.success("입력하신 경로값을 사용할 수 있습니다.")
          } else {
            setPathValid(false)
            setPathModal(true)
            // toast.error("입력하신 경로값은 사용할 수 없습니다.")
          }
        } catch(err) {
          setPathValid(false)
          setPathModal(true)
          console.error(err)
          // if (err.response.status===403) {
          //   toast.error('해당 문구는 금지어입니다.')
          // }
        }
      } else {
        // toast.warning("영문과 -, _ 만 가능합니다")
        setPathValid(false)
        setPathModal(true)
      }
    } else {
      // alert("Please enter path")
      // toast.warning("주소를 입력해 주세요")
      setPathValid(false)
      setPathModal(true)
    }
  }

  const toggle = () => setIsOpen(!isOpen)
  //meta title

  const handleToggler = value => {
    if (openEl === value) {
      setOpenEl(null)
    } else {
      setOpenEl(value)
    }
  }

  useEffect(() => {}, [openEl])

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4
  };

  const PathAction = () => {
    return (
        <>
          {/* <Button className={'theme-btn'} onClick={()=>{alert("서비스 준비 중입니다")}}>지금 등록하기</Button> */}
          <a className={'btn-secondary btn theme-btn'} href={`${console_url}`} target={'_blank'}>지금 등록하기</a>
          {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
          <Button className={'theme-btn-grey'} onClick={modalToggle}>나중에 등록하기</Button>
        </>
    )
  }

  document.title = "답변톡 플로잉"
  return (
    <React.Fragment>
      <div className="page-content">
        <section className="reply-area pt-60px">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="pb-4 d-flex align-items-center justify-content-between noflex">
                  <div className="replyTit">
                    <span>1</span>
                    <h2 className="listTitle mr-1">
                      <b>답변톡</b>으로 <b>내 회원</b>을 만드세요.
                    </h2>
                    <h5 className="mt-2">
                      유튜브 영상 시청, 댓글, 인스타 좋아요, 라방 질문, 눈팅
                      그냥 지나 가는 유저는 내 회원이 아닙니다.
                      <br /> 답변 톡이 스쳐가 가는 회원도 내 회원으로 만들어
                      줍니다.
                    </h5>
                    {/* <!-- <h5 className="mt-2">답변자 프로필을 등록 후 답변톡을 작성해 주세요.</h5> --> */}
                  </div>

                  {/* <div className="btnArea btn btn-outline-secondary btnBic"> */}
                    {/* <button className="btn btn-outline-secondary btnBic" onClick={(e)=>{
                      e.preventDefault()
                      window.open('about:blank').location.href=`${console_url}`
                    }}>
                      콘솔 로그인
                    </button> */}
                    <a href={`${console_url}`} target="_blank" className="btnArea btn btn-outline-secondary btnBic" style={{textDecoration: "none", height:'100%'}}>스튜디오 로그인</a>
                  {/* </div> */}
                  {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                </div>
              </div>
            </div>
            <div className="row pt-50px">
              <div className="col-lg-12 text-center">
                <img
                  src="/images/img_reply01.png"
                  style={{ width: "100%", maxWidth: "1100px" }}
                  alt="imagearea"
                />
              </div>
              {/* <div className="col-lg-6">
                <div className="replyImgArea">
                    <div className="d-flex">
                        <div className="col">
                            <div className="replyImg"><img src="images/replyImg01.jpg" alt="imagearea"/></div>
                        </div>
                        <div className="col">
                            <div className="replyImg"><img src="images/replyImg02.jpg" alt="imagearea"/></div>
                        </div>
                        <div className="col">
                            <div className="replyImg"><img src="images/replyImg03.jpg" alt="imagearea"/></div>
                        </div>
                    </div>
                    <div className="d-flex pt-4">
                        <div className="col">
                            <div className="replyImg"><img src="images/replyImg04.jpg" alt="imagearea"/></div>
                        </div>
                        <div className="col">
                            <div className="replyImg"><img src="images/replyImg05.jpg" alt="imagearea"/></div>
                        </div>
                        <div className="col">
                            <div className="replyImg"><img src="images/replyImg06.jpg" alt="imagearea"/></div>
                        </div>
                    </div>
                </div>

            </div>
            
            <div className="col-lg-6">
                <div className="imgText">
                    <p><b>인플루언서</b></p>
                    <p>크리에이터</p>
                    <p><b>셀럽</b></p>
                    <p>전문가</p>
                    <p><b>블로거</b></p>
                    <p>입니까?</p>
                </div>
            </div> */}
            </div>
            <div className="row pt-50px">
              <div className="col-lg-12">
                <div className="card card-item">
                  <div className="card-body">
                    <div className="adressArea d-flex align-items-center justify-content-between">
                      <span>https://flowing.space/</span>
                      <div className="input-group">
                        <input
                          className="form-control form--control"
                          type="text"
                          name="email"
                          ref={inputRef}
                          onChange={(e)=>{setPath(e.target.value)}}
                          placeholder="내 공식 답변 톡 방 주소(4자 이상 20자 이하, 영문, 숫자, -, _ 가능)"
                        />
                        <button className="btn theme-btn" type="button" onClick={checkPathFunc}>
                          등록 가능 확인
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                    {isPathChecked ? (
                <div>
                  {pathValid ? (
                    <div>
                      <DialogModal isOpen={pathModal} toggle={modalToggle}  centered={true} isClosable={true} onClosed={() => {setIsOpen(false)}} content={{
                        title: '등록 가능한 주소입니다!',
                        // message: `"${path}"는 지금 사용 가능합니다.`,
                        actions: PathAction(),
                      }} />
                    </div>
                  ) : (
                    <div>
                      <DialogModal isOpen={pathModal} toggle={modalToggle}  centered={true} isClosable={true} onClosed={() => {setIsOpen(false)}} content={{
                        title: '등록할 수 없는 주소입니다',
                        // message: `"${path}"는 지금 등록하실 수 없는 주소입니다.`,
                      }} />
                    </div>
                  )}
                </div>
              ) : null}
              </div>
            </div>
          </div>
        </section>
        <section className="reply-area pt-80px">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="pb-4 d-flex align-items-center justify-content-between text-left noflex">
                  <div className="replyTit">
                    <span>2</span>
                    <h2 className="listTitle">
                      <b>공개톡</b>으로 관심 회원을 늘려 보세요.
                    </h2>
                    <h5 className="mt-2">
                      #비트 코인 #어학연수 #골프레슨 #필라테스
                      <br /> 내 관심 키워드로 등록 해 놓으면 내 관심 분야 회원을
                      모을 수 있어요.
                    </h5>
                  </div>

                  {/* <div className="btnArea btn btn-outline-secondary btnBic"> */}
                    {/* <button className="btn btn-outline-secondary btnBic" onClick={(e)=>{
                      e.preventDefault()
                      window.open('about:blank').location.href=`${console_url}`
                    }}>
                      콘솔 로그인
                    </button> */}
                    <a href={`${console_url}`} target="_blank" className="btnArea btn btn-outline-secondary btnBic" style={{textDecoration: "none", height:"100%"}}>스튜디오 로그인</a>
                  {/* </div> */}
                  {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                </div>
              </div>
              <div className="tagImgArea justify-content-around">
                <div className="tagitem">
                  <div className="tagitemImg">
                    <img src="images/replytagImg01.jpg" alt="imagearea" />
                  </div>
                  <p className="d-flex align-items-center justify-content-between">
                    #어학연수{" "}
                    <img src="/images/replyTagImgUser.png" alt="imagearea" />{" "}
                  </p>
                </div>
                <div className="tagitem">
                  <div className="tagitemImg">
                    <img src="images/replytagImg02.jpg" alt="imagearea" />
                  </div>
                  <p className="d-flex align-items-center justify-content-between">
                    #비트코인{" "}
                    <img src="/images/replyTagImgUser.png" alt="imagearea" />{" "}
                  </p>
                </div>
                <div className="tagitem">
                  <div className="tagitemImg">
                    <img src="images/replytagImg03.jpg" alt="imagearea" />
                  </div>
                  <p className="d-flex align-items-center justify-content-between">
                    #여행{" "}
                    <img src="/images/replyTagImgUser.png" alt="imagearea" />{" "}
                  </p>
                </div>
                <div className="tagitem">
                  <div className="tagitemImg">
                    <img src="images/replytagImg04.jpg" alt="imagearea" />
                  </div>
                  <p className="d-flex align-items-center justify-content-between">
                    #심리상담{" "}
                    <img src="/images/replyTagImgUser.png" alt="imagearea" />{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="reply-area pt-80px ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="pb-4 d-flex align-items-center justify-content-between text-left noflex">
                  <div className="replyTit">
                    <span>3</span>
                    <h2 className="listTitle">
                      <b>스페이스톡</b>으로 비즈니스를 만드세요.
                    </h2>
                    <h5 className="mt-2">
                      유튜브 1개의 회원이 아니라 다양한 채널 회원으로 내 구독을
                      만들어 보세요.
                      <br />
                      저렴한 수수료로 더 높은 수익을 가져 가세요.
                    </h5>
                  </div>
                  {/* <div className="btnArea btn btn-outline-secondary btnBic"> */}
                    {/* <button className="btn btn-outline-secondary btnBic" onClick={(e)=>{
                      e.preventDefault()
                      window.open('about:blank').location.replace(`${console_url}`)
                    }}>
                      콘솔 로그인
                    </button> */}
                    <a href={`${console_url}`} target="_blank" className="btnArea btn btn-outline-secondary btnBic" style={{textDecoration: "none", height:"100%"}}>스튜디오 로그인</a>
                  {/* </div> */}
                  {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                </div>
              </div>
            </div>
            <div className="row pt-50px">
              <div className="col-lg-12 text-center">
                <div className="text-right">
                  <p className="pr-2">Ver 1.0</p>
                  <img
                    src="images/img_reply02.png"
                    style={{ width: "100%", maxWidth: "1100px" }}
                    alt="imagearea"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* TODO: 섹션 쪽 carousel 구현 살펴보기 */}

        <section className="reply-area pt-80px " >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="pb-4 ">
                            <div className="replyTit pl-0">
                                <h2 className="listTitle fs-24 "><b>멤버관리</b>를 위한  다양한 기능 </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-lg-12">
                        <div id="customers-testimonials" className="owl-carousel">

                        
                          <Slider {...slickSettings}>
                            <div className="item">
                              <div className="shadow-effect">
                                <img className="img-circle"  src="/images/img-member-gift01.png" alt="imagearea"/>
                                <p>상대적으로 저렴한 수수료</p>
                              </div>
                              <div className="testimonial-name"><span>VIP</span>하나의 구독 맴버쉽</div>
                            </div>
                            <div className="item">
                                <div className="shadow-effect">
                                  <img className="img-circle"  src="/images/img-member-gift02.png" alt="imagearea"/>
                                  <p>텔레그램보다 좋은 공지톡</p>
                                </div>
                                <div className="testimonial-name">편리한 공지톡 기능</div>
                            </div>
                            <div className="item">
                                <div className="shadow-effect">
                                  <img className="img-circle"  src="/images/img-member-gift03.png" alt="imagearea"/>
                                  <p>텔레그램보다 좋은 공지톡</p>
                                </div>
                                <div className="testimonial-name">등급별 차등 컨텐츠 관리</div>
                            </div>
                            <div className="item">
                                <div className="shadow-effect">
                                  <img className="img-circle"  src="/images/img-member-gift04.png" alt="imagearea"/>
                                  <p>일반출석, 미션출석등 편리한 관리</p>
                                </div>
                                <div className="testimonial-name">편리한 자동 출석관리</div>
                            </div>
                            <div className="item">
                                <div className="shadow-effect">
                                  <img className="img-circle"  src="/images/img-member-gift05.png" alt="imagearea"/>
                                  <p>활동, 미션, 행사 등 다양한 일정관리</p>
                                </div>
                                <div className="testimonial-name">편리한 일정관리</div>
                            </div>
                            <div className="item">
                                <div className="shadow-effect">
                                  <img className="img-circle"  src="/images/img-member-gift06.png" alt="imagearea"/>
                                  <p>회원참여 다양한 투표/설문조사</p>
                                </div>
                                <div className="testimonial-name">편리한 투표/설문</div>
                            </div>
                          </Slider>
                            
                          </div>
                    </div>
                    
                </div>     
            </div>
        </section>

        <section className="reply-area pt-60px pb-100px accodianArea mt-100px mbm">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="filters pb-4 text-center">
                  <h2 className="listTitle mr-1 mwt">
                    답변톡 프로필 등록 방법 안내
                  </h2>
                  <h5 className="mb-3">PC 답변 관리 프로그램</h5>
                </div>
              </Col>
              <Col lg={12}>
                <div className="generic-accordion" id="accordion">
                  <Card>
                    <div className="card-header" id="headingOne">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingOne")
                        }}
                      >
                        <span> 답변자 관리 프로그램 설치</span>
                        <i
                          className={
                            openEl === "headingOne"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <div className="collapse show" id="collapseOne">
                      <Collapse isOpen={openEl === "headingOne"}>
                        <CardBody>
                          <p className="fs-15 lh-24">
                            우선 답변자 관리 프로그램을 설치해주세요.
                          </p>
                          <Row>
                            <Col lg={5}>
                              <div className="productDown">
                                <p className="solutionTit">
                                  Window용<br></br>플로잉 답변 관리
                                </p>
                                <p className="solutionTxt">
                                  지금 플로잉 답변 관리를 설치하면
                                  <br></br>
                                  답변 프로필 등록이 가능합니다.
                                </p>
                                <button className="btn btn-outline-secondary d-block btnBic btndown mb-3">
                                  다운로드 (32비트)
                                </button>
                                <button className="btn btn-outline-secondary d-block btnBic btndown">
                                  다운로드 (64비트)
                                </button>
                              </div>
                            </Col>
                            <Col lg={7}>
                              <div className="productImg">
                                <img src={productImg} alt="imagearea" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                    </div>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingTwo">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingTwo")
                        }}
                      >
                        <span>
                          회원 가입 및 로그인 후에 답변 프로필 관리로 이동
                        </span>
                        <i
                          className={
                            openEl === "headingTwo"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <div className="collapse show">
                      <Collapse isOpen={openEl === "headingTwo"}>
                        <CardBody>
                          <Row className="align-items-center">
                            <Col lg={4} className="offset-lg-1">
                              <p className="fs-15 lh-24 keep">
                                회원 가입 또는 로그인 후에 답변하기의 답변
                                프로필 관리로 이동하세요.
                              </p>
                            </Col>
                            <Col lg={6} className="offset-lg-1">
                              <div className="productImg">
                                <img src={pcGuide01} alt="imagearea" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                    </div>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingThree">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingThree")
                        }}
                      >
                        <span>답변 프로필 등록하기</span>
                        <i
                          className={
                            openEl === "headingThree"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <div className="collapse show">
                      <Collapse isOpen={openEl === "headingThree"}>
                        <CardBody>
                          <Row className="align-items-center">
                            <Col lg={4} className="offset-lg-1">
                              <p className="fs-15 lh-24 keep">
                                답변 프로필 관리에서 자기소개, 답변키워드,
                                카테고리, 지역 등을 넣고 등록하기를 눌러 주세요.
                              </p>
                            </Col>
                            <Col lg={6} className="offset-lg-1">
                              <div className="productImg">
                                <img src={pcGuide02} alt="imagearea" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Collapse>
                    </div>
                  </Card>

                  <Card>
                    <div className="card-header" id="headingFour">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingFour")
                        }}
                      >
                        <span>챗봇 설정하기</span>
                        <i
                          className={
                            openEl === "headingFour"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>

                    <Collapse isOpen={openEl === "headingFour"}>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              답변 프로필 등록 후에는 안내 설정 페이지로
                              이동하여 챗봇 설정을 할 수 있어요. 카테고리, 지역
                              <br></br>
                              인사말 세팅은 답변 프로필에서 답변톡으로 들어온
                              질문자들에게 인사말을 설정할 수 있어요.
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide03} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              답변 안내 설정은 질문자들에게 질문 항목을 선택할
                              수 있도록 항목을 지정하고 그에 따른 안내를 입력할
                              수 있어요.
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide09} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              답변 시간 설정은 바로 답변이 가능한 시간을 정해서
                              그 이외 시간에 답변방에 입장하는 경우 자동 안내
                              메시지가 표시되게 할 수 있어요.
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide04} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingFive">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingFive")
                        }}
                      >
                        <span>랜딩 페이지 관리하기</span>
                        <i
                          className={
                            openEl === "headingFive"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>

                    <Collapse isOpen={openEl === "headingFive"}>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              랜딩페이지 설정에서는 자신의 프로필
                              페이지(랜딩페이지)의 대한 디자인 설정을 할 수
                              있어요.
                              <br></br>
                              디자인 설정에서는 자신이 원하는 템플릿을 설정할 수
                              있어요. (템플릿은 주기적으로 추가될 예정)
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide05} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              테마 색상 설정은 랜딩페이지와 답변방 톡화면에서
                              테마 색상을 변경할 수 있어요.
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide06} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              답변 프로필 URL설정은 답변프로필
                              페이지(랜딩페이지)의 상세 URL정보를 자신이 원하는
                              아이디로 변경할 수 있어요.
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide07} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingSix">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingSix")
                        }}
                      >
                        <span>답변톡 상품 설정하기</span>
                        <i
                          className={
                            openEl === "headingSix"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>

                    <Collapse isOpen={openEl === "headingSix"}>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col lg={4} className="offset-lg-1">
                            <p className="fs-15 lh-24 keep">
                              상품관리의 답변톡 상품 설정 메뉴에서는 답변방에서
                              톡 전송제한 기본 글자수를 설정하거나 답변톡 상품의
                              가격을 설정할 수 있어요.<br></br>
                              해당 설정은 질문자과의 개별 답변방이 만들어질 때
                              적용이 되고 개별 답변방내에서는 별도 설정도 할 수
                              있어요.
                            </p>
                          </Col>
                          <Col lg={6} className="offset-lg-1">
                            <div className="productImg">
                              <img src={pcGuide08} alt="imagearea" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Col lg={12} className="mt-5 mb-3">
                    <div className="filters pb-4 text-center">
                      <h5>APP 플로잉 어플리케이션</h5>
                    </div>
                  </Col>
                  <Card>
                    <div className="card-header" id="headingSeven">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingSeven")
                        }}
                      >
                        <span>플로잉 어플리케이션 설치</span>
                        <i
                          className={
                            openEl === "headingSeven"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <Collapse isOpen={openEl === "headingSeven"}>
                      <Row className="align-items-center">
                        <Col lg={4} className="offset-lg-1">
                          <p className="fs-15 lh-24">
                            우선 휴대폰 OS별 마켓에서 플로잉 어플을 설치하세요.
                          </p>
                          <div className="btn-area3 mt-2">
                            <a
                              href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                              className="link-app"
                            >
                              <span className="tit-ios">App Store</span>
                            </a>
                            <a
                              className="link-app"
                              href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="tit-android">Google play</span>
                            </a>
                          </div>
                        </Col>
                        <Col lg={3} className="offset-lg-1">
                          <div className="productImg2">
                            <img src={appGuide01} alt="imagearea" />
                          </div>
                        </Col>
                      </Row>
                    </Collapse>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingEight">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingEight")
                        }}
                      >
                        <span>회원 가입 및 로그인 후에 답변하기로 이동</span>
                        <i
                          className={
                            openEl === "headingEight"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <Collapse isOpen={openEl === "headingEight"}>
                      <Row className="align-items-center">
                        <Col lg={4} className="offset-lg-1">
                          <p className="fs-15 lh-24">
                            회원 가입 또는 로그인 후에 답변하기의 답변자 등록을
                            클릭하세요
                          </p>
                        </Col>
                        <Col lg={3} className="offset-lg-1">
                          <div className="productImg2">
                            <img src={appGuide03} alt="imagearea" />
                          </div>
                        </Col>
                      </Row>
                    </Collapse>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingNine">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingNine")
                        }}
                      >
                        <span>답변 프로필 등록하기</span>
                        <i
                          className={
                            openEl === "headingNine"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <Collapse isOpen={openEl === "headingNine"}>
                      <Row className="align-items-center">
                        <Col lg={4} className="offset-lg-1">
                          <p className="fs-15 lh-24">
                            답변 프로필 작성 화면에서 답변 도우미의 안내에 따라
                            내용을 입력하거나 선택하세요.
                          </p>
                        </Col>
                        <Col lg={3} className="offset-lg-1">
                          <div className="productImg2">
                            <img src={appGuide02} alt="imagearea" />
                          </div>
                        </Col>
                      </Row>
                    </Collapse>
                  </Card>
                  <Card>
                    <div className="card-header" id="headingTen">
                      <button
                        className="btn btn-link collapsed"
                        onClick={() => {
                          handleToggler("headingTen")
                        }}
                      >
                        <span>마이페이지 정보 확인하기</span>
                        <i
                          className={
                            openEl === "headingTen"
                              ? "la collapse-icon la-angle-up "
                              : "la collapse-icon la-angle-down "
                          }
                        ></i>
                      </button>
                    </div>
                    <Collapse isOpen={openEl === "headingTen"}>
                      <Row className="align-items-center">
                        <Col lg={4} className="offset-lg-1">
                          <p className="fs-15 lh-24">
                            답변 프로필 작성 완료 후 프로필 화면으로 이동하여
                            추가 정보 입력 또는 수정을 할 수 있어요.<br></br>
                            <br></br>
                            또한 설정 페이지에서 답변방 전송 제한 설정도 할 수
                            있어요.<br></br>
                            <br></br>그 외에 부가 기능들은 PC 답변 관리
                            프로그램에서만 이용하실 수 있어요
                          </p>
                        </Col>
                        <Col lg={3} className="offset-lg-1">
                          <div className="productImg2">
                            <img src={appGuide04} alt="imagearea" />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="productImg2">
                            <img src={appGuide05} alt="imagearea" />
                          </div>
                        </Col>
                      </Row>
                    </Collapse>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default ReplyGuide
