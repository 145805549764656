import {
    Form,
    FormGroup,
    Input,
    InputGroup,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Label, Alert
} from "reactstrap"
import React, { useRef, useState } from "react"
import './SignForm.scoped.scss';

import greetingCharactersImage from 'assets/images/img-login-ch.png';
import welcomeCharacterImage from 'assets/images/ava01_9.gif';
import AccountLogin from "../LandingPageLogin/Chat/AccountLogin"
import BasicLogin from "../LandingPageLogin/Chat/BasicLogin"
import { authService } from "../../services/auth.service"
import { toast } from "react-toastify"

export const LoginForm = ({setSignFlag, ...props}) => {
    const [activeTab, setActiveTeb] = useState(1);
    const [process, setProcess] = useState('login');
    const [isSimpleSignUp, setIsSimpleSignUp] = useState(false)

    const updateLoginForm = (state) => {
        setIsSimpleSignUp(state)
    }

    return (
        <>
            <div>
                <div className="d-flex justify-content-center" style={{height: 80}}>
                    <img src={welcomeCharacterImage} alt="" style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                        objectPosition: '50% 50%',
                    }} />
                </div>
            </div>

            <div className="flowing-ui--chat-modal-tab mt-1">
                <Alert
                    color="success"
                    className="alert d-flex fs12"
                    role={'alertdialog'}
                    isOpen={isSimpleSignUp}
                >
                    <div>
                        입력하신 닉네임과 휴대폰번호로 매칭되는 계정 정보를 찾을 수 없어요.<br/>
                        지금 인증받은 휴대폰번호로 바로 '간편 회원가입'을 진행하시겠어요?
                    </div>
                </Alert>
                <Nav>
                    <NavItem active={activeTab === 1}>
                        {/*<NavLink onClick={() => {setActiveTeb(1)}}>간편 로그인</NavLink>*/}
                        <NavLink onClick={() => {setActiveTeb(1)}}>
                            {isSimpleSignUp ? '간편 가입' : '간편 로그인'}
                        </NavLink>
                    </NavItem>
                    {!isSimpleSignUp ?
                        <NavItem active={activeTab === 2}>
                            <NavLink onClick={() => {setActiveTeb(2)}}>계정 로그인</NavLink>
                        </NavItem> : ''
                    }
                </Nav>
            </div>
            <article>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                        <Form className="flowing-ui-form mt-3" onSubmit={e => e.preventDefault()}>
                            {/*Simple LogIn Form*/}
                            <BasicLogin
                                updateLoginForm={updateLoginForm}
                                changeUserInfo={props.changeUserInfo}
                                ownerId={props.ownerId}
                                roomId={props.roomId}
                                isSimpleSignUp={isSimpleSignUp}
                            />
                        </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                        <Form className="flowing-ui-form mt-3" onSubmit={e => e.preventDefault()}>
                            {/*Account LogIn Form*/}
                            <AccountLogin {...props} />
                        </Form>
                    </TabPane>
                </TabContent>
                {!isSimpleSignUp ?
                    <div className="text-center mt-3">
                        플로잉 계정이 없으신가요?
                        <a className="ml-1" href="#" onClick={() => {
                            console.log('회원가입폼을 띄워봅시다.');
                            setSignFlag('signUp');
                        }}>지금 가입하세요.</a>
                    </div> : ''
                }
            </article>
        </>
    )
}

export const SignUpForm = ({setSignFlag, ...props}) => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [authCode, setAuthCode] = useState("")
    const [ref_url, setRefUrl] = useState("")
    const [resultAuthCode, setResultAuthCode] = useState(false)
    // const [loadingFile, setLoadingFile] = useState()
    const [userImg, setUserImg] = useState([])
    // const inputRef = useRef(null)
    const agreeRef = useRef()
    const [authCodeTime, setAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)
    const [isAllowed, setIsAllowed] = useState(false);

    // const [isAuthTrue, setIsAuthTrue] = useState(false)
    // const [isAgreed, setIsAgreed] = useState(false)

    let sec = 60
    let min = 1
    let time = 60000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = 0
                setAuthCodeTimeIn(false)
                setAuthCodeTime("0:00")
            }
        }
    }

    const requestCodeFunction = async () => {
        console.log('인증번호 요청');
        const result = await authService.requestCode(phoneNumber)

        if (result === "success") {
            setAuthCodeTimeIn(true)
            timerRef.current = setInterval(timer, 1000)
        } else {
            setAuthCodeTimeIn(false)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = 0
            }
        }
    }

    const checkRequestedCodeFunction = async () => {
        console.log('인증번호 확인 요청');
        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            return
        }

        //const result = await authService.checkRequestedCode(      //kms modify
        const result = await authService.checkRequestedCodeWithHeader(phoneNumber, authCode, ref_url)
        if (result.status === 201) {
            //setAuthCode(true);
            setResultAuthCode(true)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = 0
            }
            setAuthCodeTime("인증완료")
        }
    }

    // TODO : 계정 회원가입의 경우 비밀번호 설정 등의 까다로운 절차 때문에 가입 및 로그인이 불편하다.
    //  때문에 랜딩페이지에서는 간편가입을 우선하고, 이후 2.0에서 전화번호 제거 후에는 계정가입으로 대체한다.
    const signUpSimply = async (ev) => {
        ev.preventDefault();

        if(!isAllowed) {
            // document.getElementById("agree_to_terms").focus();
            agreeRef.current.focus();
            // toast.error("약관에 동의해 주세요.")
            window.alert("약관에 동의해 주세요.")
            return;
        }
        const checkedName = await authService.checkNameDuplicate(name).then(res => {
            return res?.response || res;
        })
        if (checkedName.status >= 400 || checkedName.status === 201) {
            // window.alert(checkedName?.data?.place_holder)
            return;
        }
        try {
            if (authCode || resultAuthCode) {
                let body = { phone_number: phoneNumber, nickname: name }
                const resultRes = await authService.signUpSimpleInLandingPage(body);

                if (resultRes?.status === 201) {
                    const userInfo = await authService.getUserInfo(resultRes?.data?.user_id);
                    props.changeUserInfo(userInfo);

                    toast.success('가입하신 계정으로 대화방에 입장합니다.');
                }
            }
        } catch (err) {
            console.log(err, "err")
        }
    }

    return (
        <>
            <article>
                <div>
                    <div className="text-center">
                        <img src={greetingCharactersImage} alt=""/>
                    </div>
                </div>
                <Form className="flowing-ui-form mt-3"
                      /*onSubmit={signUp}>*/
                      onSubmit={signUpSimply}>
                    <FormGroup className="custom-form-group">
                        <InputGroup size="sm">
                            <Input
                                id="mobile-number"
                                name="mobile-number"
                                placeholder="휴대폰 번호"
                                type="mobile-number"
                                value={phoneNumber}
                                valid={false}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />

                            <span className="input-group-append">
                                        <Button
                                            color="primary"
                                            className="widthAuto"
                                            type="button"
                                            onClick={requestCodeFunction}
                                        >
                                            인증코드 전송
                                        </Button>
                                    </span>
                        </InputGroup>
                        {/*<div className="helper-text"></div>*/}
                    </FormGroup>
                    <FormGroup className="custom-form-group">
                        <InputGroup size="sm">
                            <Input
                                id="InputNumberCheck"
                                name="InputNumberCheck"
                                placeholder="인증코드 입력"
                                type="text"
                                value={authCode}
                                onChange={e => setAuthCode(e.target.value)}
                            />
                            <div className="CountNum d-flex align-items-center" style={{top: 0, bottom:0,}}>
                                <span>{authCodeTime}</span>
                            </div>

                            <span className="input-group-append">
                                        <Button
                                            color="primary"
                                            className="widthAuto"
                                            type="button"
                                            onClick={checkRequestedCodeFunction}
                                        >
                                            확인
                                        </Button>
                                    </span>
                        </InputGroup>
                        {/*<div className="helper-text"></div>*/}
                    </FormGroup>

                    <FormGroup className="custom-form-group" size="sm">
                        <Input
                            bsSize="sm"
                            id="nickname"
                            name="nickname"
                            placeholder="닉네임 입력(3~10글자 한글, 영어, 숫자)"
                            type="text"
                            maxLength={10}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                        {/*<div className="helper-text"></div>*/}
                    </FormGroup>

                    <FormGroup onChange={(ev) => {
                        setIsAllowed(ev.target.checked)
                    }} check inline className="custom-form-group" size="sm">
                        <Label check>
                            <Input type="checkbox" ref={agreeRef} />
                            <a href="https://terms.flowing.tel/index.html" target="_blank">운영정책 및 약관</a> 동의
                        </Label>
                    </FormGroup>

                    <div>
                        <Button color="primary" size="sm" block={true}>
                            가입하기
                        </Button>
                    </div>
                </Form>

                <div className="mt-3 text-center">
                    혹시 이미 계정이 있으신가요?
                    <a className="ml-1" href={'#'} onClick={() => {
                        console.log('로그인 폼을 띄워봅시다!')
                        setSignFlag('login')
                    }}>로그인 하기</a>
                </div>
            </article>
        </>
    )
}
