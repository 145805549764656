import React from "react"
import BlockUsers from "./BlockUsers"
import ReportAbuse from "./ReportAbuse"
import ActiveUserList from "./ActiveUserList"
import ImageAndVideo from "./ImageAndVideo"

export default function Screens(props) {
    const { visibleMenu, roomData, targetUserId } = props
    return (
        <div>
            {visibleMenu === "imageAndVideo" && <ImageAndVideo {...props} />}
            {visibleMenu === "blockeUsers" && <BlockUsers {...props} />}
            {visibleMenu === "reportAbuse" && (
                <ReportAbuse {...props} targetUserId={targetUserId} />
            )}
            {visibleMenu === "activeUserList" && (
                <ActiveUserList {...props} roomInformation={roomData} />
            )}
        </div>
    )
}
