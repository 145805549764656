import React, { useEffect, useState, useMemo } from "react"
import { Amplify } from "aws-amplify"
import _ from "lodash"

// import css and scss
import "../../../assets/css/myStyleChat.css"
import "../../../assets/css/chat/ChatRoom.scss"
import "../../../assets/css/fontAwesome.css"
import "../../../helpers/aws_client_iot"

// Support screens
import Screens from "./chatRoomMenu/Screens"
import ChatContent from "./chatRoomMenu/ChatContent"
import ChatHeader from "./chatRoomMenu/ChatHeader"
import ModalContainer from "../QuestionChat/chatRoomMenu/ModalContainer"

// service functions
import {
    getRoomDataFunction,
    getMessagesFunction,
} from "./helper/Functions/ChatFunctions"
import { getTransferLimitFunction } from "./helper/Functions/SupportFunctions"
import moment from "moment"
import { ownerMessageReadService } from "../../../services/talk2youMessage.service"
import { authService } from "services/auth.service"

export default function AnswerChat(props) {
    // Container
    const [isDataInit, setIsDataInit] = useState(false);
    const [loading, setLoading] = useState()
    const [refreshRoom, setRefreshRoom] = useState()
    const [visibleMenu, setVisibleMenu] = React.useState("chatContainer")
    const [selectedValue, setSelectedValue] = useState({})
    const [kickRoomModal, setKickRoomModal] = useState(false)

    // Message transfer count
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState()
    const [meHashCode, setMeHashCode] = useState()
    const [transferCount, setTransferCount] = useState({})
    const [messageParam, setMessageParam] = useState({
        since: "",
        until: "",
        limit: 20,
    })

    const [lastReadMsgTime, setLastReadMsgTime] = useState('');

    // Room data
    const [roomData, setRoomData] = useState({})
    const [targetUserId, setTargetUserId] = useState()

    const [userLocalId, setUserLocalId] = useState("")
    const userId = props.user.id || userLocalId
    useEffect(() => {

        const getUserIdRes = async () => {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserLocalId(res.data.user_id)
            } else {
                return;
            }
        }
        getUserIdRes().then(r=>r)
    }, [])

    function uuidV4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const computedMessages = useMemo(() => {
        try {
            if (!messages?.length) return [];

            let nMessages = messages.slice();
            nMessages.forEach((item, idx) => {
                if (!item.instanceId) {
                    item.instanceId = uuidV4();
                }
            })

            console.log('%c nMessages', 'background:#333;color:yellow;', nMessages)
            return _.unionBy(nMessages, 'messageId');
        } catch (e) {
            console.error(e);
        }
    }, [messages])

    const onChangeMenu = value => {
        setVisibleMenu(value)
    }

    const getMoreMessages = async () => {
        try {
            if (!messages || messages?.length === 0) return false;
            const firstMsgCreated = messages[0]?.createdAt;
            let data = await getMessagesFunction(
                props.roomId,
                props.user.id,
                {
                    since: '',
                    until: firstMsgCreated,
                    limit: 20,
                }
            )

            console.log('messages', messages);
            console.log('%c getMoreMessages-test','background:black;color:yellow;' ,data)

            const currentMessages = [...messages];
            const nMessages = data?.messages || [];
            setMessages(nMessages.concat(currentMessages));
        } catch (e) {
            console.error(e);
        }
    }

    // Main useEffect
    useEffect(() => {
        if (!props.roomId) return
        // Get room data service
        async function getRoomData() {
            console.log("hereThis")
            console.log(props.user.id)
            let data = await getRoomDataFunction(props.roomId, props.user.id)

            setRoomData(data)
            getTransferLimit().then(r => r);

            console.log("Room data", data)
        }
        getRoomData().then(r => r)
        console.log(props.user)
    }, [props.roomId, props.user.id])

    // Main useEffect
    useEffect(() => {
        if (!props.roomId) return;
        getMessages().then(r => r)
        // Get message service

        async function updateMessageReadStatus(data) {
            const items = data && data?.messages.map(item => item.createdAt) || []
            items.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0); // 날짜순 정렬
            // console.log("items", items)
            const since = items.length > 0 && items[0] || new Date().toISOString();
            // console.log("since", since)
            const until = items.length > 0 && items[items.length - 1] || new Date().toISOString();
            // console.log("until", until)
            const body = {
                userId,
                since,
                until,
            }
            ownerMessageReadService(newMessage?.value?.roomId, body).then(r => {
                console.log('updateMessageReadStatus', r)
            })
        }

        async function getMessages() {
            setLoading(true)
            let data = await getMessagesFunction(
                props.roomId,
                props.user.id,
                messageParam
            )
            // console.log("Msg data", data)

            // TODO : 원래는 Chat API에서 메시지 조회 시 자동으로 읽음 IoT를 보내야 함.
            //  하지만 해당 부분이 적용돼 있지 않아 직접 수동으로 읽음 처리 Update
            await updateMessageReadStatus(data);

            changeMessage({ limit: data.limit, messages: data.messages })
            setLoading(false)

            console.log('%c set Message Init','background:rgba(0, 0, 255, 0.3);color:blue;' , data);
            setIsDataInit(true);

            // IOT subscribe room
            Amplify.PubSub.subscribe(props.roomId).subscribe({
                next: data => {
                    // console.log("IOT new message --- ", data)
                    setNewMessage(data)
                },
                error: error => console.error(error),
                close: () => console.log("Done"),
            })
            getTransferLimit()
        }

    }, [props.roomId, props.user.id, messageParam, refreshRoom])

    // IOT new message
    useEffect(() => {
        if (!newMessage) return;

        if (
            // 이 채팅방의 다른 사람이 보낸 메시지
            newMessage &&
            newMessage.value &&
            meHashCode !== newMessage?.value?.hashCode &&
            newMessage?.value?.roomId === props.roomId
            && newMessage?.value?.senderId !=="BOT"
        ) {
            if (
                // 내보내기 INFO 메시지
                newMessage.value.msgType === "INFO" &&
                newMessage.value.action === "sendoff" &&
                newMessage.value.targetId === props.user.id
            ) {
                // Block user -> Room leave
                setKickRoomModal(true)
                return
            }

            // Owner read (X) ==> 상대방이 메시지르 읽었을 때 보내는 INFO 메시지 (O)
            if (newMessage.value.action === "read") {
                // setOwnerSeen(true)

                // 상대방이 메시지를 읽은 경우 내 비행기를 날려버리기 위해 마지막 읽은 시각 기록
                // console.log('lastReadMsgTime', moment(newMessage.value?.targetData?.until || newMessage.value?.createdAt).format('HH:mm:sss') || '')
                setLastReadMsgTime(newMessage.value?.targetData?.until || newMessage.value?.createdAt) // 마지막으로 메시지 읽은 시각 기록

                // Correct with Reactive way
                if(newMessage?.value?.targetId !== props.user.id // targetId 가 나 자신인 경우에는 제외
                    && newMessage?.value?.targetData?.since && newMessage?.value?.targetData?.until) {
                    const temp = [...messages];
                    const filtered = [];
                    for (let message of temp) {
                        if(new Date(message.createdAt).getTime() >= new Date(newMessage?.value?.targetData?.since).getTime() &&
                            new Date(message.createdAt).getTime() <= new Date(newMessage?.value?.targetData?.until).getTime()) {
                            message.unreadCount = 0;
                        }
                        filtered.push(message);
                    }
                    setMessages(filtered);
                }

                return
            }

            // Enter room new user
            if (newMessage.value.action === "enter") {
                if (roomData?.roomData)
                roomData?.roomData?.activeUsers.push(newMessage.value.targetData)
            }

            getTransferLimit().then(r => r)
            setMessages([...messages, newMessage.value])

            // 신규 메시지 수신 시 메시지 읽음처리(Update DB) 수행
            if(newMessage?.value) {
                console.log('read......')
                const body = {
                    userId,
                    since:newMessage?.value?.createdAt,
                    until:newMessage?.value?.createdAt,
                }
                // console.log('body', body)
                ownerMessageReadService(newMessage?.value?.roomId, body).then(r => {
                    console.log('rs', r)
                })
            }

        }

        // IS PREVIEW MSG
        if (
            props.user.id === newMessage?.value?.senderId &&
            newMessage?.value?.roomId === props.roomId &&
            newMessage?.value?.msgType === "PREVIEW"
        ) {
            setMessages([...messages, newMessage.value])
        }

        // Delete message
        if (
            newMessage?.value?.msgType === "DELETED" &&
            newMessage?.value?.roomId === props.roomId
        ) {
            let msgIndex = _.findIndex(messages, {
                messageId: newMessage?.value?.messageId,
            })
            let msgData = _.find(messages, function (o) {
                return o.messageId === newMessage?.value?.messageId
            })
            if (msgIndex !== -1 && msgData) {
                msgData.isActivated = 0
                let newArr = [...messages]
                newArr[msgIndex] = msgData
                setMessages(newArr)
            }
            getTransferLimit()
        }

        // Set message
        if (
            // 이 채팅방에서 내가 보낸 메시지
            meHashCode === newMessage?.value?.hashCode &&
            newMessage?.value?.roomId === props.roomId
        ) {
            // setMessages([...messages, newMessage && newMessage.value || ''])
            const filterNewArr = [...messages].filter(item => item?.hashCode !== newMessage?.value?.hashCode);
			// TODO: 차단당한 유저에게 메시지를 보냈을 때 usersToRead 값이 내려오지 않음. 이에 대한 적절성 여부 판단 필요
            newMessage.value.unreadCount = newMessage.value?.usersToRead?.length || 0;
            filterNewArr.push(newMessage.value);
            // console.log('newMessage.value', newMessage.value);
            setMessages(filterNewArr)
        }
    }, [newMessage])

    useEffect(()=>{
        /* 메시지가 있을 때마다 전송가능한 글자수를 계산해서 INPUT창 막기*/
        if(transferCount?.transferMaxCount-transferCount?.transferCount!==0){
            document.querySelector('#sent-chat-area').disabled = false
        }else{
            document.querySelector('#sent-chat-area').disabled = true
        }
    }, [transferCount])

    // Get transfer limit
    const getTransferLimit = async () => {
        let result = await getTransferLimitFunction(props.roomId, props.user.id)
        setTransferCount(result.limit)
    }

    // Block user -> Remove user from active room list
    const blockUser = blockUserId => {
        _.remove(roomData?.roomData?.activeUsers, function (n) {
        return n.userId === blockUserId
        })
        setRoomData(()=>{return { ...roomData }})
    }

    // Change message action
    const changeMessage = value => {
        if (value.limit) setTransferCount(value.limit)
        if (value.meHashCode) setMeHashCode(value.meHashCode)
        if (value.messages) {
            console.log("value.message",value.messages);
            //kms modify
            let originalMessages = value.messages;
            for(var i=0;originalMessages.length > i ; i++){
				if(originalMessages[i].isActivated === 0){
					originalMessages[i].msgType = 'TEXT';
					originalMessages[i].message ="삭제된 메세지 입니다.";
				}
			}
            
            //setMessages(value.messages)
            setMessages(originalMessages)
            console.log("originalMessages",originalMessages);
        }
    }

    const headerChangeProfile = () => {
        roomData.roomData.myInfo.push =
        roomData.roomData.myInfo.push === "on" ? "off" : "on"
        setRoomData(()=>{return { ...roomData }})
    }

    return (
        <div className="chat-customize white">
            <ChatHeader
                {...props}
                roomData={roomData}
                isHeader={props.isHeader}
                refreshRoom={() => setRefreshRoom(true)}
                headerChangeProfile={() => headerChangeProfile()}
                onChangeMenu={value => onChangeMenu(value)}
                transferCount={transferCount}
                setMessageParam={setMessageParam}
            />
            <div className="chat-container">
                <div className="chat-body">
                    {visibleMenu === "chatContainer" && (
                        <ChatContent
                            isInit={isDataInit}
                            getMore={getMoreMessages}
                            {...props}
                            loading={loading}
                            roomData={roomData}
                            messages={computedMessages}
                            blockUser={blockUserId => blockUser(blockUserId)}
                            onChangeMenu={(value, targetId) => {
                                onChangeMenu(value)
                                if (targetId) setTargetUserId(targetId)
                            }}
                            changeMessage={value => {
                                changeMessage(value)
                            }}
                            imageAndVideoDetail={value => {
                                setSelectedValue(value)
                                onChangeMenu(value.screen)
                            }}
                            lastReadMsgTime={lastReadMsgTime}
                        />
                    )}
                    <Screens
                        {...props}
                        selectedValue={selectedValue}
                        targetUserId={targetUserId}
                        visibleMenu={visibleMenu}
                        roomData={roomData}
                        refreshRoom={() => setRefreshRoom(true)}
                        onChangeMenu={value => {
                            onChangeMenu(value)
                        }}
                    />
                </div>
            </div>

            <ModalContainer
                kickRoomModal={kickRoomModal}
                    setKickRoomModal={() => {
                    setKickRoomModal(false)
                    props.exitChat()
                }}
            />
        </div>
    )
}
