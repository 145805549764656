import { BehaviorSubject } from "rxjs"
import axios from "axios"
import { Alert } from "reactstrap"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory"
// import { get, post, getWithHeader, postWithHeader } from "../helpers/api_helper_user"
// import { get as getCommon, post as postCommon } from "../helpers/api_helper_common"
import { get, post, patch } from "../helpers/api_helper"
import config from "../config/configuration"
import store from "store"

import { setUserId } from 'utils/analytics';

const subscriber = new BehaviorSubject(localStorage.getItem("access_token"))
// const subscriber = new BehaviorSubject(JSON.parse(localStorage.getItem('access_token')))

export const setSessionTrigger = () => {
    localStorage.setItem('session-trigger', 'changed');
    localStorage.removeItem('session-trigger');
}

const updateAccessToken = function (accessToken) {
    subscriber.next(accessToken)
}

const getUserId = async () => {
    try {
        const body = {}
        const response = await post(`/auth/web/user`,body, {baseURL: config.api.common_api}, true)
        return response
    } catch(err) {
        console.log(err.response)
        // return Promise.reject(err)
        return err
    }
}

const getACToken = async () => {
    try {
        const body = {}
        const response = await post('/auth/web/refreshToken', body, {baseURL: config.api.common_api}, true)
        return response
    } catch(err) {
        return err
    }
}

const requestCode = async function (phone_number) {
    let body = { phone_number }
    try {
        // let response = await post(`/phone/sms/pre-signup/request`, body)
        let response = await post(`/phone/sms/pre-signup/request`, body, {baseURL: config.api.user_api})
        // toast.success("Please check your messages !")
        console.log(response)
        toast.success("메시지를 확인해 주세요!")
        //return response
        return "success"
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
    }
}

const requestRenewCode = async function (userId,phone_number) {

    let body = {phone_number};
    console.log(`${userId}/phone/sms/renew/request`,"  ", body);
    try {
        // let response = await postWithHeader(`${userId}/phone/sms/renew/request`, body);
        let response = await post(`${userId}/phone/sms/renew/request`, body, {baseURL: config.api.user_api}, true);
        console.log("response:::", response);
        return response;

    } catch (err) {
        console.log("response error:::", err)
        return err.response;
    }
};

const checkRequestedCode = async function (phone_number, authCode, ref_url) {
    try {
        let body = {
            phone_number,
            auth_code: authCode,
            ref_url,
        }
        // let response = await post("/phone/sms/pre-signup/authenticate", body)
        let response = await post("/phone/sms/pre-signup/authenticate", body, {baseURL: config.api.user_api})
        // toast.success("Code submitted successfully")
        toast.success("번호 인증을 완료했습니다.")
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
    }
}

const checkRequestedRenewCode = async function (userId, phone_number, authCode, ref_url) {
    try {
        let body = {
            userId,
            phone_number,
            auth_code: authCode,
            ref_url,
        };
        // console.log(`${userId}/phone/sms/renew/authenticate`, body);
        // let response = await postWithHeader(`${userId}/phone/sms/renew/authenticate`, body);
        let response = await post(`${userId}/phone/sms/renew/authenticate`, body, {baseURL: config.api.user_api}, true);
        console.log(response);
        return response;
    } catch (err) {
        console.log("err.response", err.response);
        return err.response;
    }
};

//kmsadd post가 status를 돌려주지 않아서 별도로 구성함
const checkRequestedCodeWithHeader = async function (phone_number, authCode, ref_url) {
    try {
        let body = {
            phone_number,
            auth_code: authCode,
            ref_url,
        }
        // let response = await postWithHeader("/phone/sms/pre-signup/authenticate", body)
        let response = await post("/phone/sms/pre-signup/authenticate", body, {baseURL: config.api.user_api}, true)
        // toast.success("Code submitted successfully")
        toast.success("번호 인증을 완료했습니다.")
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
    }
}

const checkNameDuplicate = async function (name) {
    try {
        // console.log("check name")
        // let response = await get(`/nickname/duplicate?nickname=${name}`)
        let response = await get(`/nickname/duplicate?nickname=${name}`, {baseURL: config.api.user_api}, true)
        // console.log("response:::", response)
        if (response.status === 204) {
            // toast.success("You can use this name")
            toast.success("입력하신 닉네임을 사용할 수 있습니다.")
        } else if (response.status === 201) {
            // toast.error(response.data.message);  // 영문 메시지 표시
            toast.error(response.data.place_holder) // 한글 메시지 표시
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}

const checkNameDuplicateWithHeader = async function (name) {
    try {
        // console.log("check name")
        // let response = await getWithHeader(`/nickname/duplicate?nickname=${name}`)
        let response = await get(`/nickname/duplicate?nickname=${name}`, {baseURL: config.api.user_api}, true)
        // console.log("response:::", response)
        if (response.status === 204) {
            // toast.success("You can use this name")
            toast.success("입력하신 닉네임을 사용할 수 있습니다.")
        }
        if (response.status === 201) {
            // toast.error(response.data.message);  // 영문 메시지 표시
            toast.error(response.data.place_holder) // 한글 메시지 표시
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}

const checkEmailDuplicate = async function (email) {
    try {
        // let response = await get(`/email/duplicate?email=${email}`)
        let response = await get(`/email/duplicate?email=${email}`, {baseURL: config.api.user_api})
        if (response.status === 204) {
            // toast.success("You can use this email")
            toast.success("입력하신 이메일주소를 사용 가능합니다.")
        }
        if (response.status === 201) {
            // toast.error(response.data.message); // 영문 메시지 표시
            toast.error(response.data.place_holder) // 한글 메시지 표시
        }

        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
    }
}
const checkEmailDuplicateWithHeader = async function (email) {
    try {
        // let response = await getWithHeader(`/email/duplicate?email=${email}`)
        let response = await get(`/email/duplicate?email=${email}`, {baseURL: config.api.user_api}, true)
        // if (response.status === 204) {
        //     // toast.success("You can use this email")
        //     toast.success("입력하신 이메일주소를 사용 가능합니다.")
        // }
        if (response.status === 201) {
            // toast.error(response.data.message); // 영문 메시지 표시
            toast.error(response.data.place_holder) // 한글 메시지 표시
        }

        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
    }
}
const easyLogin = async function (nickname, phone_number) {
    try {
        let body = {
            nickname,
            phone_number,
        }
        // let response = await post(`/sign-in/simple`, body)
        let response = await post(`/sign-in/simple`, body, {baseURL: config.api.user_api})

        // TODO: if 문 삭제 필요?
        if (response) {
            sessionStorage.setItem("user_id", response.user_id)
            await getUserInfo(response.user_id)
            // toast.success("Login Successful")
            // toast.success("로그인 했습니다.")
            let tokenBody = {
                user_id: response.user_id,
                user_secret: response.user_secret,
            }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token.access_token)
            // localStorage.setItem("access_token", token.access_token)
            updateAccessToken(token.access_token)
            // window.location.href = "/main"

            setSessionTrigger()
        }
        return response
    } catch (err) {
        //toast.error(err.response.data.message)
        if(err.response.status===400) toast.error("입력하신 정보와 맞는 데이타가 없어 회원가입으로 이동합니다.")
        return err
    }
}
const easyLoginInLandingPage = async function(nickname, phone_number) {
    try {
        let body = {
            nickname,
            phone_number
        }
        // let response = await post(`/sign-in/simple`, body)
        let response = await post(`/sign-in/simple`, body, {baseURL: config.api.user_api}, true)
        if (response?.data) {
            sessionStorage.setItem("user_id", response?.data.user_id)
            // toast.success("Login Successful")
            // toast.success("로그인 했습니다.")
            let tokenBody = {
                user_id: response?.data.user_id,
                user_secret: response?.data.user_secret
            }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token.access_token)
            // await getUserInfo()
            // localStorage.setItem("access_token", token.access_token)
            updateAccessToken(token.access_token)
            // window.location.href = "/main"

            setSessionTrigger();
        }
        return response
    } catch (err) {
        //toast.error(err.response.data.message)
        // toast.error("입력하신 정보와 맞는 데이타가 없어 회원가입으로 이동합니다.");
        console.log("입력하신 정보와 맞는 데이타가 없어 회원가입으로 이동합니다.");
        return err
    }
}
const login = async function (email, password) {
    try {
        let body = {
            email,
            password,
        }
        // let response = await post(`/sign-in/formal`, body)
        let response = await post(`/sign-in/formal`, body, {baseURL: config.api.user_api})
        // sessionStorage.setItem("user_id", response.user_id)
        // console.log(response, "resp")
        if (response.user_id) {
            let tokenBody = {
                user_id: response.user_id,
                user_secret: response.user_secret,
            }
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token.access_token)
            // localStorage.setItem("access_token", token.access_token)
            const rs = await getUserInfo(response.user_id)
            // console.log('rs', rs); return;
            if (rs && rs.response && rs.response.status > 400) return new Error(rs)

            // toast.success("Login Successful")
            toast.success("로그인 했습니다.")
            // let tokenBody = {
            //     user_id: response.user_id,
            //     user_secret: response.user_secret,
            // }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            // let token = await post(`/auth/getToken`, tokenBody,{baseURL: config.api.common_api})
            // // localStorage.setItem("access_token", token.access_token)
            // updateAccessToken(token.access_token)

            if (token.accessToken !== null) {
                setSessionTrigger();
                window.location.href = "/main"
            }
            /*const testJWT2 = await axios.post(`${config.api.common_api}/auth/cookies/getToken`, tokenBody, /!*{
                headers: {
                    'Access-Control-Allow-Origin': `${config.api.common_api}`	// 서버 domain
                },
                withCredentials: true
            }*!/)*/
            // const test2 = await postCommon(`/auth/cookies/getToken`, tokenBody);
            // console.log(test2)

        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        console.log("err", err)
        toast.error(err.response?.data.place_holder || err.response?.data.message)
        return err.response;
    }
}
const loginChat = async function (email, password, referer) {
    try {
        let body = {
            email,
            password,
        }
        // let response = await post(`/sign-in/formal`, body)
        let response = await post(`/sign-in/formal`, body, {baseURL: config.api.user_api})
        // sessionStorage.setItem("user_id", response.user_id)
        console.log('response', response)
        if (response.user_id) {
            await getUserInfo(response.user_id)
            // toast.success("Login Successful")
            // toast.success("로그인 했습니다.")
            let tokenBody = {
                user_id: response.user_id,
                user_secret: response.user_secret,
            }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token.access_token)
            // localStorage.setItem("access_token", token.access_token)
            updateAccessToken(token.access_token)

            if (token.accessToken !== null) {
                // window.location.href = "/main"

                setSessionTrigger()
                if (referer) {
                    window.location.href = referer
                }
            }
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err.response;
    }
}

const loginChatInLandingPage = async function (email, password, referer) {
    try {
        let body = {
            email,
            password,
        }
        // let response = await post(`/sign-in/formal`, body)
        let response = await post(`/sign-in/formal`, body, {baseURL: config.api.user_api}, true)
        console.log('response', response)
        // console.log('response.data', response?.data)
        // sessionStorage.setItem("user_id", response?.data?.user_id)
        if (response?.data?.user_id) {
            await getUserInfo(response?.data?.user_id)
            // toast.success("Login Successful")
            // toast.success("로그인 했습니다.")
            let tokenBody = {
                user_id: response?.data?.user_id,
                user_secret: response?.data?.user_secret,
            }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token?.access_token)
            // localStorage.setItem("access_token", token.access_token)
            updateAccessToken(token?.access_token)

            if (token?.access_token !== null) {
                setSessionTrigger()

                // window.location.href = "/main"
                if (referer) {
                    window.location.href = referer
                }
            }
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err.response;
    }
}

const logout = async function (props) {
    try {
        setUserId();
        const logoutAPI = await post(`${config.api.common_api}/auth/web/sign-out`, {}, {}, true)
        console.log(logoutAPI)
        if(logoutAPI.status===201) {
            sessionStorage.clear()
            localStorage.clear()
            // window.location.reload()

            setSessionTrigger();
            window.location.href = "/login"
        }
        await updateAccessToken(null)
        // window.location.href = "/login"
        return
    } catch (err) {}
}

const signUp = async function (phone_number, nickname, email, image_url) {
    try {
        if (image_url && Array.isArray(image_url) && image_url.length > 0) {
            image_url = image_url[0]
        }
        let body = {
            phone_number,
            nickname,
            email,
            age_group: "",
            gender: "",
            image_url,
            invitation_user_code: "",
        }
        // console.log("body", body)
        // let response = await post(`/sign-up/formal`, body)
        let response = await post(`/sign-up/formal`, body, {baseURL: config.api.user_api})

        if (response) {
            // toast.success("Registration Successful")
            toast.success("회원가입을 완료했습니다.")
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}
const signUpWithHeader = async function (phone_number, nickname, email, image_url) {
    try {
        if (image_url && Array.isArray(image_url) && image_url.length > 0) {
            image_url = image_url[0] || ""
        } else {
            image_url = ""
        }
        let body = {
            phone_number,
            nickname,
            email,
            age_group: "",
            gender: "",
            image_url,
            invitation_user_code: "",
        }

        // let response = await postWithHeader(`/sign-up/formal`, body)
        let response = await post(`/sign-up/formal`, body, {baseURL: config.api.user_api}, true)

        if (response) {
            // toast.success("Registration Successful")
            // toast.success("회원가입을 완료했습니다. 로그인화면으로 이동합니다.");
            toast.success(
                "회원가입을 완료했습니다. 입력하신 이메일로 전달된 링크URL을 통해 비밀번호를 설정한 후 로그인해 주세요."
            )
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        // if (err?.response?.status === 409) return;
        // console.warn('test?????')
        // toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}

const signUpSimple = async function (body, referer) {
    try {
        let response = await post(`/sign-up/simple`, body, {baseURL: config.api.user_api})
        if (response) {
            // toast.success("Registration Successful")
            toast.success("회원가입을 완료했습니다.")
            console.log("회원가입을 완료했습니다.")

            // TODO : 간편가입 완료 후 로그인 처리 하기 위한 방법 : easyLogin 참조해서 로그인 처리
            // sessionStorage.setItem("user_id", response.user_id)
            await getUserInfo(response.user_id)

            let tokenBody = {
                user_id: response.user_id,
                user_secret: response.user_secret,
            }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token.access_token)
            // localStorage.setItem("access_token", token.access_token)
            updateAccessToken(token.access_token)
            // window.location.href = "/main"
            if (referer) {
                window.location.href = referer
            }
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}

const signUpSimpleInLandingPage = async function (body, referer) {
    try {
        let response = await post(`/sign-up/simple`, body, {baseURL: config.api.user_api}, true)
        // if (response?.data) {
        if (response.status === 201) {
            // toast.success("Registration Successful")
            // toast.success("회원가입을 완료했습니다.")
            console.log("회원가입을 완료했습니다.")

            // TODO : 간편가입 완료 후 로그인 처리 하기 위한 방법 : easyLogin 참조해서 로그인 처리
            // sessionStorage.setItem("user_id", response?.data.user_id)
            await getUserInfo(response?.data.user_id)

            let tokenBody = {
                user_id: response?.data.user_id,
                user_secret: response?.data.user_secret,
            }
            // let token = await postCommon(`/auth/getToken`, tokenBody)
            let token = await post(`/auth/web/getToken`, tokenBody,{baseURL: config.api.common_api})
            sessionStorage.setItem("access_token", token.access_token)
            // localStorage.setItem("access_token", token.access_token)
            updateAccessToken(token.access_token)
            // window.location.href = "/main"
            if (referer) {
                window.location.href = referer
            }
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}

const getUserInfo = async function (userId, verbose) {
    try {
        let body = {
            user_id: userId
        }
        // let response = await post(`/profile/find`, body)
        let response = await post(`/profile/find`, body, {baseURL: config.api.user_api}, verbose)
        console.log("getUserInfo", response)
        sessionStorage.setItem("user_data", JSON.stringify(response))
        setUserId(response?.id);

        if (response.status === 201) {
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        console.log('err', err)
        // toast.error(err.response.data.place_holder || err.response.data.message)
        return err
    }
}
const emailSend = async function (email) {
    try {
        let body = {
            email,
        }
        // let response = await post(`/password/email/renew/request`, body)
        let response = await post(`/password/email/renew/request`, body, {baseURL: config.api.user_api})
        /* 
            해당 응답 성공시 빈 문자열이 반환됨.
        */
        if (response==='') {
            toast.success("비밀번호 재설정 메일을 전송했습니다.")
        }
        return response
    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)

        return err
    }
}

const patchUserEmail = async  (userId, email, verbose) => {
    try {
        return await patch(`email/update`, {
            user_id: userId,
            email: email,
        }, {baseURL: config.api.user_api}, true).then(res => {
            console.warn('patchUserEmail', res);
            if (res.status > 201) throw res;
            if (verbose) {
                return res;
            } else {
                return res?.data;
            }
        })
    } catch (err) {
        console.log('patchUserEmail-err', err);
        throw err?.response || err;
    }
}

const patchUserProfile = async (userId, payload, verbose) => {
    try {
        return await patch(`/${userId}/profile`, payload, {baseURL: config.api.user_api}, true).then(res => {
            if (res.status > 201) throw res;
            if (verbose) {
                return res;
            } else {
                return res.data;
            }
        });

    } catch (err) {
        console.log('patchUserProfile-err', err);
        throw err?.response || err;
    }
}

// 👇 개편 회원체계 적용 API

const requestEmailCode = async (reqBody) => {
    try {
        return await post(`/email/pre-signup/request`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) {throw error?.response || error}
}

const certificateEmailCode = async (reqBody) => {
    try {
        return await post(`/email/pre-signup/authenticate`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) { throw error?.response || error}
}

const newSignIn = async (reqBody, location) => {
    try {
        const request = await post(`/sign-in`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 200) throw r
            return r.data
        })
        if(request.user_id) {
            let tokenBody = {
                user_id: request.user_id,
                user_secret: request.user_secret
            }
            let token = await post(`/auth/web/getToken`, tokenBody, {baseURL: config.api.common_api})
            sessionStorage.setItem('access_token', token.access_token)
            const response = await getUserInfo(request.user_id)
            if(response && response.response && response.response.status > 400) return new Error(response)

            if (token.accessToken !== null) {
                setSessionTrigger();
                if(!location) {
                    window.location.href = "/main"
                }
            }
        }
        return request
    } catch(error) { 
        throw error?.response || error
    }
}


const newSignUp = async (reqBody) => {
    try { 
        return await post(`/sign-up`, reqBody, {baseURL: config.api.user_api},true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) {throw error?.response || error}
}

const activateEmailRequestCode = async (userId, reqBody) => {
    try {
        return await post(`/${userId}/email/activation/request`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch (error) {
        throw error?.response || error
    }
}

const certificateActivateEmailRequestCode = async (userId, reqBody) => {
    try {
        return await post(`/${userId}/email/activation/authenticate`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch (error) {
        throw error?.response || error
    }
}

const changePassword = async (userId, reqBody) => {
    try {
        return await post(`/${userId}/reset/password`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) {
        throw error?.response || error
    }
}

const addUserEmail = async (userId, reqBody) => {
    try {
        return await patch(`/${userId}/email`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch (error) {
        throw error?.response || error
    }
}

const addEmailValueableCheck = async (reqBody) => {
    try {
        return await post(`/account/check`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status!==200) throw r
            return r
        })
    } catch(error) {throw error?.response || error}
}

export const authService = {
    login,
    logout,
    requestCode,
    requestRenewCode,
    updateAccessToken,
    checkRequestedCode,
    checkRequestedRenewCode,
    checkRequestedCodeWithHeader,
    checkNameDuplicate,
    checkNameDuplicateWithHeader,
    checkEmailDuplicate,
    checkEmailDuplicateWithHeader,
    emailSend,
    signUp,
    signUpWithHeader,
    getUserInfo,
    loginChat,
    loginChatInLandingPage,
    easyLogin,
    easyLoginInLandingPage,
    signUpSimple,
    signUpSimpleInLandingPage,
    subscriber,
    getUserId,
    getACToken,
    get accessToken() {
        return subscriber.value
    },
    patchUserProfile,
    patchUserEmail,
    setSessionTrigger,

    requestEmailCode,
    certificateEmailCode,
    newSignIn,
    newSignUp,
    activateEmailRequestCode,
    certificateActivateEmailRequestCode,
    changePassword,
    addUserEmail,
    addEmailValueableCheck
}
