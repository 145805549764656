import React, { useState, useEffect } from "react"

import { useLocation, useParams } from "react-router";
import { Redirect } from 'react-router-dom';

import "../../assets/css/style.css"
import {NavLink, Link} from 'react-router-dom';

import BuyDiamonds from './BuyDiamonds';
import UseList from '../MySettings/UseList';
import {divideArray} from '../../common/utils/functions';

const BuyDiamondsWrapper = (props) => {
    const location = useLocation();
    const params = useParams();

    const path = location.pathname.split('/');
    const flag = path[path.length - 1] || 'buyDiamonds';

    document.title = "답변톡 플로잉"
    return (
        <React.Fragment>
            <section className="job-area pt-60px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/buyDiamonds" style={{padding: 10}}> 다이아 충전하기</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/mySettings/useList" style={{padding: 10}}> 충전/구매 내역</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <>
                {
                    {
                        'buyDiamonds': <BuyDiamonds/>,
                        'useList': <UseList/>,
                    }[flag] || <Redirect to={'/main'}/>
                }
            </>
        </React.Fragment>
    )
}

export default BuyDiamondsWrapper
