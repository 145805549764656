import React, {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { CardBody, Card, Alert, Input, Form, InputGroup, FormGroup, CardFooter, Button } from "reactstrap"
import { uploadImageService } from "../../services/talk2meMessage.service"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faDownload, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css"
import DialogModal from '../../components/DialogModal/DialogModal';

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux

import { withRouter, Link } from "react-router-dom"
import { toast } from "react-toastify"
// import images

import logoFoot from "../../assets/images/logo_landingFoot.png"
import logoImg from "../../assets/images/userbg.jpg"

//import auth services
import { authService } from "../../services/auth.service"

const Register = props => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [authCode, setAuthCode] = useState("")
    const [ref_url, setRefUrl] = useState("")
    const [resultAuthCode, setResultAuthCode] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)
    const [userImg, setUserImg] = useState([])
    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')
    const inputRef = useRef(null)
    const [authCodeTime, setAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)
    const [isSignedUp, setIsSignedUp] = useState(false);
    const [isShowDialog, setIsShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({});

    const dialogContentMemo = useMemo(() => dialogContent, [dialogContent]);

    const history = useHistory();

    //meta title
    document.title = "답변톡 플로잉"


    /**
     * @property title {string}
     * @property message {string}
     * @property actions {domElement}
     * */
    const showDialogFunction = useCallback(({title, message, actions}) => {
        setDialogContent({
            title: title,
            message: message,
            actions: actions,
        });
        setIsShowDialog(true);
    }, [isShowDialog]);

    const toggleDialog = useCallback(() => {
        setIsShowDialog(!isShowDialog);
    }, [isShowDialog])

    const resetCertificatedPhone = () => {
        setPhoneNumber('');
        setAuthCode('');
        // setEmail('');
        // setName('');

        setAuthCodeTimeIn(false);
        setResultAuthCode(false);
    }

    //kms addd
    let sec = 60
    let min = 1
    let time = 180000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = 0
                setAuthCodeTimeIn(false)
                setAuthCodeTime("0:00")
            }
        }
    }

    const requestCodeFunction = async () => {
        /* 
            해당 코드의 의도 : 버튼 중복클릭에 대한 방어
            TODO: 중복클릭에 대한 방어적인 로직과 디자인 일관성을 맞출 수 있도록 한다.
         */
        if(email.trim() === '') {
            toast.error('계정(이메일)을 입력하세요.')
            return;
        }
        if(!email.match(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i)) {
            toast.error('계정(이메일)을 형식에 맞게 입력하세요.')
            return;
        }

        alert('인증코드 전송을 요청합니다.') // TODO: 중복 호출 방지 위한 alert, 로직 혹은 노출 문구 논의 필요
        try {
            // const result = await authService.requestCode(phoneNumber)
            const result = await authService.requestEmailCode({email})
            console.log(result)
            toast.success('입력한 이메일로 인증코드가 발송되었습니다.')
            setAuthCodeTimeIn(true)
            timerRef.current = setInterval(timer, 1000)
        } catch (error) {
            console.log(error.data.message)
            if(error?.data?.message==='This email is already taken') {
                if(window.confirm('이미 등록된 이메일입니다. 비밀번호 재설정 페이지로 이동하시겠습니까?')) {
                    props.history.push('/forgot-password')
                } else {
                    toast.error(error?.data?.place_holder)
                    return;
                }
            }
            // toast.error(error?.data?.place_holder)
            throw error
        }
    }
    const checkRequestedCodeFunction = async () => {
        if(authCode.trim() === '') {
            toast.error('인증코드를 입력해 주세요')
            return;
        }
        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.") // TODO: 문구 논의 필요
            setAuthCodeTimeIn(false)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = 0
            }
            return
        }
        try {
            const result = await authService.certificateEmailCode({email, auth_code: authCode})
            console.log(result)
            toast.success('이메일 인증이 완료되었습니다.')
            setResultAuthCode(true)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = 0
            }
            setAuthCodeTime("인증완료")
        } catch(error) {
            console.log(error)
            toast.error(error?.data?.place_holder)
            throw error
        }
    }

    const signUpFunction = async () => {
        if(email.trim('') === '' || name.trim()==='' || password.trim()==='') {
            toast.error('항목을 모두 입력해 주세요.')
            return;
        }
        if (!/^([a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]).{2,9}$/.test(name)) {
            toast.error("닉네임은 3~10자로 한글, 영어, 숫자만 사용 가능합니다.")
            return
        }
        if(password && passwordCheck && password !== passwordCheck) {
            toast.error('비밀번호가 일치하지 않습니다.')
            return;
        }
        if(!password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) || !passwordCheck.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/)) {
            toast.error('영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자로 입력하세요.')
            return;
        }
        if (!resultAuthCode) {
            toast.error("이메일 인증을 진행하세요.");
            return;
        }

        // const checkedName = await authService.checkNameDuplicateWithHeader(name) // 가입 409 처리를 위해 닉네임 유효성 전처리를 생략함
        // const checkedEmail = await authService.checkEmailDuplicateWithHeader(email)

        try {
                // let result = await authService.signUpWithHeader(phoneNumber, name, email, userImg).then(res => {
                //     console.log('signUpWithHeader', res);
                //     if (res.status !== 201) throw res?.response || res;
                //     return res;
                // });
                await authService.newSignUp(
                        {nickname: name, email, password, image_url: userImg[0] | ''}
                    ).then(r => {
                        if(r.status !== 201) throw r
                        return r
                    })
                setIsSignedUp(true);
                toast.success('회원가입 되었습니다.')
                history.push('/login'); // TODO: 메인페이지로 보내기 위해 해당 이메일로 로그인 절차 진행 필요
        } catch (err) {
            console.log(err)
            toast.error(err?.data?.place_holder)
            throw err
            // console.warn('signUpFunction: catched-error', err);
            // if (err.status === 409) { // 기존에 가입된 유저의 경우 처리 Todo: 전화번호 기반 회원체계 정리 및 간편가입 회원 이전 프로세스 구현 후에는 제거 필요
            //     const Actions = (
            //         <div>
            //             <Button onClick={() => {
            //                 history.push('/login');
            //             }} color={'primary'}>간편 로그인 하러가기</Button>
            //         </div>
            //     );

            //     showDialogFunction({
            //         title: '가입 전에 확인해 주세요!',
            //         message: (
            //             <div>
            //                 앱이나 간편가입을 통해 이미 가입하신 적이 있으신가요? <br/>
            //                 그렇다면 간편 로그인을 통해 로그인해 주세요!<br/><br/>
            //                 혹시, 이메일 계정 가입을 원하신다면 간편 로그인을 진행하신 후 <b>"내 정보 메뉴 > 정보설정"</b>에서 이메일 계정 설정을 통해 이메일을 현재 계정에 등록하실 수 있습니다.
            //             </div>
            //         ),
            //         actions: Actions,
            //     });

            //     return;
            // }
            // if (err?.status === 408) {
            //     // 폰번호 인증 이후 회원가입 Timeout
            //     // auth 정보 및 입력 정보 초기화
            //     resetCertificatedPhone();
            // }

            // if (err?.data?.place_holder) {
            //     toast.error(err?.data?.place_holder);
            // } else {
            //     console.log("err", err);
            //     console.log('err-status', err.status)
            // }
        }
    }
    
    //profile image upload
    const imageUpload = async (formData, fileSize, fileName) => {
        let msgType = "PHOTO"
        formData.append("limitedImageSize", 1280)
        console.log(formData, "formdata")
        try {
            const imageResponse = await uploadImageService(formData)
            console.log(imageResponse, "URL  image")
            setUserImg(imageResponse.url)
        } catch(error) {
            console.log(error)
            toast.error(error?.response?.data?.msg || '다시한번 시도해 주세요') //TODO: msg 영어로 내려오고 있음
            throw error
        } finally {
            setLoadingFile(false)
        }

    }

    const selectFileFunction = async event => {
        const fileUploaded = event.target
        const formData = new FormData()

        if (fileUploaded && fileUploaded.files && fileUploaded.files[0] && fileUploaded.files[0].type) {
            setLoadingFile(true)

            let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024)
            let type = fileUploaded.files[0].type.substring(0, 5)

            if (type === "video") {
                if (originFileSize > 200) {
                    toast.error("This file is larger than 200mb")
                    setLoadingFile(false)
                    return
                }
            } else {
                if (originFileSize > 20) {
                    toast.error("This file is larger than 20mb")
                    setLoadingFile(false)
                    return
                }
            }

            let bucketName = "flowing-web-resources"
            let fileSize = originFileSize.toFixed(2)
            let fileName = fileUploaded?.files[0]?.name
            formData.append("upload", fileUploaded.files[0])
            console.log('inprocess')
            if (fileSize < 1) {
                fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + "KB"
            } else {
                fileSize += "MB"
            }

            await imageUpload(formData, fileSize, fileName)
        }
    }

    return (
        <React.Fragment>
            <DialogModal isOpen={isShowDialog} content={dialogContentMemo}
                         toggle={toggleDialog}
                         centered={true}
                         isClosable={true} />

            <div className="auth">
                <div className="logo">
                    <Link to="/">
                        <img src={logoFoot} alt="logo" />
                    </Link>
                </div>
                <div className="auth_left">
                    <Card style={{height:"auto !important", marginTop:"100px"}}>
                        <div className="card-top d-flex">
                            {/*<div className="card-title">{props.t("Create your account")}</div>*/}
                            <div className="card-title">플로잉 웹 회원 가입</div>
                            {!isSignedUp && (
                                <div className="logo_profile ml-auto">
                                    <a
                                        onClick={() => {
                                            inputRef.current.click()
                                        }}
                                        className="b-none bg-none"
                                    >
                                        <form>
                                            <input
                                                type="file"
                                                id="input-file"
                                                className="d-none"
                                                ref={inputRef}
                                                onChange={selectFileFunction}
                                            />

                                            <label className="Phonebutton">
                                                <i className="fe fe-camera"></i>
                                            </label>
                                        </form>
                                    </a>

                                    <img src={userImg.length > 0 ? userImg[0] : logoImg} alt="" className="img-fluid" />
                                </div>
                            )}
                        </div>
                        {!isSignedUp ? (<CardBody className="">
                            {/* <Form> */}
                            <FormGroup>
                                <InputGroup>
                                    <Input
                                        id="mobile-number"
                                        name="mobile-number"
                                        className="form-control blackBox"
                                        /*placeholder={props.t("Enter your mobile")}*/
                                        placeholder="이메일을 입력하세요."
                                        type="email"
                                        value={email}
                                        disabled={resultAuthCode}
                                        readOnly={resultAuthCode}
                                        onChange={e => setEmail(e.target.value)}
                                    />

                                    <span className="input-group-append">
                                        <button
                                            className="btn btn-primary widthAuto "
                                            type="button"
                                            disabled={resultAuthCode}
                                            onClick={requestCodeFunction}
                                        >
                                            {/*{props.t("Send code")}*/}
                                            인증코드 전송
                                        </button>
                                    </span>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <Input
                                        id="InputNumberCheck"
                                        name="InputNumberCheck"
                                        className="form-control blackBox"
                                        /*placeholder={props.t("Enter your verification code")}*/
                                        placeholder="인증코드 입력"
                                        type="text"
                                        disabled={resultAuthCode}
                                        value={authCode}
                                        onChange={e => setAuthCode(e.target.value)}
                                    />
                                    {/* <span className="CountNum">1:00</span> */}
                                    <span className="CountNum">{authCodeTime}</span>

                                    <span className="input-group-append">
                                        <button
                                            className="btn btn-primary widthAuto "
                                            type="button"
                                            disabled={resultAuthCode}
                                            onClick={checkRequestedCodeFunction}
                                        >
                                            {/*Confirm*/}
                                            확인
                                        </button>
                                    </span>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Input
                                    id="nickname"
                                    name="nickname"
                                    className="form-control blackBox"
                                    /*placeholder={props.t("Please enter your nickname.  (up to 10 characters)")}*/
                                    placeholder="닉네임 입력(3~10글자 한글, 영어, 숫자)"
                                    type="text"
                                    maxLength={10}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                            </FormGroup>
{/* 
                            <FormGroup>
                                <Input
                                    name="phone_number"
                                    type="text"
                                    placeholder="핸드폰번호를 입력하세요"
                                    className="form-control blackBox"
                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value)}
                                />
                            </FormGroup> */}


                            <FormGroup>
                                <Input
                                    name="email"
                                    type="password"
                                    /*placeholder={props.t("Enter your email")}*/
                                    placeholder="비밀번호를 입력하세요 (영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자)"
                                    className="form-control blackBox"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                {password && !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) && (
                                    <p style={{color:'red'}}>비밀번호 형식에 일치하지 않습니다.</p>
                                )}
                            </FormGroup>

                            
                            <FormGroup>
                                <Input
                                    name="email"
                                    type="password"
                                    /*placeholder={props.t("Enter your email")}*/
                                    placeholder="비밀번호를 다시한번 입력하세요"
                                    className="form-control blackBox"
                                    value={passwordCheck}
                                    onChange={e => setPasswordCheck(e.target.value)}
                                />
                                {passwordCheck && passwordCheck && passwordCheck !== password && (
                                    <p style={{color:'red'}}>비밀번호가 일치하지 않습니다.</p>
                                )}
                            </FormGroup>

                            <div className="mt-3">
                                <button
                                    className="btn btn-primary btn-block w-100 "
                                    type="submit"
                                    onClick={signUpFunction}
                                >
                                    {/*{props.t("Join")}*/}가입하기
                                </button>
                            </div>
                            {/* </Form> */}
                            <CardFooter className="text-muted text-center">
                                <p className="registerGuide">
                                    {/*{props.t("Set the password from the setup URL sent to the account email you entered.")}*/}
                                    가입을 완료하면 입력하신 이메일 주소로 메일이 발송됩니다.
                                    <br />
                                    링크된 URL 클릭하셔서 패스워드를 생성하시면 로그인 페이지에서 로그인이 가능합니다
                                </p>
                                <p className="">
                                    {/*{props.t("I agree to the Flowing Terms.")}*/}
                                    <Link target="_blank" to={{ pathname: "https://terms.flowing.tel/index.html" }}>
                                        {/*{props.t("Check the Terms and Conditions")}*/}
                                        운영정책 및 약관 확인하기
                                    </Link>
                                </p>
                            </CardFooter>
                            <div className="text-center mt-0 pb-4 mb-5">
                                {/*{props.t("Already have a flowing account?")}*/}
                                <Link to="/login" className="font-weight-medium text-primary">
                                    {" "}
                                    {/*{props.t("Login")}*/}
                                    기존 회원 바로 로그인
                                </Link>{" "}
                                <p className="mt-5 Copyright">ⓒ 2022 Copyright TPKOREA Co.,Ltd.</p>
                            </div>
                        </CardBody>) : (<CardBody>
                            <div>
                                <Alert color={'primary'} className={'d-flex'}>
                                    <div className="flex-grow-0 mr-4">
                                        <FontAwesomeIcon icon={faPaperPlane} size={'xl'} />
                                    </div>
                                    <div className="flex-grow-1">
                                        등록하신 이메일로 비밀번호 설정 메일이 전송되었습니다.<br/>
                                        전송된 이메일을 통해 비밀번호를 설정하시면 이메일 주소와 비밀번호를 통한 계정 로그인이 가능합니다.
                                    </div>
                                </Alert>
                            </div>
                            <div className="mt-5">
                                <button
                                    className="btn btn-primary btn-block w-100 "
                                    type="button"
                                    onClick={() => {props.history.push('/login')}}
                                >
                                    {/*{props.t("Join")}*/}로그인 하러가기
                                </button>
                            </div>

                            <div className="text-center">
                                <p className="mt-5 Copyright">ⓒ 2022 Copyright TPKOREA Co.,Ltd.</p>
                            </div>
                        </CardBody>)}
                    </Card>
                </div>
                <div className="auth_right"></div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Register))

Register.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
}
