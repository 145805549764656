import React, { useEffect, useState, useRef } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
    CardBody,
    Card,
    Alert,
    Input,
    Form,
    InputGroup,
    FormGroup,
    CardFooter,
    Button,
    Row,
    Col
} from "reactstrap"
import { uploadImageService } from "../../../services/talk2meMessage.service"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import gallery from "../../../assets/images/profile-img.png"

//redux

import { withRouter, Link } from "react-router-dom"
import { toast } from "react-toastify"
// import images

import logoFoot from "../../../assets/images/logo_landingFoot.png"
import logoImg from "../../../assets/images/userbg.jpg"

//import auth services
import { authService } from "../../../services/auth.service"

const Register = props => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [authCode, setAuthCode] = useState("")
    const [ref_url, setRefUrl] = useState("")
    const [resultAuthCode, setResultAuthCode] = useState(false)
    const [loadingFile, setLoadingFile] = useState()
    const [userImg, setUserImg] = useState([])
    const inputRef = useRef(null)
    //meta title
    document.title = "답변톡 플로잉"

    //profile image upload
    const imageUpload = async (formData, fileSize, fileName) => {
        let msgType = "PHOTO"
        formData.append("limitedImageSize", 1280)
        console.log(formData, "formdata")
        const imageResponse = await uploadImageService(formData)
        console.log(imageResponse, "URL  image")
        if (imageResponse.msg === "OK") {
            setUserImg(imageResponse.url)
        } else {
            toast.error(imageResponse.msg)
        }

        setLoadingFile(false)
    }

    const requestCodeFunction = async () => {
        const result = await authService.requestCode(phoneNumber)
    }
    const checkRequestedCodeFunction = async () => {
        const result = await authService.checkRequestedCode(
            phoneNumber,
            authCode,
            ref_url
        )
        if (result.status === 201) {
            setAuthCode(true)
        }
    }
    const signUpFunction = async () => {
        const checkedName = await authService.checkNameDuplicate(name)
        const checkedEmail = await authService.checkEmailDuplicate(email)
        try {
            if (
                authCode === true &&
                checkedName.status === 204 &&
                checkedEmail.status === 204
            ) {
                let result = await authService.signUp(phoneNumber, name, email, userImg)
                if (result.status === 201) {
                    props.changeAuthLayout("auth")
                }
            }
        } catch (err) {
            console.log(err, "err")
        }
    }
    const selectFileFunction = async event => {
        const fileUploaded = event.target
        const formData = new FormData()

        if (
            fileUploaded &&
            fileUploaded.files &&
            fileUploaded.files[0] &&
            fileUploaded.files[0].type
        ) {
            setLoadingFile(true)

            let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024)
            let type = fileUploaded.files[0].type.substring(0, 5)

            if (type === "video") {
                if (originFileSize > 200) {
                    // toast.error("This file is larger than 200mb")
                    toast.error("선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다.")
                    setLoadingFile(false)
                    return
                }
            } else {
                if (originFileSize > 20) {
                    // toast.error("This file is larger than 20mb")
                    toast.error("선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다.")
                    setLoadingFile(false)
                    return
                }
            }

            let bucketName = "flowing-web-resources"
            let fileSize = originFileSize.toFixed(2)
            let fileName = fileUploaded?.files[0]?.name
            formData.append("upload", fileUploaded.files[0])

            if (fileSize < 1) {
                fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + "KB"
            } else {
                fileSize += "MB"
            }

            imageUpload(formData, fileSize, fileName)
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className="overflow-hidden">
                        <div className="card-top d-flex">
                            {/*<div className="card-title">Create your account</div>*/}
                            <div className="card-title">회원 가입</div>
                            <div className="logo_profile ml-auto">
                                <a
                                    onClick={() => {
                                        inputRef.current.click()
                                    }}
                                    className="b-none bg-none"
                                >
                                    <form>
                                        <input
                                            type="file"
                                            id="input-file"
                                            className="d-none"
                                            ref={inputRef}
                                            onChange={selectFileFunction}
                                        />

                                        <label className="Phonebutton">
                                            <i className="fe fe-camera"></i>
                                        </label>
                                    </form>
                                </a>

                                <img
                                    src={userImg.length > 0 ? userImg[0] : logoImg}
                                    alt=""
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                        <CardBody className="pt-0">
                            <div className="p-2">
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            id="mobile-number"
                                            name="mobile-number"
                                            className="form-control blackBox"
                                            placeholder="휴대폰 번호를 입력하세요."
                                            type="mobile-number"
                                            value={phoneNumber}
                                            onChange={e => setPhoneNumber(e.target.value)}
                                        />

                                        <span className="input-group-append">
                                            <button
                                                className="btn btn-primary widthAuto "
                                                type="button"
                                                onClick={requestCodeFunction}
                                            >인증번호 발송
                                            </button>
                                        </span>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            id="InputNumberCheck"
                                            name="InputNumberCheck"
                                            className="form-control blackBox"
                                            placeholder="인증번호를 입력하세요."
                                            type="text"
                                            value={authCode}
                                            onChange={e => setAuthCode(e.target.value)}
                                        />

                                        <span className="input-group-append">
                                            <button
                                                className="btn btn-primary widthAuto "
                                                type="button"
                                                onClick={checkRequestedCodeFunction}
                                            >확인
                                            </button>
                                        </span>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        id="nickname"
                                        name="nickname"
                                        className="form-control blackBox"
                                        placeholder="닉네임을 입력하세요. (최대 10자 이내)"
                                        type="text"
                                        maxLength={10}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Input
                                        name="email"
                                        type="text"
                                        placeholder="이메일을 입력하세요."
                                        className="form-control blackBox"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </FormGroup>

                                <div className="mt-3">
                                    <button
                                        className="btn btn-primary btn-block w-100 "
                                        type="submit"
                                        onClick={signUpFunction}
                                    >
                                        {/*{props.t("Join")}*/}가입하기
                                    </button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Register))

Register.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}
