import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faDownload } from "@fortawesome/free-solid-svg-icons"

export default function Photo(props) {
    const { message, completedAction } = props

    useEffect(() => {
        if(!completedAction) return false
        completedAction()
    }, [message])

    const formatBytes = targetFileSize => {
        /**
         * message.fileSize 값이 1MB 이상일 경우에만 변환해주고 있음.
         *  아래는 -> KB단위로 변환할 수 있는 경우에 변환
         */
        let regex = /[^0-9]/g;
        let fileSize = parseInt(targetFileSize && (""+targetFileSize).replace(regex, "") || "")
        let result = targetFileSize
        if (1024 <= fileSize && fileSize < 1024 * 1024) {
            result = Math.floor(fileSize / 1024) + "KB"
        }
        return result
    }

    return (
        <div className="file-content">
            <a className="file-container" download target="_blank" href={message.fileOrigin} rel="noreferrer">
                <div className="w50">
                    <FontAwesomeIcon icon={faDownload} color={"green"} />
                </div>

                <span>
                    <span>{message.message}</span>
                    {/*{message && message?.fileSize &&*/}
                        <span>Size: {formatBytes(message.fileSize)}</span>
                    {/*}*/}
                </span>
            </a>
        </div>
    )
}
