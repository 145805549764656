import './TypeLoader.scoped.scss';

const TypeLoader = () => {
    return (
        <div className="typing-loader text-center">
            <div className="spinner">
                <div className="bounce1"/>
                <div className="bounce2"/>
                <div className="bounce3"/>
            </div>
        </div>
    )
}

export default TypeLoader;