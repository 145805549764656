import React from 'react';
import './Invoice.scoped.scss';

import diaImage from "../../assets/images/dia.png";
import CharacterImage from "../../assets/images/img_ch01.png";
import sorryCharacterImage from "../../assets/images/ava01_2.gif";
import { methodObj } from "../../constants/paymentMethod"

const formatDateString = (str) => {
    try {
        return Intl.DateTimeFormat('ko-KR', {dateStyle: 'full', timeStyle: 'short'}).format(new Date(str));
    } catch (err) {
        console.error(err);
        return '알 수 없는 날짜'
    }
};

const ResultImage = (props) => {
    return (
        <article className="article image-area">
            {props.result ? (
                <div>
                    <img src={diaImage} alt="" style={{position:'absolute', top:'15%', right: '15%'}}/>
                    <img src={CharacterImage} alt="" style={{position:'absolute', bottom:0, left: 0}}/>
                </div>
            ) : (
                <div style={{width:'80%'}}>
                    <img src={sorryCharacterImage} alt="" style={{position: 'absolute', top: 0, left:0, right: '15%', bottom: 0, objectFit: 'contain'}}/>
                </div>
            )}

        </article>
    )
}

const Invoice = (props) => {
    const data = props.data;

    const title = data.result === 'T' ? '<b>결제</b>가 성공적으로 <b>완료</b>되었습니다!' : '결제가 실패하였습니다.';
    const displayPrice = data.amount ? Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency:'KRW',
    }).format(data.amount) : 0;

    return (
        <>
            <section className="invoice-document">
                <div className="container">
                    <div className="invoice-document__box">
                        <ResultImage result={data.result === 'T'} />
                        <article className="article information-area">
                            <h3 className={"invoice-document__title"} dangerouslySetInnerHTML={ {__html: title} } />
                            <ul className="invoice-document__detail">
                                <li>
                                    <dt>주문 상품</dt>
                                    <dd>{data.goodsName}</dd>
                                </li>
                                <li>
                                    <dt>주문 일시</dt>
                                    <dd>{formatDateString(data.orderedAt)}</dd>
                                </li>
                                <li>
                                    <dt>결제 금액</dt>
                                    <dd>{displayPrice}</dd>
                                </li>
                                <li>
                                    <dt>결제 방법</dt>
                                    <dd>{data.method && methodObj[data.method] || ''}</dd>
                                </li>
                                <li>
                                    <dt>승인 결과</dt>
                                    <dd>{data.result === 'T' ? '승인' : '미승인'}</dd>
                                </li>
                            </ul>
                        </article>
                    </div>
                </div>
            </section>

            {/*<section className="invoice-doc mt-50">
                <div className="invoice-doc__title">
                    <div className="blit">
                        <img src={diaImage} alt=""/>
                    </div>
                    <h3 className="title-text">결제가 완료 되었습니다. 감사합니다!</h3>
                </div>
                <ul className="invoice-doc__info">
                    <li>주문 상품: {data.goodsName}</li>
                    <li>주문 일시: {formatDateString(data.orderedAt)}</li>
                    <li>결제 금액: {displayPrice}</li>
                    <li>결제 방법: {data.method}</li>
                    <li>승인 결과: {data.result === 'T' ? '승인' : '미승인'}</li>
                </ul>
            </section>*/}
        </>
    )
}

export default Invoice;