import React, { useEffect } from "react"

export default function Photo(props) {
    const { message, completedAction } = props

    useEffect(() => {
        if(!completedAction) return false
        completedAction()
    }, [message])

    return (
        <div
            className="chat-lefted-image d-inline-block"
            style={{
                aspectRatio: 1/message?.imgRatio || 1,
                backgroundColor: '#ebebeb',
                width: 150,
            }}
        >
            <img
                src={message.message}
                alt="user"
                className=""
                onClick={() => {
                    props.imageAndVideoDetail({
                        type: "IMAGE",
                        file: message.message,
                        screen: "imageAndVideo"
                    })
                }}
                onLoad={()=>completedAction(true, 'auto')}
            />
        </div>
    )
}
