import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import { categoryService } from "services/category.service"
import { profileSuccess } from "store/actions"
import { toast } from "react-toastify"
const CategoryModal = props => {
    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState(props.selCategories)
    const [keyword, setKeyword] = useState("")
    const [offset, setOffset] = useState("0")
    const [limit, setLimit] = useState("100")

    const getCategories = async () => {
        // let data = await categoryService.getCategories()
        let data = await categoryService.searchCategories(keyword, offset, limit)
        setCategories(data.talk_category_list)
    }
    const addItem = item => {
        if (selectedCategories.length >= 3) {
            toast.error("카테고리 선택은 3개까지만 가능합니다.")
            return
        }
        selectedCategories.push(item)
        props.onChange(selectedCategories)
    }
    const removeItem = item => {
        const newCategories = selectedCategories.filter(cate => cate.id !== item.id)
        setSelectedCategories(newCategories)
        props.onChange(newCategories)
    }
    const searchCategories = async () => {
        console.log(selectedCategories, "cateee")

        let response = await categoryService.searchCategories(keyword, offset, limit)
        setCategories(response.talk_category_list)
    }
    const handleOnKeyDownForSearchInput = e => {
        if (e.key === "Enter") {
            searchCategories()
        }
    }

    useEffect(() => {
        console.log(props, "propds")
        getCategories()
    }, [])
    //meta title
    document.title = "답변톡 플로잉"

    return (
        <Modal isOpen={props.modal_category == true} centered toggle={() => props.setModal(false)}>
            <div className="modal-header">
                <h5 className="modal-title mt-0">관련 분류 검색</h5>
                <button
                    type="button"
                    onClick={() => {
                        props.setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div id="cateArea" className="viewCon">
                    <h5>질문 내용과 관련 있는 분류를 선택하세요. (최대 3개)</h5>
                    <p className="textArea">
                        검색 태그 또는 키워드를 입력하면 원하는 분류를 쉽게 찾을 수 있습니다.
                        {/*You can search categories by tags or keywords.*/}
                    </p>

                    <div>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="검색 태그 또는 키워드 입력"
                                value={keyword}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyDown={handleOnKeyDownForSearchInput}
                            />
                            <span className="input-group-append">
                                <button className="btn btn-secondary" type="button" onClick={searchCategories}>
                                    검색
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="ReaultArea">
                        <ul>
                            {categories.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="d-flex align-items-center mt-auto">
                                            <img className="avatar avatar-md mr-2" src={item.image_url} alt="" />
                                            <div className="ml-2">{item.name}</div>
                                            <div className="ml-auto text-muted">
                                                {selectedCategories.find(({ name }) => name === item.name) == null ? (
                                                    <button
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => addItem(item)}
                                                    >
                                                        추가
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => removeItem(item)}
                                                    >
                                                        취소
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                            {Object.keys(categories).length < 1 ? (
                                <li>
                                    <p className="noResult">검색 결과가 없습니다.</p>
                                </li>
                            ) : (
                                ""
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CategoryModal
