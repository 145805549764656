import { withTranslation } from "react-i18next"
import { Alert, Input, InputGroup, FormGroup, Button, Modal } from "reactstrap"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"

import "../../../assets/css/style.css"
import "react-toastify/dist/ReactToastify.css"

import { authService } from "../../../services/auth.service"
import { createRoom, enterRoom, getUserProfileService } from "services/talk2youRooms.service"
import { toast } from "react-toastify"

const AccountLogin = props => {

    document.title = "답변톡 플로잉"

    const [modal_center, setmodal_center] = useState(false)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [isRenew, setIsRenew] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [userId, setUserId] = useState('')
    const [ref_url, setRefUrl] = useState("")

    const [authCodeTime, setAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)

    const [authCode, setAuthCode] = useState("")
    const [isAuthTrue, setIsAuthTrue] = useState(false)

    let sec = 60
    let min = 1
    let time = 60000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
                setAuthCodeTimeIn(false)
                setAuthCodeTime("0:00")
            }
        }
    }


    const requestCodeFunction = async () => {
        try {
            const result = await authService.requestRenewCode(userId, phoneNumber).then(res => {
                if (res.status !== 201) {
                    throw res;
                }

                return res;
            })

            toast.success('휴대폰 메시지를 확인해 주세요')
            setAuthCodeTimeIn(true)
            timerRef.current = setInterval(timer, 1000)
        } catch (err) {
            setAuthCodeTimeIn(false)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }

            const displayMessage = err?.data?.place_holder || err?.data?.message;
            if (displayMessage) {
                toast.error(displayMessage);
            }
        }
    }
    const checkRequestedCodeFunction = async () => {
        console.debug('checkRequestedCodeFunction___')
        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            return
        }

        try {
            const result = await authService.checkRequestedRenewCode(userId, phoneNumber, authCode, ref_url)
            console.log(result)
            setIsAuthTrue(true)
            if(result.status===400) {
                toast.error('인증코드가 일치하지 않습니다.')
                return
            }
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
            setAuthCodeTime("인증완료")
            toast.success('인증이 완료되었습니다.')
            console.log(email, password)
            await login()
        } catch(err) {
            toast.error('다시 한번 시도해 주세요')
        }
    }

    const login = async () => {
        console.log('login...')
        /* 이메일 없으면 return */
        if (email.trim() === "") {
            console.log('empty email')
            // tog_center()
            toast.error('계정(이메일)을 입력해주세요.')
            return
        }

        if (password === "") {
            toast.error('비밀번호를 입력해주세요.')
            return
        }

        // console.log('login...22222')
        /* 로그인 */
        const resultRes = await authService.loginChatInLandingPage(email, password)
        console.log('resultRes', resultRes)
        /* 비밀번호 재인증이 필요한 경우, 사용자 정보가 맞지 않는 경우 */
        if (resultRes.status === 302) {
            // toast.warning("비밀번호 재인증이 필요합니다.")
            console.log('비밀번호 재인증이 필요합니다.')
            // window.alert("비밀번호 재인증이 필요합니다.")
            setIsRenew(true)
            setPhoneNumber("")
            setUserId(resultRes.data.user_id)
            return
        }
        else if(resultRes.status >= 400) {
            console.log('resultRes.status === 400')
            return
        }
        /*else {
            console.log('resultRes.status', resultRes.status)
            window.alert(resultRes.status)
        }*/

        const userInfo = await authService.getUserInfo(resultRes?.data?.user_id)
        props.changeUserInfo(userInfo)
    }

    return (
        <div className="loginCase02 caseview">
            {!isRenew ? (
                <>
                    <div className="form-group">
                        <Input
                            bsSize={'sm'}
                            type="email"
                            aria-describedby="emailHelp"
                            placeholder="계정(이메일)을 입력하세요."
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onKeyDown={e => e.key === "Enter" && login()}
                        />
                    </div>
                    <div className="form-group">
                        <Input
                            bsSize={'sm'}
                            type="password"
                            placeholder="비밀번호를 입력하세요."
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyDown={e => e.key === "Enter" && login()}
                        />
                    </div>

                    <div className="form-footer text-center">
                        <button
                            // href="index.html"
                            style={{backgroundColor:"#01a0fc", borderRadius:"10px !important", width:"140px"}}
                            className="btn btn-primary"
                            title=""
                            onClick={() => login()}
                        >
                            로그인
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <Alert color="warning">
                        휴대폰번호 재인증이 필요합니다.
                    </Alert>
                    {/* <p>비밀번호 재인증</p> */}
                    <FormGroup className="custom-form-group">
                        <InputGroup size="sm">
                            <Input
                                type="text"
                                aria-describedby="emailHelp"
                                placeholder="핸드폰번호를 입력하세요"
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                            <span className="input-group-append">
                                <Button
                                    className="widthAuto"
                                    color="primary"
                                    type="button"
                                    onClick={() => requestCodeFunction()}
                                >
                                    인증번호 발송
                                </Button>
                            </span>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="custom-form-group">
                        <InputGroup size="sm">
                            <Input
                                type="text"
                                placeholder="인증번호를 입력하세요."
                                value={authCode}
                                onChange={e => setAuthCode(e.target.value)}
                            />
                            <div className="CountNum d-flex align-items-center" style={{top: 0, bottom:0,}}>
                                <span>{authCodeTime}</span>
                            </div>
                            <span className="input-group-append">
                            <Button
                                className="widthAuto"
                                color="primary"
                                type="button"
                                onClick={() => checkRequestedCodeFunction()}
                            >
                                확인
                            </Button>
                        </span>
                        </InputGroup>
                    </FormGroup>
                </>
            ) }
        </div>
    )
}

export default withRouter(withTranslation()(AccountLogin))

AccountLogin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}
