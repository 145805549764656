import React, { useState } from "react"
import {
    Alert,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from "reactstrap"
// import { PubSub } from "aws-amplify"
import "../../../assets/css/myStyleChat.css"

import "../../../assets/css/chat/ChatRoom.scss"
import "../../../assets/css/fontAwesome.css"

// import "../../../assets/scss/theme.scss"
import AccountLogin from "./AccountLogin"
import classnames from "classnames"
import Login from "./Login"
// import './NewLogin.css'

export default function Auth(props) {
    const [verticalActiveTab, setverticalActiveTab] = useState("2")
    // const [isSimpleSignUp, setIsSimpleSignUp] = useState(false)
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
    }

    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // const updateLoginForm = (state) => {
    //     setIsSimpleSignUp(state)
    // }

    return (
        // <Row>
        //   <Col>
        <Card className="overflow-hidden noboard">
            {/*<div className="card-top d-flex" style={isSimpleSignUp ? { marginBottom: 10 } : { marginBottom: 40 }}>*/}
            <div className="card-top d-flex" style={{ marginBottom: 0, display:"flex", justifyContent:"center", alignItems:"center" }}>
                <img src="/images/img-login-ch.png" style={{ width:"80%" }} />
                {/* <div className="card-title">먼저 로그인 해주세요</div> */}
            </div>
            <CardBody className="pt-0" style={{ padding:"20px" }}>
                <div className="p-2">
                    <Nav className="justify-content-center">
                        <NavItem>
                            <NavLink
                                onClick={() => {
                                    toggleVertical("2")
                                }}
                                className={classnames({
                                    "font-weight-bold": verticalActiveTab === "2"
                                })}
                            >
                                <span>계정 로그인</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                onClick={() => {
                                    toggleVertical("1")
                                }}
                                className={classnames({
                                    "font-weight-bold": verticalActiveTab === "1"
                                })}
                            >
                                {/* {isSimpleSignUp &&
                                    <Alert
                                        color="success"
                                        className="alert alert-icon alert-success fs12"
                                        role={alert}
                                    >
                                        <span style={{ marginLeft: 20 }}>
                                        인증받은 휴대폰번호로 '간편 회원가입'을 진행하시겠습니까?<br />
                                        동의하시면 아래 '약관 동의'에 체크하신 후 '가입하기' 버튼을 클릭해 주세요.
                                    </span>
                                    </Alert>
                                } */}
                                <span>간편 로그인</span>
                            </NavLink>
                        </NavItem>
                    </Nav>

                    {isAlert && (
                        <Alert
                            color="success"
                            className="alert alert-icon alert-success fs12"
                            role={alert}
                        >
                            <i className="fe fe-alert-triangle"></i>
                            {/*{props.t(alertMessage)}*/}
                            <span style={{ marginLeft: 20 }}>{alertMessage}</span>
                        </Alert>
                    )}
                    <TabContent activeTab={verticalActiveTab}>
                        <TabPane tabId="1">
                            <AccountLogin
                                toggleVertical={toggleVertical}
                                setIsAlert={setIsAlert}
                                setAlertMessage={setAlertMessage}
                                // updateLoginForm={updateLoginForm}
                                changeAuthLayout={props.changeAuthLayout}
                                {...props}
                            />
                        </TabPane>
                        <TabPane tabId="2">
                            <Login setIsAlert={setIsAlert} setAlertMessage={setAlertMessage} {...props} />
                        </TabPane>
                    </TabContent>
                </div>
            </CardBody>
        </Card>
        //   </Col>
        // </Row>
    )
}
