import React, { useEffect, useState } from "react"

import "../../../assets/css/myStyleChat.css"
import "../../../assets/css/chat/ChatRoom.scss"
import "../../../assets/css/fontAwesome.css"
import "../../../assets/css/myChatSiderBar.css"
import QuestionContent from "./QuestionContent"
import Register from "./Register"
import Auth from "./Auth"
import AnswerContent from "./AnswerContent"
import AnswerTalk from "../AnswerTalk"
import "./NewLogin.css"

export default function ModalExample(props) {
    const [layout, setLayout] = useState(props?.layout)
    const [referer, setReferer] = useState('question')

    const changeAuthLayout = e => {
        // console.log('changeAuthLayout : ', e)
        setLayout(e)
    }
    const changeReferer = ref => {
        setReferer(()=>{return ref})
    }
    useEffect(() => {
        console.log(referer)
    }, [referer])

    return (
        <div id={props.idType} className="bottomView chat-side-bar-dashboard sidebar-round">
            <div className="d-flex justify-content-between brand_name">
                <div
                    className="brand-name mb-3 d-flex"
                    onClick={() => props.activeChange()}
                >
                    <div className=" d-flex">
                        <div className="btnRightCon2">
                            <i className="icon-close" style={{ cursor: "pointer" }}></i>
                        </div>
                        <span id="rightMenuTitle" className="ml-2">
                            {layout === "question" ? "공개톡 질문 미리보기" : layout ==="toRegister" ? "간편가입" : "공개톡 질문 미리보기"}
                        </span>
                    </div>
                </div>
            </div>
            {layout === "question" && (
                <QuestionContent changeAuthLayout={changeAuthLayout} changeReferer={changeReferer} {...props} />
            )}
            {/* {layout === "answer" && (
              <AnswerContent changeAuthLayout={changeAuthLayout} {...props} />
            )} */}

            {/*답변자 리스트에서 '질문하기' 클릭 시*/}
            {layout === "answer" && (
                <AnswerTalk changeAuthLayout={changeAuthLayout} changeReferer={changeReferer} {...props} />
            )}
            {layout === "auth" && (
                <Auth changeAuthLayout={changeAuthLayout} referer={referer} {...props} />
            )}
            {layout === "toRegister" && (
                <Register changeAuthLayout={changeAuthLayout} />
            )}
        </div>
    )
}
