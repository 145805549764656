import React, { useEffect, useState } from "react"
import "../../../../helpers/aws_client_iot"
import imgChatBag from "../../../../assets/images/chat/clock.png"
import imgPoint from "../../../../assets/images/chat/point.png"
import imgDiamond from "../../../../assets/images/imgbosuk01.png"
import imgRuby from "../../../../assets/images/imgbosuk02.png"

import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { toast } from "react-toastify"
import moment from "moment"

import {
    changeBlindFunction,
    leaveChatRoomFunction,
    maskAllMessageFunction,
} from "../helper/Functions/SupportFunctions"

export default function ChatHeader(props) {
    const { roomData, user, roomId } = props

    const [deleteChatModal, setDeleteChatModal] = useState(false)
    const [exitChatModal, setExitChatRoomModal] = useState(false)

    // message mask
    const maskAllMessage = async () => {
        let data = {
            userId: user.id,
            maskingTime: moment().toISOString(),
        }
        const response = await maskAllMessageFunction(roomId, data)
        if (response.message === "Created" || response.message === "Updated") {
            // toast.success('Room all message deleted');
            toast.success("채팅방 내 모든 메시지를 삭제했습니다.")
        } else {
            // toast.error('Message not deleted');
            toast.error("메시지가 삭제되지 않았습니다.")
        }
        setDeleteChatModal(false)
        props.refreshRoom()
        props.setMessageParam({'tempForRefresh':new Date().toISOString()})
    }

    // leave chat room
    const leaveChatRoom = async () => {
        let data = {
            userId: user.id,
            userName: user.nickname,
        }
        const response = await leaveChatRoomFunction(roomId, data)
        if (response.message === "Deleted") {
            toast.success("채팅방에서 나왔습니다.")
            props.exitChat()
        } else {
            toast.error("채팅방에서 나갈 수 없습니다.")
        }
    }

    // Profile
    const iconHandler = async () => {
        let data = {
            userId: user.id,
            isBlind: roomData?.roomData?.isBlind === "Y" ? "N" : "Y",
        }
        const response = await changeBlindFunction(roomId, data)

        if (response.message === "Updated") {
            props.headerChangeProfile()
        }
    }
    // console.log('roomData ', roomData)
    return (
        <div>
            <div className="chat-header d-flex align-items-center m-2">
                <div className="chat-header-buttons d-flex align-items-center">
                    <button
                        className="btn close-button-chat"
                        onClick={() => props.exitChat()}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/chat-close.png"})`,
                            backgroundRepeat: "no-repeat",
                        }}
                    ></button>
                    <div className="title ml-1">
                        {roomData?.ownerData?.userImg &&
                            <div className="profileImg mr-1">
                                <img
                                    src={roomData?.ownerData?.userImg}
                                    alt=""
                                />
                            </div>
                        }
                        {roomData?.ownerData?.userName} 의 공개톡
                    </div>
                </div>
                <div className="position-absolute" style={{ right: "2%" }}>
                    {/*새로 추가한 버튼(클릭하면 톡방 정보 미리보기 표시되는)*/}
                    {/*// TODO : 아이콘 교체해야 함*/}
                    <button
                        className="btn btn_flowing"
                        style={{ display: !props.collapsePreview ? "none" : "" }}
                        onClick={() => props.changeCollapsePreview()}
                    >
                        {/* <i className="la la-angle-down fs-11"></i> */}
                    </button>
                    <UncontrolledDropdown>
                        <DropdownToggle
                            className="bg-transparent b-none dropdown-toggle-button btn-menu"
                            style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL + "/menu.png"})`,
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            {/* <i className="la la-dots"></i> */}
                        </DropdownToggle>

                        <DropdownMenu dark>
                            {/* Profile */}
                            {roomData.isOwner && (
                                <DropdownItem onClick={() => iconHandler()}>
                                    채팅방 노출
                                    {roomData?.roomData?.isBlind === "Y" ? "ON" : "OFF"}
                                </DropdownItem>
                            )}
                            {/* exit room */}
                            {!roomData.isOwner && (
                                <DropdownItem onClick={() => setExitChatRoomModal(true)}>채팅방 나가기 </DropdownItem>
                            )}
                            {/* Msg all delete */}
                            <DropdownItem onClick={() => setDeleteChatModal(true)}>대화 내용 삭제</DropdownItem>
                            {/* Block users */}
                            {roomData.isOwner && (
                                <DropdownItem
                                    onClick={e => {
                                        props.onChangeMenu("blockeUsers")
                                    }}
                                >
                                    내보내기 목록
                                </DropdownItem>
                            )}
                            {/* Active users */}
                            <DropdownItem
                                className=""
                                onClick={e => {
                                    props.onChangeMenu("activeUserList")
                                }}
                            >
                                대화 상대 목록
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
            <div className="ChatOption d-flex align-items-center">
                <div>
                    <small className="d-block ">
                        <img src={imgChatBag} width={16} alt="" /> 남은 시간 {props.timer}
                    </small>
                </div>
                <div className="ml-auto">
                    <small className="d-block">
                        채택 보상
                        {roomData?.roomData?.rewardType === "point" ? (
                            <span className="chatPoint">
                                <img src={imgPoint} width={16} alt="" />{" "}
                            </span>
                        ) : roomData?.roomData?.rewardType === "dia" ? (
                            <span className="chatPoint">
                                <img src={imgDiamond} width={16} alt="" />{" "}
                            </span>
                        ) : (
                            <span className="chatPoint">
                                <img src={imgRuby} width={16} alt="" />{" "}
                            </span>
                        )}
                        {roomData?.roomData?.rewardCount || ""}
                    </small>
                </div>
            </div>
            <Modal returnFocusAfterClose={true} isOpen={exitChatModal} centered={true}>
                <ModalBody className="text-center">
                    톡방을 나가시면 톡 내용이 모두 <br /> 사라지고 톡목록도 사라집니다.
                    <br />
                    톡방을 나가시겠습니까?.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setExitChatRoomModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => leaveChatRoom()}>
                        나가기
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal returnFocusAfterClose={true} isOpen={deleteChatModal} centered={true}>
                <ModalBody className="text-center">
                    대화 내용을 삭제하시겠습니까? <br /> 현재 톡방의 이전 모든 대화
                    <br />
                    내용이 삭제됩니다.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setDeleteChatModal(false)}>
                        취소
                    </Button>
                    <Button color="primary" onClick={() => maskAllMessage()}>
                        삭제
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
