import React, {useCallback, useEffect, useState, useMemo} from 'react';
import _ from 'lodash';
import {
    Container,
    Row, Col,
    Card, CardBody,
    Spinner,
} from 'reactstrap';
import { authService } from 'services/auth.service';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import SettingForm from './SettingForm';
import "../../assets/css/style.css";

import { uploadImageService } from "services/talk2meMessage.service";
import {userService} from 'services/user.service';

import analytics from 'utils/analytics';

const SettingUserInfo = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState();

    const history = useHistory();
    const getUserId = useCallback(async () => {
        try {
            setIsLoading(true);

            const userId = await authService.getUserId().then(r => {
                const res = r?.response || r;
                if (res?.status > 201) throw res;
                return res?.data?.user_id;
            });

            return await authService.getUserInfo(userId, true).then(r => {
                const res = r?.response || r;
                if (res?.status > 201) throw res;
                return res?.data;
            })
        } catch (e) {
            const error = e?.response || e;
            if (error?.status === 403) {
                toast.error('로그인이 필요한 페이지입니다.');
                history.push('/login');
                return false;
            }

            toast.error(error?.data?.place_holder || '회원 정보를 불러오는데 실패했습니다. 새로고침 해 보세요.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getUserId().then(r => {
            setUserInfo(r);
        });
    }, [])

    const userInfoMemo = useMemo(() => {
        return userInfo
    }, [userInfo]);

    const requestPasswordRenew = useCallback(async (email) => {
        try {
            if (!email) return;
            await authService.emailSend(email);
        } catch (e) {
            console.error(e);
        }
    }, []);

    const updatePatchedData = (data) => {
        setUserInfo(data);
        sessionStorage.setItem('user_data', JSON.stringify(data));
    }

    const updateUserImage = useCallback(async (formData) => {
        const userId = userInfo?.id;
        if (!userId) return;
        try {
            const imageUrl = await uploadImageService(formData).then(res => {
                if (res?.msg === 'OK') {
                    // authService.u
                    return res?.url[0];
                } else {
                    const err = new Error(res?.msg);
                    err.place_holder = res?.msg;
                    throw err;
                }
            });

            const updateImageRes = await userService.uploadUserImg(userId, imageUrl).then(res => {
                if (res?.image_url && res?.image_url?.length > 0) return res.image_url;
            });

            const resultUrl = updateImageRes[0];

            const nData = Object.assign({}, userInfo);
            nData.image_url = resultUrl;
            updatePatchedData(nData);
            toast.success('프로필 이미지가 변경되었습니다.');

            return nData;
        } catch (e) {
            if (e?.data?.place_holder || e?.place_holder) {
                toast.error(e?.data?.place_holder || e?.place_holder);
            } else {
                toast.error('이미지 업로드에 실패했습니다.');
            }

            console.error(e);
        }
    }, [userInfo]);

    const patchUserInfo = useCallback(async (payload) => {
        const userId = userInfo?.id;
        if (!userId) return;

        try {
            if (payload?.email) {
                await authService.patchUserEmail(userId, payload?.email?.trim()).then(res => {
                    const nData = Object.assign({}, userInfo);
                    nData.email = payload.email;
                    updatePatchedData(nData);

                    toast.success('등록하신 이메일 주소로 비밀번호 설정 이메일을 발송했습니다. \n비밀번호를 설정하시면 계정 로그인을 이용하실 수 있습니다.', {
                        autoClose: false,
                    })
                });
            }

            const patchKeys = ['gender', 'age_group'];
            let checkKeys = false;
            patchKeys.forEach(item => {
                if (_.has(payload, item)) checkKeys = true;
            })
            if (!checkKeys) return;

            const patchedUserInfo = await authService.patchUserProfile(userId, payload);
            console.log('patchedUserInfo', patchedUserInfo)

            updatePatchedData(patchedUserInfo);
            toast.success('프로필 정보가 변경되었습니다.');

            return userInfo;
        } catch (e) {
            if (e?.data?.place_holder) {
                toast.error(e?.data?.place_holder);
            } else {
                console.log('실패?', e);
                toast.error('프로필을 업데이트 하는데 실패했습니다.');
                throw e;
            }
        }
    }, [userInfo]);

    return (
        <>
            <section className="py-lg-5 py-sm-0 py-0">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="filters pb-4">
                                <h2 className="listTitle mr-1">
                                    <b>정보</b> 설정
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={8} sm={10} style={{maxWidth: 600,}}>
                            <Card className="card-item">
                                <CardBody>
                                    {isLoading ? (
                                        <CardBody>
                                            <div className="text-center">
                                                <Spinner color={'primary'} style={{
                                                    width: 90,
                                                    height: 90,
                                                }} />
                                            </div>
                                            <div className="text-center mt-3">
                                                <b>회원 정보를 불러오는 중입니다.</b>
                                            </div>
                                        </CardBody>
                                    ) : (
                                        <SettingForm userInfo={userInfoMemo}
                                                     changeProfile={patchUserInfo}
                                                     resetPass={requestPasswordRenew}
                                                     updateImage={updateUserImage} />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SettingUserInfo;