import {Fade} from 'reactstrap';
import {useState, useEffect, memo} from 'react';
import propTypes from 'prop-types';
import './FormModalContainer.scoped.scss';

import closeIcon from '../../assets/images/chat-close.png';

const Header = (props) => {
    const {close} = props;
    return (
        <div className="d-flex">
            <div className="spacer" style={{flexGrow: 1}} />
            <button className="close-btn" onClick={close}>
                <img src={closeIcon} alt=""/>
            </button>
        </div>
    )
}

const FormModalContainer = ({fade, close, onClosed, header, footer,  ...props}) => {
    const [viewMode, setViewMode] = useState(false);

    const onExitAction = async () => {
        if (onClosed && typeof(onClosed) === 'function') {
            await onClosed();
        }
        setViewMode(false);
    }

    useEffect(() => {
        if (fade) setViewMode(true);
    }, [fade])

    return (
        <Fade in={fade} onExited={onExitAction}
              className="flowing-ui--chat-modal__fader">
            {viewMode && (
                <div className="flowing-ui--chat-modal__wrapper">
                    <div className="flowing-ui--chat-modal__container">
                        <section className="flowing-ui--chat-modal__base">
                            <header className="flowing-ui--chat-modal__header">
                                {header ? header : (
                                    <Header close={close} {...props} />
                                )}
                            </header>
                            <section className="flowing-ui--chat-modal__body">
                                {props.children}
                            </section>
                            <footer className="flowing-ui--chat-modal__footer">
                                {footer ? footer : (<></>)}
                            </footer>
                        </section>
                    </div>
                </div>
            )}
        </Fade>
    )
}

FormModalContainer.prototype = {
    fade:propTypes.bool,
    close: propTypes.func,
    onClosed: propTypes.func,

    header: propTypes.element,
    footer: propTypes.element,
}

export default memo(FormModalContainer);