import React, { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { Button, Fade, Spinner } from "reactstrap"
import _, { map } from "lodash"
import "../../../../assets/css/myStyleWinner.css"

// Helper functions
import {
    addMessageFunction,
    selectFileFunction
} from "../helper/Functions/ChatFunctions"
import { rateReactionFunction } from "../helper/Functions/SupportFunctions"
// import components
import Info from "../chatComponents/Info"
import ChatLeft from "../chatComponents/ChatLeft"
import ChatRight from "../chatComponents/ChatRight"
import ChatFooter from "../chatComponents/ChatFooter"
import WinnerModal from "../chatComponents/WinnderModal"
import Rating from "../chatComponents/Rating"
import moment from "moment/moment"
import { useInView } from "utils/customHooks"

export default function ChatContent(props) {
    // props values
    const { messages, loading } = props

    // Ref
    const messagesEndRef = React.createRef()
    const messagesStartRef = React.useRef()
    const lastShowingMsg = React.useRef('')
    const messageRefs = React.useRef({});
    const setMessageRefs = (key, el) => {
        messageRefs[key] = el;
    }

    // Container
    const [loadingFile, setLoadingFile] = useState()

    // Tool tip
    const [targetCopyText, setTargetCopyText] = useState()
    const [selectedAvatarMsgId, setSelectedAvatarMsgId] = useState()
    const [selectedMsgId, setSelectedMsgId] = useState()
    const [screenAction, setScreenAction] = useState()
    const [rateAction, setRateAction] = useState()


    // Tool tip
    const [isTopShow, setIsTopShow] = useState(true);
    const [isBottomShow, setIsBottomShow] = useState(true);
    const [isBeforeLoading, setIsBeforeLoading] = useState(false);


    const [observeTop, unobserveTop] = useInView((isShow) => {
        console.log('%c top-view','color:red;font-weight:bold;' , isShow)
        setIsTopShow(isShow);
    });

    const [observeBottom, unobserveBottom] = useInView((isShow) => {
        console.log('%c bottom-view','color:red;font-weight:bold;' , isShow);
        setIsBottomShow(isShow);
    });

    const checkLastShow = () => {
        if (isBottomShow && messages?.length > 0) {
            lastShowingMsg.current = messages[messages.length - 1]?.messageId;
        }
    }

    // useEffect(() => {
    //     console.warn('isBottomShow:::', observeBottom)
    // }, [observeBottom])

    const getMoreBefore = async () => {
        setIsBeforeLoading(true);
        if (isBeforeLoading) return;
        try {
            const anchorId = messages[0]?.messageId || messages[0]?.instanceId;

            console.warn(messageRefs[anchorId])
            const anchor = messageRefs[anchorId];

            const {getMore} = props;
            if (getMore && typeof getMore === 'function') await getMore().then(r => r);

            console.log('anchor', anchor);
            if (anchor) anchor.scrollIntoView(true);
        } catch (e) {
            console.error(e);
        } finally {
            setIsBeforeLoading(false);
        }
    }


    useEffect(() => {
        if (isTopShow && !isBottomShow) {
            // before data loading event
            // Todo: 현대 불러와 있는 데이터 이전의 데이터를 현재 데이터의 앞단에 가져와 뿌려준다. 스크롤 관련 스코프가 미지수
            getMoreBefore().then(r => r);
        }

        checkLastShow();
    }, [isTopShow, isBottomShow])

    const unShowMessageCount = useMemo(() => {
        const lastShowId = lastShowingMsg.current;
        if (!lastShowId) return false;

        const idx =  _.findLastIndex(messages, ['messageId', lastShowId]);
        const len = messages?.length || 0;

        return len - idx - 1;
    }, [messages, lastShowingMsg.current]);

    useEffect(() => {
        if (props.isInit) {
            observeTop(messagesStartRef.current);
            observeBottom(messagesEndRef.current);
        }

        if (!isBottomShow && props.isInit) return;
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });

        console.log('%c messages','color:blue;' , {messages, isBottomShow})
        checkLastShow();
    }, [messages])

        /**R
     * @param isForced {boolean} 스크롤 여부와 관계 없이 강제로 스크롤을 최신화
     * @param behavior {('smooth' | 'auto')} 스크롤 애니메이션 작동방식
     */
    const scrollActivateControl = (isForced, behavior) => {
        if (!isBottomShow && isForced !== true) return;
        messagesEndRef.current?.scrollIntoView({ behavior: isForced === true && !behavior ? 'auto' : behavior || 'smooth' });
    }

    // Send new message
    const addMessage = async message => {
        // console.log('Message : ', message);
        if(message) { // 메시지가 있을 경우에만 전송
            let meHashCode = Date.now().toString()
            props.changeMessage({ meHashCode })
            scrollActivateControl(true);
            let data = await addMessageFunction(meHashCode, props, "TEXT", message)
            if (!data.status) {
                toast.error(data.message)
                return
            }
            returnData(data)
        }
    }

    // File upload
    const selectFileAction = async event => {
        setLoadingFile(true)
        let meHashCode = Date.now().toString()
        let data = await selectFileFunction(meHashCode, props, event).then(res => {
            console.log(res)
            return res
        }).catch(e=>{console.error('selectFileFunction-err', e)})
        setLoadingFile(false)
        if (!data.status) {
            toast.error(data.message)
            return
        }
        returnData(data)
    }

    // Props return to data
    const returnData = data => {
        props.changeMessage({
            limit: data.limit && data.limit,
            meHashCode: data.meHashCode && data.meHashCode
            // messages: data.message &&
            //   (messages ? [...messages, data.message] : [data.message]),
        })
    }

    const selectedMessageAction = async value => {
        setScreenAction("tool_tip")
        if (value.action === "DELETE") setSelectedMsgId()
        if (value.action === "MESSAGE_CLICK") setSelectedMsgId(value.messageId)
        if (value.action === "AVATAR") setSelectedAvatarMsgId(value.messageId)
        if (value.action === "BLOCK") setSelectedAvatarMsgId()
        if (value.action === "RATE_ACTION")
            setRateAction({
                action: true,
                messageId: value.messageId
            })
        if (value.action === "COPY") {
            navigator.clipboard.writeText(value.copyText)
            setTargetCopyText(value.copyText)
        }
    }

    // Message rating
    const setRateReaction = async rate => {
        let datas = await rateReactionFunction(props.roomId, rateAction.messageId, {
            rate
        })
        if (datas.message) {
            let msgIndex = _.findIndex(messages, {
                messageId: rateAction.messageId
            })
            let msgData = _.find(messages, function(o) {
                return o.messageId === rateAction.messageId
            })
            if (msgIndex !== -1 && msgData) {
                msgData.rate = rate
                let newArr = [...messages]
                newArr[msgIndex] = msgData
                props.setMessage(newArr)
            }
            setRateAction()
        }
    }

    // Chat box scroll to bottom
    useEffect(() => {
        if (props.isInit) {
            observeTop(messagesStartRef.current);
            observeBottom(messagesEndRef.current);
        }

        if (!isBottomShow && props.isInit) return;
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });

        console.log('%c messages','color:blue;' , {messages, isBottomShow})
        checkLastShow();
    }, [messages])

    return (
        <div>
            <div ref={messagesStartRef} className='text-center'>
                <Fade in={isBeforeLoading} className={'py-3'}
                      onEnter={(ev) => {ev.style.display='block'}}
                      onExit={(ev) => {ev.style.display='none'}} style={{display: 'none',}}>
                    <Spinner size={'sm'} style={{borderWidth:1, color: 'var(--primary, #01a0fc)',}} />
                </Fade>
            </div>
            {loading ? (
                <Spinner type="grow" color="warning" />
            ) : (
                <div
                    className="chat-body-messages"
                    onClick={() => {
                        if (screenAction === "tool_tip") {
                            setScreenAction("screen")
                            setSelectedAvatarMsgId()
                            setSelectedMsgId()
                        }
                    }}
                >
                    {messages &&
                        map(
                            messages,
                            (message, index) =>
                                message.isActivated > -1 &&
                                (message.senderId === props.user.id && message.msgType !== "INFO" ? (
                                    <div key={message?.messageId || message?.instanceId} ref={(el)=>{
                                        setMessageRefs(message?.messageId || message?.instanceId, el)
                                    }}>
                                    <ChatRight
                                        key={index}
                                        {...props}
                                        myMsg={true}
                                        message={message}
                                        selectedMsgId={selectedMsgId}
                                        selectedMessageAction={value => {
                                            selectedMessageAction(value).then(r => r)
                                        }}
                                        idx={index}
                                        completedAction={scrollActivateControl}
                                    />
                                    </div>
                                ) : message.msgType === "INFO" ? (
                                    <div key={message?.messageId || message?.instanceId} ref={(el)=>{
                                        setMessageRefs(message?.messageId || message?.instanceId, el)
                                    }}>
                                        <Info key={index} {...props} message={message} />
                                    </div>
                                ) : props.roomData.isOwner && message.senderId === "BOT" ? (

                                    <div key={message?.messageId || message?.instanceId} ref={(el)=>{
                                        setMessageRefs(message?.messageId || message?.instanceId, el)
                                    }}>
                                    <ChatRight
                                        key={index}
                                        {...props}
                                        myMsg={true}
                                        message={message}
                                        selectedMsgId={selectedMsgId}
                                        selectedMessageAction={value => {
                                            selectedMessageAction(value).then(r => r)
                                        }}
                                        idx={index}
                                        completedAction={scrollActivateControl}
                                    />
                                    </div>
                                ) : (
                                    <div key={message?.messageId || message?.instanceId} ref={(el)=>{
                                        setMessageRefs(message?.messageId || message?.instanceId, el)
                                    }}>
                                    <ChatLeft
                                        key={index}
                                        {...props}
                                        myMsg={false}
                                        message={message}
                                        selectedMsgId={selectedMsgId}
                                        selectedMessageAction={value => {
                                            selectedMessageAction(value).then(r => r)
                                        }}
                                        selectedAvatarMsgId={selectedAvatarMsgId}
                                        idx={index}
                                        completedAction={scrollActivateControl}
                                    />
                                    </div>
                                ))
                        )}
                    {loadingFile && <Spinner type="grow" color="warning" />}
                    <div ref={messagesEndRef} />
                </div>
            )}

            <Fade in={!isBottomShow}>
                {!isBottomShow && (
                    <Button className={`scroll-bottom-button ${unShowMessageCount > 0 ? 'display-count' : ''}`}
                            onClick={() => {scrollActivateControl(true, 'smooth')}}>
                        <div className="unseen-count">{unShowMessageCount}</div>
                        <i className="fa fa-chevron-down" />
                    </Button>
                )}
            </Fade>
            {props.deadline && (
                <ChatFooter
                    {...props}
                    targetCopyText={targetCopyText}
                    selectFileAction={e => selectFileAction(e)}
                    addMessage={value => addMessage(value)}
                />
            )}

            {props.winnerModal && <WinnerModal winnerMessage={props.winnerMessage} />}

            {rateAction && (
                <Rating
                    setRateAction={() => setRateAction(false)}
                    setRateReaction={value => setRateReaction(value)}
                />
            )}
        </div>
    )
}
