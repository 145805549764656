export const genderSelection = [
    {
        label: '남자',
        value: 'm',
    },
    {
        label: '여자',
        value: 'f',
    },
];

export const agesSelection = [
    {
        label: '10대',
        value: '10',
    },
    {
        label: '20대',
        value: '20',
    },
    {
        label: '30대',
        value: '30',
    },
    {
        label: '40대',
        value: '40',
    },
    {
        label: '50대',
        value: '50',
    },
    {
        label: '60대 이상',
        value: '60',
    },
];

export default {
    genderSelection, agesSelection,
}