import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import Pagination from "reactstrap"

// import css
import "../../assets/css/style.css"

// import services
import { getMyRooms } from "../../services/chatRoomList.service"

// Import components
import AnswerChatRoom from "./AnswerChat/ChatContainerModal"
import QuestionChatRoom from "./QuestionChat/ChatContainerModal"
import ChatRoomHeader from "./Chatroom/ChatRoomHeader"
import ChatRoomCol from "./Chatroom/ChatRoomCol"
import ChatRoomTableHeader from "./Chatroom/ChatRoomTableHeader"
import { authService } from "services/auth.service"

const ChatRoomList = () => {
    const [room, setRoom] = useState([])
    const [roomType, setRoomType] = useState([])
    const [chatRoomList, setChatRoomList] = useState([])
    const [user, setUserInformation] = useState()
    const [userId, setUserId] = useState(null)

    //페이지네이션 추가 kms add 20221026
    const [totalRecordCount, setTotalRecordCount] =useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(5);//페이지당 표현되는 리스트의 갯수
    const [pageSize, setPageSize] = useState(5); //페이지네이션의 갯수 
    let offset=(currentPage -1) * limit ;
    let lastPage = Math.ceil(totalRecordCount / limit);
    let currentPageUnit = Math.ceil(currentPage / pageSize) ;


  const [searchValue, setSearchValue] = useState('')

    // const userId = localStorage.getItem("user_id") || ''

    const openChatRoom = value => {
        setRoomType(value.type)
        setRoom(value.item)
    }

    const getRoomList = async (userId) => {
        try {
            await getMyRooms({userId: userId}, offset, limit, searchValue).then(r => {
                console.log(r)
                if(r.status===200) {
                    setChatRoomList(r.data.itemList)
                    setTotalRecordCount(r.data.totalRecordCount*1)
                } else if(r.status===204) {
                    setChatRoomList([])
                    setTotalRecordCount(0)
                } else throw r
            })
        } catch(err) {
            setChatRoomList([])
            setTotalRecordCount(0)
            throw err
        }
    }

    async function getUserInfo() {
        try {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserId(res.data.user_id)
                const response = await authService.getUserInfo(res.data.user_id)
                setUserInformation(response)
            }
            await getRoomList(res.data.user_id).then(r=>r)
            console.warn(totalRecordCount)
        } catch(err){
        }
        //console.log("rooms", rooms.itemList)
    }

    useEffect(() => {
        getUserInfo().then(r => r)
    }, [currentPage])

    document.title = "답변톡 플로잉"

    // console.log('chatRoomList', chatRoomList)
    return (
        <React.Fragment>
        <div className="page-content">
            <ChatRoomHeader
                getUserInfo={getUserInfo}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
            />
            <section className="job-area nopt">
            <Container>
                <Row>
                <Col lg={12}>
                    <div className="jobs-main-bar mb-50px">
                    <div className="jobs-snippet">
                        <table className="table table-hover table-vcenter text-nowrap table_custom  fs-14">
                        <ChatRoomTableHeader />
                        <tbody>
                            {chatRoomList &&
                            chatRoomList.map((item, index) => {
                                return (
                                <ChatRoomCol
                                    key={index}
                                    item={item}
                                    userId={userId}
                                    index={index}
                                    type={
                                    item.roomType === "talk2you"
                                        ? "answer"
                                        : "question"
                                    }
                                    openChatRoom={value => openChatRoom(value)}
                                    offset = {offset}
                                    limit ={limit}
                                    totalRecordCount={totalRecordCount}
                                />
                                )
                            })}

                            {/* No Content */}
                            {totalRecordCount === 0 && (
                            <tr className="text-center">
                                <td colSpan="9">
                                <p className="noResult">
                                    일치하는 톡방 결과가 없습니다.
                                </p>
                                </td>
                            </tr>
                            )}

                        </tbody>
                        </table>
                    </div>
                    </div>
                </Col>
                </Row>
                <tbody>
                <tr className="pager pt-10px pb-20px">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center generic-pagination pr-1">
                            <li className="page-item">
                                <a 
                                    className="page-link"  
                                    aria-label="Previous"
                                    onClick={()=>{setCurrentPage(1);}}
                                >
                                    <span aria-hidden="true">
                                        <i className="las la-angle-double-left"></i>
                                    </span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            <li className="page-item">
                                <a 
                                    className="page-link" 
                                    aria-label="Previous"
                                    onClick={()=>{
                                        if( ((currentPageUnit -1 )*pageSize ) >= 1){
                                            setCurrentPage(((currentPageUnit -1 )*pageSize )); 
                                        }
                                        
                                    }}
                                >
                                    <span aria-hidden="true">
                                        <i className="las la-angle-left"></i>
                                    </span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>
                            {[...Array(pageSize)].map((page, i) => {
                                if(((currentPageUnit -1 )*pageSize + i+1) <= lastPage){
                                    return(
                                        <li className={`page-item ${((currentPageUnit -1 )*pageSize + i + 1) === currentPage?"active":""}`}>
                                        <a 
                                            className="page-link" 
                                            onClick={()=>{setCurrentPage(((currentPageUnit -1 )*pageSize + i+1)); }}
                                        >{((currentPageUnit -1 )*pageSize + i+1)}
                                        </a>
                                    </li>
                                    )
                                }
                                
                            })}
                            <li className="page-item">
                                <a 
                                    className="page-link" 
                                    aria-label="Next"
                                    onClick={()=>{
                                        if(currentPageUnit*pageSize+1 <= lastPage)
                                        setCurrentPage(currentPageUnit*pageSize+1);
                                    }}
                                >
                                    <span aria-hidden="true">
                                        <i className="las la-angle-right"></i>
                                    </span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                            <li className="page-item">
                                <a 
                                    className="page-link"  
                                    aria-label="Next"
                                    onClick={()=>{setCurrentPage(lastPage);}}
                                >
                                    <span aria-hidden="true">
                                        <i className="las la-angle-double-right"></i>
                                    </span> 
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>

                </tr>
            </tbody>
            </Container>

            {roomType === "answer" && (
                <AnswerChatRoom
                    roomId={room.roomId}
                    user={user}
                    room={room}
                    isHeader={true}
                    exitChat={() => setRoomType()}
                />
            )}
            {roomType === "question" && (
                <QuestionChatRoom
                    roomId={room.roomId}
                    user={user}
                    room={room}
                    exitChat={() => setRoomType()}
                />
            )}
            </section>
        </div>
        </React.Fragment>
    )
}

export default ChatRoomList
