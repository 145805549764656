import PropTypes from "prop-types"
import React, { useState,useEffect } from "react"

import { Switch, BrowserRouter as Router, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import analytics from 'utils/analytics';

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

import {authService} from './services/auth.service';
import {useIdleTimer} from 'react-idle-timer';

import ScreenOperator from './pages/LandingPage/ScreenOperator';

// Import scss

import "./assets/css/bootstrap.min.css"
import "./assets/scss/style.scss"
import "./assets/css/line-awesome.css"
import "./assets/css/AddStyle.css"
import axios from "axios"
import config from "config/configuration"
import store from "store"

const App = props => {
    const [checkedSession, setCheckedSession] = useState(false);
    const location = useLocation();

    // 배포환경에 따른 로그 출력 Override
    const env = process.env.REACT_APP_SET_ENV || 'DEV'
    if(env === 'PRD'){
        if(!window.console) window.console = {};
        const methods = ["log", "debug", "warn", "info"]
        for(let i=0; i<methods.length; i++){
            console[methods[i]] = function(){};
        }
    }

    useEffect(() => {
        console.log('%c app-rendered-changed-checkedSession', 'background: green;', checkedSession);
        const callGetToken = async () => {
            try {
                if (checkedSession) return;

                const accessToken = await authService.getACToken().then(res => {
                    if (res?.status !== 201) throw new Error('session-out');
                    return res?.data?.access_token;
                })

                sessionStorage.setItem("access_token", accessToken);
                const userId = await authService.getUserId().then(res => {
                    if (!res?.data?.user_id) throw res;
                    return res?.data?.user_id;
                })

                await authService.getUserInfo(userId);
            } catch (err) {
                console.log('setCheckedSession-err', err);
                sessionStorage.removeItem('access_token');
                sessionStorage.removeItem('user_data');
                setCheckedSession(true);
            }

        }
        callGetToken().then(r => {
            setCheckedSession(true);
        })
    }, [checkedSession])

    useEffect(() => {
        // Respond to the `storage` event
        function storageEventHandler(event) {
            if (event.key === 'session-trigger') {
                if (event.newValue === 'changed') {
                    setCheckedSession(false);
                    localStorage.removeItem('session-trigger');
                }
            }
        }
        // Hook up the event handler
        window.addEventListener('storage', storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener('storage', storageEventHandler);
        };
    }, [])

    useEffect(() => {
        if (window.location.pathname === "/product") {
            document.body.classList.add("bgGray")
        } else if (window.location.pathname === "/answerTalk") {
            document.body.classList.add("blueBg")
            document.body.classList.remove("bgblue")
        } else {
            document.body.classList.remove("blueBg")
            document.body.classList.remove("bgblue")
        }
    }, [window.location.pathname])

    useEffect(() => {
        analytics.pageView(location);
    }, [location])

    function getLayout() {
        let layoutCls = HorizontalLayout
        return layoutCls
    }

    const Layout = getLayout()
    if (checkedSession) {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                exact
                            />
                        ))}

                        {authProtectedRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={Layout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                exact
                            />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        )
    } else {
        return (
            <ScreenOperator message="Hello, We are flowing..." />
        )
    }
}

App.propTypes = {
    layout: PropTypes.any
}

const mapStateToProps = state => {
    return {
        layout: state.Layout
    }
}

export default connect(mapStateToProps, null)(App)
