import { withTranslation } from "react-i18next"
import { Modal } from "reactstrap"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import "../../../assets/css/style.css"
import "react-toastify/dist/ReactToastify.css"

import { authService } from "../../../services/auth.service"
import { toast } from "react-toastify"

const Login = props => {
    document.title = "답변톡 플로잉"

    const [modal_center, setmodal_center] = useState(false)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")

    const login = async () => {
        props.setIsAlert(() => false)
        // if (!email && !password) {
        //     return tog_center()
        // }
        if (email.trim()===''||password.trim()===''){
            toast.error('이메일 혹은 비밀번호를 입력해 주세요')
            props.setAlertMessage(() => '이메일 혹은 비밀번호를 입력해 주세요')
            props.setIsAlert(() => true)
            return;
        }
        // const result = await authService.loginChat(email, password)
        // // console.log(result, "result")
        // // if (result.status === 200) { // TODO : 이 코드 구간은 절대 실행되지 않는다.

        // if(result.status === 302) {
        //     sessionStorage.setItem('renewUserId', result?.data?.user_id);
        //     window.alert("번호재인증이 필요하여 재인증 화면으로 이동합니다.");
        //     props.history.push("/registerRenew");

        // }

        // if(result.user_id && result.user_secret) {
        //     // props.changeAuthLayout("question")
        //     props.changeAuthLayout(props.referer || 'question')
        // }
        try {
            const result = await authService.newSignIn({email, password}, 'chat').then(r => {
                return r
            })
            console.log(result)
            props.changeAuthLayout(props.referer || 'question')
        } catch(error) {
            console.log(error)
            if(error?.status === 302) {
                sessionStorage.setItem('renewUserId', error?.data?.user_id);
                sessionStorage.setItem('renewUserEmail', email);
                window.alert("이메일 재인증이 필요하여 재인증 화면으로 이동합니다.");
                props.history.push("/registerRenew");
                return
            }
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }
    }

    // function tog_center() {
    //     setmodal_center(!modal_center)
    // }

    return (
        <div className="loginCase02 caseview">
            <div className="form-group">
                <input
                    type="email"
                    className="form-control form-round-height"
                    id="InputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="계정(이메일)을 입력하세요."
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={e => e.key === "Enter" && login()}
                />
            </div>
            <div className="form-group">
                <input
                    type="password"
                    className="form-control form-round-height"
                    id="InputPassword1"
                    placeholder="비밀번호를 입력하세요."
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={e => e.key === "Enter" && login()}
                />
            </div>

            <div className="form-footer text-center">
                <button
                    // href="index.html"
                    style={{backgroundColor:"#01a0fc", borderRadius:"10px !important", width:"140px"}}
                    className="btn btn-primary"
                    title=""
                    onClick={login}
                >
                    {/*Log in*/}로그인
                </button>
            </div>
            {/* <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center()
                }}
                centered
            >
                <div className="modal-body">
                    <p>
                        해당 휴대폰 정보와 닉네임이 존재하지 않습니다. 바로 가입하고
                        진행하시겠습니까?
                    </p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setmodal_center(false)}
                    >
                        취소
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            props.changeAuthLayout("toRegister")
                            setmodal_center(false)
                        }}
                    >
                        확인
                    </button>
                </div>
            </Modal> */}
        </div>
    )
}

export default withRouter(withTranslation()(Login))

Login.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}
