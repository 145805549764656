import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Alert, Nav, NavItem, NavLink } from "reactstrap"
import "../../assets/css/style.css"
// import "../../assets/css/AddStyle.css"
//redux
import { withRouter, Link } from "react-router-dom"

//import service
import { authService } from "../../services/auth.service"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import classnames from "classnames"
import { render } from "@testing-library/react"

const RegisterSimple = props => {
    //meta title
    document.title = "답변톡 플로잉"
    const [mobilePhone, setMobilePhone] = useState(props.userPhoneNumber)
    const [verification, setVerification] = useState(false)
    const [name, setName] = useState(props.userNickname)
    const [checkedName, setCheckedName] = useState(props.userNickname)
    const [authCode, setAuthCode] = useState()
    const [ref_url, setRefUrl] = useState("")
    const [isAgreed, setIsAgreed] = useState(false)

    const signUpSimple = async () => {
        if (!/^([a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]).{2,7}$/.test(name)) {
            toast.error("닉네임은 3~8자로 한글, 영어, 숫자만 사용 가능합니다.")
            return
        }

        if (!isAgreed) {
            document.getElementById("agree_to_terms").focus()
            toast.error("약관에 동의해 주세요.")
            return
        }

        try {
            if (authCode || verification) {
                let body = { phone_number: mobilePhone, nickname: name }
                /*let result = await authService.signUpSimple(body)
                console.log(result, "signup")
                if (result) {
                    props.setIsLogin()
                    // props.history.push("/login")
                }*/
                await authService.signUpSimple(body, "/main")
            }
        } catch (err) {
            console.log(err, "err")
        }
    }

    const requestCodeFunction = async () => {
        const result = await authService.requestCode(mobilePhone)
    }

    const checkRequestedCodeFunction = async () => {
        const result = await authService.checkRequestedCode(mobilePhone, authCode, ref_url)
        if (result.status === 201) {
            setAuthCode(true)
        }
    }

    useEffect(() => {
        if (props.userPhoneNumber && props.userNickname) {
            setVerification(true)
        }
    }, [])

    return (
        // <ToastContainer />
        <div className="card">
            <div className="card-top d-flex">
                <div className="card-title">
                    {/*Create your account*/}플로잉 간편 가입
                    {/*<h2>플로잉 간편 가입</h2>*/}
                </div>
            </div>

            <Alert color="success" className="alert alert-icon alert-success fs12" role={alert}>
                <i className="fe fe-alert-triangle"></i>
                <span style={{ marginLeft: 20 }}>
                    인증받은 휴대폰번호로 '간편 회원가입'을 진행하시겠습니까?
                    <br />
                    동의하시면 아래 '약관 동의'에 체크하신 후 '가입하기' 버튼을 클릭해 주세요.
                </span>
            </Alert>

            <div className="card-body">
                <div className="form-group">
                    <div className="input-group">
                        <input
                            type="text"
                            id="InputNumber"
                            className="form-control blackBox"
                            placeholder="휴대폰 번호를 입력하세요."
                            value={mobilePhone || ""}
                            onChange={e => setMobilePhone(e.target.value)}
                        />
                        {!mobilePhone && (
                            <span className="input-group-append">
                                <button
                                    className="btn btn-primary widthAuto"
                                    type="button"
                                    onClick={requestCodeFunction}
                                >
                                    인증번호 발송
                                </button>
                            </span>
                        )}
                    </div>
                </div>
                {!mobilePhone && (
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                type="text"
                                id="InputNumberCheck"
                                className="form-control blackBox"
                                placeholder="인증번호를 입력하세요."
                                value={authCode}
                                onChange={e => setAuthCode(e.target.value)}
                            />
                            <span className="CountNum">1:00</span>
                            <span className="input-group-append">
                                <button
                                    className="btn btn-primary widthAuto"
                                    type="button"
                                    onClick={checkRequestedCodeFunction}
                                >
                                    확인
                                </button>
                            </span>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control blackBox"
                        id="InputNickname"
                        aria-describedby="emailHelp"
                        placeholder="닉네임을 입력하세요. (최대 10자 이내)"
                        value={name || ""}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className="form-group d-flex">
                    <label className="custom-control custom-checkbox mr-2">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="agree_to_terms"
                            name="agreeToTerms"
                            checked={isAgreed}
                            onClick={() => setIsAgreed(!isAgreed)}
                            readOnly
                        />
                        <span className="custom-control-label position-relative">약관 동의</span>
                    </label>
                    <a href="https://terms.flowing.tel/index.html" target="_blank">
                        약관 확인하기
                    </a>
                </div>

                <div className="form-footer">
                    {/*<a
                        className="btn btn-primary btn-block btn-block"
                        title=""
                        onClick={signUpSimple}
                    >
                        가입하기
                    </a>*/}
                    <button className="btn btn-primary btn-block w-100 " type="submit" onClick={signUpSimple}>
                        {/*{props.t("Join")}*/}가입하기
                    </button>
                </div>
            </div>
            <div className="card-footer text-center text-muted "></div>
            <div className=" text-center mt-0 pb-4 mb-5">
                플로잉 계정이 이미 있나요?{" "}
                <a href="#" onClick={() => props.setIsLogin()}>
                    로그인하기
                </a>
                <p className="mt-5 Copyright">ⓒ 2022 Copyright TPKOREA Co.,Ltd.</p>
            </div>
        </div>
    )
}

export default withRouter(withTranslation()(RegisterSimple))

RegisterSimple.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
}
