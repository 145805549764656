import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationGr from "./locales/gr/translation.json"
import translationIT from "./locales/it/translation.json"
import translationRS from "./locales/rs/translation.json"
import translationSP from "./locales/sp/translation.json"
import translationENG from "./locales/eng/translation.json"
import translationKR from './locales/kr/translation.json'

// the translations
const resources = {
    gr: {
        translation: translationGr
    },
    it: {
        translation: translationIT
    },
    rs: {
        translation: translationRS
    },
    sp: {
        translation: translationSP
    },
    eng: {
        translation: translationENG
    },
    kr: {
        translation: translationKR
    },
}

const language = navigator.language ? navigator.language.split('-')[0] : 'kr';

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: language,
        fallbackLng: "kr", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })


console.log('i18n', i18n);

export default i18n
