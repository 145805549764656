import React, { useEffect, useState } from "react"
// defualt image
import preveiwImg from "../../../../../assets/images/chat/site_name.png"

export default function Preview(props) {
    const {completedAction} = props;
    const [url, setUrl] = useState()

    const checkUrl = url => {
        let array = url.split("//")
        if (array[0] === "https:" || array[0] === "http:") {
            setUrl(url)
        }
         else {
            setUrl(`http://${url}`)
        }
    }

    // IOT new message
    useEffect(() => {
        checkUrl(props.message.message.split(" ")[0])

        if (!completedAction) return false;
        completedAction()
        // completedAction ?? completedAction();
    }, [props.message.message])

    return (
        <a className="media_img preview_data_container" href={url} target="popup">
            <div className="preview_content">
                <img
                    src={props.message.previewData.image || preveiwImg}
                    className="img-thumbnail"
                    alt=""
                    onError={e=>{
                        e.target.setAttribute('src', preveiwImg)
                    }}

                    onLoad={completedAction ?? completedAction}
                />
                <div className="preview_data">
                    <div className="title">{props.message?.previewData?.title}</div>
                    <div className="desc">{props.message?.previewData?.description}</div>
                    <div className="link">{url}</div>
                </div>
            </div>
        </a>
    )
}
