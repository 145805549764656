import axios from "axios"
import config from "../config/configuration"

import {setSessionTrigger} from 'services/auth.service';
import {throttle} from 'lodash';

// pass new generated access token here
const token = sessionStorage.getItem('access_token')

// apply base url for axios
const API_URL = config.api.chat_api

axios.defaults.withCredentials = true;
const axiosApi = axios.create({
    baseURL: API_URL
})

axiosApi.defaults.headers.common["Authorization"] = token

// axiosApi.interceptors.response.use(
//     response => response,
//     error => Promise.reject(error)
// )

axiosApi.interceptors.request.use(
    async config => {
        
        const token = sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token") : null
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': "application/json",
        }
        return config
    },
    async error => {
        await Promise.reject(error)
    }
)

axiosApi.interceptors.response.use(
    async function(response) {
        if(response.status === 205) {
            try {
                const refreshAccessToken = await axiosApi.post(`${config.api.common_api}/auth/web/refreshToken`)
                const requestOrigin = response.config
                if(refreshAccessToken.status === 201) {
                    sessionStorage.setItem("access_token", refreshAccessToken.data.access_token)
                    try {
                        return await axiosApi(requestOrigin);
                    } catch(err) {
                        throw err
                    }
                }
            } catch(err) {
                console.log("401-1")
                if(err.response.status === 401) {
                    console.log("401-2")
                    window.alert('재로그인이 필요합니다.')
                    const logout = await axiosApi.post(`${config.api.common_api}/auth/web/sign-out`)
                    if(logout.status===201) {
                        localStorage.clear()
                        sessionStorage.clear()
                        window.location.reload()
                    }

                }
            }
        } else {
            return response
        }
    },
    async function (error) {
        const {config: conf, response} = error

        console.log("401-3")
        console.warn(error)
        // if(response.status === 401 && sessionStorage.getItem("access_token")){
        //     console.log("401-4")
        //     // window.alert('재로그인이 필요합니다.')
        //     // toast.warning("재로그인이 필요합니다.")
        //     const logout = await axiosApi.post(`${config.api.common_api}/auth/web/sign-out`)
        //     if(logout.status===201) {
        //         localStorage.clear()
        //         sessionStorage.clear()
        //         // window.location.reload()
        //         // window.alert("로그인이 필요합니다.")
        //         window.location.href="/login"
        //     }
        // }

        if (error?.request?.responseURL && (error?.request?.responseURL.includes('/refreshToken') || error?.request?.responseURL.includes('auth/web/user'))) {
            if (response?.status === 401 || response?.status === 403) {
                const logout = await axiosApi.post(`${config.api.common_api}/auth/web/sign-out`).then(res => {
                    return res.status === 201;
                });
                localStorage.clear();
                sessionStorage.clear();

                throttle(() => {setSessionTrigger()}, 1000);

                if (logout && response?.status === 401) {
                    window.location.reload();
                }
            }
        }

        if (!error?.request?.responseURL.includes('/refreshToken') && !error?.request?.responseURL.includes('auth/web/user') || response?.status === 403) return Promise.reject(error)
        // return Promise.reject(error);
    }
)

export async function get(url, config = {}, verbose = false) {
    return await axiosApi.get(url, { ...config }).then(response => verbose && response || response.data);
}

export async function post(url, data, config = {}, verbose= false) {
    return await axiosApi.post(url, data, { ...config }).then(response => verbose && response || response.data);
}

export async function put(url, data, config = {}, verbose= false) {
    return await axiosApi.put(url, { ...data }, { ...config }).then(response => verbose && response || response.data);
}

export async function patch(url, data, config = {}, verbose= false) {
    return await axiosApi.patch(url, data, { ...config }).then(response => verbose && response || response.data);
}

export async function del(url, data = {}, config = {}, verbose= false) {
    return await axiosApi.delete(url, { data: {...data}}, { ...config }).then(response => verbose && response || response.data);
}

/*export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}, verbose) {
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then(response => {
            if(verbose) {
                return response
            } else {
                return response.data
            }
        })
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function del(url, data = {}, config = {}) {
    return await axiosApi
        .delete(url, { data: data }, { ...config })
        .then(response => response.data)
}*/
