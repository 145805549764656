import React from "react"
import { Redirect } from "react-router-dom"

// Profile
// import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/index"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/SearchQuestion"
import AnswerTalk from "../pages/Dashboard/AnswerTalk"
import ChatRoomList from "../pages/MySettings/MyChatRooms"
import SettingUserInfo from '../pages/MySettings/SettingUserInfo';
// import UseList from "../pages/MySettings/UseList"
import ReplyGuide from "pages/ReplyGuide"
import LandingSidebar from '../pages/LandingPageLogin/Chat/index'
import Solution from "pages/Solution"
import QuestionsWrite from "../pages/Questions/QuestionsWrite"
// import BuyDiamonds from "../pages/Products/BuyDiamonds"
import BuyDiamondsWrapper from "../pages/Products/BuyDiamondsWrapper"
import Product from "../pages/Products/Product"
import Plan from "pages/Plan/Plan"
// import DiamondSetting from "pages/Products/DiamondSetting"
import AnswerReview1 from "pages/Review/AnswerReview1"
import AnswerReview2 from "pages/Review/AnswerReview2"
import AnswerReview3 from "pages/Review/AnswerReview3"
import AnswerReview4 from "pages/Review/AnswerReview4"
import AnswerReview5 from "pages/Review/AnswerReview5"
import AnswerReview6 from "pages/Review/AnswerReview6"
// import LandingPageLogin from "pages/LandingPageLogin/LandingPageLogin"
import RegisterRenew from "../pages/Authentication/RegisterRenew"

const authProtectedRoutes = [
    { path: "/", exact: true, component: Dashboard },
    { path: "/main", component: Dashboard },
    { path: "/answerTalk", component: AnswerTalk },
    { path: "/mySettings/chatRoomList", component: ChatRoomList },
    { path: "/replyGuide", component: ReplyGuide },
    { path: "/solution", component: Solution },
    { path: "/questionsWrite", component: QuestionsWrite },
    { path: "/buyDiamonds", component: BuyDiamondsWrapper},
    { path: "/product", component: Product },
    { path: "/mySettings/useList", component: BuyDiamondsWrapper},
    { path: '/mySettings/settingUserInfo', component: SettingUserInfo },
    { path: "/plan", component: Plan },
    // { path: "/diamondsetting", component: DiamondSetting },
    { path: "/review01", component: AnswerReview1 },
    { path: "/review02", component: AnswerReview2 },
    { path: "/review03", component: AnswerReview3 },
    { path: "/review04", component: AnswerReview4 },
    { path: "/review05", component: AnswerReview5 },
    { path: "/review06", component: AnswerReview6 },
    // //profile
    // { path: "/profile", component: UserProfile },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/shareLink", exact: true, component: () => <Redirect to="/main" /> },
    // { path: "/", exact: true, component: () => <Redirect to="/main" /> }
]

const publicRoutes = [
    // { path: "/dashboard", component: Dashboard },
    { path: "/logout", component: Logout },
    { path: "/login", component: Login },
    { path: "/forgot-password", component: ForgetPwd },
    { path: "/register", component: Register },
    { path: '/landingpage/chat/:userId', component: LandingSidebar },

    {path: '/registerRenew', component: RegisterRenew},
]

export { publicRoutes, authProtectedRoutes }
