import { get, post } from "../helpers/api_helper"
import config from "../config/configuration"

export async function fetchEstimatedPoints(userId) {
    try {
        return await get(`/point?userId=${userId}`, { baseURL: config.api.common_api })

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function fetchEstimatedAssets(userId) {
    try {
        return await get(`/payment/${userId}/deposit`, { baseURL: config.api.payment_api })

    } catch (err) {
        console.error(err)
        throw err
    }
}
