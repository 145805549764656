import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import moment, { duration } from "moment"

import "../../../assets/css/myStyleChat.css"
import "../../../assets/css/fontAwesome.css"
import images13 from "../../../assets/images/12.jpg"
import { enterRoom } from "../../../services/talk2meRooms.service"
import { checkDeadlineTimer, startDeadlineTimer } from "../../../helpers/Functions/deadlineTimer"
import { authService } from "services/auth.service"

export default function QuestionContent(props) {
    const [userInfo, setUserInfo] = useState({})
    const [deadline, setDeadline] = useState()
    const [timer, setTimer] = useState('계산중...')
    const [userId, setUserId] = useState(null)
    const [deadlineTimeId, setDeadlineTimeId] = useState(null)
    const [doing, setDoing] = useState(true)
    
    

    const startTimer = duration=>{
        const interval = 1000
        setDeadline(true)
        console.log('@@@@@@@@@@@@@@@@@@@@ :>> ')
        const deadlineTimeId_temp = setInterval(() => {
            duration = moment.duration(duration - interval, "milliseconds")
            let result = startDeadlineTimer(duration._data)
            setTimer(result)
            // Timer finished condition
            if (!result) {
                setTimer("00:00:00")
                setDeadline(false)
                clearInterval(deadlineTimeId)
                setDeadline(false)
                return
            } else {
                setTimer(result)

            }
        }, interval)

        if(deadlineTimeId) {
            clearInterval(deadlineTimeId)
            setDeadlineTimeId(null)
        }
        setDeadlineTimeId(deadlineTimeId_temp)
    }

    let item = props.selectedValue

    useEffect(() => {
        const getUserIdRes = async () => {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserId(res.data.user_id)
            } else {
                return
            }
        }
        getUserIdRes().then(r=>r)

        let duration = checkDeadlineTimer(item?.deadline)
        if (!duration) return

        startTimer(duration)
    }, [item])


    //if user logged in let him enter room to open chat
    const isAuthenticated = () => {
        if (userId === null) {
            console.log(1111)
            console.log(props)
            // props.changeReferer("question")
            props.changeAuthLayout("auth")
        } else if (userId) {
            if(doing) enterRoomFunction().then(r => r)
        }
    }
    //enter room
    const enterRoomFunction = async () => {
        // console.log(body)
        setDoing(()=>{return false})
        const token = await authService.getACToken()
        console.log(token)
        if(token.status===201) {
            sessionStorage.setItem("access_token", token.data.access_token)
            const res = await authService.getUserId()
                console.log("res::::::::",res)
                if(res.status===201) {
                    setUserId(res.data.user_id)
                    const getUserIdRes = async () => {
                        const response = await authService.getUserInfo(userId)
                        setUserInfo(response)
                        const body = {
                            userId: response.id,
                            userName: response.nickname,
                            userImg: response.image_url || "https://d1vb419ubgm46b.cloudfront.net/userbg.jpg",
                        }
                        console.log(body)
                        const result = await enterRoom(body, item.roomId)
                        console.log(result)
                        if (result?.response?.status) {
                            /* TODO
                                서버에서 보낸 메시지를 출력하게 변경해야 함.
                                toast.warning(result?.response?.data?.message)
                             */
                            // toast.warning(`질문자가 '${body.userName}' 님을 거부했습니다. 해당 질문에 답변할 수 없습니다.`)
                            toast.warning(`입장 불가! 방장에 의해서 내보내기를 당한 유저는 입장할 수 없습니다.`)
                            props.activeChange()
                        } else if (result) {
                            if (item.roomType === "talk2me") props.activeChange("question")
                            else props.activeChange("answer")
                        }
                    }
                    getUserIdRes().then(r=>r)
                } else {
                    return
                }
            // const body = {
            //     userId: userInfo.id,
            //     userName: userInfo.nickname,
            //     userImg: userInfo.image_url || "https://d1vb419ubgm46b.cloudfront.net/userbg.jpg",
            // }
            // console.log(body)
            // const result = await enterRoom(body, item.roomId)
            // console.log(result)
            // if (result?.response?.status) {
            //     /* TODO
            //         서버에서 보낸 메시지를 출력하게 변경해야 함.
            //         toast.warning(result?.response?.data?.message)
            //      */
            //     // toast.warning(`질문자가 '${body.userName}' 님을 거부했습니다. 해당 질문에 답변할 수 없습니다.`)
            //     toast.warning(`입장 불가! 방장에 의해서 내보내기를 당한 유저는 입장할 수 없습니다.`)
            //     props.activeChange()
            // } else if (result) {
            //     if (item.roomType === "talk2me") props.activeChange("question")
            //     else props.activeChange("answer")
            // }
            props.changeAuthLayout("logged")
        }
    }

    return (
        <div className="col-lg-12">
            <div className="viewContents half-vh-height">
                {" "}
                <h5>{item.name}</h5>
                <p className="textArea">{item.introduction.replace(/(https:\/\/)(.)+/gi, "")}</p>
                <p className="photoArea">
                    <img
                        className="d-block w-100"
                        style={{ height: "211px" }}
                        alt=""
                        src={item.qustion === "" ? images13 : item.question?.images?.url}
                    />
                </p>
                <p className="cateArea">
                    <i className="fe fe-grid"></i> 카테고리 :
                    {(item.relCategory && item.relCategory.map((item, index) => `  ${item.name}`)).join(", ") || ""}
                </p>
                <p className="cateArea">
                    <i className="fe fe-map-pin"></i> 관련지역 :{" "}
                    {item.relRegion !== null
                        ? item.relRegion
                              .map((item, index) => {
                                  return item.upmyeondongName
                              })
                              .join(", ")
                        : "No location"}
                </p>
                <div className="userImg text-justify mt-3">
                    <div className="avatar">
                        <img src={item.owner.imageUrl === "" ? images13 : item.owner.imageUrl} alt="avatar" />
                    </div>
                    <div className="userInfo">
                        <p>
                            <strong>{item.owner ? item.owner.nickname : "No name"}</strong>{" "}
                        </p>
                        <p className="list">
                            <span>
                                <i className="las la-user"></i>
                                {item?.activeUsersCount} 명
                            </span>
                            <span>
                                <i className="fe fe-clock"></i>
                                {/* 남은 답변 시간 : {deadline ? timer : "00:00:00"} */}
                                {deadline ? "남은 답변 시간 : " + (timer || "") : "답변시간 종료"}
                            </span>
                        </p>
                    </div>

                    <div className="chat-messenger-bottom-area">
                        <div className="first-item">
                            <p>{item.activeUsersCount}/100명</p>
                        </div>
                        <button onClick={() => isAuthenticated()} className="btn btn-primary">
                            <div className="second-item">
                                <p>
                                    {userId === item.owner.id ? "내 질문" : "참여하기"}
                                    <i className="fe fe-message-circle"></i>
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
