import React from "react"
import moment from "moment"
import CheckType from "./ChekTypeComponent"

export default function ChatRight(props) {
    const { message } = props
    return (
        <div className="chat-body-message-right">
            <div className="chat-body-message-content">
                <CheckType {...props} message={message} />
                <div className="chat-body-message-content-user">
                    <span className="rounded-circle message-righted">
                        <p>
                        {/* {message.senderName}  */}
                        </p>
                    </span>
                </div>
            </div>
        </div>
    )
}
