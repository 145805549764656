//import room service
import {
    isSeenService,
    changeBlindService,
    rateReactionService,
    ownerMessageReadService,
    leaveChatRoomService,
    sendReportUserService,
    getBlockedUsersService,
    deleteOneMessageService,
    selectedWiinerUserService,
    checkVideoUploadingService,
    maskMessageChatRoomService,
    userProfileChatRoomService,
    blockUserFromChatRoomService,
    unblockUserFromChatRoomService
} from "../../../../../services/talk2meMessage.service"

// Get block users
export async function getBlockUsers(roomId, userId) {
    const response = await getBlockedUsersService(roomId, userId)
    return {
        status: true,
        data: response?.itemList?.length > 0 ? response.itemList : []
    }
}

// Mask all message delete
export async function setRoomTransferFunction(roomId, body) {
    //return await setRoomTransferService(roomId, body)
}

// Mask all message delete
export async function leaveChatRoomFunction(roomId, body) {
    return await leaveChatRoomService(roomId, body)
}

// Mask all message delete
export async function maskAllMessageFunction(roomId, body) {
    return await maskMessageChatRoomService(roomId, body)
}

// One message delete
export async function deleteOneMessageFunction(body) {
    return await deleteOneMessageService(body)
}

// block user
export async function blockUserFromChatRoomFunction(roomId, body) {
    return await blockUserFromChatRoomService(roomId, body)
}

// winner user
export async function winnerUserRoomFunction(roomId, body) {
    return await selectedWiinerUserService(roomId, body)
}

// UnBlock user
export async function unblockUserFromChatRoomFunction(roomId, body) {
    return await unblockUserFromChatRoomService(roomId, body)
}

// Check video upload
export async function checkVideoUploadingFunction(jobId) {
    return await checkVideoUploadingService(jobId)
}

// Sent message
export async function userProfileChatRoomFunction(roomId, body) {
    return await userProfileChatRoomService(roomId, body)
}

// Is blind
export async function changeBlindFunction(roomId, body) {
    return await changeBlindService(roomId, body)
}

// Sent report user
export async function sendReportUserFunction(roomId, body) {
    return await sendReportUserService(roomId, body)
}

// Reaction
export async function rateReactionFunction(roomId, messageId, body) {
    const encodedMessageId = encodeURIComponent(messageId)
    return await rateReactionService(roomId, encodedMessageId, body)
}
