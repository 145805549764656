import React from "react"

export default function ChatRoomHeader(props) {
  const { getUserInfo, setSearchValue, searchValue } = props
  return (
    <section className="hero-area  pt-40px">
      <div className="container col-lg-12">
        <div className="hero-content">
          <form onSubmit={ev => ev.preventDefault()} className="search-form p-0 rounded-5 bg-transparent shadow-none position-relative z-index-1">
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex flex-wrap align-items-center flex-grow-1">
                <div className="form-group mr-3 flex-grow-1">
                  <input
                    className="form-control form--control "
                    type="text"
                    name="text"
                    placeholder="검색어를 입력하고 톡방을 검색하세요."
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                  />
                </div>
              </div>
              <div className="search-btn-box mb-3 ml-2">
                <button
                  className="btn theme-btn btn-search"
                  onClick={()=>{getUserInfo(searchValue)}}
                >
                  <i className="la la-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}
