import React from "react"
import podding_box from "../../../../assets/images/chat/podding_box.png"
// import so_select from "../../../../assets/images/chat/so_select.png"

export default function Info(props, args) {
    const { message } = props
    if(message.action === 'enter') return;
    if(message.action === 'leave') return;
    if(message.action === 'read') return;
    if(message.action === 'sendoff') return;
    if(message.action === 'pick') return;
    if(message.action === 'deadline') return;
    return (
        // /* 답변톡 입장 메시지 주석 처리 하기로 ---
        <>
        {/* {(message.action === 'sendoff') ? null : ( */}
            <div className="chat-body-message-center text-center">
                <div className="chat-body-message-content">
                    <div className="chat-body-message-content-text">
                        {message.action === "purchase" && (
                            <img
                                className="media-object"
                                src={podding_box}
                                alt=""
                                style={{ width: "100px" }}
                            />
                        )}
                        {/* {message.action === "pick" && (
                            <img
                                className="media-object"
                                src={so_select}
                                alt=""
                                style={{ width: "100px" }}
                            />
                        )} */}
                        <p className="chat-center-text d-inline-block">{message.message}</p>
                    </div>
                </div>
            </div>
        {/* )} */}
        </>
        // */
    )
}
