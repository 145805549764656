import React, { useEffect, useState } from "react"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import avatar1 from "../../../../assets/images/chat/defualt-image.png"
import { faCopy, faTrash, faStar } from "@fortawesome/free-solid-svg-icons"
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"
import { toast } from "react-toastify"
import {
    blockUserFromChatRoomFunction,
    winnerUserRoomFunction
} from "../helper/Functions/SupportFunctions"

export default function Avatar(props) {
    const { user, message, selectedAvatarMsgId, roomData } = props

    const [isBlockModal, setIsBlockModal] = useState(false)
    const [isWinnerModal, setIsWinnerModal] = useState(false)
    const [targetUser, setTargetUser] = useState()

    useEffect(() => {console.log(roomData?.roomData?.winner?.userName)}, [])

    // User block
    const blockUserFunction = async () => {
        setIsBlockModal(false)
        const {blockedUsers} = roomData?.roomData
        // console.log('blockedUsers', blockedUsers)
        // console.log('targetUser', targetUser)
        const already = blockedUsers && blockedUsers.includes(targetUser.id)
        if(already === true) {
            toast.info('이미 차단된 사용자 입니다.')
            return;
        }
        let data = {
            userId: user.id,
            userName: user.nickname,
            targetId: targetUser.id,
            targetName: targetUser.userName
        }
        const result = await blockUserFromChatRoomFunction(props.roomId, data)
        if (result?.response?.data?.errors?.message) {
            toast.warning(result?.response?.data?.errors?.message)
            return
        }

        if (result.message) {
            props.blockUser(targetUser.id)
            // toast.success("Block user success")
            toast.success("사용자를 차단했습니다.")
        }

        props.selectedMessageAction({
            action: "BLOCK"
        })
    }

    const setWinner = async () => {
        setIsWinnerModal(false)
        let data = {
            userId: targetUser.id,
            userName: targetUser.userName
        }
        try {
            const result = await winnerUserRoomFunction(props.roomId, data)
            if (result.message) {
                // toast.success("Winner user success")
                toast.success("우수답변자로 채택했습니다.")
            } else {
                toast.warning(result?.response?.data?.errors?.message)
            }
        } catch(err) {
            if (err?.response?.status===409) {
                toast.warning('이미 선정된 우수 답변자가 존재합니다.')
            } else {
                toast.error('다시 한번 시도해 주세요')
            }
        }
    }

    return (
        <div className="chat-body-message-content-user ">
      <span className="rounded-circle message-lefted position-relative">
        {selectedAvatarMsgId === message.messageId && (
            <div className="tool_tip">
                {roomData.isOwner && !roomData?.roomData?.winner?.userName && (
                    <button
                        onClick={() => {
                            setTargetUser({
                                id: message.senderId,
                                userName: message.senderName
                            })
                            setIsWinnerModal(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faStar} color={"white"} />
                        <p>채택하기</p>
                    </button>
                )}
                {roomData.isOwner && (
                    <button
                        onClick={() => {
                            setTargetUser({
                                id: message.senderId,
                                userName: message.senderName
                            })
                            setIsBlockModal(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faCopy} color={"white"} />
                        <p>내보내기</p>
                    </button>
                )}
                <button
                    onClick={() =>
                        props.onChangeMenu("reportAbuse", message.senderId)
                    }
                >
                    <FontAwesomeIcon icon={faTrash} color={"white"} />
                    <p>신고하기</p>
                </button>
            </div>
        )}

          <img
              onClick={() => {
                  props.selectedMessageAction({
                      action: "AVATAR",
                      messageId: message.messageId
                  })
              }}
              src={message.senderImg || avatar1}
              alt="user"
              width={30}
              height={30}
              className="rounded-circle"
          />

        
      </span>

            <Modal returnFocusAfterClose={true} isOpen={isBlockModal} centered={true}>
                <ModalBody className="text-center">
                    해당 유저를 답변톡방에서 <br /> 내보내시겠습니까?
                    <br />
                    내보내기를 하면 해당 이용자는 해당 <br /> 답변톡방에 입장할 수
                    없습니다.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setTargetUser()
                            setIsBlockModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => blockUserFunction()}>
                        내보내기
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                returnFocusAfterClose={true}
                isOpen={isWinnerModal}
                centered={true}
            >
                <ModalBody className="text-center">
                    {targetUser?.userName}님을 우수답변자로 <br /> 선택하시겠습니까? 선택
                    후에 <br /> 변경은 불가능합니다.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setIsWinnerModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => setWinner()}>
                        확인
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
