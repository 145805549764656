import Slider from "react-slick"
import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import images13 from "../../assets/images/12.jpg"
import ava01 from "../../assets/images/ava01_2.gif"
import ava01_3 from 'assets/images/ava01_3.gif'
import moment from "moment"
import { Link } from "react-router-dom"
import Sidebar from "./Chat/index"
import AnswerContent from "./Chat/AnswerContent"

import Answer from "../MySettings/AnswerChat/ChatContainerModal"
import AnswerChatRoom from "../MySettings/AnswerChat/ChatContainerModal"
import { authService } from "services/auth.service"

const AnswerRoomList = props => {
const [selectedValue, setSelectedValue] = useState({})
const {isChatActive, setIsChatActive} = props
// const [isChatActive, setIsChatActive] = useState("")
const [layout, setLayout] = useState("")
const [userInfo, setUserInfo] = useState({})
const [roomId, setRoomId] = useState("") //kms Modify :  Move chatbox from AlnswerTalk to AnswerRoomList
const [user, setUser] = useState("")

    useEffect(() => {
        console.log('answers-loading', props.loading);
    }, [props.loading])

const activeChange = data => {
    // console.log('data', data)
    // console.log('isChatActive1', isChatActive)
    setIsChatActive(data)
    // console.log('isChatActive2', isChatActive)
}

const chatURL = process.env.REACT_APP_ANSWERING_URL

useEffect(() => {
    const getUserIdRes = async () => {
        const res = await authService.getUserId()
        if(res.status===201) {
            const response = await authService.getUserInfo(res.data.user_id)
            setUserInfo(response)
        }
    }
    getUserIdRes().then(r=>r)

    // if (localStorage.getItem("user_id")) {
    //     let userInfo = localStorage.getItem("user_data")
    //     userInfo = JSON.parse(userInfo)
    //     setUserInfo(userInfo)
    // }
    },[isChatActive])
// console.log(props.answerList)
// console.log('userInfo', userInfo)
return (
    <React.Fragment>

    <section className="job-area pt-60px pb-60px mbm position-re anwserPop">
        {isChatActive === "sideBar_answer" && (
            <Sidebar
                idType={"chat-sidebar"}
                selectedValue={selectedValue}
                activeChange={screen => activeChange(screen)}
                layout={"answer"}
                setUser={setUser}
                setRoomId={setRoomId}
            />
        )}
        {/*{isChatActive === "answer" && ( // TODO : 이 컴포넌트는 roomId 값이 없는 상태에서의 호출이라 빈 채팅창만 로드
            <Answer
                roomId={selectedValue.roomId}
                user={userInfo}
                exitChat={() => activeChange()}
            />
        )}*/}
        <Container>
        <Row>
            <Col lg={12}>
            <Container>
                <div className="pb-4 d-flex align-items-center justify-content-between mobile2line">
                <h2 className="listTitle">
                    <b>답변자</b> 리스트
                </h2>
                </div>
            </Container>
            </Col>
            <Col>
            <section className="testimonial-area">
                <Container>
                {/* <button
                    className="btn btn-success"
                    onClick={() => getNextSlide()}
                >
                    sd1
                </button> */}
                <Slider
                    // ref={}
                    onSwipe={(e) => {
                        // getNextSlide() // TODO : Pagination 을 구현하려고 했던 것 같은데, 이 방식으로는 혼란만 일으킨다. 보류!!!
                        // console.log( e)
                    }}
                    afterChange={(current) => {
                        if (props.dataHandler) props.dataHandler(current).then(r => r);
                    }}
                    infinite={false}
                    rows={props.pageSize || 5}
                    arrows
                    className={'flowing-ui--main-slick-list'}
                    style={{zIndex:1}}
                >
                    {props.answerList &&
                    props.answerList.map((item, index) => (
                        <div className="carousel-card text-center" key={item?.id || index}>
                        <div className="table-responsive">
                            <div className=" text-center">
                            <div className="table-responsive">
                                <div className="media media-card align-items-center">
                                <div className="card-body">
                                    <article className="media">
                                    <div className="mr-3">
                                        <img
                                        className="w150 slider-customize-height img-fit"
                                        src={
                                            item.imageUrl === ""
                                            ? images13
                                            : item.imageUrl
                                        }
                                        alt=""
                                        />
                                    </div>
                                    <div className="media-body">
                                        <div className="content">
                                        <div className="w-100">
                                            <div className="listTxtTit justify-content-between d-flex">
                                            {item.nickname}
                                            </div>
                                            <div className="mt-2">
                                            <div className="listInfo03 fs-14 d-flex">
                                                <div className="mr10">
                                                <i className="las la-user mr-1"></i>
                                                <b>답변수</b>{" "}
                                                {item.talk2youRoomCount}
                                                </div>
                                                <div className="mr10">
                                                <i className="las la-hand-pointer mr-1"></i>
                                                <b>조회수</b> {item.viewCount}
                                                </div>
                                            </div>
                                            <div className="listInfo04 fs-14">
                                                <div className="pr-1">
                                                <i className="las la-thumbs-up mr-1"></i>
                                                <b>답변 키워드</b>{" "}
                                                {item.keywords.map(
                                                    item => `#${item}`
                                                ).join(' ')}
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div className="askTime">
                                            <span className="time fs-14">
                                            <i className="las la-clock"></i>{" "}
                                            <b className="mr-1">{/*최근 답변*/}답변자 등록일</b>{" "}
                                            {/*{moment(item.createdAt).format(
                                                "L"
                                            )}*/}
                                            {moment(item.createdAt).format(
                                                "YYYY-MM-DD"
                                            )}
                                            </span>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="media-foot">
                                        {/* <Link to={"/answerTalk?" + item.id}>
                                        <button className="btn btn-outline-secondary round" >
                                            질문하기
                                        </button>
                                        </Link> */}
                                    {item?.id !== userInfo?.id &&
                                        <button className="btn btn-outline-secondary round"
                                        onClick={e => {
                                            if(item.landingpage_path === null) {
                                                setSelectedValue(item)
                                                console.log(item)
                                                activeChange("sideBar_answer")
                                            } else {
                                                window.open('about:blank').location.href=`${chatURL}/${item.landingpage_path}`
                                            }
                                        }}
                                        >
                                        질문하기
                                        </button>
                                    }
                                    {item?.id === userInfo?.id &&
                                        <p style={{width:120}} />
                                    }
                                    </div>
                                    </article>
                                    {/* {layout === "answer" && <AnswerContent />} */}
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    ))}
                    {props?.answerList?.length < 1 ?
                        <div className="table-responsive">
                        <div className="media media-card align-items-center">
                            <div className="card-body">
                                <article className="text-center">
                                    <img className="noImg" src={props.loading ? ava01_3 : ava01} alt="" />
                                    <p className="fs-14">{props.loading ? '답변자를 검색하고 있습니다.' : '검색결과가 없습니다.'}</p>
                                </article>
                            </div>
                        </div>
                      </div> : ''
                    }
                </Slider>
                </Container>
            {/*// TODO : 이게 진짜 채팅창. roomId 값이 설정된 상태에서 로드되기 때문에 정상적인 메시지가 표시된다.*/}
            {isChatActive === "answer" && roomId && (
                <AnswerChatRoom
                    roomId={roomId}
                    user={user}
                    isHeader={true}
                    exitChat={() => setRoomId()}
                />
            )}

            </section>
            </Col>
        </Row>
        </Container>
    </section>
    </React.Fragment>
)
}

export default AnswerRoomList
