import React from "react"
import VideoPlayer from "../chatComponents/type/VideoPlayer"

export default function ImageAndVideo(props) {
    const { selectedValue } = props
    return (
        <div className="room-list">
            <div className="room-item-header d-flex align-items-center">
                <div className="room-item-close-button">
          <span
              className="la la-close"
              onClick={e => {
                  props.onChangeMenu("chatContainer")
              }}
              style={{cursor: "pointer"}}
          ></span>
                </div>
                <div className="room-item-header-title font-weight-bold">
                    {selectedValue.type === "VIDEO" ? "영상 상세보기" : "이미지 상세보기"}
                </div>
            </div>
            <div className="image-and-video">
                {selectedValue.type === "VIDEO" && (
                    <VideoPlayer
                        width={320}
                        height={320}
                        fileOrigin={selectedValue.file}
                        isDownload={false}
                    />
                )}
                {selectedValue.type === "IMAGE" && (
                    <img src={selectedValue.file} alt="user" height={320} className="" />
                )}
            </div>
        </div>
    )
}
