// import {get} from '../helpers/api_helper_payment';
import {get} from '../helpers/api_helper';
import config from "../config/configuration"

export async function getUsageList(userId, offset, limit) {
    try {
        if(!userId) return null;
        // console.log('userId ', userId);
        // const result = await get(`/${userId}/deposit/product-history?type=purchase&offset=${offset}&limit=${limit}`, {baseURL: config.api.payment_api});
        const result = await get(`/payment/${userId}/deposit/product-history?type=purchase&offset=${offset}&limit=${limit}`, {baseURL: config.api.payment_api});
        // console.log('getUsageList ', result);
        return result && result.itemList || [];
    } catch (err) {
        throw err;
    }
}

export async function getChargingList(userId, offset, limit) {
    try {
        if(!userId) return null;
        console.log('userId ', userId);
        // const result = await get(`/${userId}/deposit/charging-history?offset=${offset}&limit=${limit}`, {baseURL: config.api.payment_api});
        const result = await get(`/payment/${userId}/deposit/charging-history?offset=${offset}&limit=${limit}`, {baseURL: config.api.payment_api});
        // console.log('getChargingList ', result);
        return result && result.itemList || [];
    } catch (err) {
        throw err;
    }
}

export async function getTransactionData(transactionId) {
    try {
        if(!transactionId) return null;
        // console.log('transactionId ', transactionId);
        const result = await get(`/payment/pg/result?transactionId=${transactionId}`, {baseURL: config.api.payment_api});
        // console.log('transactionResult ', result);
        return result && result?.item;
    } catch (err) {
        throw err;
    }
}
