import React from "react"
// import static pictures
import so_so from "../../../../assets/images/so_so.png"
import bad from "../../../../assets/images/bad.png"
import good from "../../../../assets/images/good.png"

export default function Rating(props) {
    return (
        <div className="area-winner">
            <div className="winner">
                <div className="winner-sticker-header">
                    <div className="chat-header-buttons d-flex align-items-center">
                        <button
                            className="btn close-button-chat rounded-circle"
                            onClick={() => props.setRateAction()}
                        >
              <span>
                <p className="text-center span-close-style">x</p>
              </span>
                        </button>
                    </div>
                </div>
                <div className="winner-sticker-container">
                    <p className="top-header mb-2 font-weight-bold">답변 평가하기</p>
                    <div
                        className="article-sticker d-flex align-items-center mb-2"
                        onClick={() => props.setRateReaction(1)}
                    >
                        <img className="avatar  " src={good} alt="" />
                        <p className="ml-3">정말 완벽한 답변이에요!</p>
                    </div>
                    <div
                        className="article-sticker d-flex align-items-center mb-2"
                        onClick={() => props.setRateReaction(2)}
                    >
                        <img className="avatar  " src={so_so} alt="" />
                        <p className="ml-3">참 유익한 답변이네요~</p>
                    </div>
                    <div
                        className="article-sticker d-flex align-items-center mb-2"
                        onClick={() => props.setRateReaction(3)}
                    >
                        <img className="avatar " src={bad} alt="" />
                        <p className="ml-3">답변이 만족스럽지는 않네요</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
