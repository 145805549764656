import {memo} from 'react';
import propTypes from 'prop-types';
import {Button} from 'reactstrap';
import cryingDogImage from 'assets/images/ava03_5.gif';

export const SignMessageComp = ({loginAction, signUpAction, comment, ...props}) => {

    return (
        <section>
            <article>
                <div>{comment ? comment : '현재 화면에서 계속 메세지를 주고 받으려면 회원가입을 하셔야 합니다.'}</div>
                <div className="d-flex mt-3">
                    <Button className={'mr-1'} color={'primary'} size={'sm'} onClick={signUpAction}>
                        회원가입
                    </Button>

                    <Button color={'primary'} size={'sm'} onClick={loginAction}>
                        로그인
                    </Button>
                </div>
            </article>
        </section>
    )
}

export const SignMessageAgain = ({loginAction, signUpAction, comment, ...props}) => {

    return (
        <section>
            <article>
                <div className="d-flex justify-content-center">
                    <div>
                        <img src={cryingDogImage} alt=""/>
                    </div>
                </div>
                <div>{comment ? comment : '취소되었습니다. 다시 해볼까요?'}</div>
                <div className="d-flex mt-3">
                    <Button className={'mr-1'} color={'primary'} size={'sm'} onClick={signUpAction}>
                        회원가입
                    </Button>

                    <Button color={'primary'} size={'sm'} onClick={loginAction}>
                        로그인
                    </Button>
                </div>
            </article>
        </section>
    )
}

SignMessageComp.propTypes = {
    loginAction: propTypes.func,
    signUpAction: propTypes.func,
    comment: propTypes.oneOfType([propTypes.string, propTypes.element])
}
SignMessageAgain.propTypes = {
    loginAction: propTypes.func,
    signUpAction: propTypes.func,
    comment: propTypes.oneOfType([propTypes.string, propTypes.element])
}

export default  memo(SignMessageComp);