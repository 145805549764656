import {useState, memo} from 'react';
import propTypes from 'prop-types';
import ChatUIHeader from './ChatHeader';
import ChatUIContents from './ChatContents';
import ChatUIFooter from './ChatFooter';
import MessageViewer from './MessageViewer';

import { Fade } from 'reactstrap';

// import css and scss
import "../../assets/css/myStyleChat.css"
import "../../assets/css/chat/ChatRoom.scss"
import "../../assets/css/fontAwesome.css"

const ChatUI = (props) => {
    const { userId, ownerId, messages, chatDelay, loading, title, ownerName, ownerImage } = props;
    const isAnonymousMode = props.isAnonymousMode || false;

    const [fade, setFade] = useState(false);
    const [viewMessage, setViewMessage] = useState({});
    const [toggleView, setToggleView] = useState(false);

    const sendMessage = props.sendMessageEvent ? props.sendMessageEvent : null;

    const messageClickEvent = props.messageClickEvent ? props.messageClickEvent : (message, ev) => {
        setViewMessage(message);
        setFade(!fade);
    };


    return (
        <div className="chat-customize white">
            {props.header || (
                <ChatUIHeader userImg={ownerImage} title={title} userName={ownerName} isAnonymousMode={isAnonymousMode} />
            )}

            <div className="chat-container">
                <Fade in={fade}
                      className={'message-viewer-fader'}
                      onClick={() => {setFade(false)}}
                      onEnter={() => {setToggleView(true)}}
                      onExited={() => {setToggleView(false)}}>
                    {toggleView && (
                        <MessageViewer {...viewMessage} setFade={setFade} />
                    )}
                </Fade>
                <div className="chat-body">


                    {props.body || (
                        <ChatUIContents className={isAnonymousMode ? 'mb-0' : ''}
                                        messages={messages || []}
                                        userId={userId}
                                        isLoading={loading}
                                        chatDelay={chatDelay || 0}
                                        messageClickAction={messageClickEvent} {...props} />
                    )}

                    {props.footer || !isAnonymousMode && (
                        <ChatUIFooter sendMessage={sendMessage} />
                    )}
                </div>
            </div>

            {props.children}
        </div>
    )
}

ChatUI.propTypes = {
    header: propTypes.element,
    body: propTypes.element,
    footer: propTypes.element,
    isAnonymousMode: propTypes.bool,
    chatDelay: propTypes.number,

    messages: propTypes.array,
    userId: propTypes.string,
    ownerId: propTypes.string,
    ownerName: propTypes.string,
    ownerImage: propTypes.string,
    title: propTypes.string,


    messageClickEvent: propTypes.func,
    sendMessageEvent: propTypes.func,
    loading: propTypes.bool,
    isThinking: propTypes.bool,
}

export default memo(ChatUI);