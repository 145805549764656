import React, {useEffect} from 'react';
import VideoPlayer from "./VideoPlayer"

export default function Voice(props) {
    const { message, completedAction } = props

    useEffect(() => {
        if (!completedAction) return false;
        completedAction()
    }, [message])

    return (
        <div className="video-player">
            <VideoPlayer
                width={220}
                height={40}
                fileOrigin={message.fileOrigin}
                isDownload={false}
            />
        </div>
    )
}
