import React, { useState } from "react"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import avatar1 from "../../../../assets/images/chat/defualt-image.png"
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"
import { toast } from "react-toastify"
import { blockUserFromChatRoomFunction } from "../helper/Functions/SupportFunctions"

export default function Avatar(props) {
    const { user, message, selectedAvatarMsgId, roomData } = props

    const [isBlockModal, setIsBlockModal] = useState(false)
    const [targetUser, setTargetUser] = useState()

    // User block
    const blockUserFunction = async () => {
        setIsBlockModal(false)
        let data = {
            userId: user.id,
            userName: user.nickname,
            targetId: targetUser.id,
            targetName: targetUser.userName
        }
        try {
            const result = await blockUserFromChatRoomFunction(props.roomId, data)
            if (result.message === "Deleted") {
                // toast.success("Block user success")
                toast.success("사용자를 차단했습니다.")
            } else {
                if(result?.response?.data?.errors?.message === `targetUser doesn't in the room`) {
                    toast.warning('이미 차단한 사용자입니다.')
                } else {
                    toast.error('다시 한번 시도해 주세요')
                }
            }
        } catch(err) {
            console.log(err)
        } finally {
            props.selectedMessageAction({
                action: "BLOCK"
            })
        }
    }

    return (
        <div className="chat-body-message-content-user ">
      <span className="rounded-circle message-lefted position-relative">
        {selectedAvatarMsgId === message.messageId && (
            <div className="tool_tip">
                {roomData.isOwner && (
                    <button
                        onClick={() => {
                            setTargetUser({
                                id: message.senderId,
                                userName: message.senderName
                            })
                            setIsBlockModal(true)
                        }}
                    >
                        <FontAwesomeIcon icon={faCopy} color={"white"} />
                        <p>내보내기</p>
                    </button>
                )}
                <button
                    onClick={() =>
                        props.onChangeMenu("reportAbuse", message.senderId)
                    }
                >
                    <FontAwesomeIcon icon={faTrash} color={"white"} />
                    <p>신고하기</p>
                </button>
            </div>
        )}

          <img
              onClick={() => {
                  props.selectedMessageAction({
                      action: "AVATAR",
                      messageId: message.messageId
                  })
              }}
              src={message.senderImg || avatar1}
              alt="user"
              width={30}
              height={30}
              className="rounded-circle"
          />

        {/* <p className="ml-2">
          {message.senderName} {moment(message.createdAt).format("hh:mm")}
        </p> */}
      </span>

            <Modal returnFocusAfterClose={true} isOpen={isBlockModal} centered={true}>
                <ModalBody className="text-center">
                    해당 유저를 답변톡방에서 <br /> 내보내시겠습니까?
                    <br />
                    내보내기를 하면 해당 이용자는 해당 <br /> 답변톡방에 입장할 수
                    없습니다.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setTargetUser()
                            setIsBlockModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => blockUserFunction()}>
                        내보내기
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
