import {useEffect} from 'react';
import propTypes from 'prop-types';
import Avatar from './Avatar';

import botImage from 'assets/images/img_helper.jpg';
import TypeLoader from './TypeLoader';

const ChatThinkerItem = (props) => {
    const {isThinking, completeAction} = props;

    useEffect(() => {
        if (!completeAction) return false;
        completeAction();
    })

    return (
        <>
            {isThinking && (<div className="chat-body-message-left">
                <div className="chat-body-message-content">
                    <div className="chat-body-message-content-user">
                        <Avatar src={botImage}
                                alt="BOT"
                                size={30}
                                bordered={false}/>
                    </div>
                    <div>
                        <div className="chat-body-message-content-text">
                            <p className="senderName">답변 도우미</p>
                            <article className="time">
                                <div className="message-content">
                                    <div className="chat-lefted-text d-inline-block">
                                        <TypeLoader/>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>)}
        </>
    )
}

ChatThinkerItem.prototype = {
    message: propTypes.object,
    type: propTypes.string,
    direction: propTypes.oneOf(['from', 'to']),
    myMsg: propTypes.bool,
    lastMsg: propTypes.bool,
    chatDelay: propTypes.number,
}

export default ChatThinkerItem;