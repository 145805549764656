import React from "react"
import moment from "moment"
import Text from "./type/text"
import Photo from "./type/photo"
import Voice from "./type/voice"
import Video from "./type/video"
import File from "./type/file"
import Preview from "./type/preview"
import plane from '../../../../assets/images/plane.png';

export default function xCheckType(props, args) {
    const { message, myMsg, idx, completedAction } = props

    // console.log('myIndex', idx);
    const prevMsgTime =
        (idx && props.messages[idx - 1] && moment(props.messages[idx - 1].createdAt).format("HH:mm")) || ""
    // console.log('prevMsgTime', prevMsgTime)
    const currentMsgTime = (idx && props.messages[idx] && moment(props.messages[idx].createdAt).format("HH:mm")) || ""
    // console.log('currentMsgTime', currentMsgTime)
    // console.log('props', props)
    return (
        <div>
            <div className={myMsg ? "text-right chat-body-message-content-text" : " chat-body-message-content-text"}>
                {/* 채팅창 우측은 자기 자신의 메시지를 표시나는 영역이기 때문에 senderName을 표시할 필요가 없다. */}
                {props.user?.id !== message?.senderId &&
                    <p className="senderName">{message.senderName}</p>
                }
                <div className="time">
                    {message.msgType === "VOICE" && <Voice {...props} />}
                    {message.msgType === "FILE" && <File {...props} />}
                    {message.msgType === "VIDEO" && <Video {...props} />}
                    {message.msgType === "TEXT" && <Text {...props} />}
                    {message.msgType === "PHOTO" && <Photo {...props} />}
                    {(message.msgType === "PREVIEW" || message.previewData) && <Preview {...props} />}
                    {/*{' - '}*/}
                    {/*{moment(message?.createdAt).format('YYYY-MM-DD HH:mm:sss') || ''}*/}
                    {/*{' - '}*/}
                    {/*{message?.unreadCount}*/}
                    {/*{moment(props.lastReadMsgTime).format('YYYY-MM-DD HH:mm:sss') || ''}*/}
                    {/*{(moment(Number(message?.createdAt)).isSameOrBefore(moment(props.lastReadMsgTime))).toString()}*/}
                    {/*{(new Date(message?.createdAt).getTime() <= new Date(props.lastReadMsgTime).getTime()).toString()}*/}
                    {/*{!(message?.hashCode && props.lastReadMsgTime*/}
                    {/*        && moment(Number(message?.hashCode)).isSameOrBefore(moment(props.lastReadMsgTime))) &&*/}
                    {/*TODO : 어떤 이유인지 moment를 이용한 시간 비교는 작동하지 않는다.*/}
                    {/*{(message?.createdAt && props.lastReadMsgTime
                            && moment(Number(message?.createdAt)).isSameOrBefore(moment(props.lastReadMsgTime))) &&*/}
                    {/*{!(new Date(message?.createdAt).getTime() <= new Date(props.lastReadMsgTime).getTime()) &&*/}
                    {/*    props.user?.id === message?.senderId &&*/}
                    {/*    message?.usersToRead?.length > 0 && (*/}
                </div>
                <p className="time">
                    {prevMsgTime && prevMsgTime === currentMsgTime && currentMsgTime ? "" :
                        (Number(moment(message.createdAt).format("HH")) < 12 ? "오전 " : "오후 ") +
                        moment(message?.createdAt).format("hh:mm")
                    }
                    {props.user?.id === message?.senderId &&
                    !(message?.unreadCount === 0) && (
                        <img src={plane} className="img-thumbnail unread_icon" alt=""
                             style={{width:21,height:21,background:"transparent",border:"none"}} />
                    )}
                </p>
            </div>
        </div>
    )
}
