import React, { useState } from "react"

const Plan = () => {
  const [selectPlan, setSelectPlan] = useState('selectPlan1')
  return (
    <>
      <section className="buyArea pt-60px pb-60px">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="filters pb-4 align-items-center">
                    <h2 className="listTitle ">플랜 </h2>
                  
                </div>
                
            </div>
        </div>
        <div className="plan-area">
            <div className="plan-case">
                <div className="row">    
                    <div className="col m-none"></div>
                    <div className="col">
                        <div id="planBox01" onClick={()=>{setSelectPlan("selectPlan1")}} className={`planBox text-center ${selectPlan === "selectPlan1" ? "plan-On" : ""}`}>
                            <div className="ml-auto d-none">
                                <input id="selectPlan1" name="radio" type="radio" checked={selectPlan === "selectPlan1"} value="selectPlan" />
                                <label htmlFor="selectPlan1"></label>
                            </div>
                            <div className="titTxt mt-50px">무료</div>
                            <div className="infoTxt">모든 기능 무료</div>
                        </div>
                    </div>
                    
                    <div className="col text-center">
                        <div id="planBox02" onClick={()=>{setSelectPlan("selectPlan2")}} className={`planBox ${selectPlan === "selectPlan2" ? "plan-On" : ""}`}>
                            <div className="titTxt d-flex">PRO
                                <div className="ml-auto">
                                    <input id="selectPlan2" name="radio" checked={selectPlan === "selectPlan2"} type="radio" value="selectPlan" />
                                    <label htmlFor="selectPlan2"></label>
                                </div>
                            </div>
                            <div className="infoTxt">MAU 3천 이상</div>
                            <div className="infoTxt txtblue">유료 구독 오픈시 무료</div>
                            <div className="priceTxt mt-50px"><span> 1 만원 </span> 0원</div>
                        </div>
                        <div className="planMaxDay">(23년 2월 까지)</div>
                    </div>
                    <div className="col text-center">
                        <div id="planBox03" onClick={()=>{setSelectPlan("selectPlan3")}} className={`planBox ${selectPlan === "selectPlan3" ? "plan-On" : ""}`}>
                            <div className="titTxt d-flex">BIG
                                <div className="ml-auto">
                                    <input id="selectPlan3" name="radio" checked={selectPlan === "selectPlan3"} type="radio" value="selectPlan" />
                                    <label htmlFor="selectPlan3"></label>
                                </div>
                            </div>
                            <div className="infoTxt">MAU 1만 이상</div>
                            <div className="infoTxt txtblue">유료 구독 오픈시 무료</div>
                            <div className="priceTxt mt-50px"><span> 3 만원 </span> 0원</div>
                        </div>
                        <div className="planMaxDay">(23년 2월 까지)</div>
                    </div>
                    <div className="col text-center">
                        <div id="planBox04" onClick={()=>{setSelectPlan("selectPlan4")}} className={`planBox ${selectPlan === "selectPlan4" ? "plan-On" : ""}`}>
                            <div className="titTxt d-flex">GIANT
                                <div className="ml-auto">
                                    <input id="selectPlan4" name="radio" checked={selectPlan === "selectPlan4"} type="radio" value="selectPlan" />
                                    <label htmlFor="selectPlan4"></label>
                                </div>
                            </div>
                            <div className="infoTxt">MAU 3만 이상</div>
                            <div className="infoTxt txtblue">유료 구독 오픈시 무료</div>
                            <div className="priceTxt mt-50px"><span> 5 만원 </span> 0원</div>
                        </div>
                        <div className="planMaxDay">(23년 2월 까지)</div>
                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col-lg-12 text-left">
                    <div className="planStage">
                        <span className="planBlue mr-1"></span>개발완료 
                        <span className="planRed mr-1 ml-3"></span>개발중 
                        <span className="planGreen mr-1 ml-3"></span>개발예정
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="ver-txt">Ver 1.0</div>
                </div> 
            </div>    
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">스페이스톡 홈 관리</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">답변톡 URL 설정</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">디자인 설정 </li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">상단디자인</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">메뉴페이지디자인</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">메뉴순서편집</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">모바일배경설정</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">PC 배경 설정</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">외부링크설정</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">외부링크정보등록</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">챗봇설정</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">인사말세팅</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">답변분야안내설정</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">답변시간세팅</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">금지어요청</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">이모티콘 감정 세팅</span> <span className="planRde ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li></li>
                            <li></li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">답변톡</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">답변방관리</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li>일부제한</li>
                            <li>일부제한</li>
                            <li>일부제한</li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">참여자관리</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li>일부제한</li>
                            <li>일부제한</li>
                            <li>일부제한</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">공개톡</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">질문검색</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">질문방관리</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">상품관리</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">답변톡상품설정</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">구독상품설정</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">정산관리</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">정산정보입력</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">정산내역조회</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">정산보고서조회</span> <span className="planBlue ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="ver-txt">Ver 2.0</div>
                </div> 
            </div>    
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">회원 관리</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">회원리스트</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">스텝등록</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">스텝메뉴권한 설정</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">스템회원권한 설정</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">스페이스톡 </li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">스페이스란</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">스페이스톡</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">구독만들기</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">구독자리스트</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">서랍영상</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">서랍PDF</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">서랍이미지</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">활동관리</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">공지만들기</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">일정만들기</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">투표만들기</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">출석만들기</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">마케팅</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">앱푸시보내기</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">충전하기</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">쇼핑몰</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">쇼핑몰 개설</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li>일부제한</li>
                            <li>일부제한</li>
                            <li>일부제한</li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">상품등록하기</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">상품관리</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">쇼핑몰 회원리스트</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">구매 관리</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">배송 관리 </span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">안심콜</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">안심번호설정</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">안심번호통화관리</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">구독만들기</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="ver-txt">Ver 3.0</div>
                </div> 
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">오픈톡 </li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">오픈톡만들기</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">오픈톡리스트</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">오픈톡회원관리</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">화상 컨퍼런스</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">화상회의관리</span> <span className="planRed ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">화상회원관리</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">영상편집기</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">영상편집하기</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">영상관리</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">   
                <div className="col">
                    <div className="ver-txt">Ver 4.0</div>
                </div> 
            </div>
            <div className="row">   
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="mainTit">크리에터클론</li>
                        </ul>    
                    </div>
                </div>
            </div> 
            <div className="row mb-2">    
                <div className="col">
                    <div className="planTit">
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">클론세팅하기</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">답변리스트</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                        <ul>
                            <li className="subTit d-flex align-items-center"><span className="underLine">클론회원관리</span> <span className="planGreen ml-auto"></span></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                            <li><i className="fe fe-check" data-toggle="tooltip" title="" data-original-title="완료"></i></li>
                        </ul>
                    </div>
                </div>
            </div>

            
            

            
        </div>
       
    </div>
</section>
    </>
  )
}

export default Plan
