import {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import Avatar from './Avatar';

import botImage from 'assets/images/img_helper.jpg';

import ContainerLoader from './MessageContainer/ContainerLoader';

const delay = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay));

const ChatMessageItem = (props) => {
    const {message, direction, myMsg, lastMsg, chatDelay, completeAction} = props;
    const [thinkingState, setThinkingState] = useState(true);

    const messageClickAction = props.messageClickAction ? (ev) => {
        props.messageClickAction(props?.message, ev);
    } : null;

    useEffect(() => {
        const wait = async () => {
            setThinkingState(true);
            const wait = await delay(chatDelay || 0);
            setThinkingState(false);

            if (completeAction) completeAction();
        }

        if (lastMsg) {
            wait();
        } else {
            setThinkingState(lastMsg);
        }
    }, [lastMsg]);

    return (
        <div className={myMsg ? 'chat-body-message-right' : 'chat-body-message-left'}>
            <div className="chat-body-message-content">
                {!myMsg && (
                    <div className="chat-body-message-content-user">
                        <Avatar src={message.msgType === 'BOT' || !message.senderImg ? botImage : message.senderImg}
                                alt={message.senderName}
                                size={30}
                                bordered={false} />
                    </div>
                )}
                <div>
                    <div className="chat-body-message-content-text">
                        {direction !== 'to' && (
                            <p className="senderName">
                                {!message.senderName || message.msgType === 'BOT'
                                    ? '답변 도우미' : message.senderName}
                            </p>
                        )}
                        <article className="time">
                            <div className="message-content">
                                <ContainerLoader loading={thinkingState}
                                                 message={message.message}
                                                 data={message}
                                                 loadedAction={completeAction}
                                                 messageClickAction={messageClickAction}
                                                 lastMsg={lastMsg} />
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}

ChatMessageItem.prototype = {
    message: propTypes.object,
    type: propTypes.string,
    direction: propTypes.oneOf(['from', 'to']),
    myMsg: propTypes.bool,
    lastMsg: propTypes.bool,
    chatDelay: propTypes.number,
}

export default ChatMessageItem;