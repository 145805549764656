import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
    CardBody,
    Card,
    Alert,
    Input,
    Form,
    InputGroup,
    FormGroup,
    CardFooter, Label,
    Button,
} from 'reactstrap';

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './NewLogin.css'

import { withRouter, Link } from "react-router-dom"

//import auth services
import { authService } from "../../../services/auth.service"
import { createRoom, enterRoom } from "services/talk2youRooms.service"

const BasicLogin = props => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [authCode, setAuthCode] = useState("")
    const [isAuthTrue, setIsAuthTrue] = useState(false)
    const [ref_url, setRefUrl] = useState("")

    const [isSimpleSignUp, setIsSimpleSignUp] = useState(false)
    const [isAgreed, setIsAgreed] = useState(false)

    const [authCodeTime, setAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)

    // 번호 인증 타이머
    let sec = 60
    let min = 1
    let time = 60000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
                setAuthCodeTimeIn(false)
                setAuthCodeTime("0:00")
            }
        }
    }

    const requestCodeFunction = async () => {
        const result = await authService.requestCode(phoneNumber)
        if (result === "success") {
            setAuthCodeTimeIn(true)
            timerRef.current = setInterval(timer, 1000)
        } else {
            setAuthCodeTimeIn(false)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
        }
    }
    const checkRequestedCodeFunction = async () => {
        console.log('checkRequestedCodeFunction...')
        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            return
        }

        const result = await authService.checkRequestedCodeWithHeader(phoneNumber, authCode, ref_url)
        console.log('result', result)
        if (result.status === 201) {
            setIsAuthTrue(true)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
            setAuthCodeTime("인증완료")
        }
    }
    const signUpSimply = async () => {
        if(!isAgreed) {
            // document.getElementById("agree_to_terms").focus();
            toast.error("약관에 동의해 주세요.")
            return;
        }
        const checkedName = await authService.checkNameDuplicate(name).then(res => {
            return res?.status === 204;
        }).catch(err => {
            console.warn('checkNameDuplicate', err);
        })

        if (checkedName) {
            const resultRes = await authService.signUpSimpleInLandingPage({
                phone_number: phoneNumber,
                nickname: name
            });
            const userInfo = await authService.getUserInfo(resultRes?.data?.user_id);

            toast.success('성공적으로 가입되어 가입한 계정으로 채팅방에 입장합니다.')
            props.changeUserInfo(userInfo)
        }
    }

    const Login = async () => {

        if(isSimpleSignUp) {
            await signUpSimply()
            return
        } 

        const response = await authService.easyLoginInLandingPage(name, phoneNumber)

        console.log('easyLoginInLandingPage', response);

        if(response?.response?.status === 400){
            /* 닉네임 '일치 x 새로 가입해야함' */
            props.updateLoginForm(true)
            setIsSimpleSignUp(true)
            return
        }

        const userInfo = await authService.getUserInfo(response?.data?.user_id);
        props.changeUserInfo(userInfo);
    }

    useEffect(() => {

    }, [])

    return (
        <div className="loginCase01 caseview">
            <FormGroup className="custom-form-group">
                <InputGroup size="sm">
                    <Input
                        type="text"
                        placeholder="휴대폰 번호를 입력하세요."
                        value={phoneNumber}
                        readOnly={isSimpleSignUp}
                        onChange={e => setPhoneNumber(e.target.value)}
                    />
                    {!isSimpleSignUp && (
                        <span className="input-group-append">
                            <Button
                                className="widthAuto"
                                color="primary"
                                type="button"
                                onClick={() => requestCodeFunction()}
                            >
                              인증번호 발송
                            </Button>
                        </span>
                    )}
                </InputGroup>
            </FormGroup>
        {!isSimpleSignUp &&
            <FormGroup className="custom-form-group">
                <InputGroup className="input-group" size="sm">
                    <Input
                        type="text"
                        placeholder="인증번호를 입력하세요."
                        value={authCode}
                        onChange={e => setAuthCode(e.target.value)}
                    />

                    <div className="CountNum d-flex align-items-center" style={{top: 0, bottom:0,}}>
                        <span>{authCodeTime}</span>
                    </div>
                    <span className="input-group-append">
                        <Button
                            color={'primary'}
                            className="widthAuto"
                            type="button"
                            onClick={() => {
                                console.log('clicked...')
                                checkRequestedCodeFunction().then(r => r)
                            }}
                        >
                          확인
                        </Button>
                    </span>
                </InputGroup>
            </FormGroup>
        }
            <FormGroup className="custom-form-group" size="sm">
                <Input
                    bsSize="sm"
                    type="text"
                    aria-describedby="nicknameHelp"
                    placeholder="닉네임을 입력하세요."
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </FormGroup>
            {isSimpleSignUp &&
                <FormGroup onChange={(ev) => {
                    setIsAgreed(ev.target.checked)
                }} check inline className="custom-form-group" size="sm">
                    <Label check>
                        <Input type="checkbox" />
                        <a href="https://terms.flowing.tel/index.html" target="_blank">운영정책 및 약관</a> 동의
                    </Label>
                </FormGroup>
            }

            <div className="text-center">
                <Button
                    style={{backgroundColor:"#01a0fc", borderRadius:"10px !important", width:"140px"}}
                    color={'primary'}
                    onClick={Login}
                >
                    {props.isSimpleSignUp ? '가입하기' : '로그인'}
                </Button>
            </div>
        </div>
    )
}

export default withRouter(withTranslation()(BasicLogin))

BasicLogin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}
