import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

//actions
import {
    changeLayout,
    changeTopbarTheme,
    changeLayoutWidth,
    showRightSidebarAction
} from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

//components
import Navbar from "./Navbar"
import Footer from "./Footer"
import RightSidebar from "../CommonForBoth/RightSidebar"

const Layout = props => {
    const dispatch = useDispatch()

    const { topbarTheme, layoutWidth, isPreloader, showRightSidebar } =
        useSelector(state => ({
            topbarTheme: state.Layout.topbarTheme,
            layoutWidth: state.Layout.layoutWidth,
            isPreloader: state.Layout.isPreloader,
            showRightSidebar: state.Layout.showRightSidebar
        }))
    document.title = "답변톡 플로잉"
    /*
    document title
    */
    // useEffect(() => {
    //   const title = props.location.pathname
    //   let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    //   document.title = currentage + " | Flowing"
    // }, [props.location.pathname])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    //hides right sidebar on body click
    const hideRightbar = event => {
        var rightbar = document.getElementById("right-bar")
        //if clicked in inside right bar, then do nothing
        if (rightbar && rightbar.contains(event.target)) {
            return
        } else {
            //if clicked in outside of rightbar then fire action for hide rightbar
            dispatch(showRightSidebarAction(false))
        }
    }

    /*
    layout settings
    */
    useEffect(() => {
        dispatch(changeLayout("horizontal"))
        document.body.classList.add("bgGray")
    }, [dispatch])

    useEffect(() => {
        //init body click event fot toggle rightbar
        document.body.addEventListener("click", hideRightbar, true)

        if (isPreloader === true) {
            document.getElementById("preloader").style.display = "block"
            document.getElementById("status").style.display = "block"

            setTimeout(function() {
                document.getElementById("preloader").style.display = "none"
                document.getElementById("status").style.display = "none"
            }, 2500)
        } else {
            document.getElementById("preloader").style.display = "none"
            document.getElementById("status").style.display = "none"
        }
    }, [isPreloader])

    useEffect(() => {
        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme))
        }
    }, [dispatch, topbarTheme])

    useEffect(() => {
        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth))
        }
    }, [dispatch, layoutWidth])

    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const openMenu = () => {
        setIsMenuOpened(!isMenuOpened)
    }

    return (
        <React.Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                    </div>
                </div>
            </div>

            <div id="layout-wrapper " className="horizontal-layout-wrapper">
                {/* <Header
                  theme={topbarTheme}
                  isMenuOpened={isMenuOpened}
                  openLeftMenuCallBack={openMenu}
                /> */}
                <Navbar menuOpen={isMenuOpened} />
                <div className="main-content pt-100">{props.children}</div>
                <Footer />
            </div>

            {showRightSidebar ? <RightSidebar /> : null}
        </React.Fragment>
    )
}

Layout.propTypes = {
    changeLayout: PropTypes.func /*  */,
    changeLayoutWidth: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any
}

export default withRouter(Layout)
