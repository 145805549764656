import { toast } from "react-toastify"
import { get, post } from "../helpers/api_helper"

export async function getQuestion(keyword) {
    try {
        return await get(`talk2me/rooms/list?keyword=${keyword}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}
export async function createRoom(body) {
    try {
        return await post(`talk2me/rooms/batch`, body)

    } catch (err) {
        // TODO: 임시처리, 주석 코드 추후 리팩토링 필요
        // err.response.data.errors.errorMessage.map(item => toast.error(item))
        console.log("err:::",err)
        toast.error(`${err?.response?.data?.place_holder}`)
        throw err
    }
}
