// import { get, post } from "../helpers/api_helper_payment"
import {get, post} from '../helpers/api_helper';
import config from "../config/configuration"

export async function getPaymentSucceed(paymentKey, orderId, amount) {
    try {
        return await get(`/pg/succeed?paymentKey=${paymentKey}&orderId=${orderId}&amount=${amount}`, {baseURL: config.api.payment_api})
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getPaymentFail(code, orderId, message) {
    try {
        return await get(`/pg/succeed?code=${code}&orderId=${orderId}&message=${message}`, {baseURL: config.api.payment_api})
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function paymentPreTransactionService(body) {
    try {
        return await post("/payment/pg/pre-transaction", body, {baseURL: config.api.payment_api})
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getChargingList(userId) {
    try {
        return await get(`/${userId}/web/deposit/charging-history`, {baseURL: config.api.payment_api})
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getChargingListOwner(userId) {
    try {
        return await get(`/${userId}/web/deposit/product-history`, {baseURL: config.api.payment_api})
    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function myDiamondList(userId) {
    try {
        return await get(`/payment/${userId}/deposit`, {baseURL: config.api.payment_api})
    } catch (err) {
        console.error(err)
        throw err
    }
}

// 전송패키지 상품 조회
export async function getPurchaseProductInfo(roomId, userId) {
    try {
        return await get(`/talk2you/rooms/${roomId}/product?userId=${userId}`, {baseURL: config.api.chat_api}, true); // 채팅 API 쪽에 해당 기능이 있다.
    } catch (err) {
        console.error(err)
        throw err
    }
}

// 전송패키지 상품 구매
export async function purchaseProduct(roomId, userId, productId, depositType) {
    try {
        const body = {
            userId,
            depositType
        }
        return await post(`/talk2you/rooms/${roomId}/product/${productId}`, body, {baseURL: config.api.chat_api}, true); // 채팅 API 쪽에 해당 기능이 있다.
    } catch (err) {
        console.error(err)
        throw err
    }
}