import React, { useState } from "react"
import { toast } from "react-toastify"
import { Label } from "reactstrap"
import { sendReportUserFunction } from "../helper/Functions/SupportFunctions"

export default function ReportAbuse(props) {
    const { user, targetUserId } = props

    const [loading, setLoading] = useState(false)
    const [reportTextCount, setReportTextCount] = useState(0)
    const [reportAbuseCase, setReportAbuseCase] = useState()
    const [reportText, setReportText] = useState("")

    // User Report
    const sendReportFunction = async () => {
        console.log(
            reportAbuseCase,
            " case",
            reportText,
            "reportText",
            user,
            "user",
            targetUserId,
            "target id"
        )
        if (reportAbuseCase && reportText) {
            let data = {
                userId: user.id,
                targetId: targetUserId,
                abuseCase: Number(reportAbuseCase),
                abuseDesc: reportText,
                evidenceList: []
            }
            setLoading(true)
            const response = await sendReportUserFunction(data)
            setLoading(false)
            if (response.message === "Created") {
                // toast.success("report user successful")
                toast.success("사용자를 신고했습니다.")
            } else {
                toast.error("사용자 신고를 완료하지 못했습니다.")
            }
            props.onChangeMenu("chatContainer")
        } else {
            // toast.error("report information is missing")
            toast.error("신고 내용 중 누락된 정보가 있습니다.")
        }
    }

    return (
        <div className="room-list">
            <div className="room-item-header d-flex align-items-center">
                <div className="room-item-close-button">
                  <span
                      className="la la-close"
                      onClick={e => {
                          props.onChangeMenu("chatContainer")
                      }}
                  />
                </div>
                <div className="room-item-header-title font-weight-bold">신고하기</div>
            </div>
            <div className="viewContents top-report-alert ml-3">
                <small>
                    주의 : 의도적으로 잘못된 정보를 신고하는 하는 경우 신고한 사람이
                    <br/>
                    제재를 받을 수 있습니다
                </small>
            </div>
            <div className="ml-5">
                <div className="ml-2">
                    신고 유형<br/>
                </div>
                <div
                    onChange={value => {
                        setReportAbuseCase(value.target.value)
                    }}
                >
                    <input type="radio" name="report" className="mb-2 mr-2" value="1" />
                    욕설 또는 인신공격<br/>
                    <input type="radio" name="report" className="mb-2 mr-2" value="2" />
                    음란 또는 성적인 비하<br/>
                    <input type="radio" name="report" className="mb-2 mr-2" value="3" />
                    기타 (불법정보 등)
                </div>
            </div>
            <div className="ml-2 mr-2">
                <Label for="exampleText" style={{ textAlign: "end" }}>
                    {reportTextCount}/200
                </Label>
                <textarea
                    rows={5}
                    className="form-control pr-2 pl-2"
                    onChange={e => {
                        if (e.target.value.length <= 200) {
                            setReportTextCount(e.target.value.length)
                            setReportText(e.target.value)
                        }
                    }}
                />
                {/*<div className="input-box">*/}
                {/*    <label className="fs-14 text-black fw-small">미디어 자료(선택)</label>*/}
                {/*    <div className="file-upload-wrap file-upload-layout-2">*/}
                {/*        <input*/}
                {/*            type="file"*/}
                {/*            name="files[]"*/}
                {/*            className="file-upload-input"*/}
                {/*            multiple=""*/}
                {/*            style={{ height: "50px", width: "50px" }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="room-body d-flex align-items-center">
                <button
                    onClick={() => sendReportFunction()}
                    className="btn btn-success text-center"
                    style={{ width: "100%" }}
                >
                    신고 접수하기
                </button>
            </div>
        </div>
    )
}
