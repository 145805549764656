import React, { useEffect, useState } from "react"

import "../../../assets/css/myStyleChat.css"
import "../../../assets/css/chat/ChatRoom.scss"
import "../../../assets/css/fontAwesome.css"
import "../../../assets/css/myChatSiderBar.css"
import "./NewLogin.css"
import AnswerChatRoom from "pages/MySettings/AnswerChat/ChatContainerModal"
import { useLocation, useParams } from "react-router"
import { checkPathId } from "services/checkProfilePath.service"
import { authService } from "services/auth.service"
import { checkPermission, createRoom, enterRoom, getUserProfileService } from "services/talk2youRooms.service"
import ScreenOperator from '../../LandingPage/ScreenOperator';
import { toast } from "react-toastify"

import Intro from '../../LandingPage/Intro';

import './LandingPageChat.scoped.scss';

export default function ModalExample(props) {
    // console.log(props)
    const location = useLocation()
    const [isOperating, setIsOperating] = useState(true);
    const [isLoadingPageProfile, setIsLoadingPageProfile] = useState(true);
    const [isBlocked, setIsBlocked] = useState(false);
    const [layout, setLayout] = useState("auth")
    const [referer, setReferer] = useState('question')
    const [ownerId, setOwnerId] = useState('')
    const [pageProfile, setPageProfile] = useState(null);
    const [user,setUser] = useState(null)
    const [roomId, setRoomId] = useState('')
    const params = useParams()
    const [renewId, setRenewId] = useState('')
    const [screen, setScreen] = useState({
        message: '데이터를 불러오는 중입니다!',
    });
    const [isRoomProcessing, setIsRoomProcessing] = useState(false);

    const buyDiamonds = () => {
        window.open('/buyDiamonds');
    }

    const changeUserInfo = user => {
        setUser(user)
    }

    const changeReferer = ref => {
        setReferer(ref)
    }

    const getUser = async () => {
        try {
            const sessionUserData = sessionStorage.getItem('user_data');
            const userId = sessionUserData ? JSON.parse(sessionUserData)?.id : await authService.getUserId().then(res => {
                if (res.status !== 201) {
                    const err = new Error('Failed get User ID');
                    err.code = 'failed-get-user-id';

                    throw err;
                }

                return res?.data?.user_id || '';
            });
            const accessToken = sessionStorage.getItem('access_token') || await authService.getACToken().then(res => {
                console.log('accessToken', res);
            });

            if (userId && accessToken) {
                return await authService.getUserInfo(userId).then(res => {
                    if (res.status) {
                        console.log('getUserInfo running occured', res.status);
                        const err = new Error('Error for getUserInfo?');
                        err.code = 'user-information-getting-error';

                        throw err;
                    }
                    console.log('getUserInfo', res);

                    return res;
                });
            } else {
                setIsOperating(false);
                return null;
            }
        } catch (err) {
            setIsOperating(false);
            return null;
        }
    }

    useEffect(() => {
        const path = params.userId;
        const getProfile = async () => {
            try {
                const pathData = await checkPathId(path).then(res => {
                    if (res?.status === 204) {
                        const err = new Error('pathId를 찾을 수 없음');
                        err.code = 'path-id-not-found';
                        err.status = 404;
                        throw err;
                    }

                    return res?.data?.itemList;
                });

                setOwnerId(pathData.user_id || null);

                return await getUserProfileService(pathData.user_id).then(res => {
                    const items = res?.itemList || [];
                    return items[0];
                });
            } catch (e) {
                console.error('방정보 가져오기 에러');
                setIsBlocked(true);
                setScreen({
                    code: 404,
                    message: '해당 페이지의 정보를 찾을 수 없습니다.',
                });
                return null;
            }
        }

        getProfile().then(profile => {
            if (profile) {
                setOwnerId(profile.userId);
                setPageProfile(profile);

                console.log('profile', profile);

                setIsLoadingPageProfile(false);
            }
        });

        getUser().then(user => {
            console.log('user', user);
            if (!user) {
                setIsOperating(false); return;
            }
            setUser(user);
        });
    }, [])

    useEffect(() => {
        if (!user?.id || !ownerId) return;

        // 방을 만들고, 들어가고 UI change
        const checkRoom = async () => {
            return await checkPermission({
                ownerId: ownerId,
            }, user.id).then(res => {
                const status = res.status;
                const invalid = res.data?.invalid;

                console.log('%c check-permission', 'background: rgba(255,0,0,0.3);color:rgba(255,0,0,1);', res);

                let code;
                switch(status) {
                    case 200: code = invalid ? 'self' : 'exist'; break;
                    case 204: code = 'ok'; break;
                    case 403: code = 'forbidden'; break;
                    default: code = 'other-case';
                }

                if (code === 'self' || code === 'forbidden') {
                    const err = new Error(code === 'forbidden' ?
                        '입장 불가! 방장에 의해서 내보내기를 당한 유저는 입장할 수 없습니다.'
                        : '입장불가! 자신의 방에 입장할 수 없습니다.');
                    err.code = code === 'forbidden' ? 'error-forbidden-user'
                        : 'error-user-is-owner';

                    throw err;
                }

                return {
                    code: code,
                    rooms: res?.data?.itemList || [],
                }
            });
        }

        const makeRoom = async () => {
            try {
                const rooms = await createRoom({
                    userId: user.id,
                    ownerId: ownerId,
                    roomType: "talk2you",
                }).then(res => {
                    console.log('%c check make room', 'background-color:rgba(0,0,255,0.3);color:blue;', res);
                    const roomsType = Object.prototype.toString.call(res?.itemList).slice(8, -1);
                    if (roomsType !== 'Array') {
                        let err = new Error('채팅방을 만드는 과정에서 오류 발생');
                        err.response = res;
                        throw err;
                    }

                    return res.itemList;
                })

                return rooms[0];
            } catch (e) {
                console.log(`%c 채팅방을 만드는 과정에서 오류 발생`, 'background-color:rgba(255,0,0,0.4);color:red;', e);
                throw e;
            }
        }

        const participateRoom = async () => {
            if (isRoomProcessing) return;
            setIsRoomProcessing(true);
            try {
                const permission = await checkRoom();

                const room = permission.rooms?.length > 0 ? permission.rooms[0] : await makeRoom();

                setRoomId(room.roomId);
                await enterRoom({
                    userId: user.id,
                    userName: user.name || user.nickname,
                    userImg: user.image_url,
                }, room.roomId).then(res => {
                    console.log('%c enterRoom-res', 'background:rgba(255,255,0,0.3);color: rgba(255,0,0,1);', res);
                    const status = res?.status || res?.response?.status;
                    if (status === 403) {
                        const err = new Error('입장 불가! 방장에 의해서 내보내기를 당한 유저는 입장할 수 없습니다.');
                        err.code = 'error-forbidden-user';

                        throw err;
                    } else if (status > 201) {
                        throw res?.response || res;
                    }

                    return res;
                })
                setLayout('chat');
            } catch (e) {
                if (e.code && e.message) {
                    console.log('chat-room-enter-error', {
                        message: e.message,
                        code: e.code,
                    })

                    switch(e.code) {
                        case 'error-user-is-owner': setScreen({
                            code: 403,
                            message: e.message,
                        }); setIsBlocked(true); break;
                        case 'error-forbidden-user': setScreen({
                            code: 403,
                            message: e.message,
                        }); setIsBlocked(true); break;
                        default: toast.error(e.message);
                    }

                    console.error(e);
                    return;
                }

                console.log(`%c 채팅방 permission 체크 및 방입장 프로세스 과정에서 오류가 발생??`, 'background-color:rgba(255,0,0,0.4);color:red;', e);
                console.error(e);
            } finally {
                setIsRoomProcessing(false);
            }
        }

        participateRoom().then(r => {
            setIsOperating(false);
        });
    }, [ownerId, user])


    return (
        <div id={props.idType} className="landing-page-chat-wrapper" style={{
            // position: 'relative',
            minHeight: '100vh',
            display: 'flex',
            // backgroundColor: '#ccc',
        }}>
            {(isOperating || isLoadingPageProfile || isBlocked) && (
                <ScreenOperator message={screen?.message} status={screen?.code} />
            )}
            {!isLoadingPageProfile && !isBlocked && layout === "auth" && (
                // <Auth setRenewId={setRenewId} changeAuthLayout={changeAuthLayout} setOwnerId={setOwnerId} ownerId={ownerId} setRoomId={setRoomId} setUser={setUser} setLayout={setLayout} referer={referer} {...props} />
                <Intro {...props}
                       ownerId={ownerId}
                       ownerPath={params.userId}
                       isAnonymousMode={true}
                       changeUserInfo={changeUserInfo}
                       setUser={setUser}
                       pageProfile={pageProfile}
                />
            )}
            {!isOperating && !isBlocked && layout === "chat" && (
                <AnswerChatRoom roomId={roomId} ownerId={ownerId} user={user} isEmbeddedView={true} doChargeAction={buyDiamonds}/>
            )}

            {/*<div>{isOperating ? 'true' : 'false'}</div>*/}
            {/*<div>{isLoadingPageProfile ? 'true' : 'false'}</div>*/}
        </div>
    )
}
