import {memo} from 'react';

import images13 from '../assets/images/12.jpg';
import Avatar from './Common/Avatar';
import {displayedAt} from '../common/utils/functions';
import React from 'react';

const QuestionListItem = ({question, name, activeUsersCount = 0, relCategory = [], relRegion= [], rewardType, rewardCount = 0, owner = {}, setSelectedValue, activeChange, userInfo, ...props}) => {
    const currentItem = {question, name, activeUsersCount, relCategory, relRegion, rewardType, rewardCount, owner, ...props};

    return (
        <div className="table-responsive">
            <div className="media media-card align-items-center">
                <div className="card-body">
                    <article className="media">
                        <div className="mr-3">
                            <img
                                className="w150 slider-customize-height img-fit"
                                src={
                                    question?.images?.url ? question?.images?.url : images13
                                }
                                alt={question?.title}
                            />
                        </div>
                        <div className="media-body">
                            <div className="content">
                                <div className="w-100">
                                    <div
                                        className="listTxtTit justify-content-between d-flex">
                                        {name ? name : "No title"}
                                    </div>
                                    <div className="mt-2 d-flex">
                                        <div className="listInfo01 fs-14">
                                            <p className="pr-1">
                                                <i className="las la-user mr-1" />
                                                {activeUsersCount} people
                                            </p>
                                            <p className="pr-1">
                                                <i className="las la-th-large mr-1" />
                                                {relCategory !== null
                                                    ? relCategory.map(
                                                        (item, index) => {
                                                            return item?.name
                                                        }
                                                    ).join(", ")
                                                    : "No category"}
                                            </p>
                                        </div>
                                        <div className="listInfo02 fs-14">
                                            <p className="pr-1">
                                                <i className="las la-coins mr-1" />
                                                {/*100*/}
                                                {rewardType ? rewardCount + " " + rewardType : ""}
                                            </p>
                                            <p className="pr-1">
                                                <i className="la la-map-marker mr-1" />
                                                {relRegion !== null
                                                    ? relRegion.map(
                                                        (item, index) => {
                                                            return item?.upmyeondongName
                                                        }
                                                    ).join(", ")
                                                    : "No location"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="nicname">
                                    <div className="fs-14">
                                        <div className="profileImg mr-1">
                                            <Avatar src={owner?.imageUrl} alt={owner?.nickname || ''} bordered={false} />
                                        </div>
                                        {owner
                                            ? owner.nickname
                                            : "No name"}
                                    </div>
                                    <span className="time fs-14">
                                        <i className="las la-clock" /> {displayedAt(props?.createdAt)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="media-foot">
                            <button
                                className="btn btn-outline-secondary round"
                                onClick={e => {
                                    if (setSelectedValue) setSelectedValue(currentItem);
                                    if (activeChange) activeChange("sideBar_question");
                                }}
                            >
                                {userInfo && userInfo.id === owner.id
                                    ? "내 질문" : "답변하기"
                                }
                            </button>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}

export default memo(QuestionListItem);