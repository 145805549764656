import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// import { get } from "../helpers/api_helper_common"
// import { get as getSearch } from "../helpers/api_helper_search"
import { get } from "../helpers/api_helper"
import config from "../config/configuration"

const getCategories = async function() {
    try {
        return await get(`/categories/talk?type=`,{baseURL: config.api.common_api})

    } catch (err) {
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}
const getAnswerList = async function(keyword, limit, offset) {
    try {
        // return await getSearch(`/search/talk2you-profile?keyword=${keyword}&limit=${limit}&offset=${offset}`)
        return await get(`/search/talk2you-profile?keyword=${keyword}&limit=${limit}&offset=${offset}`, {baseURL: config.api.search_api})

    } catch (err) {
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}
const getQuestionList = async function(keyword, limit, offset) {
    try {
        // return await getSearch(`/search/talk2me-room/search-keyword?searchKeyword=${keyword}`)
        return await get(`/search/talk2me-room/search-keyword?searchKeyword=${keyword}&offset=${offset}&limit=${limit}`, {baseURL: config.api.search_api})

    } catch (err) {
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}

const searchCategories = async function(keyword, offset, limit) {
    try {
        return await get(`/categories/talk/search?keyword=${keyword}&offset=${offset}&limit=${limit}`,{baseURL: config.api.common_api})

    } catch (err) {
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}
const getKeyWordList = async function(categoryId, userId) {
    try {
        return await get(`/categories/talk/${categoryId}/keyword?userId=${userId}&offset=0&limit=5`,{baseURL: config.api.common_api})

    } catch (err) {
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}

const searchDistrictCategories = async function(keyword, offset, limit) {
    try {
        return await get(`/categories/district/search?keyword=${keyword}&offset=${offset}&limit=${limit}`,{baseURL: config.api.common_api})

    } catch (err) {
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}
export const categoryService = {
    getCategories,
    searchCategories,
    searchDistrictCategories,
    getKeyWordList,
    getAnswerList,
    getQuestionList
}
