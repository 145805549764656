import {lazy, Suspense, memo, useMemo, useEffect} from 'react';
import TypeLoader from '../TypeLoader';

import './ContainerLoader.scoped.scss';

const EmptyContainer = (props) => {
    return (
        <div className="chat-lefted-text d-inline-block">
            <TypeLoader/>
        </div>
    )
}

const LazyTextContainer = lazy(() => import('./Text').then(
    module => (module)
));

const LazyImageContainer = lazy(() => import('./Image').then(
    module => (module)
));

const LazyVideoContainer = lazy(() => import('./Video').then(
    module => (module)
));

const LazyCustomContainer = lazy(() => import('./Custom').then(
    module => (module)
));

const ContainerLoader = (props) => {
    const {data} = props;
    const memo = useMemo(() => props, [props]);

    return (
        <Suspense fallback={<EmptyContainer/>}>
            {
                {
                    'TEXT': <LazyTextContainer {...memo}/>,
                    'VIDEO': <LazyVideoContainer {...memo}/>,
                    'PHOTO': <LazyImageContainer {...memo}/>,
                    'CUSTOM': <LazyCustomContainer {...memo}/>
                }[data.msgType] || <LazyTextContainer {...memo}/>
            }
        </Suspense>
    )
}

export default memo(ContainerLoader);