const moment = require('moment');

module.exports = {
    computeOffset,
    axiosErrorHandler,
    computeDateForPaging,
    computeRangeOfDate,
    validateParam,
    validateISO8601,
    validateNumber,
    validateBoolean,
    isEmpty,
    isEmptyObject,
    isNum,
    replaceKebabCase,
    displayedAt,
    compareArray,
    divideArray,
    getDivision,
    numberFormat,
}

function computeOffset(perPage, pageNum) {
    return (pageNum - 1) * perPage;
}

function axiosErrorHandler(err) {
    if (err.response) {
        console.debug(err.response.status + ": " + err.response.data.message);
        return {
            status: err.response.status,
            message: err.response.data.message
        };
    } else {
        return {
            status: 500,
            message: err.message
        };
    }
}

//topStaticCount: 테이블 상단에 있는 고정 row 수
function computeDateForPaging(perPage, pageNum, startDate, endDate, topStaticCount) {
    const range = computeRangeOfDate(startDate, endDate);
    if (range > perPage) {
        const offset = computeOffset(perPage, pageNum);
        let subtractCount = offset === 0 ? offset : offset - topStaticCount;

        const momentEnd = moment(endDate).utc();
        const momentStart = moment(startDate).utc();

        const subEndDate = momentEnd.subtract(subtractCount, 'days');
        const endDateString = subEndDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');

        if (Number(pageNum) === 1) {
            subtractCount = perPage - topStaticCount;
        } else {
            subtractCount = perPage;
        }

        const subStartDate = subEndDate.subtract(subtractCount, 'days')
        .set({'h': momentStart.get('h'), 'm': momentStart.get('m'), 's': momentStart.get('s'), 'ms': momentStart.get('ms')});

        return {
            startDate: subStartDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: endDateString
        };
    }

    return {
        startDate,
        endDate
    };
}

function computeRangeOfDate(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start, 'days'), 'days');
    return duration.asDays() + 1;
}

function validateParam(value) {
    let error;
    if (isEmpty(value)) {
        error = new Error('Client specified an invalid argument.');
        error.status = 400;
        throw error;
    }

    return value;
}

function validateISO8601(date) {
    let error;
    if (!moment(validateParam(date), 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
        console.debug('validateISO8601');
        error = new Error('Client specified an invalid argument.');
        error.status = 400;
        throw error;
    }

    return date;
}

function validateNumber(value) {
    let error;
    if (isNaN(validateParam(value)) || value <= 0) {
        error = new Error('Client specified an invalid argument.');
        error.status = 400;
        throw error;
    }

    return value;
}

function validateBoolean(value) {
    let error;
    if (String(value) !== 'true' && String(value) !== 'false') {
        error = new Error('Client specified an invalid argument.');
        error.status = 400;
        throw error;
    }

    return value;
}

function isEmpty(value) {
    // if (value === "" || value == null || value == undefined || ( value != null && typeof value == 'object' && !Object.keys(value).length)) {
    //     return true;
    // } else {
    //     return false;
    // }
    return  value == null || value === "" || (typeof value == 'object' && !Object.keys(value).length);
}
function isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
}
function isNum(s) {
    s += '';
    s = s.replace(/^\s*|\s*$/g, '');
    return !(s === '' || isNaN(s));
}

function replaceKebabCase(value) {
    return value.toLowerCase().replace(/_/gi, '-');
}

function displayedAt(createdAt) {
    const milliSeconds = new Date() - new Date(createdAt)
    const seconds = milliSeconds / 1000
    if (seconds < 60) return `방금 전`
    const minutes = seconds / 60
    if (minutes < 60) return `${Math.floor(minutes)}분 전`
    const hours = minutes / 60
    if (hours < 24) return `${Math.floor(hours)}시간 전`
    const days = hours / 24
    if (days < 7) return `${Math.floor(days)}일 전`
    const weeks = days / 7
    if (weeks < 5) return `${Math.floor(weeks)}주 전`
    const months = days / 30
    if (months < 12) return `${Math.floor(months)}개월 전`
    const years = days / 365
    return `${Math.floor(years)}년 전`
}

function compareArray(origin, target) {
    origin.sort();
    target.sort();

    if (origin.length !== target.length) return false;

    for (let i = 0; i < target.length; i++) {
        if (origin[i] instanceof Array && target[i] instanceof Array) {
            if (!origin[i].equals(target[i])) return false;
        } else if (origin[i] !== target[i]) {
            return false;
        }
    }
    return true;
}

function divideArray(arr, n) {
    // const arr = this;
    const len = arr.length;
    const cnt = Math.floor(len / n);
    const tmp = [];

    for (let i = 0; i <= cnt; i++) {
        tmp.push(arr.splice(0, n));
    }

    return tmp;
}

function getDivision(arr, n) {
    n = n || 100;
    const division = [];
    const len = arr.length;
    const cnt = Math.floor(len / n) + (Math.floor(len % n) > 0 ? 1 : 0);
    for (let i=0; i < cnt; i++) {
        division.push(arr.splice(0, n));
    }
    return division;
}

function numberFormat(num) {
    if(!num) return 0;
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    return num.toString().replace(regexp, ',');
}