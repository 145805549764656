import React from "react"
import moment from "moment"
import replyImg01 from "../../../assets/images/replyImg01.jpg"

export default function ChatRoomCol(props) {
    const { item, index, type, offset, limit, totalRecordCount } = props

    return (
        <tr className="align-items-center" key={`${item.userId}+${index}`}>
            {/* <td className="text-center hidden-xs">{index + 1}</td> */}
            <td className="text-center hidden-xs">{totalRecordCount - offset - index}</td>
            <td>
                <div className="talkRoomImg">
                    <img src={item.ownerProfileImage || item.representativeImg} alt="talkRoomImg" />
                </div>
            </td>
            <td className="hidden-xs">
                <div className="text-muted">
                    {item.roomType === "talk2you" ? "답변방" : "질문방"}
                </div>
            </td>
            <td className="hidden-xs">
                <div className="text-muted">{item.ownerName}</div>
            </td>
            <td>
                <div className="text-muted">
                    <p className="maxTxt">{item.roomType === "talk2you" ? (props.userId === item.ownerId ? `나의 답변방(with ${item.partnerName})` : `${item.ownerName}님의 답변방`) : item.name} </p>
                    {/* <p className="maxTxt">{item.name}-{offset}-{limit}-{totalRecordCount} </p> */}
                </div>
            </td>
            <td className="hidden-xs">
                <div className="text-muted">
                    {item.lastMessagedAt && moment(item.lastMessagedAt).format("YYYY-MM-DD HH:mm") || ''}
                </div>
            </td>
            <td className="hidden-xs">
                <div className="text-muted">{item.activeUsersCount}</div>
            </td>
            <td className="hidden-xs">
                <div className="text-muted">
                    {moment(item.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
            </td>
            <td className="hidden-xs">
                <div
                    className="text-muted"
                    onClick={() => props.openChatRoom({ item, type })}
                >
                    <div className={item.roomType === "talk2you"
                                        ? (props.userId === item.ownerId ? "btn btn-outline-secondary round" : "btn btn-outline-primary round")
                                        : (props.userId === item.ownerId ? "btn btn-outline-primary round" : "btn btn-outline-secondary round")
                                    }
                    >
                        {item.roomType === "talk2you"
                            ? (props.userId === item.ownerId ? "답변하기" : "질문하기")
                            : (props.userId === item.ownerId ? "질문하기" : "답변하기")
                        }
                    </div>
                </div>
            </td>
        </tr>
    )
}
