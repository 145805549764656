import React, {useEffect, useState} from 'react';
import { Spinner } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import { checkVideoUploadingFunction } from "../../helper/Functions/SupportFunctions"

export default function Video(props) {
    const { message, completedAction } = props
    const [loadingVideo, setLoadingVideo] = useState()
    const [selectedValue, setSelectedValue] = useState({})

    const checkVideoUploading = async (fileOrigin, jobId) => {
        setLoadingVideo(true)
        setSelectedValue({ jobId })
        let videoData = {
            type: "VIDEO",
            file: fileOrigin,
            jobId,
            screen: "imageAndVideo"
        }
        try {
            const response = !jobId || await checkVideoUploadingFunction(jobId).then((res)=>{
                const {status} = res;
                // AWS MEDIACONVERT STATUS MESSAGE
                // COMPLETE: 완료, SUBMITTED: 작업 제출, PROGRESSING: 작업 중, ERROR: 작업 실패, CANCELED: 작업 취소
                switch(status) {
                    case 'COMPLETE': return true;
                    case 'SUBMITTED': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
                    case 'PROGRESSING': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
                    case 'CANCELED': throw new Error('비디오 업로드가 취소되었습니다.')
                    case 'ERROR': throw new Error('비디오 업로드에 실패하였습니다.')
                    default: throw new Error('다시 한번 시도해 주세요.')
                }
            })
            if(response === true) {
                setSelectedValue(videoData)
                props.imageAndVideoDetail(videoData)
            }
        } catch(err) {
            toast.error(`${err}`)
        } finally {
            setLoadingVideo(false)
        }
//         if (response.status === "COMPLETE") {
//             let videoData = {
//                 type: "VIDEO",
//                 file: fileOrigin,
//                 jobId,
//                 screen: "imageAndVideo"
//             }
//             setSelectedValue(videoData)
//             props.imageAndVideoDetail(videoData)
//         } else if (response.status === "SUBMITTED" || response.status === "PROGRESSING") {
//             // toast.info("This video uploading, please wait for a moment")
//             toast.info("비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.")
//         } else if(response.status === "CANCELED") {
//             // toast.error("Video cannot be played")
//             toast.error("비디오를 재생할 수 없습니다.")
//         } else if (response.status === "ERROR") {
//             toast.error("비디오를 재생할 수 없습니다.")
//         }
//         setLoadingVideo(false)
    }

    useEffect(() => {

        if (!completedAction) return false;
        completedAction()
    }, [message])

    return (
        <div
            className="media_img chat-lefted-image d-inline-block"
            onClick={() => {
                checkVideoUploading(message.fileOrigin, message.jobId)
            }}
        >
            <div className="video_container" style={{
                aspectRatio: 1/message?.imgRatio || 1,
                backgroundColor: '#ebebeb',
                width: 150,
            }}>
                <img src={message.message} className="img-thumbnail" alt="" onLoad={props.completedAction} onLoadedMetadata={ev => {console.log('onloadedmetadata', ev)}} style={{
                    objectFit: 'cover',
                }} />
                <FontAwesomeIcon icon={faPlay} color={"white"} className="video" />

                {/* <i className="fas fa-play-circle video"> */}
                {loadingVideo && selectedValue.jobId === message.jobId && (
                    <Spinner animation="border" variant="primary">
                        {" "}
                    </Spinner>
                )}
                {/* </i> */}
            </div>
        </div>
    )
}
