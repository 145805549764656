import {createRef, useMemo} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import VideoPlayer
    from '../../../pages/MySettings/AnswerChat/chatComponents/type/VideoPlayer';

import erroredImage from 'assets/images/image-errored.webp';

import './MessageViewer.scoped.scss';

const ImageViewer = (props) => {
    const imageView = createRef();

    return (
        <article className="image-view-container">
            <img src={props.fileOrigin} alt="" className="original-image"
                 onError={(error) => {imageView.current.src=erroredImage}}
                 ref={imageView}/>
        </article>
    )
}

const VideoViewer = (props) => {
    const {imgRatio, fileOrigin} = props;
    const orientation = imgRatio > 0 ? 'portrait' : 'landscape';

    const w = orientation === 'landscape' ? '100%' : `${(1 / imgRatio)*100}%`;
    const h = orientation === 'portrait' ? '100%' : `${imgRatio*100}%`;

    return (
        <VideoPlayer fileOrigin={fileOrigin} width={w} height={h} {...props} />
    )
}

const MessageViewer = (props) => {
    const {senderName, fileOrigin, message, msgType, createdAt} = props;
    const type = msgType || 'PHOTO';

    const displayTime = useMemo(() => {
        try {
            return moment(createdAt).format('YYYY-MM-DD');
        } catch (err) {
            console.error(err);
        };
    }, [createdAt]);

    return (
        <div className="flowing-ui--chat-message-viewer">
            <header className="flowing-ui--chat-message-viewer__header">
                <div className="d-flex align-items-center">
                    <div className="mr-10">
                        <button className="icon-btn" onClick={() => {props.setFade(false)}}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                    </div>
                    <div>
                        <div className={'title'}>{senderName && senderName !== 'BOT' ? senderName : '답변 도우미' || ''}</div>
                        {createdAt && (
                            <div className={'date-view'}><small>{displayTime}</small></div>
                        )}
                    </div>
                </div>
            </header>
            <section className="flowing-ui--chat-message-viewer__body">
                {
                    {
                        'PHOTO': <ImageViewer {...props} />,
                        'VIDEO': <VideoViewer {...props} />,
                    }[type] || <ImageViewer {...props} />
                }
            </section>
            <footer className="flowing-ui--chat-message-viewer__footer">
                {/*Footer*/}
            </footer>
        </div>
    )
}

export default MessageViewer;