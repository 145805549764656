import React, { useRef, useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action

//redux

import { withRouter, Link } from "react-router-dom"

//import auth services
import { authService } from "../../services/auth.service"
import { a } from "aws-amplify"
import { Alert } from "reactstrap"

const AccountLogin = props => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [phoneAuthCode, setPhoneAuthCode] = useState("")
    const [isPhoneAuthTrue, setIsPhoneAuthTrue] = useState(false)
    const [ref_url, setRefUrl] = useState("")

    const [phoneAuthCodeTime, setPhoneAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [phoneAuthCodeTimeIn, setPhoneAuthCodeTimeIn] = useState(false)



    const [isValuableAddEmail, setIsValuableAddEmail] = useState(false)

    const [userId, setUserId] = useState('')

    const [email, setEmail] = useState("")
    const [emailAuthCode, setEmailAuthCode] = useState('')
    const [isEmailAuthTrue, setIsEmailAuthTrue] = useState(false)
    const [emailAuthCodeTime, setEmailAuthCodeTime] = useState(0)
    // const emailTimerRef = useRef(180)
    const [emailAuthCodeTimeIn, setEmailAuthCodeTimeIn] = useState(false)

    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')


    const secToMMSS = (sec) => {
        if (sec > 0) return `0${~~(sec / 60)}:${sec % 60<10 ? '0':''}${sec % 60}`
        else return '00:00'
    }
    useInterval(() => {
        setEmailAuthCodeTime(emailAuthCodeTime - 1)
    }, emailAuthCodeTime > 0 ? 1000 : null)

    // 번호 인증 타이머
    let sec = 60
    let min = 1
    let time = 60000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setPhoneAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                
                timerRef.current = null
                setPhoneAuthCodeTimeIn(false)
                setPhoneAuthCodeTime("0:00")
            }
        }
    }

    // const emailTimer = () => {
    //     time = time - 1000 //1초씩 줄어듦
    //     min = time / (60 * 1000) //초를 분으로 나눠준다.

    //     if (sec > 0) {
    //         //sec=60 에서 1씩 빼서 출력해준다.
    //         sec = sec - 1
    //         setEmailAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
    //     } else {
    //         if (timerRef.current) {
    //             clearInterval(timerRef.current)
                
    //             timerRef.current = null
    //             setEmailAuthCodeTimeIn(false)
    //             setEmailAuthCodeTime("0:00")
    //         }
    //     }
    // }

    const requestCodeFunction = async () => {
        props.setIsAlert(() => false)
        /* 
            해당 코드의 의도 : 버튼 중복클릭에 대한 방어
            TODO: 중복클릭에 대한 방어적인 로직과 디자인 일관성을 맞출 수 있도록 한다.
         */
        if(phoneNumber.trim()==='') {
            props.setAlertMessage(() => '핸드폰 번호를 입력해 주세요')
            props.setIsAlert(() => true)
            toast.error('핸드폰번호를 입력해 주세요')
            return;
        }
        alert('인증번호 전송을 요청합니다.')

        const result = await authService.requestCode(phoneNumber)
        if (result === "success") {
            setPhoneAuthCodeTimeIn(true)
            if(timerRef?.current!==undefined){
                clearInterval(timerRef.current)
            }
            timerRef.current = setInterval(timer, 1000)
            props.setAlertMessage(() => "메시지를 확인해 주세요!")
            props.setIsAlert(() => true)
        } else {
            setPhoneAuthCodeTimeIn(false)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
        }
    }
    const requestEmailCodeFunction = async () => {
        props.setIsAlert(() => false)
        /* 
            해당 코드의 의도 : 버튼 중복클릭에 대한 방어
            TODO: 중복클릭에 대한 방어적인 로직과 디자인 일관성을 맞출 수 있도록 한다.
         */
        if(!email.trim()) {
            props.setAlertMessage(() => '이메일을 입력해 주세요')
            props.setIsAlert(() => true)
            toast.error('이메일을 입력해 주세요')
            return;
        }
        alert('인증번호 전송을 요청합니다.')
        try {
            await authService.activateEmailRequestCode(userId, {email}).then(r => {
                if(r.status !== 201) throw r
                return r
            })
            setEmailAuthCodeTimeIn(()=>true)
            setEmailAuthCodeTime(()=>180)
            // if(emailTimerRef?.current!==undefined) {
            //     clearInterval(emailTimerRef.current)
            // }
            // emailTimerRef.current = setInterval(emailTimer, 1000)
            props.setAlertMessage(() => '이메일을 확인해 주세요')
            props.setIsAlert(() => true)
            toast.success('이메일을 확인해 주세요')
        } catch(error) {
            setEmailAuthCodeTimeIn(()=>false)
            // if(emailTimerRef.current) {
            //     clearInterval(emailTimerRef.current)
            //     emailTimerRef.current = null
            // }
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }

    }

    const checkRequestedCodeFunction = async () => {
        props.setIsAlert(() => false)
        if (!phoneAuthCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            props.setAlertMessage(() => "인증시간이 지났습니다. 다시 인증을 받아주세요.")
            props.setIsAlert(() => true)
            return
        }

        const result = await authService.checkRequestedCode(phoneNumber, phoneAuthCode, ref_url)
        if (result) {
            setIsPhoneAuthTrue(true)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
            setPhoneAuthCodeTime("인증완료")
            props.setAlertMessage(() => "번호 인증이 완료되었습니다.")
            props.setIsAlert(() => true)
        }
    }
    const checkRequestedEmailCodeFunction = async () => {
        props.setIsAlert(() => false)
        if (!emailAuthCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            props.setAlertMessage(() => "인증시간이 지났습니다. 다시 인증을 받아주세요.")
            props.setIsAlert(() => true)
            return
        }

        // const result = await authService.checkRequestedCode(phoneNumber, phoneAuthCode, ref_url)
        // if (result) {
        //     setIsPhoneAuthTrue(true)
        //     if (timerRef.current) {
        //         clearInterval(timerRef.current)
        //         timerRef.current = null
        //     }
        //     setPhoneAuthCodeTime("인증완료")
        // }
        try {
            await authService.certificateActivateEmailRequestCode(userId, {email, auth_code: emailAuthCode}).then(r => {
                if(r.status!==201)throw r
                return r
            })
            setIsEmailAuthTrue(()=>true)
            toast.success('인증이 완료되었습니다.')
            props.setAlertMessage(() => "인증이 완료되었습니다.")
            props.setIsAlert(() => true)
            // if(emailTimerRef.current) {
            //     clearInterval(emailTimerRef.current)
            //     emailTimerRef.current = null
            // }
            setEmailAuthCodeTime('인증완료')
        } catch(error) {
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }
    }

    const Login = async () => {
        props.setIsAlert(() => false)
        if (!/^([a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]).{2,7}$/.test(name)) {
            toast.error("닉네임은 3~8자로 한글, 영어, 숫자만 사용 가능합니다.")
            props.setAlertMessage(() => "닉네임은 3~8자로 한글, 영어, 숫자만 사용 가능합니다.")
            props.setIsAlert(() => true)
            return
        }
        if(!isPhoneAuthTrue) {
            toast.error('핸드폰번호 인증을 먼저 진행해 주세요')
            props.setAlertMessage(() => '핸드폰번호 인증을 먼저 진행해 주세요')
            props.setIsAlert(() => true)
            return;
        }

        // if (!name && !phoneNumber) {
        //     // return props.easyCreate()
        //     props.history.push('/register')
        // }
        try {
            // await authService.easyLogin(name, phoneNumber).then(r => {
            //     console.log(r)
            //     if(!r.user_id || !r.user_secret)throw r
            //     return r
            // })
            // console.log(response)
            // setUserId(() => response.user_id)
            const {isAccountUser, userId: newId} = await authService.addEmailValueableCheck({nickname: name, phone_number: phoneNumber}).then(r => {
                if(r.status !== 200) throw r
                return r.data
            })
            setUserId(() => newId)
            if(!isAccountUser) {
                setIsValuableAddEmail(()=>true)
                props.setAlertMessage(() => '플로잉 서비스가 이메일(계정)이 통합됩니다. 이메일(계정)을 등록해 주세요')
                props.setIsAlert(() => true)
            }
            else {
                setIsValuableAddEmail(()=>false)
                props.setAlertMessage(() => '플로잉 서비스가 이메일(계정)이 통합됩니다. 기존 등록된 이메일로 로그인해 주세요')
                props.setIsAlert(() => true)
                // props.history.push('/login')
                props.toggleVertical('2')
            }
            // console.log("response1:::", response)
            //kms add
            // if(response.user_id) {
            //     window.location.href = "/mySettings/settingUserInfo"
            // }

        } catch(error) {
            console.log(error)
            if (error?.status === 400) {
                // return props.easyCreate(phoneNumber, name)
                alert('일치하는 정보가 없습니다. 회원가입을 진행해 주세요.')
                props.history.push('/register')
            } else {
                toast.error('다시한번 시도해 주세요')
                props.setAlertMessage(() => '다시한번 시도해 주세요')
                props.setIsAlert(() => true)
            }
            throw error
        }
    }

    const setEmailPassword = async() => {
        props.setIsAlert(() => false)
        if(password !== passwordCheck) {
            toast.error('비밀번호를 확인해 주세요')
            props.setAlertMessage(() => '비밀번호를 확인해 주세요')
            props.setIsAlert(() => true)
            return;
        }
        try {
            const request = await authService.addUserEmail(userId, {email, password}).then(r => {
                // if(r.suatus!==201 )throw r
                return r
            })
            console.log(request)
            toast.success('계정이 설정되었습니다. 계정으로 로그인해주세요')
            props.setAlertMessage(() => '계정이 설정되었습니다. 계정으로 로그인해주세요')
            props.setIsAlert(() => true)
            props.toggleVertical('2')
        } catch(error) {
            console.error(error)
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }
    }
    //meta title

    return (
        <div className="loginCase01 caseview">
            {/* {isPhoneAuthTrue && ( */}
                <>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                disabled={isValuableAddEmail}
                                type="text"
                                id="InputNumber"
                                className="form-control blackBox"
                                placeholder="휴대폰 번호를 입력하세요."
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                            <span className="input-group-append">
                                <button
                                    disabled={isValuableAddEmail}
                                    className="btn btn-primary widthAuto"
                                    type="button"
                                    onClick={() => requestCodeFunction()}
                                >
                                    인증번호 발송
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                disabled={isValuableAddEmail}
                                type="text"
                                id="InputNumberCheck"
                                className="form-control blackBox"
                                placeholder="인증번호를 입력하세요."
                                onChange={e => setPhoneAuthCode(e.target.value)}
                            />
                            {/* <span className="CountNum">1:00</span> */}
                            <span className="CountNum">{phoneAuthCodeTime}</span>
                            <span className="input-group-append">
                                <button
                                    disabled={isValuableAddEmail}
                                    className="btn btn-primary widthAuto"
                                    type="button"
                                    onClick={() => checkRequestedCodeFunction()}
                                >
                                    확인
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <input
                            disabled={isValuableAddEmail}
                            type="text"
                            className="form-control blackBox"
                            id="InputNickname"
                            aria-describedby="emailHelp"
                            placeholder="닉네임을 입력하세요."
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                </>
            {/* )} */}
            {/*<div className="form-group">*/}
            {/*    <label className="custom-control custom-checkbox">*/}
            {/*        <input type="checkbox" className="custom-control-input" />*/}
            {/*        <span className="custom-control-label">로그인 정보 저장하기</span>*/}
            {/*    </label>*/}
            {/*</div>*/}

            {isPhoneAuthTrue && isValuableAddEmail && (
                <>
                    <p>이메일 통합 안내 및 비밀번호 입력 폼</p>


                    <div className="form-group">
                        <div className="input-group">
                            <input
                                type="text"
                                id="InputNumber"
                                className="form-control blackBox"
                                placeholder="이메일을 입력하세요."
                                disabled={isEmailAuthTrue}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <span className="input-group-append">
                                <button
                                    className="btn btn-primary widthAuto"
                                    type="button"
                                    disabled={isEmailAuthTrue}
                                    onClick={() => requestEmailCodeFunction()}
                                >
                                    인증번호 발송
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                disabled={isEmailAuthTrue}
                                type="text"
                                id="InputNumberCheck"
                                className="form-control blackBox"
                                placeholder="인증번호를 입력하세요."
                                onChange={e => setEmailAuthCode(e.target.value)}
                            />
                            {/* <span className="CountNum">1:00</span> */}
                            <span className="CountNum">{secToMMSS(emailAuthCodeTime)}</span>
                            <span className="input-group-append">
                                <button
                                    disabled={isEmailAuthTrue}
                                    className="btn btn-primary widthAuto"
                                    type="button"
                                    onClick={() => checkRequestedEmailCodeFunction()}
                                >
                                    확인
                                </button>
                            </span>
                        </div>
                    </div>

                    {isEmailAuthTrue && (
                        <>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control blackBox"
                                    id="InputPassword"
                                    aria-describedby="emailHelp"
                                    placeholder="비밀번호를 입력하세요."
                                    onChange={e => setPassword(e.target.value)}
                                />
                                {password && !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) && (
                                    <p style={{color:'red', fontSize:'13px'}}>
                                        영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자로 입력하세요.
                                    </p>
                                )}
                            </div>


                            <div className="form-group">
                                <div className="input-group">
                                    <input
                                        type="password"
                                        id="InputPasswordCheck"
                                        className="form-control blackBox"
                                        placeholder="비밀번호를 다시 한번 입력하세요."
                                        onChange={e => setPasswordCheck(e.target.value)}
                                    />
                                    <span className="input-group-append">
                                        <button
                                            className="btn btn-primary widthAuto"
                                            type="button"
                                            disabled={
                                                password.trim() === '' ? true :
                                                !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) ? true :
                                                passwordCheck.trim() === '' ? true :
                                                passwordCheck !== password ? true : false
                                            }
                                            onClick={() => setEmailPassword()}
                                        >
                                            완료
                                        </button>
                                    </span>
                                </div>
                                {passwordCheck && password !== passwordCheck && (
                                    <p style={{color:'red', fontSize:'13px'}}>
                                        비밀번호가 일치하지 않습니다.
                                    </p>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}

            {!isValuableAddEmail && (
                <div className="form-footer">
                    <button
                        href="index.html"
                        className="btn btn-primary btn-block btn-lg"
                        title=""
                        onClick={Login}
                    >
                        {/*Log in*/}로그인
                    </button>
                </div>
            )}
        </div>
    )
}

export default withRouter(withTranslation()(AccountLogin))

AccountLogin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
}



function useInterval(callback, delay) {
    const savedCallback = useRef(callback)
  
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
  
    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }
  
        const id = setInterval(() => savedCallback.current(), delay)
        return () => clearInterval(id)
    }, [delay])
}