import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {button} from 'aws-amplify';
import './DialogModal.scoped.scss';
import propTypes from 'prop-types';

/**
 * @param content {object} title / message / actions
 * @typeof {object} content
 * @property title {string}
 * @property message {string}
 * @property actions {dom}
 * @param toggle {function} 다이얼로그 창을 토글시키는 setState 혹은 함수 - 혹은 다이얼로그를 닫는 함수
 * @param isClosable {boolean} 닫기버튼 활성화 여부 - toggle이 상속되지 않으면 단독으로는 작동하지 않음
 * @param ...props - reactstrap Modal 컴포넌트의 상속 옵션 적용
 *
 * @returns {React.ReactComponentElement}
 * */
const DialogModal = ({content, toggle, isClosable, ...props},) => {
    console.log('DialogProps', {content, toggle, isClosable,});

    const CloseBtn = isClosable && toggle && (
        <button onClick={toggle} className="dialog-Message-modal__close" type="button">&times;</button>
    );

    return (
        <Modal className="dialog-Message-modal" {...props}>
            {props.children ? (
                <>
                    {props.children}
                </>
            ) : (
                <>
                    {content?.title && (
                        <ModalHeader toggle={isClosable && toggle} close={CloseBtn}>
                            {content?.title}
                        </ModalHeader>
                    )}
                    {content?.message && (
                        <ModalBody className="text-center">
                            {content?.message}
                        </ModalBody>
                    )}
                    {content?.actions ? (
                        <ModalFooter>
                            {content?.actions}
                        </ModalFooter>
                    ) : (isClosable && toggle) && (
                        <ModalFooter>
                            <Button onClick={toggle}>닫기</Button>
                        </ModalFooter>
                    )}
                </>
            )}
        </Modal>
    );
}

DialogModal.propTypes = {
    content: propTypes.object,
    toggle: propTypes.func,
    isClosable: propTypes.bool,
}

export default React.memo(DialogModal);