import React, { useEffect, useMemo, useRef, useState } from "react"
import { Amplify } from "aws-amplify"
import _ from "lodash"
import moment from "moment"

// import css and scss
import "../../../assets/css/myStyleChat.css"
import "../../../assets/css/chat/ChatRoom.scss"
import "../../../assets/css/fontAwesome.css"
import "../../../helpers/aws_client_iot"

import ModalContainer from "./chatRoomMenu/ModalContainer"
import ChatContent from "./chatRoomMenu/ChatContent"
import ChatHeader from "./chatRoomMenu/ChatHeader"
import Screens from "./chatRoomMenu/Screens"

// Helper functions
import {
    getRoomDataFunction,
    getMessagesFunction
} from "./helper/Functions/ChatFunctions"
import {
    checkDeadlineTimer,
    startDeadlineTimer
} from "../../../helpers/Functions/deadlineTimer"
import collapse from "bootstrap/js/src/collapse"
import { ownerMessageReadService } from "../../../services/talk2meMessage.service"
import { useIsInViewport } from "../../../helpers/Functions/useIsInViewport"
import { authService } from "services/auth.service"

export default function QuestionChat(props) {
    // Container
    const [isDataInit, setIsDataInit] = useState(false);
    const [loading, setLoading] = useState()
    const [refreshRoom, setRefreshRoom] = useState()
    const [visibleMenu, setVisibleMenu] = React.useState("chatContainer")
    const [selectedValue, setSelectedValue] = useState({})
    const [deadline, setDeadline] = useState(true)
    const [timer, setTimer] = useState("00:00:00")

    // sub menu screens and modals
    const [kickRoomModal, setKickRoomModal] = useState(false)
    const [winnerMessage, setWinnerMessage] = useState()
    const [winnerModal, setWinnerModal] = useState()

    // Message transfer count
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState()
    const [meHashCode, setMeHashCode] = useState()
    const [transferCount, setTransferCount] = useState({})
    const [messageParam, setMessageParam] = useState({
        since: "",
        until: "",
        limit: 20
    })

    const [lastReadMsgTime, setLastReadMsgTime] = useState('');

    // Room data
    const [roomData, setRoomData] = useState({})
    const [targetUserId, setTargetUserId] = useState()
    const [winnerTargetUserId, setWinnerTargetUserId] = useState()
    let deadlineTimeId

    // const titleBar = useRef(null);
    // const isInViewport = useIsInViewport(titleBar);
    // console.log('isInViewport : ', isInViewport);


    const [userLocalId, setUserLocalId] = useState("")
    const userId = props.user.id || userLocalId

    useEffect(() => {

        const getUserIdRes = async () => {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserLocalId(res.data.user_id)
            } else {
                return;
            }
        }
        getUserIdRes().then(r=>r)
    }, [])

    function uuidV4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const computedMessages = useMemo(() => {
        try {
            if (!messages?.length) return [];

            let nMessages = messages.slice();
            nMessages.forEach((item, idx) => {
                if (!item.instanceId) {
                    item.instanceId = uuidV4();
                }
            })

            console.log('%c nMessages', 'background:#333;color:yellow;', nMessages)
            return _.unionBy(nMessages, 'messageId');
        } catch (e) {
            console.error(e);
        }
    }, [messages])

    // change display screen
    const onChangeMenu = value => {
        setVisibleMenu(value)
    }

    // get more messages
    const getMoreMessages = async () => {
        try {
            if (!messages || messages?.length === 0) return false;
            const firstMsgCreated = messages[0]?.createdAt;
            let data = await getMessagesFunction(
                props.roomId,
                props.user.id,
                {
                    since: '',
                    until: firstMsgCreated,
                    limit: 20,
                }
            )

            console.log('messages', messages);
            console.log('%c getMoreMessages-test','background:black;color:yellow;' ,data)

            const currentMessages = [...messages];
            const nMessages = data?.messages || [];
            setMessages(nMessages.concat(currentMessages));
        } catch (e) {
            console.error(e);
        }
    }

    // Set time start
    const clearDeadlineTimer = duration => {
        var interval = 1000
        deadlineTimeId = setInterval(() => {
            duration = moment.duration(duration - interval, "milliseconds")
            let result = startDeadlineTimer(duration._data)
            // Timer finished condition
            if (!result) {
                setTimer("00:00:00")
                setDeadline(false)
                clearInterval(deadlineTimeId)
                return
            } else setTimer(result)
        }, interval)
    }

    const [bodyAreaClass, setBodyAreaClass] = useState(true)
    const [collapsePreview, setCollapsePreview] = useState(false)


    // Main useEffect
    useEffect(() => {
        if (!props.roomId) return
        getRoomData().then(r => r)

        // Get room data service
        async function getRoomData() {
            // let data = await getRoomDataFunction(props.roomId, props.user.id)
            let data = await getRoomDataFunction(props.roomId, userId)
            setRoomData(()=>data)

            // Room timer calculation
            if (data?.roomData?.deadline) {
                let duration = checkDeadlineTimer(
                    data?.roomData?.deadline,
                    deadlineTimeId
                )
                if (duration===false) {
                    setDeadline(false)
                    return
                }

                setDeadline(true)
                clearDeadlineTimer(duration)
            }
        }

        return () => {
            clearInterval(deadlineTimeId)
        }
    }, [props.roomId, props.user.id])

    // Main useEffect
    useEffect(() => {
        if (!props.roomId) return
        getMessages().then(r => r)

        // Get message service
        async function getMessages() {
            setLoading(true)
            let msgData = await getMessagesFunction(
                props.roomId,
                // props.user.id,
                userId,
                messageParam
            )
            // console.log('msgData', msgData)
            changeMessage({ limit: msgData.limit, messages: msgData.messages })
            setLoading(false)
            setIsDataInit(true);

            // IOT subscribe room
            Amplify.PubSub.subscribe(props.roomId).subscribe({
                next: data => {
                    // console.log('iotData', data?.value)
                    // console.log('roomData', roomData)
                    setNewMessage(data)
                },
                error: error => console.error(error),
                close: () => console.log("Done")
            })
        }
    // }, [props.roomId, props.user.id, messageParam, refreshRoom])
    }, [props.roomId, userId, messageParam, refreshRoom])

    // IOT new message
    useEffect(() => {
        if (
            // 이 채팅방의 다른 사람이 보낸 메시지
            meHashCode !== newMessage?.value?.hashCode &&
                newMessage?.value?.roomId === props.roomId
        ) {
            if (
                // 내보내기 INFO 메시지
                newMessage.value.msgType === "INFO" &&
                newMessage.value.action === "sendoff" &&
                // newMessage.value.targetId === props.user.id
                newMessage.value.targetId === userId
            ) {
                // Block user -> Room leave
                setKickRoomModal(true)
                return
            }

            // Owner read (X) ==> 상대방이 메시지르 읽었을 때 보내는 INFO 메시지 (O)
            if (newMessage.value.action === "read") {
                // setOwnerSeen(true)

                // 방장이 메시지를 읽은 경우 내 비행기를 날려버리기 위해 마지막 읽은 시각 기록
                console.log('lastReadMsgTime', moment(newMessage.value?.targetData?.until || newMessage.value?.createdAt).format('HH:mm:sss') || '')
                setLastReadMsgTime(newMessage.value?.targetData?.until || newMessage.value?.createdAt) // 마지막으로 메시지 읽은 시각 기록
                return
            }

            // Winner action
            if (newMessage.value.action === "pick") {
                winnerFunction()
            }

            // Enter room new user
            if (newMessage.value.action === "enter") {
                if (roomData?.roomData)
                    roomData?.roomData?.activeUsers.push(newMessage.value.targetData)
            }

            setMessages([...messages, newMessage.value])

            // TODO : 방장이 메시지를 읽었으면 읽음 정보를 업데이트하는 API를 추가로 호출해 주어야 한다.
            //  그렇지 않으면 다른 디바이스에서 상태 업데이트가 되지 않는다.
            // console.log('visibleMenu', titleBar?.current) // 채팅창 Element 정보
            // console.log('isOwner', roomData?.ownerData?.userId === userId)
            // console.log('isInViewport : ', isInViewport);
            // console.log('newMessage : ', newMessage.value);
            if(roomData && roomData?.ownerData?.userId === userId) {
                // console.log('read......')
                const body = {
                    userId,
                    since:newMessage?.value?.createdAt,
                    until:newMessage?.value?.createdAt,
                }
                ownerMessageReadService(newMessage?.value?.roomId, body).then(r => {
                    console.log('rs', r)
                })
            }

        }

        // IS PREVIEW MSG
        if (
            // props.user.id === newMessage?.value?.senderId &&
            userId === newMessage?.value?.senderId &&
            newMessage?.value?.roomId === props.roomId &&
            newMessage?.value?.msgType === "PREVIEW"
        ) {
            setMessages([...messages, newMessage.value])
        }

        // Delete msg
        if (
            newMessage?.value?.msgType === "DELETED" &&
            newMessage?.value?.roomId === props.roomId
        ) {
            let msgIndex = _.findIndex(messages, {
                messageId: newMessage?.value?.messageId
            })
            let msgData = _.find(messages, function(o) {
                return o.messageId === newMessage?.value?.messageId
            })
            if (msgIndex !== -1 && msgData) {
                msgData.isActivated = 0
                let newArr = [...messages]
                newArr[msgIndex] = msgData
                setMessages(newArr)
            }
        }

        // Set message
        if (
            // 이 채팅방에서 내가 보낸 메시지
            meHashCode === newMessage?.value?.hashCode &&
            newMessage?.value?.roomId === props.roomId
        ) {
            setMessages([...messages, newMessage.value])
        }

        // 채택 function
        function winnerFunction() {
            setWinnerMessage({
                message: newMessage.value.message,
                messageId: newMessage.value.targetId
            })
            setWinnerModal(true)
            setWinnerTargetUserId(newMessage.value.targetId)

            const timeId = setInterval(() => {
                setWinnerModal(false)
                clearTimeout(timeId)
            }, 3000)
        }
    }, [newMessage])

    // Change message action
    const changeMessage = value => {
        if (value.limit) setTransferCount(value.limit)
        if (value.meHashCode) setMeHashCode(value.meHashCode)
        if (value.messages) setMessages(value.messages)
    }

    // Block user -> Remove user from active room list
    const blockUser = blockUserId => {
        _.remove(roomData?.roomData?.activeUsers, function(n) {
            return n.userId === blockUserId
        })
        setRoomData({ ...roomData })
    }

    const headerChangeProfile = () => {
        roomData.roomData.isBlind = roomData?.roomData?.isBlind === "Y" ? "N" : "Y"
        setRoomData({ ...roomData })
    }

    const changeCollapsePreview = () => {
        setCollapsePreview(false)
    }

    return (
        // <div className="chat-customize light-blue" ref={titleBar}>
        <div className="chat-customize light-blue">
            <div className="chat-customized-header">
                <ChatHeader
                    {...props}
                    timer={timer}
                    roomData={roomData}
                    refreshRoom={() => setRefreshRoom(true)}
                    headerChangeProfile={() => headerChangeProfile()}
                    onChangeMenu={value => onChangeMenu(value)}
                    transferCount={transferCount}
                    collapsePreview={collapsePreview}
                    changeCollapsePreview={() => changeCollapsePreview()}
                    setMessageParam={setMessageParam}
                />
                <div className="chat-container">
                    <div className="chat-body">
                        <div className="chat-body-noti">
                            <div className="titArea" style={{ display: collapsePreview ? "none" : "" }}>
                                <span className="q"></span>
                                <span className="tit">{roomData?.roomData?.name}</span>
                                {/*새로 추가한 버튼(클릭하면 톡방 정보 미리보기 영역 사라지는)*/}
                                {/*// TODO : 아이콘 교체해야 함*/}
                                <button
                                    className="btn arr"
                                    onClick={() => {
                                        setBodyAreaClass(true)
                                        setCollapsePreview(true)
                                    }}
                                >
                                     <i className="la close fs-11"></i>
                                </button>
                                <button
                                    className="btn arr"
                                    onClick={() => {
                                        setBodyAreaClass(!bodyAreaClass)
                                    }}
                                >
                                    <i className="la la-angle-down fs-11"></i>
                                </button>
                            </div>
                            <div className="bodyArea" style={{ display: bodyAreaClass ? "none" : "" }}>
                                {(roomData?.roomData?.introduction.replace(/(https:\/\/)(.)+/gi, ""))}
                            </div>
                        </div>
                        {visibleMenu === "chatContainer" && (
                            <ChatContent
                                isInit={isDataInit}
                                getMore={getMoreMessages}
                                {...props}
                                loading={loading}
                                deadline={deadline}
                                roomData={roomData}
                                messages={computedMessages}
                                setMessage={data => setMessages(data)}
                                winnerModal={winnerModal}
                                winnerMessage={winnerMessage}
                                blockUser={blockUserId => blockUser(blockUserId)}
                                onChangeMenu={(value, targetId) => {
                                    onChangeMenu(value)
                                    if (targetId) setTargetUserId(targetId)
                                }}
                                changeMessage={value => {
                                    changeMessage(value)
                                }}
                                imageAndVideoDetail={value => {
                                    setSelectedValue(value)
                                    onChangeMenu(value.screen)
                                }}
                                lastReadMsgTime={lastReadMsgTime}
                            />
                        )}
                        <Screens
                            {...props}
                            winnerTargetUserId={winnerTargetUserId}
                            selectedValue={selectedValue}
                            targetUserId={targetUserId}
                            visibleMenu={visibleMenu}
                            roomData={roomData}
                            refreshRoom={() => setRefreshRoom(true)}
                            onChangeMenu={value => {
                                onChangeMenu(value)
                            }}
                        />
                    </div>
                </div>
            </div>

            <ModalContainer
                kickRoomModal={kickRoomModal}
                setKickRoomModal={() => {
                    setKickRoomModal(false)
                    props.exitChat()
                }}
            />
        </div>
    )
}
