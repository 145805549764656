import {memo, useState} from 'react';
import propTypes from 'prop-types';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faBellSlash} from '@fortawesome/free-solid-svg-icons';

import Avatar from './Avatar';

import closeIcon from '../../assets/images/chat-close.png';
import HamburgerIcon from '../../assets/images/menu.png';

const ChatHeader = (props) => {
    const {userImg, title, userName, isAnonymousMode, extendMenu} = props;
    const [isClicked, setIsClicked] = useState(true);

    const iconHandler = async () => {
        let data = {
            userId: props.user?.id,
            push: roomData?.roomData.myInfo.push === "on" ? "off" : "on",
        }
    }

    const roomData = {
        "isOwner": false,
        "roomData": {
            "roomType": "talk2you",
            "roomId": "talk2-talk2you-1670312682661",
            "createdAt": "2022-12-06T07:44:42.661Z",
            "lastMessagedAt": "2022-12-20T12:32:19.591Z",
            "ownerId": "14437c75-63ec-4dc6-a752-14d8ef2f2146",
            "transfer": {
                "freeTransferCount": 100,
                "transferOnOff": "on",
                "productPurchaseNeedsCount": 10
            },
            "updatedAt": "2022-12-20T12:07:30.073Z",
            "buddies": [
                "14437c75-63ec-4dc6-a752-14d8ef2f2146",
                "afbf6634-315a-4506-8450-e95384417f98"
            ],
            "invitedUsers": [
                {
                    "userId": "14437c75-63ec-4dc6-a752-14d8ef2f2146",
                    "userName": "유한쓰",
                    "userImg": "https://d3cigyie8ipin0.cloudfront.net/20220926021752092_qmFlKNfP.jpeg",
                    "invitationTime": "2022-12-06T07:44:42.661Z"
                }
            ],
            "lastMessage": {
                "createdAt": "2022-12-20T12:32:19.591Z",
                "senderId": "afbf6634-315a-4506-8450-e95384417f98",
                "msgType": "TEXT",
                "message": "18"
            },
            "activeUsers": [
                {
                    "userId": "afbf6634-315a-4506-8450-e95384417f98",
                    "userName": "체셔고양이",
                    "userImg": "https://d1vb419ubgm46b.cloudfront.net/PRD/202212/49c2a36eef2eaf60bbc4b0312418f9c4.png"
                }
            ],
            "isActivated": 1,
            "maxUser": 2,
            "activeUsersCount": 1,
            "blockedUsersCount": 0,
            "shareShortLink": "https://www.answering.chat/yoohans",
            "myInfo": {
                "push": "on",
                "userName": "체셔고양이",
                "userImg": "https://d1vb419ubgm46b.cloudfront.net/PRD/202212/49c2a36eef2eaf60bbc4b0312418f9c4.png"
            }
        },
        "ownerData": {
            "userId": "14437c75-63ec-4dc6-a752-14d8ef2f2146",
            "userName": "유한쓰",
            "userImg": "https://d3cigyie8ipin0.cloudfront.net/20220926021752092_qmFlKNfP.jpeg",
            "invitationTime": "2022-12-06T07:44:42.661Z"
        }
    }

    return (
        <div className="chat-header d-flex align-items-center m-2">
            <div className="chat-header-buttons d-flex align-items-center">
                {!isAnonymousMode && (
                    <button
                        className="btn close-button-chat"
                        // onClick={() => props.exitChat()}
                        style={{
                            backgroundImage: `url(${closeIcon})`,
                            backgroundRepeat: "no-repeat",
                        }}
                    />
                )}
                <div className="title ml-1">
                    <Avatar className="mr-1" src={userImg}/>
                    {title || `${userName}의 답변톡`}
                </div>
            </div>

            {!isAnonymousMode && (
                <div className="position-absolute" style={{ right: "2%" }}>
                    <UncontrolledDropdown>
                        <DropdownToggle className="bg-transparent b-none dropdown-toggle-button button-bell ">
                            <FontAwesomeIcon
                                onClick={iconHandler}
                                icon={isClicked ? faBell : faBellSlash}
                                color={"grey"}
                            />
                        </DropdownToggle>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown>
                        <DropdownToggle
                            className="bg-transparent b-none dropdown-toggle-button btn-menu"
                            style={{
                                backgroundImage: `url(${HamburgerIcon})`,
                                backgroundRepeat: "no-repeat",
                            }}
                        />

                        <DropdownMenu dark>
                            {extendMenu || (
                                <>
                                    <DropdownItem>
                                        Example 1
                                    </DropdownItem>
                                    <DropdownItem>
                                        Example 2
                                    </DropdownItem>
                                </>
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            )}
        </div>
    )
};

/** ChatHeader
 * @property userImg {string} 유저 썸네일 이미지 URL
 * @property title {string}  표시될 타이틀
 * @property isAnonymousMode {boolean} 익명 대응 모드, 일부 UI를 숨김
 * @property extendMenu {element} 확장메뉴 slot
 */
ChatHeader.prototype = {
    userImg: propTypes.string,
    title: propTypes.string,
    userName: propTypes.string,
    isAnonymousMode: propTypes.bool,
    extendMenu: propTypes.element,
}

export default memo(ChatHeader);