import {memo, useEffect, useState, createRef} from 'react';
import propTypes from 'prop-types';
import {map} from 'lodash';

import ChatMessageItem from './ChatMessageItem';
import ChatThinkerItem from './ChatThinkerItem';
import TypeLoader from './TypeLoader';

const ChatContents = (props) => {
    const messagesEndRef = createRef();
    const {messages, userId, className, isLoading, isThinking} = props;
    const [messageLength, setMessageLength] = useState(messages.length)

    useEffect(() => {
        setMessageLength(messages.length);

        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [messages]);

    const scrollActivateControl = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div
            className={`chat-body-messages ${className || ''}`}
        >
            {messages && map(
                messages,
                (item, idx) => (
                    <ChatMessageItem
                                     key={item.id || item.messageId || item.message_id || item.hashCode || idx}
                                     direction={(userId && userId === item.senderId) ? 'to' : 'from'}
                                     myMsg={userId && userId === item.senderId}
                                     lastMsg={idx+1 === messageLength} message={item} {...props} completeAction={scrollActivateControl} />
                )
            )}

            <ChatThinkerItem {...props} completeAction={scrollActivateControl} />

            {isLoading && (
                <TypeLoader />
            )}

            <div ref={messagesEndRef} />
        </div>
    )
}

ChatContents.propTypes = {
    messages: propTypes.array,
    userId: propTypes.string,
    className: propTypes.string,
    isLoading: propTypes.bool,
}

export default memo(ChatContents);