import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row } from "reactstrap"
import { Link, useLocation, withRouter } from "react-router-dom"
import classname from "classnames"
import "../../assets/css/style.css"
// import "../../assets/css/AddStyle.css"
import logo from "../../assets/imageshtml/logo-black.png"
import avatar from "../../assets/imageshtml/img_avatar.jpg"
import { authService } from "services/auth.service"
import { userService } from "services/user.service"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import Avatar from '../Common/Avatar';

const Navbar = props => {
    const [userId, setUserId] = useState(null)
    const [userImg, setUserImg] = useState("")
    const [nickname, setNickname] = useState("")
    const [canvasMenuState, setCanvasMenuState] = useState(false)
    const [menuSelected, setMenuSelected] = useState(0)
    const location = useLocation()
    const console_url = process.env.REACT_APP_CONSOLE_URL

    // TODO: 이 Function이 사용하는 API는 사용자 프로필 이미지 변경 history를 조회하는 용도의 API.
    //  사용자 프로필 조회 시에 image_url 값을 받을 수 있기 때문에 별도 프로필 이미지 조회는 불필요하다.
    /*const getUserImg = async () => {
        const response = await userService.getUserImg(
            localStorage.getItem("user_id")
        )
        console.log('response', response);
        setUserImg(response.image_list[0]?.image_url)
    }*/

    useEffect(() => {
        /*getUserImg()*/
        const getUserIdRes = async () => {
            const res = await authService.getUserId();
            if(res.status !== 201) return;

            const uid = res.data.user_id;

            setUserId(uid)
            const storedUserData = sessionStorage.getItem('user_data') ? JSON.parse(sessionStorage.getItem('user_data')) : null;
            // const response = await authService.getUserInfo(res.data.user_id)
            const obj = storedUserData && storedUserData.id === uid ? storedUserData : await authService.getUserInfo(uid);
            setNickname(()=>{return obj.nickname})
            setUserImg(()=>{return obj.image_url})
        }
        getUserIdRes().then(r=>r)

        // if (localStorage.getItem("user_data")) {
        //     const obj = JSON.parse(localStorage.getItem("user_data"))
        //     setAge(obj.age_group)
        //     setNickname(obj.nickname)
        //     setEmail(obj.email)
        //     setPhoneNumber(obj.phone_number)
        //     setUserImg(obj.image_url)
        // }
    }, [nickname])

    const canvasMenuToggle = () => {
        setCanvasMenuState(!canvasMenuState)
    }

    return (
        <React.Fragment>
            <header className="header-area fixed-top w-100">
                <div className="container bar-round mt-3 bg-white">
                    <Row className="align-items-center">
                        <div className="col-lg-2">
                            <div className="logo-box">
                                <a href="/main" className="link logo">
                                    <img src={logo} alt="logo" />
                                </a>
                                <div className="user-action">
                                    <div
                                        className="off-canvas-menu-toggle icon-element icon-element-xs shadow-sm active"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Main menu"
                                        onClick={canvasMenuToggle}
                                    >
                                        <i className="la la-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="menu-wrapper">
                                <nav className="menu-bar mr-auto " id="navigation">
                                    <ul>
                                        <li id="menu01" className={location.pathname === "/main" ? "active": ""}>
                                            <Link to="/main">질문하기</Link>
                                        </li>
                                        <li id="menu02" className={location.pathname === "/replyGuide" ? "active": ""}>
                                            <Link to="/replyGuide">답변자들</Link>
                                            {/* <ul className="dropdown-menu-item">
                                                <li>
                                                    <Link to="/replyGuide">답변자 등록 안내</Link>
                                                </li>
                                                <li>
                          <Link to="replyList.html">추천 답변자 리스트</Link>
                        </li>
                                            </ul> */}
                                        </li>
                                        <li id="menu03" className={location.pathname === "/product" ? "active": ""}>
                                            <Link to="/product">
                                                답변톡
                                                {/* <i className="la la-angle-down fs-11"></i> */}
                                            </Link>
                                            {/* <ul className="dropdown-menu-item">
                                                <li>
                                                    <Link to="/product">답변톡이란?</Link>
                                                </li>
                                                <li>
                                                    <Link to="/solution">답변톡 솔루션</Link>
                                                </li>
                                                <li>
                          <Link to="useInfo.html">답변톡 이용사례</Link>
                        </li>
                                            </ul> */}
                                        </li>
                                        {/* <li id="menu04">
                                            <Link to="/buyDiamonds">답변톡 상품 구매</Link>
                                        </li> */}
                                        {/* <li id="menu05" className={location.pathname === "/plan" ? "active": ""}>
                                            <Link to="/plan">플랜</Link>
                                        </li> */}
                                    </ul>
                                </nav>
                                <div className="nav-right-button d-flex">
                                    {userId == null ? (
                                        <>
                                            <div>
                                                <button
                                                    className="btn btn-outline-secondary round mr-2"
                                                    onClick={() => {
                                                        props.history.push("/login")
                                                    }}
                                                >
                                                    로그인
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    className="btn btn-outline-secondary round mr-2"
                                                    onClick={() => {
                                                        props.history.push("/register")
                                                    }}
                                                >
                                                    회원가입
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <nav className="menu-bar">
                                            <ul>
                                                <li id="menu05" style={{'textTransform': 'none'}}>
                                                    <Link to="/mySettings/chatRoomList">
                                                        <div className="d-flex align-items-center">
                                                            <div className="profileImg">
                                                                <Avatar src={userImg} alt={nickname} bordered={false} />
                                                            </div>
                                                            <span className="mx-1">{nickname}</span>
                                                            <i className="la la-angle-down fs-11" />
                                                        </div>
                                                    </Link>
                                                    <ul className="dropdown-menu-item">
                                                        <li>
                                                            <a
                                                                className="accent-link"
                                                                href={`${console_url}`}
                                                                target="_blank"
                                                            >
                                                                스튜디오 바로가기
                                                            </a>
                                                        </li>
                                                        {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                                                        <li>
                                                            <Link
                                                                className="accent-link"
                                                                to="/mySettings/chatRoomList"
                                                            >
                                                                참여 톡방 리스트
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link 
                                                                className="accent-link"
                                                                to="/buyDiamonds"
                                                            >
                                                                다이아 충전하기
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="/mySettings/useList">
                                                                구매/사용 리스트
                                                            </Link>
                                                        </li> */}
                                                        <li>
                                                            <Link className="accent-link"
                                                                  to="/mySettings/settingUserInfo">
                                                                정보설정
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" onClick={() => authService.logout()}>
                                                                로그아웃
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </nav>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
                <div
                    className={
                        canvasMenuState
                            ? "off-canvas-menu custom-scrollbar-styled active"
                            : "off-canvas-menu custom-scrollbar-styled"
                    }
                >
                    <div
                        className="off-canvas-menu-close icon-element icon-element-sm shadow-sm"
                        data-toggle="tooltip"
                        data-placement="left"
                        title=""
                        data-original-title="Close menu"
                    >
                        <i className="la la-times" onClick={canvasMenuToggle}></i>
                    </div>
                    <ul className="generic-list-item off-canvas-menu-list pt-90px">
                        <li id="menu01">
                            <Link to="/main">질문하기</Link>
                        </li>
                        <li id="menu02 " className={menuSelected === 1 ? "active" : ""}>
                            <Link to="#">
                                <p
                                    to="#"
                                    onClick={() => {
                                        props.history.push("/replyGuide")
                                    }}
                                >
                                    답변자들
                                </p>
                                {/* <button
                                    className="sub-nav-toggler"
                                    type="button"
                                    onClick={() => setMenuSelected(menuSelected !== 1 && 1)}
                                >
                                    <i
                                        className={
                                            menuSelected === 1 ? "la la-angle-up" : "la la-angle-down"
                                        }
                                    ></i>
                                </button> */}
                            </Link>
                            {/* <ul
                                className={
                                    menuSelected === 1 ? "sub-menu d-block" : "sub-menu "
                                }
                            >
                                <li>
                                    <Link to="/replyGuide">답변자 등록 안내</Link>
                                </li>
                                <li>
                                    <Link to="#">트</Link>
                                </li>
                            </ul> */}
                        </li>
                        <li id="menu03" className={menuSelected === 2 ? "active" : ""}>
                            <Link to="#">
                                <p
                                    onClick={() => {
                                        props.history.push("/product")
                                    }}
                                >
                                    답변톡
                                </p>
                                {/* <button
                                    className="sub-nav-toggler"
                                    type="button"
                                    onClick={() => setMenuSelected(menuSelected !== 2 && 2)}
                                >
                                    <i
                                        className={
                                            menuSelected === 2 ? "la la-angle-up" : "la la-angle-down"
                                        }
                                    ></i>
                                </button> */}
                            </Link>
                            {/* <ul
                                className={
                                    menuSelected === 2 ? "sub-menu d-block" : "sub-menu "
                                }
                            >
                                <li>
                                    <Link to="/product">답변톡이란?</Link>
                                </li>
                                <li>
                                    <Link to="/solution">답변톡 솔루션</Link>
                                </li>
                            </ul> */}
                        </li>
                        {/* <li id="menu04">
                            <Link to="/plan">플랜</Link>
                        </li> */}
                        {userId && userImg ? (
                            <li id="menu05" className={menuSelected === 3 ? "active" : ""}>
                                <div className="d-flex align-items-center" style={{'textTransform': 'none', padding: '6px 20px',cursor: 'pointer'}} onClick={() => setMenuSelected(menuSelected !== 3 && 3)}>
                                    <div className="profileImg">
                                        {/*<img src={userImg} alt="avatar" />*/}
                                        <Avatar src={userImg} alt={nickname} bordered={false} />
                                    </div>
                                    <span className="mx-1">{nickname}</span>
                                    <div style={{flexGrow: 1}} />
                                    <button
                                        className="sub-nav-toggler"
                                        type="button"
                                    >
                                        <i className={
                                            menuSelected === 3 ? "la la-angle-up" : "la la-angle-down"
                                        }
                                        />
                                    </button>
                                </div>
                                <ul
                                    className={
                                        menuSelected === 3 ? "sub-menu d-block" : "sub-menu "
                                    }
                                    style={{marginTop: '10px'}}
                                >
                                    <li>
                                        <a
                                            className="bg-transparent b-none"
                                            href={`${console_url}`}
                                            target="_blank"
                                        >
                                            스튜디오 바로가기
                                        </a>
                                    </li>
                                    {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                                    <li>
                                        <Link to="/mySettings/chatRoomList">참여 톡방 리스트</Link>
                                    </li>
                                    <li>
                                        {/*<Link to="/#">충전/구매 리스트</Link>*/}
                                        <Link to="/buyDiamonds">
                                            충전/구매 리스트
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/mySettings/settingUserInfo">
                                            정보설정
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={() => authService.logout()}>로그아웃</Link>
                                    </li>
                                </ul>
                            </li>
                        ) : ""}

                    </ul>
                    {userId === null ? (
                        <div className="off-canvas-btn-box px-4 pt-5 d-flex">
                            <Link
                                to="/login"
                                className="btn theme-btn-sm theme-btn-outline round w150 mr-2"
                            >
                                {" "}
                                로그인
                            </Link>

                            <Link
                                to="/register"
                                className="btn theme-btn-sm theme-btn-outline round w150 "
                            >
                                회원가입
                            </Link>
                        </div>
                    ) : ""
                    }
                </div>
            </header>
        </React.Fragment>
    )
}

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any
}

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout
    return { leftMenu }
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
