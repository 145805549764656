import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap"

import { withRouter, Link } from "react-router-dom"

// import images
import logoFoot from "../../assets/images/logo_landingFoot.png"
import { authService } from "../../services/auth.service"
import { toast } from "react-toastify"

const ForgetPasswordPage = props => {
    //meta title
    document.title = "답변톡 플로잉"

    const [email, setEmail] = useState("")
    const [isSent, setIsSent] = useState(false)

    const isValidEmail = () => {
        const pattern =
            /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        return email.match(pattern) === null ? false : true
    }

    const sendEmail = async () => {
        if (isSent) {
            props.history.push("/login")
            return
        }

        /* 
            해당 코드의 의도 : 버튼 중복클릭에 대한 방어
            TODO: 중복클릭에 대한 방어적인 로직과 디자인 일관성을 맞출 수 있도록 한다.
         */
        alert('이메일 전송을 요청합니다.')

        if (!isValidEmail()) {
            toast.error("유효하지 않은 이메일 형식입니다.")
            return
        }

        const response = await authService.emailSend(email)
        if (response === "") {
            setIsSent(true)
        }
    }

    const emailInputKeyDownHandler = (event)=>{
        if(event.code==='Enter'){
            sendEmail()
        }
    }

  return (
    <React.Fragment>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}
      <div className="auth">
        <div className="logo">
          <Link to="/">
            <img src={logoFoot} alt="logo" />
          </Link>
        </div>
        <div className="auth_left">
          <Card>
            <div className="card-top">
              <div className="card-title">{/*{props.t("Forgot Password")}*/}비밀번호가 생각나지 않나요?</div>
            </div>
            <CardBody>
              {isSent == true && (
                <Alert
                  color="success"
                  className="alert alert-icon alert-success fs12"
                  role={alert}
                >
                  <i className="fe fe-alert-triangle"></i>
                  {/*{props.t("A password reset instruction message will be sent to your email.")}*/}
                  비밀번호 재설정을 위한 URL이 이메일을 통해 발송되었습니다.
                </Alert>
              )}

                            <div className="mb-3">
                                <Input
                                    name="email"
                                    className="form-control"
                                    /*placeholder={props.t("Please enter your account (email).")}*/
                                    placeholder="로그인 계정으로 사용된 이메일 주소를 입력해 주세요."
                                    type="email"
                                    onChange={e => setEmail(e.target.value)}
                                    onKeyDown={emailInputKeyDownHandler}
                                />
                            </div>
                            <Row className="">
                                <Col className="fluid">
                                    <button
                                        className="btn btn-primary btn-block w-100"
                                        type="submit"
                                        onClick={sendEmail}
                                    >
                                        {/*{props.t("initialization")}*/}비밀번호 재설정
                                    </button>
                                </Col>
                            </Row>
                        </CardBody>
                        <div className="mt-4 text-center">
                            <p className="pb-4">
                                {/*{props.t("What if you forgot your password?")}*/}
                                만약 비밀번호가 기억났다면?
                                <Link to="login" className="font-weight-medium text-primary">
                                    {/*{props.t("Login")}*/}
                                    로그인
                                </Link>{" "}
                            </p>
                            <p className="text-center text-muted mt-2 Copyright">
                                © {new Date().getFullYear()} {props.t("Copyright TPKOREA Co.,Ltd.")}
                            </p>
                        </div>
                    </Card>
                </div>
                <div className="auth_right"></div>
            </div>
        </React.Fragment>
    )
}

ForgetPasswordPage.propTypes = {
    history: PropTypes.object,
}

export default withRouter(withTranslation()(ForgetPasswordPage))
