const methodObj = {
    'CARD' : '신용카드',
    'TRANSFER' : '실시간계좌이체',
    // 'VIRTUAL_ACCOUNT' : '가상계좌',
    'MOBILE_PHONE' : '휴대폰결제',
    'TOSSPAY': '토스페이',
}

export {
    methodObj
}