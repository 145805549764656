import React, { useEffect, useState } from "react"
import { Spinner } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"
import { checkVideoUploadingFunction } from "../../helper/Functions/SupportFunctions"

export default function Video(props) {
    const { message, completedAction } = props
    const [loadingVideo, setLoadingVideo] = useState()
    const [selectedValue, setSelectedValue] = useState({})
    
    useEffect(() => {
        if(!completedAction) return false
        completedAction()
    }, [message])

    // console.log('message', message);

    const checkVideoUploading = async (fileOrigin, jobId) => {
        setLoadingVideo(true)
        setSelectedValue({ jobId })
        // const response = await checkVideoUploadingFunction(jobId)
        // TODO : message obj 안에 jobId가 없기 때문에 jobId를 이용한 Media Convert status 를 체크할 수 없다.
        //  API 를 변경하여 jobId 가 전달되는 경우 해당 message 의 hashCode 를 jobId 값으로 대체하여 저장하는 것은 어떤지 검토 필요.

        // TODO: 다시 jobId를 이용한 로직으로 변경
        // const response = await checkMediaConvertProgressService(fileOrigin.split('https://d1ck4997lynyqe.cloudfront.net')[0])
        let videoData = {
            type: "VIDEO",
            file: fileOrigin,
            jobId,
            screen: "imageAndVideo"
        }
        try {
            const response = !jobId || await checkVideoUploadingFunction(jobId).then(res => {
                const {status} = res
                console.warn(res)
                // AWS MEDIACONVERT STATUS MESSAGE
                // COMPLETE: 완료, SUBMITTED: 작업 제출, PROGRESSING: 작업 중, ERROR: 작업 실패, CANCELED: 작업 취소
                switch(status) {
                    case 'COMPLETE': return true;
                    case 'SUBMITTED': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
                    case 'PROGRESSING': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
                    case 'CANCELED': throw new Error('비디오 업로드가 취소되었습니다.')
                    case 'ERROR': throw new Error('비디오 업로드에 실패하였습니다.')
                    default: throw new Error('다시 한번 시도해 주세요.')
                }
            })
            // console.log('response', response); //return;
            // if (response === 0) {
            if (response===true) {
                setSelectedValue(videoData)
                props.imageAndVideoDetail(videoData)
            }
            // else if (response === 1) {
        //     } else if (response.status === 403) {
        //         // toast.info("This video uploading, please wait for a moment")
        //         toast.info("원활한 영상 재생을 위한 준비 중입니다. 잠시 후 다시 시도해 주세요.")
        //     } else {
        //         // toast.error("Video cannot be played")
        //         toast.error("요청하신 영상은 재생 가능한 상태가 아닙니다. 잠시 후 다시 시도해 주세요.")
        //     }
        } catch(err) {
            toast.error(`${err}`)
        } finally {
            setLoadingVideo(false)
        }
    }

    return (
        <div
            className="media_img chat-lefted-image d-inline-block"
            onClick={() => {
                checkVideoUploading(message.fileOrigin, message.jobId).then(r => r)
            }}
        >
            <div
                className="video_container"
                style={{
                    aspectRatio: 1/message?.imgRatio || 1,
                    backgroundColor: '#ebebeb',
                    width: 150,
                }}
            >
                <img src={message.message} className="img-thumbnail" alt="" onLoad={()=>completedAction(true, 'auto')} onLoadedMetadata={ev => {console.log('onloadedmetadata', ev)}} style={{objectFit: 'cover'}} />
                <FontAwesomeIcon icon={faPlay} color={"white"} className="video" />

                {/* <i className="fas fa-play-circle video"> */}
                {loadingVideo && selectedValue.jobId === message.jobId && (
                    <Spinner animation="border" variant="primary">
                        {" "}
                    </Spinner>
                )}
                {/* </i> */}
            </div>
        </div>
    )
}
