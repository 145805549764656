import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import { categoryService } from "services/category.service"
import { toast } from "react-toastify"
const DistrictModal = props => {
    const [districtCategories, setDistrictCategories] = useState(props.selDisCategories)
    const [offset, setOffset] = useState("0")
    const [limit, setLimit] = useState("100")
    const [keyword, setKeyword] = useState("")
    const [searchedCate, setSearchedCate] = useState([])

    const searchCategories = async () => {
        let response = await categoryService.searchDistrictCategories(keyword, offset, limit)
        console.log('response@@ :>> ', response);
        setSearchedCate(response.district_category_list)
    }
    const addItem = item => {
        if (districtCategories.length >= 5) {
            toast.error("지역 선택은 5개까지만 가능합니다.")
            return
        }
        districtCategories.push(item)
        props.onDistChange(districtCategories)
    }
    const removeItem = item => {
        const newCategories = districtCategories.filter(cate => cate.id !== item.id)
        setDistrictCategories(newCategories)
        props.onDistChange(newCategories)
    }
    const handleOnKeyDownForSearchInput = e => {
        if (e.key === "Enter") {
            searchCategories()
        }
    }

    useEffect(() => {
        searchCategories()
    }, [])
    //meta title
    document.title = "답변톡 플로잉"
    return (
        <Modal isOpen={props.modal_district == true} centered toggle={() => props.setModal(false)}>
            <div className="modal-header">
                <h5 className="modal-title mt-0">관련 지역 검색</h5>
                <button
                    type="button"
                    onClick={() => {
                        props.setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div id="AreaArea" className="viewContents viewCon">
                    <h5>질문 내용과 관련 있는 지역을 선택하세요.(최대 5개).</h5>
                    <p className="textArea">
                        검색 키워드를 입력하면 원하는 지역을 쉽게 찾을 수 있습니다.
                        {/*Enter your region to receive local questions.*/}
                    </p>

                    <div>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="검색 지역 입력"
                                value={keyword}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyDown={handleOnKeyDownForSearchInput}
                            />
                            <span className="input-group-append">
                                <button className="btn btn-secondary" type="button" onClick={searchCategories}>
                                    검색
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="ReaultArea">
                        <ul>
                            {searchedCate &&
                                searchedCate.map((item, index) => (
                                    <li key={index}>
                                        <div className="d-flex align-items-center mt-auto">
                                            <div>{`${item.sido_name},${item.sigungu_name},${item.upmyeondong_name}`}</div>
                                            <div className="ml-auto text-muted">
                                                {districtCategories.find(({ id }) => id == item.id) == null ? (
                                                    <button
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => addItem(item)}
                                                    >
                                                        추가
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn btn-outline-secondary btn-sm"
                                                        onClick={() => removeItem(item)}
                                                    >
                                                        취소
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            {Object.keys(searchedCate).length < 1 ? (
                                <li>
                                    <p className="noResult">검색 결과가 없습니다.</p>
                                </li>
                            ) : (
                                ""
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DistrictModal
