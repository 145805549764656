import React from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import point from "../../../../assets/images/chatroom/point.png"

const WinnerModal = props => {
  return (
    <React.Fragment>
      <div className="me-modal">
        <div className="me-modal_content">
          <img src={point} alt="" className="pick-image" />
          <p>{props.winnerMessage?.message}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(WinnerModal))
