import React, { useRef, useState } from "react"
import { Input, Button } from "reactstrap"
import Picker from "emoji-picker-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from "@fortawesome/free-solid-svg-icons"

export default function ChatFooter(props) {
    const { targetCopyText } = props

    const fileRef = useRef(null)
    const textAreaRef = useRef(null)
    const [curMessage, setcurMessage] = useState("")
    const [copyTextAction, setCopyTextAction] = useState(false)
    const [isShowEmoji, setIsShowEmoji] = useState(false)

    // Emoji action
    const showEmojeContent = (condition, self) => {
        console.log("emoji action")
        setIsShowEmoji(self ? !isShowEmoji : condition)
    }

    const onEmojiClick = (event, emojiObject) => {
        let newEmoji = curMessage + emojiObject.emoji
        setcurMessage(newEmoji)
    }


    // Enter keyboard action
    const onKeyPress = e => {
        setIsShowEmoji(false)
        const { key } = e
        if (e.shiftKey) {
        } else if (key === "Enter") {
        if (e.target.value && e.target.value !== "" && e.target.value !== "\n") {
            sentMessage()
        } else {
            setcurMessage("")
            e.preventDefault()
        }
        }
    }

    const sentMessage = () => {
        setIsShowEmoji(false)
        setcurMessage("")
        props.addMessage(curMessage)
    }

    const handleClick = event => {
        setIsShowEmoji(false)
        switch (event.detail) {
        case 2: {
            setCopyTextAction(true)
            break
        }
        default: {
            setCopyTextAction(false)
            break
        }
        }
    }

    return (
        <>
            <div className="chat-footer mt-2">
                <button
                    className="chat-footer-icon chat-button"
                    onClick={() => fileRef.current.click()}
                >
                    <input
                        className="d-none"
                        type="file"
                        id="selected-file"
                        name="attachments"
                        ref={fileRef}
                        onChange={e => props.selectFileAction(e)}
                    />
                    <i className="icon-paper-clip" />
                </button>

                {isShowEmoji && (
                    <Picker
                        id="emojiPicker"
                        preload={true}
                        groupVisibility={{
                            flags: false,
                        }}
                        onEmojiClick={onEmojiClick}
                    />
                )}

                <button
                    className="chat-footer-icon"
                    onClick={() => showEmojeContent(true, true)}
                >
                    <i className="icon-emoticon-smile" />
                </button>

                {copyTextAction && (
                    <div className={"tool_tip"}>
                        <button
                            onClick={() => {
                                let copyText = targetCopyText || ""
                                setcurMessage(curMessage + copyText)
                                setCopyTextAction(false)
                                props.selectedMessageAction({
                                    action: "PASTE",
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faCopy} color={"white"} />
                            <p>Paste</p>
                        </button>
                    </div>
                )}

                <Input
                    ref={textAreaRef}
                    type="text"
                    id="sent-chat-area"
                    className="form-control bgChatText mt-0 chat-input"
                    placeholder="내용을 입력해주세요."
                    value={curMessage}
                    onKeyPress={onKeyPress}
                    onClick={handleClick}
                    onChange={e => {
                        const { key } = e
                        if (key !== "Enter") {
                            setcurMessage(e.target.value)
                        }
                    }}
                />
                <button className={curMessage.length>0?'chat-footer-btn active':'chat-footer-btn'}
                        onClick={() => sentMessage()}>
                    <i className="icon-paper-plane" />
                </button>
            </div>
        </>
    )
}
