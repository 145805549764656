import React, { useEffect, useState } from "react"
import { Card, Container, Row, Col, Form, Collapse, CardBody } from "reactstrap"
import productImg from "../../assets/images/productImg.png"
import productImg2 from "../../assets/images/productImg2.png"
import productImg3 from "../../assets/images/productImg3.png"
import "../../assets/css/style.css"
// import "../../assets/css/AddStyle.css"
const Solution = args => {
  //meta title
  document.title = "답변톡 플로잉"
  return (
    <React.Fragment>
      <section className="product-area pt-60px">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="filters pb-4">
                <h5>
                  답변톡 PC 버전 <span className="bgbluebar">무료</span>
                </h5>

                <h2 className="listTitle mr-1">
                  <span>Creator, Influencer, Expert</span>
                </h2>
                <h2 className="listTitle">
                  위한 답변톡 <b>솔루션</b>
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="pt-20px">
            <Col lg={5}>
              <div className="productDown">
                <p className="solutionTit">
                  Window용 <br></br>플로잉 답변 관리
                </p>
                <p className="solutionTxt">
                  지금 플로잉 답변 관리를 설치하면<br></br>
                  답변 프로필 등록이 가능합니다.
                </p>
                <div className="mobileFlex">
                  <button className="btn btn-outline-secondary d-block btnBic btndown mb-3">
                    다운로드 (32비트)
                  </button>
                  <button className="btn btn-outline-secondary d-block btnBic btndown">
                    다운로드 (64비트)
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={7}>
              <div className="productImg">
                <img src={productImg} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product-area pt-60px">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="filters pb-4">
                <h5>
                  답변톡 PC 버전 주요기능
                  <span className="bgbluebar">무료</span>
                </h5>
                <h2 className="listTitle mr-1">
                  <span>MAIN FUNCTION</span>
                </h2>
                <h6 className="solutionTxt mb-1">
                  <span>PC 환경에서 확인하실수 있습니다.</span>
                </h6>
                <h6 className="solutionTxt mb-4">
                  <span>반짝이는 버튼을 클릭해 주요기능을 살펴보세요.</span>
                </h6>
              </div>
            </Col>
          </Row>
          <Row className="pt-20px">
            <Col lg={12} className="text-center">
              <div className="productImg">
                <img src={productImg2} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product-area pt-60px">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="filters pb-4">
                <h5>
                  답변톡 플로잉 앱<span className="bgbluebar">무료</span>
                </h5>
                <h2 className="listTitle mr-1">
                  <span>FLOWING</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="pt-20px">
            <Col lg={5}>
              <div className="productDown">
                <p className="solutionTit">답변톡 플로잉</p>
                <p className="solutionTxt">
                  세상의 모든 톡은 질문과 답변으로 통한다.<br></br>
                  당신의 답변은 지식이고 힘이고 모이면 돈이 된다.<br></br>
                  답변의 가치! 플로잉
                </p>
                <div className="btn-area3 mt-2">
                  <a
                    href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                    className="link-app"
                  >
                    <span className="tit-ios">App Store</span>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                    className="link-app"
                  >
                    <span className="tit-android">GooglePlay</span>
                  </a>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="productImg">
                <img src={productImg3} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default Solution
