import config from "config/configuration"
import { toast } from "react-toastify"
// import {config} from '../config/configuration'
import "react-toastify/dist/ReactToastify.css"
// import { get, post } from "../helpers/api_helper_user"
import { get, post } from "../helpers/api_helper"

const uploadUserImg = async function(userId, imageUrl) {
    try {
        return await post(`/${userId}/images`, {
            images: imageUrl,
        }, {baseURL: config.api.user_api})

    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}
const getUserImg = async function(userId) {
    try {
        return await get(`/${userId}/images`, {baseURL: config.api.user_api})

    } catch (err) {
        // toast.error(err.response.data.message)
        toast.error(err.response.data.place_holder || err.response.data.message)
        console.error(err)
        return err
    }
}

export const userService = {
    uploadUserImg,
    getUserImg
}
