import propTypes from 'prop-types';
import './Avatar.scoped.scss';

import defaultAvatarImage from 'assets/images/userbg.jpg';

const Avatar = (props) => {
    const {src, alt, className, size, bordered, bgColor} = props;
    return (
        <div className={`tp-ui--avatar-box ${className || ''}`} style={{
            width: size || 36,
            height: size || 36,
            border: bordered === false ? 'none' : '1px solid #ccc',
            '--v-bg-color': bgColor,
        }}>
            <img src={src || defaultAvatarImage} alt={alt || ''} onError={(ev) => {
                ev.target.src = defaultAvatarImage;
                console.log('image-on-error', ev.target);
            }} />
        </div>
    );
}

Avatar.propTypes = {
    src: propTypes.string,
    alt: propTypes.string,
    className: propTypes.string,
    size: propTypes.oneOfType([propTypes.number, propTypes.string]),
    bordered: propTypes.bool,
    bgColor: propTypes.string,
}

export default Avatar;