import diaGoods01 from "../assets/images/diaGoods01.png"
import diaGoods02 from "../assets/images/diaGoods02.png"
import diaGoods03 from "../assets/images/diaGoods03.png"
import diaGoods04 from "../assets/images/diaGoods04.png"
import diaGoods05 from "../assets/images/diaGoods05.png"
import diaGoods06 from "../assets/images/diaGoods06.png"
import diaGoods07 from "../assets/images/diaGoods07.png"
import diaGoods08 from "../assets/images/diaGoods08.png"

const goodsDepositId = [
    {
        id: "f2d6f331-d132-47ea-a065-94f887d64458",
        amount: 1100,
        quantity: 10,
        title: "다이아",
        subTitle: "10개",
        img: diaGoods01
    },
    {
        id: "54e71533-0b9a-446c-ab17-0f6ade93475d",
        amount: 5500,
        quantity: 50,
        title: "다이아",
        subTitle: "50개",
        img: diaGoods02
    },
    {
        id: "8b3f4d5c-f00c-4494-bfd4-540a9228a433",
        amount: 11000,
        quantity: 100,
        title: "다이아",
        subTitle: "100개",
        img: diaGoods03
    },
    {
        id: "8596734a-6686-4712-be5a-1683a5ff0fa8",
        amount: 22000,
        quantity: 200,
        title: "다이아",
        subTitle: "200개",
        img: diaGoods04
    },
    {
        id: "0be521fd-2a4f-4094-b091-9fe6afa14c9d",
        amount: 33000,
        quantity: 300,
        title: "다이아",
        subTitle: "300개",
        img: diaGoods05
    },
    {
        id: "9ffbc068-211e-4317-9361-f06c90d70b9d",
        amount: 55000,
        quantity: 505,
        title: "다이아",
        subTitle: "500개 +5 개",
        img: diaGoods06,
        addCount: "+ 5"
    },
    {
        id: "a98f3e4e-5586-417d-895b-31c08af533b8",
        amount: 88000,
        quantity: 810,
        title: "다이아",
        subTitle: "800개 +10 개",
        img: diaGoods07,
        addCount: "+ 10"
    },
    {
        id: "7447934a-871e-4218-9034-2deb6fa67faa",
        amount: 110000,
        quantity: 1015,
        title: "다이아",
        subTitle: "1000개 +15 개",
        img: diaGoods08,
        addCount: "+ 15"
    }
]

export {
    goodsDepositId
}