import ReactGA from 'react-ga4';

const gaId = 'G-QRPYRJPE4E';

if (process.env.REACT_APP_SET_ENV === 'PRD') ReactGA.initialize(gaId);

export const pageView = (location) => {
    if (!ReactGA.isInitialized) return false;
    try {
        if (!location) throw new Error('location param이 포함되지 않았음');
        ReactGA.send({
            hitType: 'pageview',
            page: location?.pathname,
        });
    } catch (e) {
        console.log(e);
    }
}

export const setUserId = (userId = '') => {
    if (!ReactGA.isInitialized) return false;
    try {
        ReactGA.set({
            userId: userId,
        });
    } catch (e) {
        console.log(e);
    }
}


export const analytics = {
    isInitialized: ReactGA.isInitialized,
    setUserId,
    pageView,
    ga: ReactGA.ga,
    event: ReactGA.event,
    set: ReactGA.set,
};

export default analytics;