import React, { useEffect, useRef, useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
// import { landingPageService } from "services/landing-page.service"
import imgAvatar from "../../assets/images/img_avatar.jpg"
import imgCh01 from "../../assets/images/img_ch01.png"
import imgGift from "../../assets/images/img_gift.png"
import imgbosuk01 from "../../assets/images/imgbosuk01.png"
import imgbosuk02 from "../../assets/images/imgbosuk02.png"
import landingYoutube from "../../assets/images/landingYoutube.jpg"
import landingFacebook from "../../assets/images/landingFacebook.jpg"
import logoLandingFoot from "../../assets/images/logo_landingFoot.png"
import { checkPermission, createRoom, enterRoom, getUserProfileService } from "services/talk2youRooms.service"
import AnswerChatRoom from "../MySettings/AnswerChat/ChatContainerModal"
import { toast } from "react-toastify"
import { authService } from "services/auth.service"
import _ from "lodash"

const AnswerTalk = props => {
    const [colorType, setColorType] = useState("")
    const [layoutType, setLayoutType] = useState("basic")
    // const userId = localStorage.getItem("user_id")
    const [userId, setUserId] = useState(null)
    const [roomId, setRoomId] = useState("")
    const [user, setUser] = useState("")
    const [item, setItem] = useState({})
    const [ownerId, setOwnerId] = useState({})
    const [doIng, setDoIng] = useState(true)

    // const throttled = useRef(
    //     throttle((newValue) => console.log(newValue), 10000),
    //     []
    //   );
    
    //   useEffect(() => throttled(doIng), [doIng]);

    useEffect(() => {
        const getUserIdRes = async () => {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserId(res.data.user_id)
            } else {
                return;
            }
        }
        getUserIdRes().then(r=>r)
    }, [])

    const isAuthenticated = () => {
        if (userId === null) {
            console.log(props)
            props.changeReferer("answer")
            props.changeAuthLayout("auth")
        } else if (userId) {
            if(doIng) PostRoom().then(r => r)
        }
    }

    // const isAuthenticated = _.throttle(() => {
    //     if(userId===null) {
    //         props.changeReferer("answer")
    //         props.changeAuthLayout("auth")
    //     } else if(userId) {
    //         console.log(11)
    //         PostRoom().then(r=>r)
    //     }
    // }, 5000)

    const PostRoom = async () => {
        setDoIng(()=>{return false})
        console.log("PostRoom....", roomId)
        // TODO : 로그인 여부 체크해서 로그인 또는 간편가입을 먼저 진행한다.
        // const rs = await isAuthenticated();
        // if(!rs) return false;

        // TODO : 답변톡방에 입장 및 톡방 신규 생성 전에 퍼미션(Validation) API를
        //  먼저 호출해서 생성 또는 입장 가능 여부를 확인해야 함

        /*let body = {
            userId,
            ownerId: ownerId,
            roomType: "talk2you"
        }

        const result = await enterRoom(body, item.roomId)
        console.log('result', result)
        if (result?.response?.status) {
            toast.warning(result?.response?.data?.message)
            props.activeChange()
        } else if (result) {
            if (item.roomType === "talk2me") props.activeChange("question")
            else props.activeChange("answer"); // <===========
        }
        props.changeAuthLayout("logged")*/

        console.log(roomId)
        // if (roomId) {
            /*let rs = await checkPermission({ownerId}, userId)
            console.log('ownerId', ownerId);
            console.log('userId', userId);*/
            // console.log('rs', rs); return;
        const token = await authService.getACToken()
        if(token.status===201){
            sessionStorage.setItem("access_token", token.data.access_token)
            let body = {
                userId: userId,
                ownerId: ownerId,
                roomType: "talk2you",
            }
            let rs = await createRoom(body)
            console.log(rs, "response")
            // const newRoomId = rs && rs.hasOwnProperty('itemList') && Array.isArray(rs.itemList)
            //                     && rs.itemList.length > 0 && rs.itemList[0].hasOwnProperty('roomId') && rs.itemList[0].roomId || '';
            const newRoomId = rs && rs?.itemList[0]?.roomId
            if (newRoomId) {
                setRoomId(newRoomId)
                props.setRoomId(newRoomId)
    
                // let userInfo = sessionStorage.getItem("user_data")
                const userInfo = await authService.getUserInfo(userId)
                // userInfo = JSON.parse(userInfo)
                setUser(userInfo)
                props.setUser(userInfo)
    
                let body = {
                    userId: userInfo.id,
                    userName: userInfo.nickname,
                    userImg: userInfo.image_url,
                }
                const result = await enterRoom(body, newRoomId)
                console.log("result", result)
                if (result?.response?.status) {
                    const status = result?.response?.status;

                    let message;
                    if (status === 403) {
                        message = '입장 불가! 방장에 의해서 내보내기를 당한 유저는 입장할 수 없습니다.';
                    } else {
                        message = result?.response?.data?.place_holder || result?.response?.data?.message;
                    }

                    if (message) toast.warning(message);
                    // toast.warning(result?.response?.data?.message)
                    props.activeChange()
                } else if (result) {
                    // if (item.roomType === "talk2me") props.activeChange("question")
                    // else props.activeChange("answer"); // <===========
                    props.activeChange("answer")
                }
                props.changeAuthLayout("logged")
            }
        // } else {
        //     console.log("here")
        // }
        }
    }

    useEffect(() => {
        // TODO : 실제 호출되는 API 는 제대로 구현된 API 가 아님.
        /*const getInfo = async userId => {
            let response = await landingPageService.getInfo(userId); // TODO : 실제 호출되는 API 는 제대로 구현된 API 가 아님.
            setColorType(response?.colorType)
            setLayoutType(response?.layoutType)
        }*/

        //param from location
        const getUserProfile = async () => {
            let searchUserId = props.location.search

            if (!searchUserId) {
                searchUserId = "?" + props.selectedValue.id
            }

            if (searchUserId) {
                searchUserId = searchUserId.substring(1, searchUserId.length)
                setOwnerId(searchUserId)
                // getInfo(searchUserId) // TODO : 실제 호출되는 API 는 제대로 구현된 API 가 아님.
                let response = await getUserProfileService(searchUserId)
                if (response?.itemList[0]) setItem(response.itemList[0])
                console.log("response : ", response)
            }
        }

        getUserProfile().then(r => r)
    }, [])

    return (
        <React.Fragment>
            <section className="answer-area">
                <Container>
                    <Row>
                        <Col lg={12} className="p-0">
                            <div className="PcBg">
                                <div className="phone_over">
                                    <div className={`PhoneArea ${colorType || "blueBg"}`} style={{ height: "auto" }}>
                                        <div className="TackBtnArea">
                                            <button
                                                className="bg-transparent border-0"
                                                // TODO: onDoubleClick 이벤트로 더블클릭 시에만 실행되도록 하는 방법도 존재
                                                onClick={() => {
                                                    /*PostRoom().then(r => r)*/
                                                    isAuthenticated()
                                                    // console.log(1)
                                                    // props.activeChange()
                                                }}
                                            >
                                                <span>톡하기</span>
                                            </button>
                                            <img
                                                className="avatar avatar-md mr-2"
                                                //onClick={PostRoom}
                                                onClick={() => {
                                                    /*PostRoom().then(r => r)*/
                                                    isAuthenticated()
                                                    // props.activeChange()
                                                }}
                                                src={item.userImg?.length !== 0 ? item.userImg : "https://d1vb419ubgm46b.cloudfront.net/userbg.jpg"}
                                                alt=""
                                            />
                                        </div>

                                        <div className="answerMobilebg CaseOn">
                                            <div className="topCase01 ">
                                                <div className="PhoneTop phoneRound">
                                                    {/* <Link to="/main" className="ic_home">
                                                        <i className="las la-home"></i>
                                                    </Link> */}
                                                    <div className="ChArea">
                                                        <img src={imgCh01} />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img
                                                            className="avatar avatar-lg mr-2"
                                                            src={item.userImg?.length !== 0 ? item?.userImg : "https://d1vb419ubgm46b.cloudfront.net/userbg.jpg"}
                                                            alt=""
                                                        />
                                                        <div className="w-100">
                                                            <div className="d-block">
                                                                <b>{item.userName}</b>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-12 text-left d-flex">
                                                                    <div className="d-block mr-4">
                                                                        <i className="las la-user"></i>{" "}
                                                                        {item.questionerCount || 0}
                                                                        {/*15460명*/}
                                                                    </div>
                                                                    <div className="d-block">
                                                                        <i className="las la-comments"></i>{" "}
                                                                        {/*{item.questionerCount}명*/}
                                                                        {item.viewCount || 0}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="PhoneBody">
                                            <div className="tabArea">
                                                <span id="viewTab01" className="tag tag-rounded active">
                                                    답변톡
                                                </span>
                                                <span id="viewTab02" className="tag tag-rounded ml-2 ">
                                                    정보
                                                </span>
                                                <span id="viewTab03" className="tag tag-rounded ml-2">
                                                    상품
                                                </span>
                                            </div>
                                            <div className="conArea pb-100px">
                                                {/*<div className="d-block mb-2 sectorTit">정보</div>
                                                <div className="txtArea2 partOn">
                                                    <div className="d-block w-100 ">
                                                        <div className="d-block">
                                                            <i className="las la-clock"></i>{" "}
                                                            <b>답변가능시간 :</b>오전10:00 ~ 오후1:00{" "}
                                                        </div>
                                                    </div>

                                                    <div className="d-block w-100 ">
                                                        <div className="d-block">
                                                            <i className="las la-calendar"></i>{" "}
                                                            <b>답변가능요일 :</b> 월~금
                                                        </div>
                                                    </div>
                                                    <div className="d-block w-100 mt-2">
                                                        <div className="d-block">
                                                            공휴일에는 답변이 불가능합니다. 답변시간이
                                                            아니어도 톡에 입장하셔서 톡 전송을 하실 수
                                                            있습니다. 하지만 답변자의 답변은 운영시간을
                                                            기다려주세요. 감사합니다.
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                <div className="d-block  mt-2 sectorTit">답변톡 정보</div>
                                                <div className="txtArea mt-2">{item.introduction}</div>
                                                <div className="mBox">
                                                    <div className="d-block mb-2 mt-2 ">답변키워드</div>
                                                    <div className="answerInfo">
                                                        <div className=" align-items-center mt-auto">
                                                            <div className="d-block w-100 ">
                                                                {item?.keywordList?.map((item, index) => {
                                                                    return (
                                                                        <span
                                                                            className="tag tag-rounded mr-1 mb-1"
                                                                            key={index}
                                                                        >
                                                                            {`#${item}`}
                                                                        </span>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-block mb-2 mt-2 ">답변지역</div>
                                                    <div className="answerInfo">
                                                        <div className=" align-items-center mt-auto">
                                                            <div className="d-block w-100 ">
                                                                {item?.regionList?.map((oneItem, index) => {
                                                                    return (
                                                                        <span key={index} className="tag tag-rounded mr-1 mb-1">
                                                                            {oneItem?.upmyeondong_name}
                                                                        </span>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*<div className="d-block mb-2 mt-4 sectorTit">상품</div>
                                                <div className="giftInfo">
                                                    <div className="align-items-center mt-auto">
                                                        <div className="d-block w-100 ">
                                                            <div className="giftTit">
                                                                <img src={imgGift} alt="" /> 플로잉 답변톡 상품
                                                            </div>
                                                            <p>
                                                                플로잉 답변톡 상품은 답변방에서 글자 전송 제한을
                                                                500글자 추가시켜주는 상품입니다.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                                        <div>
                                                            <img src={imgbosuk01} alt="" /> 100
                                                        </div>
                                                        <div>
                                                            <img src={imgbosuk02} alt="" /> 100
                                                        </div>
                                                        <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">
                                                            충전하기
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                {/*<div className="adressArea1">
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img
                                                            className="avatar avatar-md mr-2"
                                                            src={landingYoutube}
                                                            alt=""
                                                        />
                                                        <div>
                                                            <div className="d-block">youtube.com</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="adressArea2">
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img
                                                            className="avatar avatar-md mr-2"
                                                            src={landingFacebook}
                                                            alt=""
                                                        />
                                                        <div>
                                                            <div className="d-block">facebook.com</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="footlogo">
                                                    <img src={logoLandingFoot} />
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* {roomId && (
            <AnswerChatRoom
                roomId={roomId}
                user={user}
                exitChat={() => setRoomId()}
            />
            )} */}
            </section>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(AnswerTalk))

AnswerTalk.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
}
