import React from "react"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

export default function ModalContainer(props) {
    const { kickRoomModal } = props
    return (
        <div>
            <Modal
                returnFocusAfterClose={true}
                isOpen={kickRoomModal}
                centered={true}
            >
                <ModalBody className="text-center">
                    입장 불가<br/><br/>
                    방장에 의해서 내보내기를 당한 유저는<br />
                    입장할 수 없습니다.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            props.setKickRoomModal(false)
                        }}
                    >
                        확인
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
