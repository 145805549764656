import React, {useCallback, useEffect, useState, useRef} from 'react';
import { Container, Row, Col } from "reactstrap"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../assets/css/myStyleSlick.css"
import "../../assets/css/theme1.css"
import "../../assets/css/line-awesome.css"
import './slick-slider-custom.scss';

import SideBar from "./Chat/index"
import Question from "../MySettings/QuestionChat/ChatContainerModal"

import ava01 from "../../assets/images/ava01_2.gif"
import { authService } from "services/auth.service"
import QuestionListItem from '../../components/QuestionListItem';
import ava01_3 from '../../assets/images/ava01_3.gif';

const SuggestedQuestion = props => {
    // document.title = "답변톡 플로잉"

    // const [isChatActive, setIsChatActive] = useState("")
    const {isChatActive, setIsChatActive} = props
    const [selectedValue, setSelectedValue] = useState({})
    const [userInfo, setUserInfo] = useState({})

    const activeChange = useCallback(data => {
        setIsChatActive(data)
    }, []);

    const selectItem = useCallback(item => {
        console.log('selectItem', item);
        setSelectedValue(item);
    }, [])

    useEffect(() => {
        console.log('questions-loading', props.loading);
    }, [props.loading])

    useEffect(
        () => {
            const getUserIdRes = async () => {
                const res = await authService.getUserId()
                if(res.status===201) {
                    const response = await authService.getUserInfo(res.data.user_id)
                    setUserInfo(response)
                } else {
                    return;
                }
            }
            getUserIdRes().then(r=>r)
            // if (localStorage.getItem("user_id")) {
            //     let userInfo = localStorage.getItem("user_data")
            //     userInfo = JSON.parse(userInfo)
            //         // console.log('userInfo', userInfo)
            //     setUserInfo(userInfo)
            // }
        },
        [isChatActive]
    )
    // console.log('props.roomList', props.roomList)
    return (
        <React.Fragment>
            <section className="page-content">
                <div className="job-area  position-re">
                    {isChatActive === "sideBar_question" && (
                        <SideBar
                            idType={"chat-sidebar"}
                            selectedValue={selectedValue}
                            lazyLoad={true}
                            activeChange={screen => activeChange(screen)}
                            layout={"question"}
                            key={selectedValue.roomId}
                        />
                    )}

                    {/*{isChatActive === "answer" && (
                        <Answer
                            roomId={selectedValue.roomId}
                            user={userInfo}
                            exitChat={() => activeChange()}
                        />
                    )}*/}

                    {isChatActive === "question" && (
                        <Question
                            roomId={selectedValue.roomId}
                            user={userInfo}
                            exitChat={() => activeChange()}
                        />
                    )}

                    <div className="container">
                        <Row>
                            <div className="col-lg-12">
                                <Container>
                                    <div className="filters pb-4 d-flex align-items-center justify-content-between">
                                        <h2 className="listTitle mr-1">
                                            <b>공개톡</b> 리스트
                                        </h2>
                                    </div>
                                </Container>
                            </div>

                            <div className="col-lg-12">
                                <div className="testimonial-area ">
                                    <div className="container">
                                        {/* <button
                                        className="btn btn-success"
                                        onClick={() => getNextSlide()}
                                        >
                                        </button> */}
                                        <Slider
                                            className="flowing-ui--main-slick-list"
                                            onSwipe={(e) => {
                                                // getNextSlide() // TODO : Pagination 을 구현하려고 했던 것 같은데, 이 방식으로는 혼란만 일으킨다. 보류!!!
                                                // console.log('Suggested-Question-onSwipe', e)
                                                // e === left => next 이벤트
                                                // e === right => prev 이벤트
                                            }}
                                            afterChange={(current) => {
                                                if (props.dataHandler) props.dataHandler(current).then(r => r);
                                            }}
                                            infinite={false}
                                            speed={500}
                                            rows={props.pageSize || 3}
                                            slideToScroll={3}
                                            slideToShow={3}
                                            arrows
                                            style={{zIndex:1}}
                                        >
                                            {
                                                props.roomList &&
                                                props.roomList.map((item, index) => (
                                                    <QuestionListItem userInfo={userInfo} activeChange={activeChange} setSelectedValue={selectItem} {...item} key={`${item?.roomId}-${index}` || index} />
                                                ))
                                            }
                                            {
                                                props?.roomList?.length < 1 ?
                                                    <div className="table-responsive">
                                                        <div className="media media-card align-items-center">
                                                            <div className="card-body">
                                                                <article className="text-center">
                                                                    <img className="noImg" src={props.loading ? ava01_3 : ava01} alt="" />
                                                                    <p className="fs-14">{props.loading ? '공개톡을 검색하고 있습니다.' : '검색결과가 없습니다.'}</p>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SuggestedQuestion
