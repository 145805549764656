import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import { toast } from "react-toastify"
import { Button, Modal, ModalBody } from "reactstrap"
import { map } from "lodash"

import config from "../../config/configuration"

import "../../assets/css/style.css"
import diaImage from "../../assets/images/dia.png"
import diaGoods01 from "../../assets/images/diaGoods01.png"
import { goodsDepositId } from "../../constants/goodsDepositId"

import { loadTossPayments } from "@tosspayments/payment-sdk"
import {
    paymentPreTransactionService,
    myDiamondList
} from "../../services/payment.service"
import { authService } from "services/auth.service"
import { methodObj as paymentMethods } from 'constants/paymentMethod';

// const clientKey = "test_ck_4vZnjEJeQVxDGq9J5nOVPmOoBN0k"
const clientKey = config.toss.client_key

const BuyDiamonds = () => {

    const [selectedAmount, setSelectedAmount] = useState()
    const [selectedIndex, setSelectedIndex] = useState()
    const [paymentModal, setPaymentModal] = useState()
    const [paymentType, setPaymentType] = useState()
    const [userInfo, setUserInfo] = useState()
    const [orderId, setOrderId] = useState()
    const [myDiamond, setMyDiamond] = useState()
    const [userId, setUserId] = useState()

    useEffect(() => {
        /*getUserImg()*/
        const getUserIdRes = async () => {
            const userData =  sessionStorage.getItem('user_data') ? JSON.parse(sessionStorage.getItem('user_data')) : null;
            if (userData) {
                setUserId(userData?.id);
                setUserInfo(userData);
                return;
            }

            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserId(res.data.user_id)
                const response = await authService.getUserInfo(res.data.user_id)
                console.log(response)
                setUserInfo(()=>{return response})
                // setNickname(obj.nickname)
            } else {
                return;
            }
        }

        getUserIdRes().then(r => r);
    }, [])

    async function getPaymentView(
        type,
        amount,
        orderId,
        orderName,
        customerName
    ) {
        console.log("111:!!!:!:!:!")
        console.log(clientKey)
        const tossPayments = await loadTossPayments(clientKey)
        console.log(tossPayments)
        loadTossPayments(clientKey).then(tossPayments => {
            tossPayments
                .requestPayment(type, {
                    amount: amount,
                    orderId: orderId,
                    orderName: orderName,
                    customerName: customerName,
                    // successUrl: "https://pay.api.flowing.tel/payment/pg/succeed",
                    // failUrl: "https://pay.api.flowing.tel/payment/pg/fail"
                    successUrl: config.api.payment_api + "/payment/pg/succeed",
                    failUrl: config.api.payment_api + "/payment/pg/fail"
                })
                .then((rs) => {
                    // TODO : 결제 성공에 대한 처리 필요함
                    console.log('rs', rs);
                    console.log('rs', typeof rs);
                })
                .catch(function(error) {
                    if (error.code === "USER_CANCEL") {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                        // toast.warning("USER_CANCEL")
                        toast.warning("사용자가 결제를 취소했습니다.")
                    } else if (error.code === "INVALID_CARD_COMPANY") {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                        // toast.warning("INVALID_CARD_COMPANY")
                        toast.warning("카드회사 정보가 유효하지 않습니다.")
                    }
                })
        })
    }

    const getMyDiamonds = async () => {
        try {
            let response = await myDiamondList(userId)
            console.log('myDiamondList', response)
            if (response) {
                setMyDiamond(response?.itemList[0].dia)
            } else {
                setMyDiamond(0)
            }
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => {
        if (!userId) return;
        getMyDiamonds().then(r => r)
    }, [userId])

    const paymentPreTransaction = async type => {
        if (userId) {

            setPaymentModal(false)
            setPaymentType(type)

            let body = {
                userId: userId,
                goodsDepositId: goodsDepositId[selectedIndex].id,
                amount: goodsDepositId[selectedIndex].amount,
                transactionMethod: type
            }

            let response = await paymentPreTransactionService(body)
            console.log('paymentPreTransaction', response);
            if(!response || !response?.orderId) {
                toast.warning("결제정보를 로드하지 못했습니다. 잠시 후 다시 시도해 주세요.")
            } else {
                setOrderId(response && response?.orderId || '')
                console.log(11,"::")

                await getPaymentView(
                    type,
                    goodsDepositId[selectedIndex].amount,
                    response.orderId,
                    goodsDepositId[selectedIndex].title + ' ' + goodsDepositId[selectedIndex].subTitle,
                    userInfo.nickname
                )
            }

        } else {
            // toast.warning("Please log in")
            toast.warning("로그인 해주세요.")
        }
    }

    //meta title
    document.title = "답변톡 플로잉"
    return (
        <React.Fragment>
            <section className="buyArea job-area pb-60px">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="filters pb-4 align-items-center">
                                <h2 className="listTitle">
                                    다이아 <b>충전</b>
                                </h2>
                                <h5 className="mt-1">
                                    {" "}
                                    다이아를 통해서 플로잉 답변톡 상품을 구매할 수 있어요.
                                </h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className="buyInfo">
                                <div className="buyBox d-flex align-items-center justify-content-between">
                                    현재 보유
                                    <b>
                                        <img src={diaImage} alt="" /> {myDiamond}
                                    </b>
                                </div>
                                <Col lg={12} className="text-right mt-5">
                                    {/*<button className="btn btn-outline-secondary">*/}
                                        <a href="https://terms.flowing.tel/" target="_blank">다이아 충전 및 사용안내 약관 {">"}</a>
                                    {/*</button>*/}
                                </Col>
                                <Col lg={12} className="mt-3 p-0">
                                    <Row>
                                        {map(goodsDepositId, (item, index) => (
                                            <Col key={item.id} lg={6} className="mb-4">
                                                <div className="buyGoods align-items-center">
                                                    <div className="diaImg">
                                                        <img src={item.img} alt="" />
                                                    </div>
                                                    <div className="textArea">
                                                        다이아 <b>{item.subTitle}</b>
                                                        <span>
                                                            ￦{" "}
                                                            {item.amount.toLocaleString(undefined, {
                                                                maximumFractionDigits: 3
                                                            })}{" "}
                                                        </span>
                                                    </div>
                                                    <div className="ml-auto position-relative">
                                                        {item.addCount && (
                                                            <span className="plusPoint">{item.addCount}</span>
                                                        )}

                                                        <button
                                                            className="btn btn-outline-secondary buyBtn"
                                                            onClick={() => {
                                                                setSelectedAmount(item.amount)
                                                                setSelectedIndex(index)
                                                                setPaymentModal(true)
                                                            }}
                                                        >
                                                            충전
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal returnFocusAfterClose={true} isOpen={paymentModal} centered={true}>
                <div className="modal-header align-items-center">
                    <h5 className="modal-title" id="recoverModalTitle">
                        결제수단 선택
                        <span>￦ {goodsDepositId[selectedIndex]?.amount}</span>
                    </h5>
                    <button
                        className="close p-0 m-0"
                        aria-label="Close"
                        type="button"
                        data-dismiss="modal"
                        onClick={() => {
                            setPaymentModal(false)
                        }}
                    >
                        <span aria-hidden="true" className="la la-times"></span>
                    </button>
                </div>
                <ModalBody className="">
                    <div className="input-box">
                        <div className="form-group">
                            <div className="buyGoods align-items-center">
                                <div className="diaImg">
                                    <img src={diaGoods01} alt="" />
                                </div>
                                <div className="textArea">
                                    다이아 <b>{goodsDepositId[selectedIndex]?.subTitle}</b>
                                    <span>{new Intl.NumberFormat('ko', { style: 'currency', currency: 'KRW' }).format(goodsDepositId[selectedIndex]?.amount || 0)}</span>
                                </div>
                            </div>
                            <p className="fs-14 fs-gray">결제 수단을 선택하세요.</p>
                        </div>
                        <div className="form-group">
                            {paymentMethods && Object.entries(paymentMethods).map(([key, value], index) => {
                                return (
                                    <Button key={key} className="theme-btn col-12 mb-2 b-none" block onClick={() => {
                                        paymentPreTransaction(key).then(r => r);
                                    }}>{value}</Button>
                                )
                            })}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default BuyDiamonds
