import { get, post } from "../helpers/api_helper"

export async function checkPath(body) {
    try {
        return await post("/talk2you/users/profile/landing-page/check", body)

    } catch (err) {
        console.error(err)
        throw err
    }
}
export async function checkPathId(path) {
    try {
        return await get(`/talk2you/users/profile/landing-page/${path}`,{},true)

    } catch (err) {
        console.error(err)
        throw err
    }
}