//import room service
import {
    isSeenService,
    ownerMessageReadService,
    leaveChatRoomService,
    sendReportUserService,
    setRoomTransferService,
    getBlockedUsersService,
    getRoomTransferService,
    deleteOneMessageService,
    checkVideoUploadingService,
    maskMessageChatRoomService,
    userProfileChatRoomService,
    blockUserFromChatRoomService,
    unblockUserFromChatRoomService
} from "../../../../../services/talk2youMessage.service"

// Get block users
export async function getBlockUsers(roomId, userId) {
    const response = await getBlockedUsersService(roomId, userId)
    return {
        status: true,
        data: response?.itemList?.length > 0 ? response.itemList : []
    }
}

// Mask all message delete
export async function setRoomTransferFunction(roomId, body) {
    return await setRoomTransferService(roomId, body)
}

// Mask all message delete
export async function leaveChatRoomFunction(roomId, body) {
    return await leaveChatRoomService(roomId, body)
}

// Mask all message delete
export async function maskAllMessageFunction(roomId, body) {
    return await maskMessageChatRoomService(roomId, body)
}

// One message delete
export async function deleteOneMessageFunction(body) {
    return await deleteOneMessageService(body)
}

// block user
export async function blockUserFromChatRoomFunction(roomId, body) {
    return await blockUserFromChatRoomService(roomId, body)
}

// UnBlock user
export async function unblockUserFromChatRoomFunction(roomId, body) {
    return await unblockUserFromChatRoomService(roomId, body)
}

// Check video upload
export async function checkVideoUploadingFunction(jobId) {
    return await checkVideoUploadingService(jobId)
}

// Sent message
export async function userProfileChatRoomFunction(roomId, body) {
    return await userProfileChatRoomService(roomId, body)
}

// Sent report user
export async function sendReportUserFunction(roomId, body) {
    return await sendReportUserService(roomId, body)
}

// Get chat limit
export async function getTransferLimitFunction(roomId, userId) {
    const response = await getRoomTransferService(roomId, userId)
    if (response && response?.itemList?.length > 0) {
        console.log("asdasdddd", response)
        return {
            status: true,
            limit: {
                purchaseCount: response?.itemList[0].purchaseCount,
                transferCount: response?.itemList[0].transferCount,
                transferMaxCount: response?.itemList[0].transferMaxCount
            }
        }
    } else {
        return {
            status: false,
            message: "Can't message limit"
        }
    }
}
