import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { ToastContainer } from 'react-toastify';

import store from "./store"

const root = ReactDOM.createRoot(document.getElementById("root"))

document.title = "답변톡 플로잉"
root.render(
    <Provider store={store}>
        <ToastContainer className="pre-wrap" />
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

serviceWorker.unregister()
