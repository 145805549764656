import {useEffect, useState} from 'react';

import helloCowImage from 'assets/images/ava02_hello.gif';
import helloPuddingImage from 'assets/images/ava01_9.gif';
import sorryPuddingImage from 'assets/images/ava01_2.gif';
import busyPuddingImage from 'assets/images/ava01_3.gif';
import cryDogImage from 'assets/images/ava03_5.gif';
import propTypes from 'prop-types';

const ScreenOperator = ({message, absolute, screenBody, status, image, ...props}) => {
    const [defaultSet, setDefaultSet] = useState({
        image: helloCowImage,
        message: 'Now Operating...',
    });

    useEffect(() => {
        switch (status) {
            case 404: setDefaultSet({
                image: busyPuddingImage,
                message: '해당 정보를 찾을 수 없습니다.',
            }); break;
            case 403: setDefaultSet({
                image: sorryPuddingImage,
                message: '해당 정보에 접근할 자격이 없습니다.',
            }); break;
            case 500: setDefaultSet({
                image: cryDogImage,
                message: '알 수 없는 오류가 발생했습니다.',
            }); break;
        }
        console.log(status, defaultSet);
    }, [status])

    return (
        <div style={{
            position: absolute ? 'absolute' : 'fixed',
            inset: 0,
            backgroundColor: 'rgba( 255, 255, 255, 1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999,
        }}>
            {props.children || screenBody || (
                <div>
                    <div style={{
                        maxWidth: 250,
                        padding: 20,
                    }}>
                        <img src={defaultSet.image || image} alt="" style={{
                            maxWidth: '100%',
                        }}/>
                        <div className="text-center font-weight-bold" style={{
                            wordBreak: 'keep-all',
                            wordWrap: 'break-word',
                        }}>{message || defaultSet.message}</div>
                    </div>
                </div>
            )}
        </div>
    )
}

ScreenOperator.prototype = {
    image: propTypes.string,
    message: propTypes.string,
    absolute: propTypes.bool,
    screenBody: propTypes.element,
    status: propTypes.oneOf([404, 403, 500]),
}

export default ScreenOperator;