import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import React, { useState } from "react"

import { Alert, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import "../../assets/css/style.css"
// import "../../assets/css/AddStyle.css"
//redux
import logo from "../../assets/imageshtml/logo_landingFoot.png"

import { withRouter, Link } from "react-router-dom"

//import service
import "react-toastify/dist/ReactToastify.css"
import classnames from "classnames"
import Login from "./Login"
import Register from "./Register"
import AccountLogin from "./AccountLogin"
import RegisterSimple from "./RegisterSimple"

const Auth = props => {
    //meta title
    document.title = "답변톡 플로잉"
    const [isLogin, setIsLogin] = useState(true)
    const [userPhoneNumber, setUserPhoneNumber] = useState("");
    const [userNickname, setUserNickname] = useState("");
    const [verticalActiveTab, setverticalActiveTab] = useState("2")
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab)
        }
    }


    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // const login = async () => {
    //   const result = await authService.login(email, password)
    //   if (result.status == 200) {
    //     props.history.push("/dashboard")
    //     window.location.href = "/dashboard"
    //   }
    // }

    return (
        <React.Fragment>
            <section className="login-area position-relative">
                <div className="auth">
                    <div className="logo">
                        <a href="/main">
                            <img src={logo} />
                        </a>
                    </div>
                    <div className="auth_left">
                        {!isLogin && <RegisterSimple
                                        setIsLogin={() => setIsLogin(true)}
                                        userPhoneNumber={userPhoneNumber}
                                        userNickname={userNickname}
                                    />
                        }

                        {isLogin && (
                            <div className="card">
                                <div className="card-top">
                                    {/*<div className="card-title">Log in Flowing</div>*/}
                                    <div className="card-title">플로잉 웹 로그인</div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex">
                                        <Nav className="newtab">
                                            <NavItem>
                                                <NavLink
                                                    onClick={() => {
                                                        toggleVertical("2")
                                                    }}
                                                    className={classnames({
                                                        defaultChecked: verticalActiveTab === "2"
                                                    })}
                                                >
                                                    <div className="payment-tab-toggle pl-0">
                                                        <input
                                                            id="login2"
                                                            name="loginCase"
                                                            type="radio"
                                                            value="loginCase"
                                                            defaultChecked={
                                                                verticalActiveTab === "2" ? true : false
                                                            }
                                                            checked={verticalActiveTab === "2" ? true : false}
                                                        />
                                                        <label htmlFor="login2">계정 로그인</label>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    onClick={() => {
                                                        toggleVertical("1")
                                                    }}
                                                    className={classnames({
                                                        defaultChecked: verticalActiveTab === "1"
                                                    })}
                                                >
                                                    <div className="payment-tab-toggle pl-0">
                                                        <input
                                                            id="login1"
                                                            name="loginCase"
                                                            type="radio"
                                                            value="loginCase"
                                                            defaultChecked={
                                                                verticalActiveTab === "1" ? true : false
                                                            }
                                                            checked={verticalActiveTab === "1" ? true : false}
                                                        />
                                                        <label htmlFor="login1">간편 로그인</label>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    {isAlert && (
                                        <Alert
                                            color="success"
                                            className="alert alert-icon alert-success fs12"
                                            role={alert}
                                        >
                                            <i className="fe fe-alert-triangle"></i>
                                            {/*{props.t(alertMessage)}*/}
                                            <span style={{ marginLeft: 20 }}>{alertMessage}</span>
                                        </Alert>
                                    )}
                                    <TabContent
                                        activeTab={verticalActiveTab}
                                        // className="text-muted mt-4 mt-md-0"
                                    >
                                        <TabPane tabId="1">
                                            <AccountLogin easyCreate={(phoneNumber, nickName) => {
                                                // console.log('phoneNumber 1 ', phoneNumber);
                                                setIsLogin(false)
                                                setUserPhoneNumber(phoneNumber);
                                                setUserNickname(nickName);
                                            }}
                                            setAlertMessage={setAlertMessage}
                                            setIsAlert={setIsAlert}
                                            toggleVertical={toggleVertical}
                                            />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Login setAlertMessage={setAlertMessage} setIsAlert={setIsAlert} easyCreate={() => setIsLogin(false)} />
                                        </TabPane>
                                    </TabContent>
                                </div>


                                <div className="card-footer text-center text-muted">
                                    <a href="forgot-password">
                                        <i className="fa fa-lock"></i>{" "}
                                        <span className="blackColor">
                                            비밀번호가 생각나지 않나요?
                                        </span>
                                    </a>
                                </div>
                                <div className=" text-center mt-0 pb-4">
                                    플로잉 회원이 아니신가요?{" "}
                                    <Link to="/register">지금 가입하세요.</Link>
                                    <p className="mt-5 Copyright">
                                        © 2022 Copyright TPKOREA Co.,Ltd.
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="auth_right"></div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Auth))

Auth.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}
