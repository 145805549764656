import React from "react";

const AnswerReview1 = () => {
    return (
<section class="product-area pt-90px pb-90px ">
    <div class="container">
        <div class="row">    

            <div class="col-lg-12">
                <div class="filters pb-4 ">
                    <h2 class="listTitle mr-1"><b> 답변톡</b> 이용사례 </h2>
                    <p>많은 분들이 답변톡 상품으로 수익을 만들고 있어요.</p>
                </div>
            </div>
        </div>
    </div>    
    <div class="container">
        <div class="row">    
            <div class="col-lg-12">
                <div class="review-avatar-area">
                    <img src="images/review-avatar04.jpg" />
                    헬스 트레이너 서힘찬
                </div>
            </div>
        </div>
        <div class="row mt-4">    
            <div class="col-lg-12">
                <div class="review-area">
                    <div class="col"><img src="images/review04-01.png" /></div>
                    <div class="arrow-area"><img src="images/review-arrow-icon.png" /></div>
                    <div class="col"><img src="images/review04-02.png" /></div>
                    <div class="arrow-area"><img src="images/review-arrow-icon.png" /></div>
                    <div class="col"><img src="images/review04-03.png" /></div>
                 </div>
            </div>
        </div>     

    </div>
</section>

    )
}

export default AnswerReview1;