import React, {useEffect, useState, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
    CardBody,
    Card,
    Alert,
    Input,
    Form,
    InputGroup,
    FormGroup,
    CardFooter,
    Button,
} from 'reactstrap';
import {uploadImageService} from '../../services/talk2meMessage.service';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {withRouter, Link, Redirect} from 'react-router-dom';
import {toast} from 'react-toastify';

import logoFoot from '../../assets/images/logo_landingFoot.png';
import logoImg from '../../assets/images/userbg.jpg';

import {authService} from '../../services/auth.service';

const Register = (props) => {
    const [emailCheck, setEmailCheck] = useState(false);
    const [authCode, setAuthCode] = useState('');
    const [resultAuthCode, setResultAuthCode] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [userId, setUserId] = useState(sessionStorage.getItem('renewUserId'));

    const [authCodeTime, setAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)

    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')
    const [isPasswordShow, setIsPasswordShow] = useState(false)
    const [isPasswordCheckShow, setIsPasswordCheckShow] = useState('')


    const valueEmail = sessionStorage.getItem('renewUserEmail')

    //meta title
    document.title = '번호재인증 | Flowing';

    useEffect(() => {
        return () => {
            sessionStorage.clear()
        }
    }, [])

    // 번호 인증 타이머
    let sec = 60
    let min = 1
    let time = 180000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
                setAuthCodeTimeIn(false)
                setAuthCodeTime("0:00")
            }
        }
    }

    const requestCodeFunction = async () => {
        // console.log("userId:::", userId);

        // if (phoneNumber === null || phoneNumber === "") {
        if (!valueEmail) {
            setAlertMessage("이메일을 입력해 주세요");
            setIsAlert(true);
            return;
        }else{
            setIsAlert(false);
            try {
                const request = await authService.activateEmailRequestCode(userId, {email: valueEmail}).then(r => {
                    if(r.status !== 201) throw r
                    return r
                })
                setAlertMessage("인증번호를 확인 후 입력해 주세요.");
                setEmailCheck(true);
                setIsAlert(true);

                // 번호입력 타이머 설정
                setAuthCodeTimeIn(true)
                timerRef.current = setInterval(timer, 1000)
                return;
            } catch(error) {
                console.log(error)
                toast.error(error?.data?.place_holder)
                //번호 입력 타이머 해제
                if (timerRef.current) {
                    clearInterval(timerRef.current)
                    timerRef.current = null
                }
                throw error
            }
        //     const result = await authService.requestRenewCode(userId, phoneNumber);
        //     console.log(result);
        //     if(result.status === 201){
        //         setAlertMessage("인증번호를 확인 후 입력해 주세요.");
        //         setEmailCheck(true);
        //         setIsAlert(true);

        //         // 번호입력 타이머 설정
        //         setAuthCodeTimeIn(true)
        //         timerRef.current = setInterval(timer, 1000)
        //         return;

        //     }else if(result.status === 400){
        //         setAlertMessage(result.data.place_holder);
        //         setIsAlert(true);
        //     }

        //     //번호 입력 타이머 해제
        //     setAuthCodeTimeIn(false)
        //     if (timerRef.current) {
        //         clearInterval(timerRef.current)
        //         timerRef.current = null
        //     }
        }
    };

    const checkRequestedCodeFunction = async () => {
        setIsAlert(false);

        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            setAlertMessage("인증시간이 지났습니다. 다시 인증을 받아주세요.");
            setIsAlert(true);
            return
        }
        if(authCode.trim()==='') {
            toast.error("인증코드를 입력해 주세요")
            setAlertMessage("인증코드를 입력해 주세요");
            setIsAlert(true);
            return
        }

        if(!emailCheck){
            // setAlertMessage("SMS 인증번호 요청 API를 먼저 호출해주세요.");
            setAlertMessage("이메일 인증번호 발송 버튼을 눌러 인증번호를 받아주세요");
            setIsAlert(true);
        }else{
            setIsAlert(false);
            // const result = await authService.checkRequestedRenewCode(userId, valueEmail, authCode,"");
            // console.log(result);
            // if(result.status === 201){
            //     setAlertMessage("재인증이 완료 되었습니다. 다시 로그인 해주세요");
            //     setEmailCheck(true);
            //     setIsAlert(true);
            //     window.alert("재인증이 완료 되었습니다. 로그인으로 이동합니다.");
            //     props.history.push("/login");
            // }else if(result.status === 400){
            //     setAlertMessage(result.data.place_holder);
            //     setIsAlert(true);
            // }
            // console.log(result);
            try {
                const request = await authService.certificateActivateEmailRequestCode(
                    userId, {email: valueEmail, auth_code: authCode}
                ).then(r => {
                    if(r.status!==201)throw r
                    return r
                })
                setAlertMessage("인증되었습니다. 비밀번호를 새로 입력 후 인증 완료 버튼을 눌러주세요");
                setResultAuthCode(true);
                setIsAlert(true);
            } catch(error) {
                toast.error(error?.data?.place_holder)
                throw error
            }
        }

    };

    const completeActivateEmail = async() => {
        try {
            const request = await authService.addUserEmail(
                userId, {email: valueEmail, password}
            ).then(r => {
                if(r.status!==201) throw r
                return r
            })
            window.alert("재인증이 완료 되었습니다. 로그인 페이지로 이동합니다.");
            props.history.push("/login");
        } catch(error){
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            throw error
        }
    }

    return (
        <React.Fragment>
            <div className="auth">
                <div className="logo">
                    <Link to="/">
                        <img src={logoFoot} alt="logo"/>
                    </Link>
                </div>
                <div className="auth_left">
                    <Card>
                        <div className="card-top d-flex">
                            <div className="card-title">이메일 재인증</div>
                        </div>
                        <CardBody className="">
                            {isAlert === true && (
                                <Alert
                                    color="success"
                                    className="alert alert-icon alert-success fs12"
                                    role={alert}
                                >
                                    <i className="fe fe-alert-triangle"></i>
                                    {/*{props.t(alertMessage)}*/}
                                    <span style={{ marginLeft: 20 }}>{alertMessage}</span>
                                </Alert>
                            )}

                            <FormGroup>
                                <InputGroup>
                                    <Input
                                        id="mobile-number"
                                        name="mobile-number"
                                        className="form-control blackBox"
                                        placeholder="이메일을 입력해주세요"
                                        type="mobile-number"
                                        value={valueEmail}
                                        disabled={valueEmail !== ''}
                                    />

                                    <span className="input-group-append">
									<button
                                        className="btn btn-primary widthAuto "
                                        type="button"
                                        onClick={requestCodeFunction}
                                    >인증번호 발송</button>
								</span>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <Input
                                        id="InputNumberCheck"
                                        name="InputNumberCheck"
                                        className="form-control blackBox"
                                        placeholder="인증코드 6자리를 입력해 주세요"
                                        type="text"
                                        value={authCode}
                                        onChange={(e) => setAuthCode(e.target.value)}
                                    />
                                    <span className="CountNum" style={{right:130}}>{authCodeTime}</span>
                                    <span className="input-group-append">
										<button
                                            className="btn btn-primary widthAuto "
                                            type="button"
                                            onClick={checkRequestedCodeFunction}
                                        >확인</button>
									</span>
                                </InputGroup>
                            </FormGroup>
                            {resultAuthCode && (
                                <div>
                                    <div style={{display:'flex', gap: '20px', alignItems: 'center'}}>
                                        <FormGroup style={{width: '100%', display:'flex', flexDirection:'column'}}>
                                            <Input
                                                id="InputPassword"
                                                name="InputPassword"
                                                className="form-control blackBox"
                                                placeholder="새로운 비밀번호를 입력해 주세요"
                                                type={'password'}
                                                value={password}
                                                onChange={(e) => {setPassword(e.target.value)}}
                                            />
                                            {password && !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) && (
                                                <p style={{color:'red'}}>영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자로 입력하세요.</p>
                                            )}
                                        </FormGroup>
                                    </div>

                                    <div style={{display:'flex', gap: '20px', alignItems: 'center'}}>
                                        <FormGroup style={{ display:'flex', flexDirection:'column', width: '100%'}}>
                                            <InputGroup>
                                                <Input
                                                    id="InputPassword"
                                                    name="InputPassword"
                                                    className="form-control blackBox"
                                                    placeholder="새로운 비밀번호를 입력해 주세요"
                                                    type={'password'}
                                                    value={passwordCheck}
                                                    onChange={(e) => {setPasswordCheck(e.target.value)}}
                                                />
                                                <span className="input-group-append">
                                                    <button
                                                        disabled={
                                                            password.trim() === '' ? true :
                                                            !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) ? true :
                                                            passwordCheck.trim() === '' ? true :
                                                            passwordCheck !== password ? true : false
                                                        }
                                                        className="btn btn-primary widthAuto "
                                                        type="button"
                                                        onClick={()=>{completeActivateEmail()}}
                                                    >완료</button>
                                                </span>
                                            </InputGroup>
                                            {passwordCheck && (password !== passwordCheck) && (
                                                <p style={{color:'red'}}>비밀번호가 일치하지 않습니다.</p>
                                            )}
                                        </FormGroup>
                                    </div>

                                </div>
                            )}

                        </CardBody>
                        <CardFooter className="text-muted text-center">
                            {/* TODO: 문구 변경 필요 */}
                            <p className="">
                                현재 등록된 휴대폰번호는 본인 또는 제 3자에 의해 사용 중인 번호입니다.<br/>
                                새로운 휴대폰번호 또는 기존 휴대폰번호를 이용해 재인증을 받으시기 바랍니다.
                            </p>
                            <p className="">
                                플로잉 회원이세요?
                                <Link to="/login" className="font-weight-medium text-primary">
                                    {' '}
                                    로그인
                                </Link>
                            </p>
                            <p className="mt-5 Copyright">
                                © {new Date().getFullYear()} Copyright TPKOREA Co.,Ltd.
                            </p>
                        </CardFooter>
                    </Card>

                    {/*<div className=" text-center mt-0 pb-4 mb-5">*/}
                    {/*</div>*/}

                </div>
                <div className="auth_right"></div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Register));

Register.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
