import React from "react"
import moment from "moment"
import Text from "./type/text"
import Photo from "./type/photo"
import Voice from "./type/voice"
import Video from "./type/video"
import File from "./type/file"
import Preview from "./type/preview"
import plane from '../../../../assets/images/plane.png';

export default function CheckType(props, args) {
    const { message, myMsg, idx } = props
    // console.log('myIndex', idx);
    const prevMsgTime =
        (idx && props.messages[idx - 1] && moment(props.messages[idx - 1].createdAt).format("HH:mm")) || ""
    // console.log('prevMsgTime', prevMsgTime)
    const currentMsgTime = (idx && props.messages[idx] && moment(props.messages[idx].createdAt).format("HH:mm")) || ""
    // console.log('currentMsgTime', currentMsgTime)
    // console.log('props', props)
    return (
        <div>
            <div className={myMsg ? "text-right chat-body-message-content-text" : " chat-body-message-content-text"}>
                {props.user?.id !== message?.senderId &&
                    <p className="senderName">{message.senderName} {/*props?.roomData?.ownerData?.userId*/}</p>
                }
                <div>
                    {message.msgType === "VOICE" && <Voice {...props} />}
                    {message.msgType === "FILE" && <File {...props} />}
                    {message.msgType === "VIDEO" && <Video {...props} />}
                    {message.msgType === "TEXT" && <Text {...props} />}
                    {message.msgType === "PHOTO" && <Photo {...props} />}
                    {(message.msgType === "PREVIEW" || message.previewData) && <Preview {...props} />}

                    {/*{!(message?.hashCode && props.lastReadMsgTime*/}
                    {/*        && moment(Number(message?.hashCode)).isSameOrBefore(moment(props.lastReadMsgTime))) &&*/}
                    {/*{!(message?.createdAt && props.lastReadMsgTime
                            && moment(message?.createdAt).isSameOrBefore(moment(props.lastReadMsgTime))) &&*/}


                    {/*{!ownerSeen &&
                        ownerData?.userId !== message.senderId &&
                        message.read !== 1 && (
                            <img
                                src={plane}
                                className="img-thumbnail"
                                style={{
                                    height: '14px',
                                    weight: '14px',
                                    backgroundColor: 'white',
                                    border: '0px',
                                }}
                                alt=""
                            />
                        )}*/}
                </div>
                <p className="time">
                    {prevMsgTime && prevMsgTime === currentMsgTime && currentMsgTime ? "" :
                        (Number(moment(message.createdAt).format("HH")) < 12 ? "오전 " : "오후 ") +
                        moment(message.createdAt).format("hh:mm")
                    }
                    {!(new Date(message?.createdAt).getTime() <= new Date(props.lastReadMsgTime).getTime()) &&
                    // props.user?.id === message?.senderId &&
                    // !(props?.roomData?.isOwner) &&
                    props?.roomData?.ownerData?.userId !== props.user?.id && // 방장인 경우 표시 안함
                    props?.roomData?.ownerData?.userId !== message?.senderId && // 방장이 아닌 모든 이의 메시지에 표시함
                    // message?.senderId === props.user?.id && // 나의 메시지에만 표시함
                    message?.read !== 1 && (
                        <img src={plane} className="img-thumbnail unread_icon" alt=""
                             style={{width:21,height:21,background:"transparent",border:"none"}} />
                    )}
                </p>
            </div>
        </div>
    )
}
