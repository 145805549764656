import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { uniqBy } from 'lodash';
import SuggestedQuestion from "./SuggestedQuestion"
import "../../assets/css/style.css"
import { getQuestion } from "../../services/question.service"
import { categoryService } from "services/category.service"
import AnswerRoomList from "./AnswerRoomList"
import { toast } from "react-toastify"
import { authService } from "services/auth.service"

import { usePrevious } from 'utils/customHooks'

const Dashboard = () => {
    const [roomList, setRoomList] = useState([])
    const [answerList, setAnswerList] = useState([])
    const [searchInput, setSearchInput] = useState('')
    const questionPageSize = 3;
    const answerPageSize = 5;
    const [userId, setUserId] = useState('')
    const [isChatActive, setIsChatActive] = useState('');
    const previousIsChatActive = usePrevious(isChatActive);
    const [isQuestionLoading, setIsQuestionLoading] = useState(false);
    const [isAnswerLoading, setIsAnswerLoading] = useState(false);

    const computedRoomList = useMemo(() => {
        return uniqBy(roomList, 'roomId');
    }, [roomList])

    const computedAnswerList = useMemo(() => {
        return uniqBy(answerList, 'id')
    }, [answerList])

    useEffect(() => {
        const getUserIdRes = async () => {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserId(res.data.user_id)
            } else {
                return;
            }
        }
        getUserIdRes().then(r=>r)
    } ,[])

    //Getting rooms
    const getQuestionRooms = useCallback(async (searchInput, limit, offset) => {
        try {
            console.info('getQuestionRooms-call', {searchInput, limit})
            return await categoryService.getQuestionList(
                searchInput,
                limit,
                offset,
            ).then(res => {
                return res?.itemList || [];
            })
        } catch (e) {
            throw e;
        }
    }, [])

    const getAnswers = useCallback(async (searchInput, limit, offset) => {
        try {
            return await categoryService.getAnswerList(
                searchInput,
                limit,
                offset,
            ).then(res => {
                return res?.userProfileList || [];
            })
        } catch (e) {
            throw e;
        }
    }, [])

    const setQuestionRooms = async () => {
        try {
            setIsQuestionLoading(true);
            setRoomList([]);
            const initPageSize = questionPageSize * 5
            const items = await getQuestionRooms(searchInput, initPageSize, 0);

            setRoomList(items);
        } catch (e) {
            console.error(e);
        } finally {
            setIsQuestionLoading(false);
        }
    }

    const setAnswers = async () => {
        try {
            setIsAnswerLoading(true);
            setAnswerList([]);
            const initPageSize = answerPageSize * 5
            const items = await getAnswers(searchInput, initPageSize, 0);

            setAnswerList(items);
        } catch (e) {
            console.error(e);
        } finally {
            setIsAnswerLoading(false);
        }
    }

    const appendQuestionHandle = async (current) => {
        try {
            const limit = questionPageSize;
            const nList = computedRoomList.slice(0);
            const currentLastSlide = (nList.length / limit);

            if (currentLastSlide - current < 2) {
                const items = await getQuestionRooms(searchInput, limit, nList.length);
                const compositeItems = nList.concat(items);
                setRoomList(compositeItems);
            }
        } catch (e) {
            toast.error(e?.response?.data?.place_holder || e?.response?.data?.message || e?.data?.place_holder || e?.data?.message)
            console.error(e);
        }
    }

    const appendAnswerHandle = async (current, next) => {
        try {
            const limit = answerPageSize;
            const nList = computedAnswerList.slice(0);
            const currentLastSlide = (nList.length / limit);

            if (currentLastSlide - current < 2) {
                const items = await getAnswers(searchInput, limit, nList.length);
                const compositeItems = nList.concat(items);
                setAnswerList(compositeItems);
            }
        } catch (e) {
            toast.error(e?.response?.data?.place_holder || e?.response?.data?.message || e?.data?.place_holder || e?.data?.message)
            console.error(e);
        }
    }

    const searchItems = () => {
        setQuestionRooms().then(r => r)
        setAnswers().then(r => r)
    }

    useEffect(() => {
        setQuestionRooms().then(r => r)
        setAnswers().then(r => r)
    }, [])

    useEffect(() => {
        // ToDo: isChatActive (!value && !!oldValue) - 활성화 창이 닫히는 것을 기준으로 prepend 데이터 구현. (현재 지점 포커스(스와이프 페이지)는 유지?)
        const value = isChatActive;
        const oldValue = previousIsChatActive;

        // console.warn('isChatActive Changed', {
        //     value: value,
        //     oldValue: oldValue,
        // })
    }, [isChatActive])

    useEffect(() => {
        console.log('%c re-rendering-main-search-component', 'background: rgba(255,0,0,0.3);color: #cc0000;')
    })

    // Enter keyboard action
    const onKeyPress = e => {
        const { key } = e
        if (e.shiftKey) {
        } else if (key === "Enter") {
            searchItems();
        }
    }
    const check_login = () => {
        console.log(userId)
        if (userId) {
            window.location.href = "/questionsWrite"
        } else return toast.error("로그인 후 작성 가능합니다.")
    }

    document.title = "답변톡 플로잉"
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="hero-area  pt-40px">
                    <div className="container max1280">
                        <div className="hero-content">
                            {/* <form className="search-form p-0 rounded-5 bg-transparent shadow-none position-relative z-index-1"> */}
                            <div className="searchBox align-items-center">
                                <div className="text-center w-100">
                                    <div className="d-flex align-items-center">
                                        <div className="form-group mr-3 mb-0 flex-grow-1">
                                            <input
                                                className="form-control form--control "
                                                type="text"
                                                name="text"
                                                placeholder="검색어를 입력하고 질문 또는 답변자를 검색하세요."
                                                onChange={e => setSearchInput(e.target.value)}
                                                onKeyPress={onKeyPress}
                                                value={searchInput}
                                            />
                                        </div>
                                        <div className="search-btn-box mb-0 ml-2">
                                            <button
                                                className="btn theme-btn btn-search"
                                                onClick={() => {
                                                    searchItems();
                                                }}
                                            >
                                                <i className="la la-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center writeBtnArea">
                                    <a
                                        href="#"
                                        className="btn theme-btn d-block btnWrite"
                                        onClick={check_login}
                                    >
                                        질문 작성하기
                                    </a>
                                </div>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
                <SuggestedQuestion
                    loading={isQuestionLoading}
                    pageSize={questionPageSize}
                    roomList={computedRoomList}
                    isChatActive={isChatActive}
                    setIsChatActive={setIsChatActive}
                    dataHandler={appendQuestionHandle}
                />
                <AnswerRoomList
                    loading={isAnswerLoading}
                    pageSize={answerPageSize}
                    answerList={computedAnswerList}
                    isChatActive={isChatActive}
                    setIsChatActive={setIsChatActive}
                    dataHandler={appendAnswerHandle}
                />
            </div>
        </React.Fragment>
    )
}

export default Dashboard
