import React, { useEffect, useState } from "react"
import { Card, Container, Row, Col, Form, Collapse, CardBody } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import productImg from "../../assets/images/productImg.png"
import productImg2 from "../../assets/images/productImg2.png"
import productImg3 from "../../assets/images/productImg3.png"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const Product = () => {
    //meta title
    document.title = "답변톡 플로잉"
    const [selectTab, setSelectTab] = useState(1)
    const [switchTab, setSwitchTab] = useState(1)
    const console_url = process.env.REACT_APP_CONSOLE_URL
    // var $ = require( "jquery" );

    const slickSettings1 = {
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        centerPadding: "2px",
        autoplay: true,
        slidesToShow: 5,
        speed: 500,
        responsive: [
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
        ]
    }

    const slickSettings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => {
            // console.log(dots)
            return <div className="">{dots}</div>
        },
        customPaging: i => (
            <img
                src={`images/banner-some-img0${i + 1}.jpg`}
                alt="dot_image"
                style={{
                    cursor: "pointer",
                    // width: "50px",
                    // height: "50px",
                    borderRadius: "50%",
                }}
            />
        ),
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <div className="tab-user">
                        <a
                            href="#"
                            id="user-case01"
                            onClick={() => {
                                setSelectTab(1)
                            }}
                            className={`${selectTab === 1 && "active"}`}
                        >
                            <div className="roller">
                                <div id="rolltext">
                                    인플루언서
                                    <span id="spare-time">크리에이터</span>
                                    <span id="spare-time2">전문가</span>
                                </div>
                            </div>
                        </a>
                        <a
                            href="#"
                            id="user-case02"
                            className={`${selectTab === 2 && "active"}`}
                            onClick={() => {
                                setSelectTab(2)
                            }}
                        >
                            일반사용자
                        </a>
                    </div>
                </Row>
            </Container>
            {selectTab === 1 ? (
                <>
                    <section className="product-area pt-90px pb-90px bgGray">
                        <Container>
                            <Row className="align-items-center">
                                <Col lg={6}>
                                    <p className="productTit">
                                        Manage customer <br />
                                        from various <br />
                                        channels in your
                                        <br />
                                        <span>flowing.space</span>
                                    </p>
                                    <p className="productTxt">
                                        <span>다양한 채널 회원을 답변톡 하나로 관리</span>
                                        <br/>
                                        많은 채널, 댓글, 라방 문의, 무례한 DM<br/>
                                        너무 다양한 채널과 다른 방식의 질문들로 힘드셨죠!!<br/>
                                        이제는 답변톡 하나로 모든 관리가 가능 합니다.
                                    </p>
                                </Col>
                                <Col lg={6} className="text-center">
                                    <div className="frameAni01">
                                        <iframe src="banner/mainBanner.html" className="sessonAni01"/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="product-area pt-90px pb-90px bgSession1">
                        <Container>
                            <Row className="align-items-center">
                                <Col lg={6} className="text-center mobileOrder2">
                                    <div className="frameAni02">
                                        <iframe src="banner/section02.html" className="sessonAni02"/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <p className="productTit">
                                        Price your <br />
                                        response with
                                        <br />
                                        <span>flowing.space </span>
                                    </p>
                                    <p className="productTxt mobileOrder1">
                                        <span>당신의 능력이 답변 톡을 만나면 SUPER 수익을 만들어 줍니다.</span>
                                        <br />
                                        SNS, 재능 마켓, 다양한 채널 당신의 답변 시간은 더 이상 <br />
                                        공짜가 아닙니다. 당신은 능력으로 답변 수익을 만드세요
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="product-area pt-90px pb-90px bgSession2">
                        <Container>
                            <Row className="align-items-center">
                                <Col lg={6}>
                                    <p className="productTit">
                                        Make your
                                        <br />
                                        business easier
                                        <br />
                                        with <span>chat bot </span>
                                    </p>
                                    <p className="productTxt">
                                        <span>챗봇 답변 시나리오 </span> <br />
                                        반복 되는 불필요한 설명 답변 시나리오로 해결 <br />
                                        인사말, 답변 시간, 욕설 차단 <br />
                                        회원 관리 업무가 편해 집니다.
                                    </p>
                                </Col>
                                <Col lg={6}>
                                    <div className="frameAni03">
                                        <iframe src="banner/section03.html" className="sessonAni03"/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="product-area pt-90px pb-90px bgSession3 mbm">
                        <Container>
                            <Row className="align-items-center">
                                <Col lg={6} className="text-center mobileOrder2">
                                    <div className="mainImg">
                                        <iframe src="banner/section04.html" className="sessonAni04"/>
                                    </div>
                                </Col>
                                <Col lg={6} className="mobileOrder1 w-100">
                                    <p className="productTit">
                                        Bring everything
                                        <br/>
                                        you are with <br/>
                                        one simple link
                                    </p>
                                    <p className="productTxt">
                                        <span>다양한 링크가 한 개의 답변 톡 안으로 </span>
                                        <br/>
                                        내가 운영하는 다양한 채널 및 홈페이지를 한 개의 링크로 <br/>
                                        관리하 실 수 있습니다.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="product-area pt-90px pb-90px bgSession2">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <p className="productTit">
                                        Make your
                                        <br />
                                        Business Space
                                        <br />
                                        with <span>Space Talk </span>
                                    </p>
                                    <p className="productTxt">
                                        <span>스페이스톡으로 비즈니스를 만드세요. </span> <br />
                                        유튜브 1개의 회원이 아니라 다양한 채널 회원으로 <br />
                                        내 구독을 만들어 보세요.
                                        <br />
                                        저렴한 수수료로 더 많은 수익을 가져 가세요.
                                    </p>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <div className="frameAni05">
                                        <iframe src="banner/section05.html" className="sessonAni05"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        className="product-area pt-20px"
                        style={{ background: "#333", overflow: "hidden", position: "relative" }}
                    >
                        <div className="container">
                            <p className="productTit mt-5 mb-3" style={{ color: "#fff" }}>
                                flowing.space
                            </p>
                        </div>
                        <div className="leftShadow"/>
                        <div className="rightShadow"/>
                        <div className="row m-0">
                            <div className="col-lg-12">
                                <div className="carousel-wrap">
                                    <div className="productCarousel1">
                                        <div className="owl-carousel">
                                            <Slider {...slickSettings1}>
                                                {/* TODO: 더미데이터 주석처리 */}
                                                <div className="item">
                                                    <a href="https://flowing.space/songcamp" rel="noreferrer" target="_blank">
                                                        {/* 장고리듬다이 */}
                                                        <img src="/images/banner_sample01.png" alt="이미지" />
                                                    </a>
                                                </div>
                                                <div className="item">
                                                    {/* 피치쌤 */}
                                                    <a href="https://flowing.space/peach" rel="noreferrer" target="_blank">
                                                        <img src="/images/banner_sample02.png" alt="이미지" />
                                                    </a>
                                                </div>
                                                {/* <div className="item">
                                                    <a href="#">
                                                        <img src="/images/banner_sample03.png" alt="이미지" />
                                                    </a>
                                                </div> */}
                                                <div className="item">
                                                    {/* 심규열 */}
                                                    <a href="https://flowing.space/englishtrainer" rel="noreferrer" target="_blank">
                                                        <img src="/images/banner_sample04.png" alt="이미지" />
                                                    </a>
                                                </div>
                                                {/* <div className="item">
                                                    <a href="#">
                                                        <img src="/images/banner_sample05.png" alt="이미지" />
                                                    </a>
                                                </div> */}
                                                <div className="item">
                                                    {/* 이선경 */}
                                                    <a href="https://flowing.space/withinsight" rel="noreferrer" target="_blank">
                                                        <img src="/images/banner_sample06.png" alt="이미지" />
                                                    </a>
                                                </div>
                                                <div className="item">
                                                    {/* 메국자 */}
                                                    <a href="https://flowing.space/thebeautist" rel="noreferrer" target="_blank">
                                                        <img src="/images/banner_sample07.png" alt="이미지" />
                                                    </a>
                                                </div>
                                                {/* <div className="item">
                                                    <a href="#">
                                                        <img src="/images/banner_sample08.png" alt="이미지" />
                                                    </a>
                                                </div> */}
                                                <div className="item">
                                                    {/* 손원준 */}
                                                    <a href="https://flowing.space/pyson" rel="noreferrer" target="_blank">
                                                        <img src="/images/banner_sample09.png" alt="이미지" />
                                                    </a>
                                                </div>
                                                {/* <div className="item">
                                                    <a href="#">
                                                        <img src="/images/banner_sample10.png" alt="이미지" />
                                                    </a>
                                                </div> */}
                                                <div className="item">
                                                    <a href="#">
                                                        <img src="images/banner_sample.png" alt="이미지" />
                                                    </a>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="product-area pt-100px pb-50px bgSession1 ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="filters pb-4">
                                        <h2 className="listTitle mr-1">
                                            <b> 답변톡</b> 이용사례
                                        </h2>
                                        <p>많은 분들이 답변톡 상품으로 수익을 만들고 있어요.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* TODO: 반응형 확인 */}
                        <div className="container bgUser">
                            <div className="userMan01">
                                <div className="useTxt">
                                    골프 트레이너 유튜버
                                    <p>
                                        <Link to="/review01">
                                            임예진 <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="userMan02">
                                <div className="useTxt">
                                    패션 인플루언서
                                    <p>
                                        <Link to="/review02">
                                            이보람 <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="userMan03">
                                <div className="useTxt">
                                    비트코인 유튜버
                                    <p>
                                        <Link to="/review03">
                                            레알코인 <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="userMan04">
                                <div className="useTxt">
                                    헬스 트레이너 유튜버
                                    <p>
                                        <Link to="/review04">
                                            서힘찬 <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="userMan05">
                                <div className="useTxt">
                                    보컬 트레이너 유튜버
                                    <p>
                                        <Link to="/review05">
                                            이서진 <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="userMan06">
                                <div className="useTxt">
                                    필라테스 인플루언서
                                    <p>
                                        <Link to="/review06">
                                            진은별 <i className="icon-arrow-right"></i>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container bgUser2">
                            <div className="row">
                                <div className="col-sm-6 col-md-4">
                                    <div className="userbox">
                                        <div className="man01"></div>
                                        <div className="useTxt">
                                            골프 트레이너 유튜버
                                            <p>
                                                <Link to="/review01">
                                                    임예진 <i className="icon-arrow-right"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="userbox">
                                        <div className="man02"></div>
                                        <div className="useTxt">
                                            패션 인플루언서
                                            <p>
                                                <Link to="/review02">
                                                    이보람 <i className="icon-arrow-right"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="userbox">
                                        <div className="man03"></div>
                                        <div className="useTxt">
                                            비트코인 유튜버
                                            <p>
                                                <Link to="/review03">
                                                    레알코인 <i className="icon-arrow-right"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="userbox">
                                        <div className="man04"></div>
                                        <div className="useTxt">
                                            헬스 트레이너 유튜버
                                            <p>
                                                <Link to="/review04">
                                                    서힘찬 <i className="icon-arrow-right"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="userbox">
                                        <div className="man05"></div>
                                        <div className="useTxt">
                                            보컬 트레이너 유튜버
                                            <p>
                                                <Link to="/review05">
                                                    이서진 <i className="icon-arrow-right"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4">
                                    <div className="userbox">
                                        <div className="man06"></div>
                                        <div className="useTxt">
                                            필라테스 인플루언서
                                            <p>
                                                <Link to="/review06">
                                                    진은별 <i className="icon-arrow-right"></i>
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="product-area pt-60px bgGray">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="filters pb-4">
                                        <h5>
                                            답변톡 PC 버전 <span className="bgbluebar">무료</span>
                                        </h5>
                                        <h2 className="listTitle mr-1">
                                            <span>Creator, Influencer, Expert</span>
                                        </h2>
                                        <h2 className="listTitle">
                                            를 위한 회원관리 <b>솔루션</b>
                                        </h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-20px">
                                <Col lg={5}>
                                    <div className="productDown">
                                        {/* <div className="mobileFlex btn btn-outline-secondary d-block btnBic btndown mb-3"> */}
                                            {/* <button
                                                className="btn btn-outline-secondary d-block btnBic btndown mb-3"
                                                onClick={() => {
                                                    window.open(`${console_url}`)
                                                }}
                                            >
                                                로그인
                                            </button> */}
                                            <a href={`${console_url}`} target="_blank" className="mobileFlex btnArea btn btn-outline-secondary btnBic btndown mb-3" style={{textDecoration: "none", height:"100%"}}>스튜디오 로그인</a>
                                        {/* </div> */}
                                    </div>
                                    {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                                </Col>
                                <Col lg={7}>
                                    <div className="productImg">
                                        <img src={productImg} alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="product-area pt-60px bgSession1">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="filters pb-4">
                                            <h5>
                                                답변톡 PC 버전 주요기능 <span
                                                className="bgbluebar">무료</span>
                                            </h5>
                                            <h2 className="listTitle mr-1">
                                                <span>MAIN FUNCTION</span>
                                            </h2>
                                            <h6 className="solutionTxt mb-1">
                                                <span>PC 환경에서 확인하실수 있습니다.</span>
                                            </h6>
                                            <h6 className="solutionTxt mb-4">
                                                <span>반짝이는 버튼을 클릭해 주요기능을 살펴보세요.</span>
                                            </h6>
                                        </div>
                                        {/* <div className="d-flex justify-content-lg-end btn btn-outline-secondary d-block btnBic btndown mb-3"> */}
                                            {/* <button
                                                className="btn btn-outline-secondary d-block btnBic btndown mb-3"
                                                onClick={() => {
                                                    window.open(`${console_url}`)
                                                }}
                                                style={{
                                                    width: 240,
                                                }}
                                            >
                                                로그인
                                            </button> */}
                                            <a href={`${console_url}`} target="_blank" className="d-flex justify-content-lg-end btnArea btn btn-outline-secondary btnBic" style={{textDecoration: "none",height:"100%"}}>스튜디오 로그인</a>
                                        {/* </div> */}
                                        {/* TODO: 콘솔 오픈 시 주석처리 제거 */}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-20px">
                                <Col lg={12} className="text-center">
                                    <div className="productImg">
                                        <img src={productImg2} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="product-area pt-60px bgGray">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="filters pb-4">
                                        <h5>
                                            답변톡 플로잉 APP<span className="bgbluebar">무료</span>
                                        </h5>
                                        <h2 className="listTitle mr-1">
                                            <span>FLOWING</span>
                                        </h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-20px">
                                <Col lg={5}>
                                    <div className="productDown">
                                        <p className="solutionTit">답변톡 플로잉</p>
                                        <p className="solutionTxt">
                                            당신의 답변톡을 만들어 <br />
                                            여러 채널에 흩어진 회원을 하나로 관리 하세요.
                                        </p>
                                        <div className="btn-area3 mt-2">
                                            <a
                                                href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                className="link-app"
                                            >
                                                <span className="tit-ios">App Store</span>
                                            </a>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                className="link-app"
                                            >
                                                <span className="tit-android">GooglePlay</span>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5}>
                                    <div className="productImg">
                                        <img src={productImg3} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </>
            ) : (
                <section className="user-area">
                    <div id="fullpage">
                        <div className="section fp-auto-height">
                            <div className="productCarousel2">
                                <Slider {...slickSettings2}>
                                    <div className="slide" id="slide1" style={{ backgroundColor: "#fff" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat01"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide01_bg"></div>
                                    </div>
                                    <div className="slide" id="slide2" style={{ backgroundColor: "#f0e7df" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat02"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide02_bg"></div>
                                    </div>
                                    <div className="slide" id="slide3" style={{ backgroundColor: "#bae9ed" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat03"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide03_bg"></div>
                                    </div>
                                    <div className="slide" id="slide4" style={{ backgroundColor: "#ece2db" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat04"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide04_bg"></div>
                                    </div>
                                    <div className="slide" id="slide5" style={{ backgroundColor: "#d8dde1" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat05"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide05_bg"></div>
                                    </div>
                                    <div className="slide" id="slide6" style={{ backgroundColor: "#ebf3f6" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat06"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide06_bg"></div>
                                    </div>
                                    <div className="slide" id="slide7" style={{ backgroundColor: "#efe0be" }}>
                                        <div className="inner">
                                            <div className="text-content">
                                                <p className="chat07"></p>
                                                <div className="btn-area2">
                                                    <a
                                                        href="https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-ios">App Store</span>
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=tpkorea.com.flowing"
                                                        className="link-app"
                                                        target="_blank"
                                                    >
                                                        <span className="tit-android">Google play</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide07_bg"></div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>

                    <div className="pt-90px pb-90px">
                        <div className="container position-relative">
                            <div className="talk-mock-tab">
                                <div
                                    className={`mock-tab-01 ${switchTab === 1 ? "active" : ""}`}
                                    onClick={() => {
                                        setSwitchTab(1)
                                    }}
                                >
                                    공개톡
                                </div>
                                <div
                                    className={`mock-tab-02 ${switchTab === 2 ? "active" : ""}`}
                                    onClick={() => {
                                        setSwitchTab(2)
                                    }}
                                >
                                    답변톡
                                </div>
                                <div
                                    className={`mock-tab-03 ${switchTab === 3 ? "active" : ""}`}
                                    onClick={() => {
                                        setSwitchTab(3)
                                    }}
                                >
                                    답변 프로필
                                </div>
                            </div>
                            <div className="talk-mock01" style={{ display: `${switchTab === 1 ? "block" : "none"}` }}>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="phone-banner-tit">
                                            궁금하면 <br />
                                            <span>공개톡</span>으로 바로 질문하기
                                        </div>
                                        <div className="phone-banner-txt">
                                            여행, 지역, 어학연수, 학원 <br />
                                            어떤 질문도 공금하면 바로
                                            <br />
                                            공개톡 하세요. <br />
                                            관련 경험자에게 알림이 갑니다.
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <img src="images/banner-phone01.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="talk-mock02" style={{ display: `${switchTab === 2 ? "block" : "none"}` }}>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="phone-banner-tit">
                                            내 공식 <br />
                                            <span>답변톡</span>을 만들어 보세요.
                                        </div>
                                        <div className="phone-banner-txt">
                                            궁금하면 <br />
                                            공식 답변톡방을 찾아 직접 질문해 보세요.
                                            <br />
                                            바로 답변을 받을 수 있습니다.
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <img src="images/banner-phone02.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="talk-mock03" style={{ display: `${switchTab === 3 ? "block" : "none"}` }}>
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="phone-banner-tit">
                                            <span>답변프로필</span>을<br />
                                            등록하세요.
                                        </div>
                                        <div className="phone-banner-txt">
                                            내가 등록한 답변 키워드 #여행 #지역 #어학연수
                                            <br />
                                            관련 질문을 받을 수 있고 답변 보상은 물론
                                            <br />
                                            관심 회원을 모을 수 있어요.
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <img src="images/banner-phone03.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </React.Fragment>
    )
}

export default Product
