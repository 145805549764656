import {useEffect, useRef} from 'react';

/**
 * 특정 callback을 반복시키는 custom hook, Component가 재구성될 때 interval을 삭제하고, callback의 변경에 대응함
 * @param callback {function} 반복실행할 기능
 * @param delay {number} 반복주기 - 1/1000sec
 * */
export const useInterval = (callback, delay) => {
    // 최근에 들어온 callback을 저장할 ref를 하나 만든다.
    const savedCallback = useRef();

    // callback이 바뀔 때마다 ref를 업데이트 해준다.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            // tick이 실행되면 callback 함수를 실행시킨다.
            savedCallback.current();
        }
        if (delay !== null) { // 만약 delay가 null이 아니라면
            let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
            return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
        }
    }, [delay]); // delay가 바뀔 때마다 새로 실행된다.
}

/**
 * 특정 state value가 변경된 후, 시점에 해당 Value가 변화하기 전에 어떤 값이 었는지 참조할 수 있다.
 * @param state {any} - 변경 전 값을 추적할 state
 * */
export const usePrevious = (state) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = state;
    })

    return ref.current;
}


/**
 * Dom이 특정 뷰포트 안에 들어온 것을 추적하기 위한 intersection observer Hook
 * 참조: https://velog.io/@jsi06138/React-Intersection-Observer-API%EB%A5%BC-%EC%9D%B4%EC%9A%A9%ED%95%9C-%EB%AC%B4%ED%95%9C-%EC%8A%A4%ED%81%AC%EB%A1%A4-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0
 * @param callback {function}
 * @return [observe, unobserve]
 * */
export const useInView = (callback) => {
    const observer = useRef(
        new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        callback(true);
                    } else {
                        callback(false);
                    }
                });
            },
            { threshold: 1 }
        )
    );

    const observe = (element) => {
        observer.current.observe(element);
    };

    const unobserve = (element) => {
        observer.current.unobserve(element);
    };

    return [observe, unobserve];
}