import React from "react"
import { map } from "lodash"

// Defualt image
import defualt_img from "../../../../assets/images/chat/defualt-image.png"
import owner_icon from "../../../../assets/images/chat/owner_icon.png"
import winner_icon from "../../../../assets/images/chat/winner_icon.png"
import "../../../../assets/css/chat/UserList.scss"

export default function ActiveUserList(props) {
  const { roomInformation, user } = props

  return (
    <div className="room-list">
      <div className="room-item-header d-flex align-items-center">
        <div className="room-item-header-title font-weight-bold">
          {" "}
          대화 상대 목록
          <span className="room-item-header-count font-weight-bold">
            {roomInformation?.roomData?.activeUsers.length}
          </span>
        </div>
        <div className="room-item-close-button">
          <span
            className="la la-close"
            onClick={e => {
              props.onChangeMenu("chatContainer")
            }}
          ></span>
        </div>
      </div>
      {roomInformation?.roomData?.activeUsers &&
        map(roomInformation?.roomData?.activeUsers, (activeUser, index) => (
          <div key={index} className="room-body d-flex align-items-center">
            <div className="ProfileImg">
              <img
                src={activeUser.userImg || defualt_img}
                width={37}
                height={37}
                className="rounded-circle avatar-border"
                alt=""
              />
              {roomInformation.ownerData.userId === activeUser.userId && (
                <img
                  src={owner_icon}
                  width={20}
                  height={20}
                  className="rounded-circle bg-none owner-badge-image"
                  alt=""
                />
              )}
              {(activeUser.userId ===
                roomInformation.roomData?.winner?.userId ||
                activeUser.userId === props.winnerTargetUserId) && (
                <img
                  src={winner_icon}
                  width={25}
                  alt=""
                  className="rounded-circle bg-none owner-badge-image"
                />
              )}
            </div>
            <div className="title">{activeUser.userName}</div>
            {user.id === activeUser.userId && (
              <div className="owner-title">
                <small className="d-block ">My</small>
              </div>
            )}
            <div className="room-item">
              <div className="room-item-body">
                <div className="room-item-body-title"></div>
                <div className="room-item-body-time"></div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
