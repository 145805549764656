import React, { useEffect, useState } from "react"
import imgChatBag from "../../../../assets/images/imgChatBag.png"
import imgClock from "../../../../assets/images/chat/clock.png"
import imgPoint from "../../../../assets/images/chat/point.png"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Modal,
    Button,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toast } from "react-toastify"
import moment from "moment"

import {
    leaveChatRoomFunction,
    maskAllMessageFunction,
    userProfileChatRoomFunction,
} from "../helper/Functions/SupportFunctions"

export default function ChatHeader({isEmbeddedView, ...props}) {
    const { transferCount, roomData, user, roomId } = props

    const [isClicked, setIsClicked] = useState(true)
    const [deleteChatModal, setDeleteChatModal] = useState(false)
    const [exitChatModal, setExitChatRoomModal] = useState(false)
    
    // message mask
    const maskAllMessage = async () => {
        let data = {
            userId: user.id,
            maskingTime: moment().toISOString(),
        }
        const response = await maskAllMessageFunction(roomId, data)
        if (response.message === "Created" || response.message === "Updated") {
            // toast.success("Room all message deleted")
            toast.success("채팅방 내 모든 메시지를 삭제했습니다.")
        } else {
            // toast.error("Message not deleted")
            toast.error("메시지가 삭제되지 않았습니다.")
        }
        setDeleteChatModal(false)
        props.refreshRoom()
        props.setMessageParam({'tempForRefresh':new Date().toISOString()})
    }

    // leave chat room
    const leaveChatRoom = async () => {
        let data = {
            userId: user.id,
            userName: user.nickname,
        }
        const response = await leaveChatRoomFunction(roomId, data)
        if (response.message === "Deleted") {
            // toast.success("Leave chat room")
            toast.success("채팅방을 나갔습니다.")
            props.exitChat()
        } else {
            // toast.error("Leave cant chat room")
            toast.error("채팅방 나가기를 완료하지 못했습니다.")
        }
    }

    // Profile
    const iconHandler = async () => {
        let data = {
            userId: props.user.id,
            push: roomData?.roomData.myInfo.push === "on" ? "off" : "on",
        }
        const response = await userProfileChatRoomFunction(roomId, data)

        if (response.message === "Created") {
            props.headerChangeProfile()
        }
    }

    // Main useEffect
    useEffect(() => {
        setIsClicked(props.roomData?.roomData?.myInfo?.push === "on")
    }, [props.roomData])

    return (
        <div>
            <div className="chat-header d-flex align-items-center m-2">
                <div className="chat-header-buttons d-flex align-items-center">
                    {!isEmbeddedView && (
                        <button
                            className="btn close-button-chat"
                            onClick={() => props.exitChat()}
                            style={{
                                backgroundImage: `url(${process.env.PUBLIC_URL + "/chat-close.png"})`,
                                backgroundRepeat: "no-repeat",
                            }} />
                    )}
                    <div className="title ml-1">
                        <div className="profileImg mr-1">
                            <img
                                src={roomData?.ownerData?.userImg.length !== 0 ? roomData?.ownerData?.userImg : "https://d1vb419ubgm46b.cloudfront.net/userbg.jpg"}
                                alt=""
                            />
                        </div>
                        {roomData?.ownerData?.userName} 의 답변톡
                    </div>
                </div>
                <div className="position-absolute" style={{ right: "2%" }}>
                    {!isEmbeddedView && (
                        <UncontrolledDropdown>
                            <DropdownToggle className="bg-transparent b-none dropdown-toggle-button button-bell ">
                                <FontAwesomeIcon
                                    onClick={iconHandler}
                                    icon={isClicked ? faBell : faBellSlash}
                                    color={"grey"}
                                />
                            </DropdownToggle>
                        </UncontrolledDropdown>
                    )}

                    {!isEmbeddedView && (
                        <UncontrolledDropdown>
                            <DropdownToggle
                                className="bg-transparent b-none dropdown-toggle-button btn-menu"
                                style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/menu.png"})`,
                                    backgroundRepeat: "no-repeat",
                            }} />

                            <DropdownMenu dark>
                                <DropdownItem onClick={() => setExitChatRoomModal(true)}>
                                    {/* uruunuus garah */}
                                    채팅방 나가기{" "}
                                </DropdownItem>
                                <DropdownItem onClick={() => setDeleteChatModal(true)}>
                                    {/* Msg all delete */}
                                    대화 내용 삭제
                                </DropdownItem>
                                {roomData.isOwner && (
                                    <DropdownItem
                                        onClick={e => {
                                            props.onChangeMenu("blockeUsers")
                                        }}
                                    >
                                        {/* Block users */}
                                        내보내기 목록
                                    </DropdownItem>
                                )}
                                {roomData.isOwner && (
                                    <DropdownItem
                                        onClick={e => {
                                            props.onChangeMenu("settingsLimit")
                                        }}
                                    >
                                        {/* Transfer limit */}
                                        채팅 제한 설정
                                    </DropdownItem>
                                )}
                                <DropdownItem
                                    className=""
                                    onClick={e => {
                                        props.onChangeMenu("activeUserList")
                                    }}
                                >
                                    {/* Active users */}
                                    대화 상대 목록
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}
                </div>
            </div>
            {!roomData.isOwner && (
                <div className="ChatOption d-flex align-items-center">
                    <div>
                        <small className="d-block font-14 bold">
                            {/* <img src={imgChatBag} width={16} alt="" />{" "} */}
                            {/* <span className="mr-2">잔여 전송 글자수 :</span>
                        {transferCount.transferCount}
                         / {transferCount.transferMaxCount} */}
                            <span className="mr-2">전송 가능 글자수 :</span>
                            {(typeof transferCount.transferMaxCount === 'number') ? transferCount.transferMaxCount-transferCount.transferCount : ''}
                        </small>
                    </div>
                    <div className="ml-auto">
                        <small className="d-block">
                            <button
                                className="btn_use"
                                onClick={() =>
                                    props.onChangeMenu("purchaseProduct")
                                }
                            >이용권 구매</button>
                        </small>
                    </div>
                </div>
            )}
            <Modal returnFocusAfterClose={true} isOpen={exitChatModal} centered={true}>
                <ModalBody className="text-center">
                    톡방을 나가시면 톡 내용이 모두 <br /> 사라지고 톡목록도 사라집니다.
                    <br />
                    톡방을 나가시겠습니까?.
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setExitChatRoomModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => leaveChatRoom()}>
                        나가기
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal returnFocusAfterClose={true} isOpen={deleteChatModal} centered={true}>
                <ModalBody className="text-center">
                    대화 내용을 삭제하시겠습니까? <br /> 현재 톡방의 이전 모든 대화
                    <br />
                    내용이 삭제됩니다.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => setDeleteChatModal(false)}>
                        취소
                    </Button>
                    <Button color="primary" onClick={() => maskAllMessage()}>
                        삭제
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
