import React from "react"
import CheckType from "./ChekTypeComponent"
import Avatar from "./Avatar"

export default function ChatLeft(props) {
  return (
    <div className="chat-body-message-left">
      <div className="chat-body-message-content">
        <Avatar {...props} />
        <CheckType {...props} />
      </div>
    </div>
  )
}
