import { get, post } from "../helpers/api_helper"

export async function checkPermission(body, userId) {
    try {
        return await post(`talk2you/users/profile/${userId}/permission`, body, {}, true)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function enterRoom(body, roomId) {
    try {
        return await post(`talk2you/rooms/${roomId}/enter`, body, {}, true)

    } catch (err) {
        return err
        // console.log(err)
        // throw err
    }
}

export async function retriveRoomList(userId, roomId) {
    try {
        return await get(`talk2you/rooms/${roomId}/fetch/roomId/lastMessagedAt/activeUsers?userId=${userId}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function createRoom(body) {
    try {
        return await post(`talk2you/rooms`, body)

    } catch (err) {
        console.log(err)
        return err
    }
}

export async function retriveRoomListUsers(body, roomId) {
    try {
        return await get(`talk2you/rooms/${roomId}/info/users`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function retriveMyRoomList(userId, type) {
    try {
        return await get(`/talk2you/rooms/${userId}/question?type=${type}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function retriveUnreadMessageCount(userId, body) {
    try {
        return await post(`common/users/${userId}/unread`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function retriveQuestionList(userId) {
    try {
        return await get(
            `talk2you/rooms/feed?userId=${userId}=&lat=&lon=&offset=&limit=`
        )

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getUserProfileService(userId) {
    try {
        return await get(`/talk2you/users/web/profile/${userId}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}
