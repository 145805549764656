import { del, get, post, put } from "../helpers/api_helper"
// import { post as postApi } from "../helpers/api_helper_2"
// import { post as postApiImage } from "../helpers/api_helper_image"
// import { get as getApiProxy } from "../helpers/api_helper_3"
import config from "../config/configuration"

export async function sendMessagesService(body, roomId) {
    try {
        return await post(`talk2you/messages/send`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getMessagesService(roomId, userId, since, until, limit) {
    try {
        return await get(`talk2you/messages/${roomId}?userId=${userId}&since=${since}&until=${until}&limit=${limit}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getBlockedUsersService(roomId, userId) {
    try {
        return await get(`talk2you/rooms/${roomId}/users/blocked`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function blockUserFromChatRoomService(roomId, body) {
    try {
        return await del(`/talk2you/rooms/${roomId}/send-off`, body)

    } catch (err) {
        console.error(err)
        return err
    }
}

export async function unblockUserFromChatRoomService(roomId, body) {
    try {
        return await put(`/talk2you/rooms/${roomId}/unblock`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function sendReportUserService(body) {
    try {
        return await post(`/common/users/report/abuse`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function leaveChatRoomService(roomId, body) {
    try {
        return await del(`/talk2you/rooms/${roomId}/leave`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function maskMessageChatRoomService(roomId, body) {
    try {
        return await put(`/talk2you/rooms/${roomId}/info/users/mask-message`,body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function userProfileChatRoomService(roomId, body) {
    try {
        return await put(`/talk2you/rooms/${roomId}/info/users/push`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function checkBlockUserService(body) {
    try {
        return await post(`/common/users/block/user/check`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function previewMessageService(body) {
    try {
        return await post(`/talk2you/messages/preview`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function uploadVAFService(formData, bucketName, uploadType) {
    try {
        // return await postApi(`/common/files/upload/${bucketName}/${uploadType}`, formData)
        return await post(`/common/files/upload/${bucketName}/${uploadType}?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api})

    } catch (err) {
        console.error(err)
        return err
    }
}

export async function getThumbnailUrl(formData, bucketName, uploadType) {
    try {
        // return await postApiImage(`common/files/generate/thumbnail`,formData)
        return await post(`common/files/generate/thumbnail?env=${process.env.REACT_APP_SET_ENV}`,formData, {baseURL: config.api.proxy_api})

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function uploadImageService(formData) {
    try {
        // return await postApi(`common/files/upload/flowing-web-resources/image`, formData)
        return await post(`common/files/upload/flowing-web-resources/image?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api})

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function ownerMessageReadService(roomId, body) {
    try {
        return await put(`/talk2you/messages/${roomId}/read`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function isSeenService(roomId, messageId) {
    try {
        const encodedMessageId = encodeURIComponent(messageId)
        return await get(`/talk2you/messages/${roomId}/${encodedMessageId}/read`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function checkVideoUploadingService(jobId) {
    try {
        // return await getApiProxy(`/common/files/mediaconvert/fetch/state?jobId=${jobId}`)
        return await get(`/common/files/mediaconvert/fetch/state?jobId=${jobId}`, {baseURL: config.api.proxy_api})

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function deleteOneMessageService(body) {
    try {
        return await del(`/talk2you/messages`, body)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getRoomData(roomId, userId) {
    try {
        return await get(`talk2you/rooms/${roomId}/fetch?userId=${userId}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function getRoomTransferService(roomId, userId) {
    try {
        return await get(`/talk2you/rooms/${roomId}/transfer?userId=${userId}`)

    } catch (err) {
        console.error(err)
        throw err
    }
}

export async function setRoomTransferService(roomId, body) {
    try {
        return await put(`/talk2you/rooms/${roomId}/transfer-limit`, body)

    } catch (err) {
        console.error(err)
        return err
    }
}
