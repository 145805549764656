import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faTrash, faStar } from "@fortawesome/free-solid-svg-icons"
import { deleteOneMessageFunction } from "../../helper/Functions/SupportFunctions"
import badSmall from "../../../../../assets/images/badSmall.png"
import goodSmall from "../../../../../assets/images/goodSmall.png"
import sosoSmall from "../../../../../assets/images/sosoSmall.png"

export default function Text(props) {
    const { message, selectedMsgId, myMsg, user, roomId, roomData, completedAction } = props

    const [isMesageDeleteModal, setIsMesageDeleteModal] = useState(false)

    const deleteTextAction = async () => {
        setIsMesageDeleteModal(false)
        let body = {
            messageId: message.messageId,
            userId: user.id,
            roomId: roomId,
        }

        const response = await deleteOneMessageFunction(body)
        if (response.message !== "Deleted") {
            toast.error("Cannot this message deleted")
            return
        }
    }

    useEffect(() => {
        if(!completedAction) return false
        completedAction()
    }, [message])

    const rateCheck = index => {
        if (index === 1) return goodSmall
        if (index === 2) return sosoSmall
        if (index === 3) return badSmall
    }

    return (
        <div className="message-content">
            {message.isActivated > 0 ? (
                <div className="d-flex align-items-center">
                    {myMsg && message.rate && <img src={rateCheck(message.rate)} alt="" />}
                    <p
                        className="chat-lefted-text d-inline-block"
                        onClick={() => {
                            props.selectedMessageAction({
                                action: "MESSAGE_CLICK",
                                messageId: message.messageId,
                            })
                        }}
                    >
                        {message.message}
                    </p>
                    {!myMsg && message.rate && <img src={rateCheck(message.rate)} alt="" />}
                </div>
            ) : (
                <p className="chat-lefted-text d-inline-block">삭제된 메시지입니다</p>
            )}
            {selectedMsgId === message.messageId && (
                <div className={myMsg ? "tool_tip tool_tip_right" : "tool_tip"}>
                    <button
                        onClick={() => {
                            props.selectedMessageAction({
                                action: "COPY",
                                copyText: message.message,
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faCopy} color={"white"} />
                        <p>복사</p>
                    </button>
                    {!myMsg && roomData.isOwner && (
                        <button
                            onClick={() => {
                                props.selectedMessageAction({
                                    action: "RATE_ACTION",
                                    messageId: message.messageId,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faStar} color={"white"} />
                            <p>평가하기</p>
                        </button>
                    )}
                    {myMsg && (
                        <button
                            onClick={() => {
                                setIsMesageDeleteModal(true)
                                props.selectedMessageAction({
                                    action: "DELETE",
                                    messageId: message.messageId,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} color={"white"} />
                            <p>삭제</p>
                        </button>
                    )}
                </div>
            )}

            <Modal returnFocusAfterClose={true} isOpen={isMesageDeleteModal} centered={true}>
                <ModalBody className="text-center">이 내용을 삭제 하겠습니까 ?</ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setIsMesageDeleteModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => deleteTextAction()}>
                        삭제
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
