import React, { useEffect, useState } from "react"
import propTypes from 'prop-types';
import { toast } from "react-toastify"
import { Label } from "reactstrap"
import { sendReportUserFunction } from "../helper/Functions/SupportFunctions"
import { Link } from "react-router-dom"
import { fetchEstimatedAssets } from "../../../../services/rewardPoints.service"
import imgDiamond from "../../../../assets/images/imgbosuk01.png"
import imgRuby from "../../../../assets/images/imgbosuk02.png"
import imgPoint from "../../../../assets/images/chat/point.png"
import { getPurchaseProductInfo, purchaseProduct } from "../../../../services/payment.service"
import { authService } from "services/auth.service"

export default function PurchaseProduct({doChargeAction, ...props}) {
    const { user, targetUserId } = props

    const [loading, setLoading] = useState(false)
    const [myRuby, setMyRuby] = useState(0)
    const [myDia, setMyDia] = useState(0)
    const [userId, setUserId] = useState("")

    useEffect(() => {
        const getUserIdRes = async () => {
            const res = await authService.getUserId()
            console.log("res::::::::",res)
            if(res.status===201) {
                setUserId(res.data.user_id)
                if (!res.data.user_id) {
                    props.changeAuthLayout("auth")
                    console.log('Need to login')
                } else if (res.data.user_id) {
                    fetchMyEstimatedAssets(res.data.user_id).then(r => r)
                }

            } else {
                return;
            }
        }
        getUserIdRes().then(r=>r)

        // console.log(userId)
        // if (!userId) {
        //     props.changeAuthLayout("auth")
        //     console.log('Need to login')
        // } else if (userId) {
        //     fetchMyEstimatedAssets(userId).then(r => r)
        // }
    }, [])

    const fetchMyEstimatedAssets = async (userId) => {
        const rs = await fetchEstimatedAssets(userId)
        if(rs && rs?.itemList[0]) {
            console.log('rs', rs);
            setMyRuby(rs?.itemList[0].ruby)
            setMyDia(rs?.itemList[0].dia)
        }
    }
    const goPurchageProduct = async (depositType) => {
        if (window.confirm('답변톡 이용권을 구매하시겠습니까?')) {
            // return;
            const roomId = props?.roomId
                // console.log('roomId', roomId)
            const userId = props?.user?.id
                // console.log('userId', userId)
            const rs = await getPurchaseProductInfo(roomId, userId) // TODO : API 변경으로 userId 값이 필수값이 아닌 것으로 변경됨. 이후 처리 바람
                // console.log('rs', rs)
            let productId, productPurchaseNeedsCount;
            if(rs && rs.status === 200) {
                if(rs.data && rs.data?.itemList[0]) {
                    productId = rs.data?.itemList[0]?.productId;
                    productPurchaseNeedsCount = rs.data?.itemList[0]?.productPurchaseNeedsCount;
                    if(productId || productPurchaseNeedsCount) {
                        if(depositType === 'dia') {
                            if(productPurchaseNeedsCount > myDia) {
                                window.alert('구매 가능한 다이아 보유개수가 모자랍니다. 충전해 주세요.')
                                window.location.replace('/buyDiamonds')
                                return;
                            }
                        }
                        if(depositType === 'ruby') {
                            if(productPurchaseNeedsCount > myRuby) {
                                window.alert('구매 가능한 루비 보유개수가 모자랍니다. 충전해 주세요.')
                                window.location.replace('/buyDiamonds')
                                return;
                            }
                        }
                        const rs2 = await purchaseProduct(roomId, userId, productId, depositType);
                            // console.log('rs2', rs2);
                        if(rs2 && rs2.status === 201) {
                            window.alert('이용권을 구매를 완료했습니다.\n이제 마음껏 플로잉 답변톡을 이용해 주세요.');
                            props.onChangeMenu("chatContainer")
                            return;

                        } else if(rs2 && rs2.status === 204) {
                            window.alert('구매 가능한 상품정보가 없습니다. 관리자에게 문의해 주세요.');
                            props.onChangeMenu("chatContainer")
                            return;

                        } else if(rs2 && rs2.status === 403) {
                            window.alert('구매 가능한 보석 보유개수가 모자랍니다. 충전 후 다시 이용해 주세요.');
                            window.location.replace('/buyDiamonds')
                            return;
                        }
                    }
                    window.alert('구매 가능한 상품정보가 없습니다. 잠시 후 다시 시도해 주세요.')
                    return false;
                }

            } else if(rs && rs.status === 403) {
                console.log('구매 권한 없음'); return false;
            }
        }
        return false;
    }

    // useEffect(() => {
    //     console.log(userId)
    //     if (!userId) {
    //         props.changeAuthLayout("auth")
    //         console.log('Need to login')
    //     } else if (userId) {
    //         fetchMyEstimatedAssets(userId).then(r => r)
    //     }
    // }, [])


    return (
        // <div className="room-list">
        //     <div className="room-item-header d-flex align-items-center">
        //         <div className="room-item-header-title font-weight-bold">답변톡 이용권 구매</div>
        //         <div className="room-item-close-button">
        //             <button
        //                 className="la la-close"
        //                 onClick={e => {
        //                     props.onChangeMenu("chatContainer")
        //                 }}
        //             ></button>
        //         </div>
        //     </div>
        //     <div className=" ticket-user">
        //         <div className="">
        //         {props?.roomData?.ownerData?.userImg &&
        //             <img src={props?.roomData?.ownerData?.userImg} alt="profile image" className="profileImg" />
        //         } {props?.roomData?.ownerData?.userName}의 답변톡 이용권
        //         </div>
        //         <div className="ticket-buy-box">

        //             <div className="box">
        //                 <div>답변톡 이용권 <br/> 다이아 {props?.roomData?.roomData?.transfer?.productPurchaseNeedsCount}</div>
        //             <button
        //                 onClick={e => goPurchageProduct('dia') }
        //             >구매하기</button>
        //             </div>
        //             <div className="box">
        //                 <div>답변톡 이용권 <br/>  루비 {props?.roomData?.roomData?.transfer?.productPurchaseNeedsCount}</div>
        //             <button
        //                 onClick={e => goPurchageProduct('ruby') }
        //             >구매하기</button>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="ticket-info">
        //         <h3>답변톡 이용권 구성</h3>
        //         <h4>무료 전송 글자 수는 최대 {props?.roomData?.roomData?.transfer?.freeTransferCount}자까지 설정 가능</h4>
        //         <ul>
        //             <li>한/영 모두 1자</li>
        //             <li>띄어쓰기/줄바꿈 1자</li>
        //             <li>이미지, 영상, 음성 및 파일은 50자</li>
        //         </ul>
        //     </div>
        //     <div className="ticket-box">
        //         <div className="">
        //             <img src={imgDiamond} width={20} alt="" /> {myDia}
        //         </div>
        //         <div className="ml-5">
        //             <img src={imgRuby} width={20} alt="" /> {myRuby}
        //         </div>
        //         <div className="ml-auto">
        //             <Link to="/buyDiamonds">충전</Link>
        //         </div>
        //     </div>
        // </div>
        <div className="room-list2">
            <div className="room-item-header d-flex align-items-center">
                <div className="room-item-header-title font-weight-bold" style={{color:"#000"}}>답변톡 이용권 구매</div>
                <div className="room-item-close-button">
                    {/* <button className="la la-close" onClick={()=>{props.onChangeMenu('chatContainer')}}></button> */}
                    <button style={{borderStyle:"none", backgroundColor:"inherit"}} onClick={()=>{props.onChangeMenu('chatContainer')}}><i className="fe fe-x" data-toggle="tooltip" title="" data-original-title="fe fe-x"></i></button>
                </div>
            </div>
            <div className="ticket-user">
                <div style={{color:"#000"}}>{props?.roomData?.ownerData?.userImg&&(<img src={props?.roomData?.ownerData?.userImg} alt="" className="profileImg" />)} {props?.roomData?.ownerData?.userName}의 답변톡 이용권</div>
                <div className="ticket-buy-box">
                    <div className="box">
                        <div className="inner-box">
                            <img src="/images/img-use-coupon01.jpg" />
                            <div>다이아 {props?.roomData?.roomData?.transfer?.productPurchaseNeedsCount}</div>
                            
                        </div>
                        <button onClick={() => goPurchageProduct('dia') }>구매하기</button>
                    </div>
                    <div className="box">
                        <div className="inner-box">
                            <img src="/images/img-use-coupon02.jpg" />
                            <div>루비 {props?.roomData?.roomData?.transfer?.productPurchaseNeedsCount}</div>
                            
                        </div>
                        <button onClick={() => goPurchageProduct('ruby') }>구매하기</button>
                    </div>
                    
                </div>
            </div>
            {/* <div className="ticket-info">
                <h3><i className="fe fe-user-plus" ></i> 답변톡 상세 설명</h3>
                <h4>여행하는 작가 별입니다. <br/> 여행 관련 영상 촬영 편집하는 노하우 알려드려요.</h4>
                <ul>
                    <li>★ 여행 영상 촬영</li>
                    <li>★ 영상 편집법</li>
                    <li>★ 사진 잘찍는법</li>
                </ul>
            </div> */}
            <div className="ticket-info">
                <h3><i className="fe fe-message-circle" /> 답변톡 이용권 구성</h3>
                {/*<h4>입력가능한 글자수 {props?.roomData?.roomData?.transfer?.freeTransferCount}자 <br/> (이미지, 영상, 음성 파일은 50글자로 인정)</h4>*/}
                {/*<p>무료 전송 글자 수는 최대 500자까지 설정 가능</p>*/}
                <p>구매 시, 전송 가능 글자수가 500자 증가합니다.</p>
                <p>메시지 종류에 따라 소진되는 글자 수는 다음과 같습니다.</p>
                <ul style={{
                    listStyle: 'inside',
                    listStyleType: 'disc',
                }}>
                    <li className="fs-12" style={{listStyle: 'unset', lineHeight:1.2}}>한/영 모두 1자</li>
                    <li className="fs-12" style={{listStyle: 'unset', lineHeight:1.2}}>띄어쓰기/줄바꿈 1자</li>
                    <li className="fs-12" style={{listStyle: 'unset', lineHeight:1.2}}>이미지, 영상, 음성 및 파일은 50자</li>
                </ul>
            </div>
            <div className="ticket-box">
                <h3>내 보유 보석</h3>
                <div>
                    <img src={imgDiamond} width={20} alt="다이아" /> {myDia}
                </div>
                <div className="ml-5">
                    <img src={imgRuby} width={20} alt="루비" /> {myRuby}
                </div>
                <div className="ml-auto">
                    {!doChargeAction ? (
                        <Link to="/buyDiamonds" className="btn-charge">충전</Link>
                    ): (
                        <Link to="#" onClick={() => {doChargeAction(props)}} className="btn-charge">충전</Link>
                    )}
                </div>
            </div>
        </div>
    )
}

PurchaseProduct.prototype = {
    doChargeAction: propTypes.func,
}
