import React from "react"

export default function ChatRoomTableHeader(props) {
    return (
        <tbody>
        <tr>
            <th className="hidden-xs">No.</th>
            <th>톡방 이미지</th>
            <th className="hidden-xs">톡방 종류</th>
            <th className="hidden-xs">방장 닉네임</th>
            <th>톡방 제목</th>
            <th className="hidden-xs">최근 톡 시간</th>
            <th className="hidden-xs">참여자수</th>
            <th className="hidden-xs">개설 시간</th>
            <th className="hidden-xs">톡방 보기</th>
        </tr>
        </tbody>
    )
}
