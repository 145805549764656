import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Alert
} from "reactstrap"
import "../../assets/css/myStyleWinner.css"
import {
    get ,
    getChargingListOwner
} from "../../services/payment.service"

import {methodObj} from "../../constants/paymentMethod"

import {
    getUsageList as getUsageData,
    getChargingList as getChargingData, getTransactionData
} from "../../services/chargingHistory.service"
import moment from "moment"
import { NavLink, Link } from "react-router-dom"
import { useLocation } from "react-router"

import Invoice from '../../components/UserList/Invoice';
import { authService } from "services/auth.service"

const UseList = (...args) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggle = () => setDropdownOpen(prevState => !prevState)
    const [userInformation, setUserInformation] = useState([])
    const [itemArrayMy, setItemArrayMy] = useState([])
    const [itemArrayOwner, setItemArrayOwner] = useState([])
    const [selectedValue, setSelectedValue] = useState("충전")
    const [usageList, setUsageList] = useState([])
    const [chargingList, setChargingList] = useState([])
    const [usageOffset, setUsageOffset] = useState(0)
    const [chargingOffset, setChargingOffset] = useState(0)
    const [relatedTransactionData, setRelatedTransactionData] = useState(null)
    const [isAlert, setIsAlert] = useState(false);
    const location = useLocation();
    // const [userIds, setUserIds] = useState("")
    // const userId = sessionStorage.getItem("user_id")
    // useEffect(() => {

    //     const getUserIdRes = async () => {
    //         const res = await authService.getUserId()
    //         if(res.status===201) {
    //             setUserIds(()=>{return res.data.user_id})
    //         } else {
    //             return;
    //         }
    //     }
    //     getUserIdRes().then(r=>r)
    //     console.log(userIds)
    // } ,[])

    const fetchTransactionData = async () => {
        console.log(11)
        console.log(location.search)
        if(location.search) {
            const params = new URLSearchParams(location.search);
            console.log(params)
            const transactionId = params.get("transactionId");
            console.log(transactionId)
            if (transactionId) {
                const result = await getTransactionData(transactionId);
                console.log('rs', result);
                if(result) {
                    setRelatedTransactionData(result)
                    setIsAlert(true)
                }
            }
        }
    }

    // const userId = '04e9860a-14a5-4cab-bfaa-f94b82c19673' // TODO : 테스트용 임시 아이디 적용
    // const userId = localStorage.getItem("user_id")

    const getUsageList = async () => {
        console.log("getuseagelist")
        const response = await authService.getUserId()
        if(response.status===201){
            // const result = await getUsageData(userId, 0, 10)
            const result = await getUsageData(response.data.user_id, 0, 10)
            console.log("result", result)
            setUsageList(result || [])
        }
    }
    const getChargingList = async () => {
        const response = await authService.getUserId()
        if(response.status===201){
            // const result = await getChargingData(userId, 0, 10)
            const result = await getChargingData(response.data.user_id, 0, 10)
            console.log("result", result)
            setChargingList(result || [])
        }
    }

    useEffect(() => {
        /*async function getUserInfo() {
                let userInfo = localStorage.getItem("user_data")
                if (!userInfo) {
                    // return toast.error("User information not found")
                    return toast.error("사용자 정보를 찾을 수 없습니다.")
                } else {
                    userInfo = JSON.parse(userInfo)
                    setUserInformation(userInfo)
                }
            }

            let response = getChargingList(localStorage.getItem("user_id"))
            let responseOwner = getChargingListOwner(localStorage.getItem("user_id"))
            if (response) {
                setItemArrayMy(response)
            }
            if (responseOwner) {
                setItemArrayOwner(responseOwner)
            }*/
        fetchTransactionData().then(r => r)
        getUsageList().then(r => r)
        getChargingList().then(r => r)
    }, [])

    document.title = "답변톡 플로잉"
    return (
        <React.Fragment>
            <div className="page-content">
                <section
                    className="job-area pb-60px"
                    style={{ minHeight: "679px" }}
                >
                    <Container>
                        <Row>
                        {isAlert && relatedTransactionData && (
                            <Col lg={12} className="pb-100">
                                <Invoice data={{
                                    goodsName: relatedTransactionData.goods_name,
                                    orderedAt: relatedTransactionData.ordered_at,
                                    amount: relatedTransactionData.amount,
                                    method: relatedTransactionData.transaction_method,
                                    result: relatedTransactionData.transaction_result
                                }} />
                            </Col>
                        )}
                            <Col lg={12}>
                                <div className="filters  d-flex align-items-center justify-content-between">
                                    <h2 className="listTitle mr-1">
                                        <b>충전/구매</b> 내역
                                    </h2>
                                    <div className="form-group col-3 mb-0 pr-0">
                                        <div className="d-flex useList-dropdown">
                                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                <DropdownToggle caret>{selectedValue}</DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        className="text-left"
                                                        onClick={() => setSelectedValue("충전")}
                                                    >
                                                        충전
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        className="text-left"
                                                        onClick={() => setSelectedValue("구매")}
                                                    >
                                                        구매
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={12}>
                                <div className="jobs-main-bar mb-50px">
                                    <div className="jobs-snippet">
                                        {selectedValue === "충전" ? (
                                            <table
                                                className="table table-hover table-vcenter text-nowrap table_custom  table-custom-styled fs-14">
                                                <thead>
                                                <tr>
                                                    <th className="hidden-xs">No.</th>
                                                    <th className="hidden-xs">충전 시간</th>
                                                    <th className="hidden-xs">충전 재화</th>
                                                    <th>충전 수량</th>
                                                    <th>충전 금액</th>
                                                    <th>취소 여부</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {chargingList.length > 0 ? (
                                                    chargingList.map((item, index) => {
                                                        return (
                                                            <tr
                                                                className="align-items-center"
                                                                key={item.created_at}
                                                                id={item.id}
                                                            >
                                                                <td className="text-center hidden-xs">
                                                                    {chargingOffset + 1 + index}
                                                                </td>
                                                                <td className="hidden-xs">
                                                                    <div className="text-muted">
                                                                        {moment(item.created_at).format(
                                                                            "YYYY-MM-DD HH:mm:ss"
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-muted">
                                                                        {item.deposit_type === "ruby"
                                                                            ? "루비"
                                                                            : "다이아"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-muted">
                                                                        {item.deposit_amount}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-muted">
                                                                        {item.deposit_price}
                                                                    </div>
                                                                </td>
                                                                <td className="hidden-xs">
                                                                    <div className="text-muted">
                                                                        {item.is_canceled === "Y" ? "취소" : ""}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            <p className="noResult">
                                                                충전/구매한 내역이 없습니다.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <table
                                                className="table table-hover table-vcenter text-nowrap table-custom-styled table_custom fs-14">
                                                <thead>
                                                <tr>
                                                    <th className="hidden-xs">No.</th>
                                                    <th className="hidden-xs">사용 시간</th>
                                                    <th className="hidden-xs">사용 내역</th>
                                                    <th>사용 재화</th>
                                                    <th>사용 수량</th>
                                                    <th>취소 여부</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {usageList.length > 0 ? (
                                                    usageList.map((item, index) => {
                                                        return (
                                                            <tr
                                                                className="align-items-center"
                                                                key={item.created_at}
                                                                id={item.id}
                                                            >
                                                                <td className="text-center hidden-xs">
                                                                    {usageOffset + 1 + index}
                                                                </td>
                                                                <td className="hidden-xs">
                                                                    <div className="text-muted">
                                                                        {moment(item.created_at).format(
                                                                            "YYYY-MM-DD HH:mm:ss"
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="hidden-xs">
                                                                    <div className="text-muted">
                                                                        {item.description}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-muted">
                                                                        {item.deposit_type === "ruby"
                                                                            ? "루비"
                                                                            : "다이아"}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-muted">
                                                                        {item.deposit_amount}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="text-muted">
                                                                        {item.is_canceled === "Y" ? "취소" : ""}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            <p className="noResult">
                                                                충전/구매한 내역이 없습니다.
                                                            </p>
                                                        </td>
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>

                                    {/*// TODO : 현재 API 구조에서는 Pagination 구현 불가능. 앱과의 호환성 위해 'More' 형태로 적용 필요함*/}
                                    {/*{itemArrayMy.length > 0 ? (
                                        <div className="pager pt-10px pb-20px">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center generic-pagination pr-1">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="First">
                                                            <span aria-hidden="true">
                                                              <i className="las la-angle-double-left"></i>
                                                            </span>
                                                            <span className="sr-only">First</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            aria-label="Previous"
                                                        >
                                                            <span aria-hidden="true">
                                                              <i className="las la-angle-left"></i>
                                                            </span>
                                                            <span className="sr-only">Previous</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item active">
                                                        <a className="page-link" href="#">
                                                            1
                                                        </a>
                                                    </li>
                                                    <li className="page-item ">
                                                        <a className="page-link" href="#">
                                                            2
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            3
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">
                                                            4
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">
                                                              <i className="las la-angle-right"></i>
                                                            </span>
                                                            <span className="sr-only">Next</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="End">
                                                            <span aria-hidden="true">
                                                              <i className="las la-angle-double-right"></i>
                                                            </span>
                                                            <span className="sr-only">End</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    ) : ""}*/}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </React.Fragment>
    )
}

export default UseList
