import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
    CardBody,
    Card,
    Alert,
    Input,
    Form,
    InputGroup,
    FormGroup,
    CardFooter
} from "reactstrap"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './NewLogin.css'
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action

//redux

import { withRouter, Link } from "react-router-dom"

//import auth services
import { authService } from "../../../services/auth.service"

//css
// import "..//..//assets/css/theme1.css";
const AccountLogin = props => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [authCode, setAuthCode] = useState("")
    const [isAuthTrue, setIsAuthTrue] = useState(false)
    const [ref_url, setRefUrl] = useState("")
    const [authCodeTime, setAuthCodeTime] = useState("")
    const timerRef = useRef(60)
    const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)


    const [email, setEmail] = useState("")
    const [emailAuthCode, setEmailAuthCode] = useState('')
    const [isValuableAddEmail, setIsValuableAddEmail] = useState(false)
    const [isEmailAuthTrue, setIsEmailAuthTrue] = useState(false)
    const [emailAuthCodeTime, setEmailAuthCodeTime] = useState(0)
    const [emailAuthCodeTimeIn, setEmailAuthCodeTimeIn] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')


    const secToMMSS = (sec) => {
        if (sec > 0) return `0${~~(sec / 60)}:${sec % 60<10 ? '0':''}${sec % 60}`
        else return '00:00'
    }
    useInterval(() => {
        setEmailAuthCodeTime(emailAuthCodeTime - 1)
    }, emailAuthCodeTime > 0 ? 1000 : null)

    const [userId, setUserId] = useState('')

    // 번호 인증 타이머
    let sec = 60
    let min = 1
    let time = 60000
    const timer = () => {
        time = time - 1000 //1초씩 줄어듦
        min = time / (60 * 1000) //초를 분으로 나눠준다.

        if (sec > 0) {
            //sec=60 에서 1씩 빼서 출력해준다.
            sec = sec - 1
            setAuthCodeTime(sec > 9 ? Math.floor(min) + ":" + sec : Math.floor(min) + ":0" + sec) //실수로 계산되기 때문에 소숫점 아래를 버리고 출력해준다.
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
                setAuthCodeTimeIn(false)
                setAuthCodeTime("0:00")
            }
        }
    }

    const requestCodeFunction = async () => {
        const result = await authService.requestCode(phoneNumber)
        if (result === "success") {
            setAuthCodeTimeIn(true)
            timerRef.current = setInterval(timer, 1000)
        } else {
            setAuthCodeTimeIn(false)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
        }
    }

    const checkRequestedCodeFunction = async () => {
        props.setIsAlert(() => false)
        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            props.setAlertMessage(() => '인증시간이 지났습니다. 다시 인증을 받아주세요.')
            props.setIsAlert(() => true)
            return
        }

        const result = await authService.checkRequestedCode(phoneNumber, authCode, ref_url)
        if (result.status === 201) {
            setIsAuthTrue(true)
            if (timerRef.current) {
                clearInterval(timerRef.current)
                timerRef.current = null
            }
            setAuthCodeTime("인증완료")
        }
    }
    const Login = async () => {
        props.setIsAlert(() => false)
        // if(isSimpleSignUp) {
        //     if(!isAgreed) {
        //         document.getElementById("agree_to_terms").focus();
        //         toast.error("약관에 동의해 주세요.")
        //         return;
        //     }
        //     const checkedName = await authService.checkNameDuplicate(name)
        //     try {
        //         if (authCode || isAuthTrue) {
        //             let body = { phone_number: phoneNumber, nickname: name }
        //             await authService.signUpSimple(body);
        //             props.changeAuthLayout("question")
        //         }
        //     } catch (err) {
        //         console.log(err, "err")
        //     }

        // } else {
            try{
                // const response = await authService.easyLogin(name, phoneNumber)
                // console.log(response)
                // if (response.user_id) {
                // }
                const {isAccountUser, userId:newId} = await authService.addEmailValueableCheck({nickname: name, phone_number: phoneNumber}).then(r => {
                    if(r.status !== 200) throw r
                    return r.data
                })
                console.log(isAccountUser)
                setUserId(() => newId)
                if(!isAccountUser) setIsValuableAddEmail(() => true)
                else {
                    setIsValuableAddEmail(()=>false)
                    // toast.success('로그인 되었습니다.')
                    toast.info('플로잉 서비스가 이메일(계정)이 통합됩니다. 기존 등록된 이메일로 로그인해 주세요')
                    props.setAlertMessage(() => '플로잉 서비스가 이메일(계정)이 통합됩니다. 기존 등록된 이메일로 로그인해 주세요')
                    props.setIsAlert(() => true)
                    // props.changeAuthLayout("question")
                    props.toggleVertical('2')
                }
                console.log(props)
                // props.changeAuthLayout("answer")
                console.log(props.referer)
                // props.changeAuthLayout(props.referer || 'question')

            } catch(error){
                if (error.status === 400) {
                    alert('일치하는 정보가 없습니다. 회원가입을 진행해 주세요.')
                    props.history.push("/register");
                }
                throw error
            }
            // console.log('resp', response.status);
            // TODO : 로그인에 성공하면 아래 코드까지 도달하지 않고 리다이렉트 됨.
            // if (response.status === 200) {
            //     props.changeAuthLayout("question")
            // }
        // }
    }


    const requestEmailCodeFunction = async () => {
        props.setIsAlert(() => false)
        /* 
            해당 코드의 의도 : 버튼 중복클릭에 대한 방어
            TODO: 중복클릭에 대한 방어적인 로직과 디자인 일관성을 맞출 수 있도록 한다.
         */
        if(!email.trim()) {
            props.setAlertMessage(() => '이메일을 입력해 주세요')
            props.setIsAlert(() => true)
            toast.error('이메일을 입력해 주세요')
            return;
        }
        alert('인증번호 전송을 요청합니다.')
        try {
            await authService.activateEmailRequestCode(userId, {email}).then(r => {
                if(r.status !== 201) throw r
                return r
            })
            setEmailAuthCodeTimeIn(()=>true)
            setEmailAuthCodeTime(()=>180)
            // if(emailTimerRef?.current!==undefined) {
            //     clearInterval(emailTimerRef.current)
            // }
            // emailTimerRef.current = setInterval(emailTimer, 1000)
            toast.success('이메일을 확인해 주세요')
            props.setAlertMessage(() => '이메일을 확인해 주세요')
            props.setIsAlert(() => true)
        } catch(error) {
            setEmailAuthCodeTimeIn(()=>false)
            // if(emailTimerRef.current) {
            //     clearInterval(emailTimerRef.current)
            //     emailTimerRef.current = null
            // }
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }

    }



    const checkRequestedEmailCodeFunction = async () => {
        props.setIsAlert(() => false)
        if (!emailAuthCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            props.setAlertMessage(() => '인증시간이 지났습니다. 다시 인증을 받아주세요.')
            props.setIsAlert(() => true)
            return
        }

        // const result = await authService.checkRequestedCode(phoneNumber, phoneAuthCode, ref_url)
        // if (result) {
        //     setIsPhoneAuthTrue(true)
        //     if (timerRef.current) {
        //         clearInterval(timerRef.current)
        //         timerRef.current = null
        //     }
        //     setPhoneAuthCodeTime("인증완료")
        // }
        try {
            await authService.certificateActivateEmailRequestCode(userId, {email, auth_code: emailAuthCode}).then(r => {
                if(r.status!==201)throw r
                return r
            })
            setIsEmailAuthTrue(()=>true)
            toast.success('인증이 완료되었습니다.')
            props.setAlertMessage(() => '인증이 완료되었습니다.')
            props.setIsAlert(() => true)
            // if(emailTimerRef.current) {
            //     clearInterval(emailTimerRef.current)
            //     emailTimerRef.current = null
            // }
            setEmailAuthCodeTime('인증완료')
        } catch(error) {
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }
    }


    const setEmailPassword = async() => {
        props.setIsAlert(() => false)
        if(password !== passwordCheck) {
            toast.error('비밀번호를 확인해 주세요')
            props.setAlertMessage(() => '비밀번호를 확인해 주세요')
            props.setIsAlert(() => true)
            return;
        }
        try {
            const request = await authService.addUserEmail(userId, {email, password}).then(r => {
                // if(r.suatus!==201 )throw r
                return r
            })
            console.log(request)
            toast.success('계정이 설정되었습니다. 계정으로 로그인해주세요')
            props.setAlertMessage(() => '계정이 설정되었습니다. 계정으로 로그인해주세요')
            props.setIsAlert(() => true)
            props.toggleVertical('2')
        } catch(error) {
            console.error(error)
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
            props.setIsAlert(() => true)
            throw error
        }
    }

    return (
        <div className="loginCase01 caseview">
            <div className="form-group">
                <div className="input-group">
                    <input
                        disabled={isValuableAddEmail}
                        type="text"
                        id="InputNumber"
                        className="form-control form-round-height"
                        placeholder="휴대폰 번호를 입력하세요."
                        onChange={e => setPhoneNumber(e.target.value)}
                    />
                    <span className="input-group-append">
                        <button
                            disabled={isValuableAddEmail}
                            className="btn btn-primary"
                            style={{
                                fontSize:"14px",
                                backgroundColor:"#01a0fc",
                                width:"100px",
                                padding: "0",
                                letterSpacing:"-1px"
                            }}
                            type="button"
                            onClick={() => requestCodeFunction()}
                        >
                            인증번호 발송
                        </button>
                    </span>
                </div>
            </div>
            <div className="form-group">
                <div className="input-group">
                    <input
                        disabled={isValuableAddEmail}
                        type="text"
                        id="InputNumberCheck"
                        className="form-control form-round-height"
                        placeholder="인증번호를 입력하세요."
                        onChange={e => setAuthCode(e.target.value)}
                    />
                    <span className="CountNum" style={{top:5,right:110}}>{authCodeTime}</span>
                    <span className="input-group-append">
                        <button
                            disabled={isValuableAddEmail}
                            className="btn btn-primary"
                            style={{
                                fontSize:"14px",
                                backgroundColor:"#01a0fc",
                                width:"100px",
                                padding: "0",
                                letterSpacing:"-1px"
                            }}
                            type="button"
                            onClick={() => checkRequestedCodeFunction()}
                        >
                            확인
                        </button>
                    </span>
                </div>
            </div>
            <div className="form-group">
                <input
                    disabled={isValuableAddEmail}
                    type="text"
                    className="form-control form-round-height"
                    id="InputNickname"
                    aria-describedby="nicknameHelp"
                    placeholder="닉네임을 입력하세요."
                    onChange={e => setName(e.target.value)}
                />
            </div>


            {isAuthTrue && isValuableAddEmail && (
                <>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                className="form-control form-round-height"
                                type="email"
                                placeholder="이메일을 입력하세요."
                                disabled={isEmailAuthTrue}
                                onChange={(e)=>{setEmail(e.target.value)}}
                            />
                            <span className="input-group-append">
                                <button
                                    disabled={isEmailAuthTrue}
                                    className="btn btn-primary"
                                    style={{
                                        fontSize:"14px",
                                        backgroundColor:"#01a0fc",
                                        width:"100px",
                                        padding: "0",
                                        letterSpacing:"-1px"
                                    }}
                                    type="button"
                                    onClick={() => requestEmailCodeFunction()}
                                >
                                    인증번호 발송
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <input
                                disabled={isEmailAuthTrue}
                                type="text"
                                id="InputNumberCheck"
                                className="form-control form-round-height"
                                placeholder="인증번호를 입력하세요."
                                onChange={e => setAuthCode(e.target.value)}
                            />
                            <span className="CountNum" style={{top:5,right:110}}>{secToMMSS(emailAuthCodeTime)}</span>
                            <span className="input-group-append">
                                <button
                                    disabled={isEmailAuthTrue}
                                    className="btn btn-primary"
                                    style={{
                                        fontSize:"14px",
                                        backgroundColor:"#01a0fc",
                                        width:"100px",
                                        padding: "0",
                                        letterSpacing:"-1px"
                                    }}
                                    type="button"
                                    onClick={() => checkRequestedEmailCodeFunction()}
                                >
                                    확인
                                </button>
                            </span>
                        </div>
                    </div>
                    {isEmailAuthTrue && (
                        <>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control form-round-height"
                                    id="InputNickname"
                                    aria-describedby="nicknameHelp"
                                    placeholder="비밀번호를 입력하세요."
                                    onChange={e => setPassword(e.target.value)}
                                />
                                {password && !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) && (
                                    <p style={{color:'red', fontSize:'13px'}}>
                                        영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자로 입력하세요.
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <div className="input-group">
                                    <input
                                        type="password"
                                        id="InputNumberCheck"
                                        className="form-control form-round-height"
                                        placeholder="비밀번호를 다시 한번 입력하세요"
                                        onChange={e => setPasswordCheck(e.target.value)}
                                    />
                                    <span className="input-group-append">
                                        <button
                                            className="btn btn-primary"
                                            disabled={
                                                password.trim() === '' ? true :
                                                !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) ? true :
                                                passwordCheck.trim() === '' ? true :
                                                passwordCheck !== password ? true : false
                                            }
                                            style={{
                                                fontSize:"14px",
                                                backgroundColor:"#01a0fc",
                                                width:"100px",
                                                padding: "0",
                                                letterSpacing:"-1px"
                                            }}
                                            type="button"
                                            onClick={() => setEmailPassword()}
                                        >
                                            완료
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}




            <div className="form-footer text-center">
                <button
                    // href="index.html"
                    style={{backgroundColor:"#01a0fc", borderRadius:"10px !important", width:"140px"}}
                    className="btn btn-primary"
                    title=""
                    onClick={Login}
                >
                    로그인
                </button>
            </div>
        </div>
    )
}

export default withRouter(withTranslation()(AccountLogin))

AccountLogin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}


function useInterval(callback, delay) {
    const savedCallback = useRef(callback)
  
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
  
    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }
  
        const id = setInterval(() => savedCallback.current(), delay)
        return () => clearInterval(id)
    }, [delay])
}