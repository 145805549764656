import axios from "axios"
import config from "config/configuration"
import _ from "lodash"
import { toast } from "react-toastify"

//import room service
import {
    getRoomData,
    getThumbnailUrl,
    uploadVAFService,
    uploadImageService,
    sendMessagesService,
    getMessagesService,
    previewMessageService,
} from "../../../../../services/talk2youMessage.service"
import { isValidUrl } from "../Helper"

// Sent message
export async function addMessageFunction(
    newHashCode,
    data,
    msgType,
    curMessage,
    fileName,
    jobId,
    fileOrigin,
    fileSize,
    imgRatio,
    durationResult

    // newHashCode,
    // data,
    // "VIDEO",
    // "",
    // thumbnailUrlResponse.thumbnailUrl,
    // videoResponse.jobIds[0],
    // videoResponse.url[0],
    // fileSize,
    // durationResult
) {
    let checkMsg = curMessage

    if (msgType === "TEXT") if (checkMsg.substring(0, 1) === "\n") checkMsg = checkMsg.substring(1)

    const body = {
        roomId: data.roomId,
        senderId: data.user.id,
        senderImg: data.user.image_url,
        senderName: data.user.nickname,
        msgType: msgType,
        message: fileName || checkMsg,
        imgRatio: imgRatio || 0,
        duration: durationResult,
        jobId: jobId,
        fileOrigin: fileOrigin || "",
        fileSize: fileSize || 0,
        hashCode: newHashCode,
        isActivated: 1,
    }
    console.log(body)
    const result = await sendMessagesService(body)

    if (msgType === "TEXT") {
        const first = checkMsg.split(" ")[0]
        let urlCheck = isValidUrl(first)

        if (urlCheck) {
            const previewbody = {
                url: first,
                hashCode: newHashCode,
            }
            await previewMessageService(previewbody)
        }
    }

    if (result.limit) {
        return {
            status: true,
            message: body,
            limit: {
                transferCount: result.limit.transferCount,
                transferMaxCount: result.limit.transferMaxCount,
            },
        }
    } else
        return {
            status: false,
            message: `글 전송 글자수가 초과되었습니다.\n추가적인 전송을 위해서는 답변톡 이용권을 구매하셔야 합니다.`,
        }
}

// Get room data
export async function getRoomDataFunction(roomId, userId) {
    const response = await getRoomData(roomId, userId)
    if (response.itemList.length < 1) {
        return {
            status: false,
            message: "채팅방 정보를 찾을 수 없습니다.",
        }
    }

    let roomData = response.itemList[0]

    // Is the current user the room owner?
    let isOwner = userId === roomData.ownerId

    // Serach owner data
    let ownerData = _.find(roomData.activeUsers, function (o) {
        return o.userId === roomData.ownerId
    })

    if (!ownerData) {
        ownerData = _.find(roomData.invitedUsers, function (o) {
            return o.userId === roomData.ownerId
        })
    }

    // Active users array empty
    if (!ownerData && userId === roomData.ownerId) {
        ownerData = {
            userId: roomData.ownerId,
            userImg: roomData.myInfo.userImg,
            userName: roomData.myInfo.userName,
        }
    }

    return {
        isOwner,
        roomData,
        ownerData,
    }
}

// Get messages
export async function getMessagesFunction(roomId, userId, messageParam) {
    const response = await getMessagesService(
        roomId,
        userId,
        messageParam.since,
        messageParam.until,
        messageParam.limit
    )

    return {
        limit:
            response && response.limit
                ? {
                      transferCount: response.limit.transferCount,
                      transferMaxCount: response.limit.transferMaxCount,
                  }
                : {},
        messages: response && response.itemList.length > 0 ? response.itemList : [],
    }
}

// Select file
export async function selectFileFunction(newHashCode, data, event) {
    const fileUploaded = event.target
    const formData = new FormData()



    if (fileUploaded && fileUploaded.files && fileUploaded.files[0] && fileUploaded.files[0].type) {
        let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024)
        let type = fileUploaded.files[0].type.substring(0, 5)

        if (type === "video") {
            if (originFileSize > 200) {
                return {
                    status: false,
                    message: "선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다.",
                }
            }
        } else {
            if (originFileSize > 20) {
                return {
                    status: false,
                    message: "선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다.",
                }
            }
        }

        let bucketName = "flowing-web-resources"
        let fileSize = originFileSize.toFixed(2)
        let fileName = fileUploaded?.files[0]?.name
        formData.append("upload", fileUploaded.files[0])

        if (fileSize < 1) {
            //fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + "KB"
            fileSize = fileUploaded?.files[0]?.size + "Byte"
        } else {
            fileSize += "MB"
        }

        if (type === "audio") {
            return audioUpload(newHashCode, data, formData, bucketName, fileSize, fileName)
        } else if (type === "video") {
            let durationResult = null;
            const vid = document.createElement("video");
            const fileURL = URL.createObjectURL(fileUploaded.files[0]);
            vid.src = fileURL;
            vid.ondurationchange = async function() {
                durationResult = this.duration;
                console.log("test duration::: ", durationResult)
                return videoUpload(newHashCode, data, formData, bucketName, fileSize, fileName, durationResult).then(r=>{
                    // TODO: duration 이슈 때문에 여기서 분기처리 후 toast 설정, 추후 리팩토링 요망
                    if(!r.status) {
                        toast.error(`${r.message}`)
                    }
                })
            };
        } else if (type === "image") {
            return imageUpload(newHashCode, data, formData, fileSize, fileName)
        } else {
            return fileUpload(newHashCode, data, formData, bucketName, fileSize, fileName)
        }
    } else {
        return {
            status: false,
            message: "오류가 발생했습니다. 다시 시도해 주세요.",
        }
    }
}

// docs and zip etc ... other file upload
const fileUpload = async (newHashCode, data, formData, bucketName, fileSize, fileName) => {
    let msgType = "FILE"
    let uploadType = "docs"
    
    const fileResponse = await uploadVAFService(formData, bucketName, uploadType)
    if (fileResponse.msg === "OK") {
        return addMessageFunction(newHashCode, data, msgType, "", fileName, "", fileResponse.url[0], fileSize)
    } else {
        return {
            status: false,
            // message: "Error upload this file",
            message: "지원하지 않는 파일입니다.",
        }
    }
}

// Image upload function
const imageUpload = async (newHashCode, data, formData, fileSize, fileName) => {
    let msgType = "PHOTO"
    formData.append("limitedImageSize", 1280)
    const imageResponse = await uploadImageService(formData)
    console.log('imageResponse', imageResponse); //return;
    if (imageResponse.msg === "OK") {
        return addMessageFunction(
            newHashCode,
            data,
            msgType,
            imageResponse?.url[0],
            "",
            "",
            imageResponse?.url[0],
            fileSize,
            imageResponse?.imgRatio[0] || 0.57
        )
    } else {
        return {
            status: false,
            message: "이미지파일 업로드 오류 발생",
        }
    }
}

// Audio upload function
const audioUpload = async (newHashCode, data, formData, bucketName, fileSize, fileName) => {
    let uploadType = "audio"

    const audioResponse = await uploadVAFService(formData, bucketName, uploadType)
    if (audioResponse.msg === "OK") {
        return addMessageFunction(newHashCode, data, "VOICE", "", fileName, "", audioResponse.url[0], fileSize)
    } else {
        return {
            status: false,
            message: "오디오파일 업로드 오류 발생",
        }
    }
}

// Video upload function
const videoUpload = async (newHashCode, data, formData, bucketName, fileSize, fileName, durationResult) => {
    formData.append("needToConvert", "Y")
    const videoResponse = await uploadVAFService(formData, bucketName, "video")

    if (videoResponse && videoResponse.msg !== "OK") {
        return {
            status: false,
            message: "비디오파일을 전송하지 못했습니다.",
        }
    }

    let body = {
        video_url: videoResponse.src_url[0],
        bucket_name: bucketName,
    }

    const thumbnailUrlResponse = await getThumbnailUrl(body)

    if (!thumbnailUrlResponse.thumbnailUrl) {
        return {
            status: false,
            message: "썸네일이미지를 전송하지 못했습니다.",
        }
    }

    return addMessageFunction(
        newHashCode,
        data,
        "VIDEO",
        "",
        thumbnailUrlResponse.thumbnailUrl,
        videoResponse.jobIds[0],
        videoResponse.url[0],
        fileSize,
        thumbnailUrlResponse.imgRatio,
        durationResult
    )
}
