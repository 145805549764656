import React, {useEffect, useState} from 'react';
import { toast } from "react-toastify"
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons"
import { deleteOneMessageFunction } from "../../helper/Functions/SupportFunctions"

export default function Text(props) {
    const { message, selectedMsgId, myMsg, user, roomId, completedAction } = props

    const [isMesageDeleteModal, setIsMesageDeleteModal] = useState(false)

    const deleteTextAction = async () => {
        setIsMesageDeleteModal(false)
        let body = {
            messageId: message.messageId,
            userId: user.id,
            roomId: roomId,
        }
        console.log(body)

        const response = await deleteOneMessageFunction(body)
        console.log(response)
        if (response.message !== "Deleted") {
            // toast.error("Cannot this message deleted")
            toast.error("메시지를 삭제하지 못했습니다.")
            return
        }
    }

    useEffect(() => {

        if (!completedAction) return false;
        completedAction()
    }, [message])

    return (
        <div className="message-content">
            {message.isActivated > 0 ? (
                <p
                    className="chat-lefted-text d-inline-block"
                    onClick={() => {
                        props.selectedMessageAction({
                            action: "MESSAGE_CLICK",
                            messageId: message.messageId,
                        })
                    }}
                >
                    {message.message}
                </p>
            ) : (
                <p className="chat-lefted-text d-inline-block">삭제된 메시지입니다</p>
            )}
            {selectedMsgId === message.messageId && (
                <div className={myMsg ? "tool_tip tool_tip_right" : "tool_tip"}>
                    <button
                        onClick={() => {
                            props.selectedMessageAction({
                                action: "COPY",
                                copyText: message.message,
                            })
                        }}
                    >
                        <FontAwesomeIcon icon={faCopy} color={"white"} />
                        <p>복사</p>
                    </button>
                    {myMsg && (
                        <button
                            onClick={() => {
                                setIsMesageDeleteModal(true)
                                props.selectedMessageAction({
                                    action: "DELETE",
                                    messageId: message.messageId,
                                })
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} color={"white"} />
                            <p>삭제</p>
                        </button>
                    )}
                </div>
            )}

            <Modal returnFocusAfterClose={true} isOpen={isMesageDeleteModal} centered={true}>
                <ModalBody className="text-center">이 내용을 삭제 하겠습니까 ?</ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {
                            setIsMesageDeleteModal(false)
                        }}
                    >
                        취소
                    </Button>
                    <Button color="primary" onClick={() => deleteTextAction()}>
                        삭제
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
