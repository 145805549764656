import {memo} from 'react';
import propTypes from 'prop-types';

const ChatFooter =  (props) => {
    return (
        <div className="chat-footer mt-2">
            <button className="chat-footer-icon chat-button">
                <input type="file" className="d-none" id="selected-file" name="attachment"/>
                <i className="icon-paper-clip" />
            </button>
            <button className="chat-footer-icon chat-button">
                <i className="icon-emoticon-smile" />
            </button>
            <input type="text" id="sent-chat-area" className="form-control bgChatText mt-0 chat-input" />
            <button className="chat-footer-btn active" onClick={props.sendMessage}>
                <i className="icon-paper-plane"/>
            </button>
        </div>
    )
}

ChatFooter.propTypes = {
    sendMessage: propTypes.func
}

export default memo(ChatFooter);