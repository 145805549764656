import React from "react"
import logoFoot from "../../assets/imageshtml/logo_foot.png"
import insta from "../../assets/imageshtml/ic_instar.png"
import youtube from "../../assets/imageshtml/ic_youtube.png"
import logoBlack from "../../assets/imageshtml/logo-black.png"
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <React.Fragment>
            <section className="footer-area position-relative bg-white">
                <hr className="border-top-gray my-4" />
                <div className="container">
                    <div className="row align-items-center pb-4 copyright-wrap">
                        <div className="col-lg-2 text-left">
                            <Link to="/" className="d-inline-block">
                                <img
                                    src={logoBlack}
                                    alt="footer logo"
                                    className="footer-logo"
                                />
                            </Link>
                        </div>
                        <div className="col-lg-8">
                            <div className="text-left fs-14">
                                <div className="footLink">
                                    <a href="https://terms.flowing.tel/index.html" target="_blank">운영정책</a>
                                    <a href="https://terms.flowing.tel/terms.html" target="_blank">서비스 약관</a>
                                    <a href="https://terms.flowing.tel/privacy.html" target="_blank">개인정보 보호방침</a>
                                    {/*<a onClick={()=>{alert('준비중인 서비스 입니다.')}} >공지사항</a>*/}
                                    {/*<a onClick={()=>{alert('준비중인 서비스 입니다.')}} >문의하기</a>*/}
                                </div>
                            </div>
                            {/*<p className="text-left fs-14">플로잉 서비스</p>*/}
                            <p className="text-left fs-14">
                                플로잉 서비스 고객센터 이메일 :{" "}
                                <a href="mailto:help@flowing.tel">help@flowing.tel</a>
                            </p>

                        <div className="footInfo text-left">
                            <span className="fs-11">㈜ 티피코리아</span>
                            <span className="fs-11">주소 : 서울 강동구 길동 천호 대로 1121 일성하이츠 205</span>
                            <span className="fs-11">사업자등록번호 212-819-8353</span>
                            <span className="fs-11">통신 판매신고 : 제 2012-서울강동-0311 호</span>
                            <span className="fs-11">개인정보책임관리자 : 손유한</span>
                            <span className="fs-11">전화번호 : 070-4504-4055</span>
                            <span className="fs-11">문의 <a href="mailto:help@flowing.tel">help@flowing.tel</a></span>
                            <span className="fs-11">대표이사: 오석환</span>
                        </div>
                            <p className="text-left fs-14">
                                2022 Flowing Corp.
                                <a
                                    href="http://flowing-app-share.s3-website.ap-northeast-2.amazonaws.com/"
                                    className="company_logo ml-1"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={logoFoot} alt="" />
                                </a>
                            </p>
                        </div>
                        <div className="col-lg-2 text-right position-static">
                            <div className="shareLink">
                                <a
                                    href="https://www.youtube.com/@answeringchat"
                                    target="_blank"
                                    className="company_share mr-4"
                                >
                                    <img src={youtube} alt="" />
                                </a>

                                <a
                                    href="https://www.instagram.com/flowingtalk/"
                                    target="_blank"
                                    className="company_share "
                                >
                                    <img src={insta} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Footer
