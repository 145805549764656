import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import "../../../../assets/css/myStyleChat.css"

import {
    setRoomTransferFunction,
    getTransferLimitFunction
} from "../helper/Functions/SupportFunctions"

export default function SettingsLimit(props) {
    const { user, roomId } = props

    const [freeTransfer, setFreeTransfer] = useState()
    const [purchaseNeedsCount, setPurchaseNeedsCount] = useState()

    // set limit
    const saveTransfer = async () => {
        let data = {
            userId: user.id,
            freeTransferCount: parseInt(freeTransfer),
            productPurchaseNeedsCount: parseInt(purchaseNeedsCount),
            productDescription: "상품설명"
        }
        const result = await setRoomTransferFunction(roomId, data)
        if (result.message === "Updated") {
            props.refreshRoom()
            props.onChangeMenu("chatContainer")
            // toast.success("Save transfer limit")
            toast.success("전송제한 값을 설정했습니다.")
        } else {
            toast.warning(result?.response?.data?.message)
        }
    }

    // Main useEffect
    useEffect(() => {
        getTransferLimit()

        async function getTransferLimit() {
            let result = await getTransferLimitFunction(roomId, user.id)
            setFreeTransfer(result.limit.transferMaxCount)
            setPurchaseNeedsCount(result.limit.purchaseCount)
        }
    }, [])

    return (
        <div className="room-list">
            <div className="room-item-header d-flex align-items-center">
                <div className="room-item-close-button">
          <span
              className="la la-close"
              onClick={e => {
                  props.onChangeMenu("chatContainer")
              }}
          ></span>
                </div>
                <div className="room-item-header-title font-weight-bold">
                    답변방 채팅 제한 설정
                </div>
                <div className="position-absolute right-0 mr-2">
                    <button
                        className="btn btn-outline-secondary btn-submit-limits"
                        style={{ padding: "pt-4px pb-4px" }}
                        onClick={() => saveTransfer()}
                    >
                        저장
                    </button>
                </div>
            </div>
            <div className="chat-trans-limit-body p-10">
                <div className="trans-limit-row d-flex align-items-center justify-content-between">
                    <div className="">- 전송 제한 적용</div>
                    <div className="">- 전송 제한 적용</div>
                </div>
            </div>
            <div className="chat-trans-limit-body p-10">
                <div className="trans-limit-row d-flex align-items-center justify-content-between">
                    <div className="">- 무료 전송 글자수</div>
                    <input
                        className="pt-2px pb-2px text-right input-tans-limit"
                        placeholder=""
                        style={{ width: "80px" }}
                        value={freeTransfer}
                        onChange={e => {
                            setFreeTransfer(e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="chat-trans-limit-body p-10">
                <div className="trans-limit-row d-flex align-items-center justify-content-between">
                    <div className="">- 500 답변톡 상품 구매가(보석)</div>
                    <input
                        className="pt-2px pb-2px text-right input-tans-limit"
                        placeholder=""
                        value={purchaseNeedsCount}
                        onChange={e => {
                            setPurchaseNeedsCount(e.target.value)
                        }}
                        style={{ width: "80px" }}
                    />
                </div>
            </div>
            <div className="chat-trans-limit-body p-10">
                <div className="p-10">
                    변경된 설정 내용은 현재 답변방에만 적용됩니다. 이미지, 영상, 음성,
                    파일 등의 전송은 50글자로 인식하여 전송됩니다.
                </div>
            </div>
        </div>
    )
}
