import moment from "moment"

export function checkDeadlineTimer(deadline) {
    var endDate = new Date(deadline)
    var nowDate = new Date()
    var endTime = endDate.getTime()
    var nowTime = nowDate.getTime()
    var diffTime = endTime - nowTime
    var duration = moment.duration(diffTime, "milliseconds")

    if (diffTime > 1) {
        return duration
    } else {
        return false
    }
}

// Deadline timer
export function startDeadlineTimer(e) {
    let { days, hours, minutes, seconds } = e
    if (days >= 0 || hours >= 0 || minutes >= 0 || seconds >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        if (days >= 1) hours = hours + days * 24
        return (
            (hours > 9 ? hours : "0" + hours) +
            ":" +
            (minutes > 9 ? minutes : "0" + minutes) +
            ":" +
            (seconds > 9 ? seconds : "0" + seconds)
        )
    } else {
        // End room timer
        return false
    }
}
