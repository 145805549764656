import { use } from "i18next"
import { getImageSize } from "react-image-size"
import React, { useEffect, useState, useRef } from "react"
import { Card, Container, Row, Col, Form, Collapse, CardBody, Modal, Alert } from "reactstrap"
import "../../assets/css/style.css"
import CategoryModal from "./CategoryModal"
import DistrictModal from "./DistrictModal"
import { toast } from "react-toastify"
import { getThumbnailUrl, uploadImageService } from "services/talk2meMessage.service"
import { createRoom } from "services/question.service"
import { fetchEstimatedAssets, fetchEstimatedPoints } from "../../services/rewardPoints.service"
import { uploadVAFService } from "../../services/talk2youMessage.service"
import { enterRoom } from "../../services/talk2meRooms.service"
import { checkDeadlineTimer } from "../../helpers/Functions/deadlineTimer"
import { authService } from "services/auth.service"
// import "../../assets/css/AddStyle.css"

const QuestionsWrite = args => {
    const [userInfo, setUserInfo] = useState({})


    const [openEl, setOpenEl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [adoptionPoint, setAdoptionPoint] = useState("")
    const [selCategories, setSelcategories] = useState([])
    const [selDisCategories, setSelDisCategories] = useState([])
    const [categoryIds, setCategoryIds] = useState([])
    const [distCategoryIds, setDistCategoryIds] = useState([])
    const [modal_category, setmodalCategory] = useState(false)
    const [modal_district, setmodalDistrict] = useState(false)
    const [loadingFile, setLoadingFile] = useState()
    const [userImg, setUserImg] = useState([])
    const [fileBox, setFileBox] = useState(false)
    const [resourceList, setResourceList] = useState([])
    const inputRef = useRef(null)
    const toggle = () => setIsOpen(!isOpen)
    const [isAlert, setIsAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    //kms add
    const [rewardType, setRewardType] = useState("") //리워드타입
    const [rewardCount, setRewardCount] = useState("") //리워드갯수

    // TODO : 질문톡방 일괄 생성(API 문서 52번) API 규격과 전혀 맞지 않는다. 아래 uploadFiles function 으로 대체
    /*const imageUpload = async (formData, fileSize, fileName) => {
        let msgType = "PHOTO"
        formData.append("limitedImageSize", 1280)
        console.log('formData', formData);
        const imageResponse = await uploadImageService(formData)
        console.log('imageResponse', imageResponse);
        if (imageResponse.msg === "OK") {
            setUserImg(imageResponse.url)
        } else {
            toast.error(imageResponse.msg)
        }

        setLoadingFile(false)
    }*/

    useEffect(() => {
        /*getUserImg()*/
        const getUserIdRes = async () => {
            try {
                const res = await authService.getUserId()
                console.log("res::::::::",res)
                if(res.status===201) {
                    const response = await authService.getUserInfo(res.data.user_id)
                    setUserInfo(response)
                    console.log(response)
                } else {
                    args.history.push("/login")
                    return;
                }
            } catch(err) {
                console.log(err)
            }
        }
        getUserIdRes().then(r=>r)
    }, [])

    async function uploadImageFile(formData, fileSize) {
        formData.append("needToConvert", "Y")
        const rsOrigin = await uploadVAFService(formData, "flowing-web-resources", "video")
        console.log("rsOrigin", rsOrigin)
        if (rsOrigin.msg === "OK") {
            const newForm = new FormData()
            newForm.append("video_url", rsOrigin?.src_url[0]) // Media Convert 파일 아닌 원본파일로 썸네일 생성해야 함
            newForm.append("bucket_name", "flowing-web-resources")
            const thumbnail = await getThumbnailUrl(newForm)
            const thumbnailUrl = (thumbnail && thumbnail?.thumbnailUrl) || ""
            const imgRatio = (thumbnail && thumbnail?.imgRatio) || 0.56
            const newResources = [...resourceList]
            const resource = {
                resourceType: "VIDEO",
                thumbnailUrl,
                mediaUrl: rsOrigin?.url[0],
                imgRatio,
                fileSize
                // duration // todo : 구현해야 함
            }
            newResources.push(resource)
            setResourceList(newResources)

            const newUserImg = [...userImg]
            newUserImg.push(thumbnailUrl)
            setUserImg(newUserImg)
            if (newUserImg.length >= 4) setFileBox(true)
        } else {
            toast.error(rsOrigin.msg)
        }
    }

    async function uploadVideoFile(formData, fileSize) {
        formData.append("limitedImageSize", 1280)
        const rsOrigin = await uploadImageService(formData)
        console.log("rsOrigin", rsOrigin)
        if (rsOrigin.msg === "OK") {
            const { width, height } = await getImageSize(rsOrigin?.url[0])
            console.log("width", width)
            console.log("height", height)
            let thumbnailUrl = rsOrigin?.url[0]
            if (Number(width) > 170) { // 정해진(default 170, 이후 변경 가능) 사이즈보다 클 경우 썸네일 추가 생성
                formData.set("limitedImageSize", 170)
                const thumbnail = await uploadImageService(formData)
                if (thumbnail && thumbnail?.msg === "OK") {
                    thumbnailUrl = thumbnail?.url[0]
                }
            }
            const newUserImg = [...userImg]
            newUserImg.push(thumbnailUrl)
            setUserImg(newUserImg)
            if (newUserImg.length >= 4) setFileBox(true)

            const newResources = [...resourceList]
            const resource = {
                resourceType: "PHOTO",
                thumbnailUrl,
                mediaUrl: rsOrigin?.url[0],
                imgRatio: (height / width).toFixed(2) || 0.56,
                fileSize
            }
            newResources.push(resource)
            setResourceList(newResources)
        } else {
            toast.error(rsOrigin.msg)
        }
    }

    const uploadFiles = async (formData, fileType, fileSize, fileName) => {
        console.log("formData", formData)
        if (fileType === "video") {
            await uploadImageFile(formData, fileSize);

        } else if (fileType === "image") {
            await uploadVideoFile(formData, fileSize)

        } else {
            toast.error("사진파일 또는 영상파일만 업로드 가능합니다.")
            return false
        }

        setLoadingFile(false)
    }

    const handleToggler = value => {
        if (openEl === value) {
            setOpenEl(null)
        } else {
            setOpenEl(value)
        }
    }
    const selectFileFunction = async event => {
        console.log('selectFileFunction');
        console.log('fileBox', fileBox);
        console.log('resourceList', resourceList)

        const fileUploaded = event.target
        const formData = new FormData()

        if (fileUploaded && fileUploaded.files && fileUploaded.files[0] && fileUploaded.files[0].type) {
            setLoadingFile(true)

            let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024)
            // let fileType = fileUploaded.files[0].type.substring(0, 5)
            console.log("file", fileUploaded.files[0])
            let fileType = fileUploaded.files[0]?.type.split("/")[0]
            // console.log('fileType', fileType); return;

            if (fileType === "video") {
                if (originFileSize > 200) {
                    toast.error("선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다.")
                    setLoadingFile(false)
                    return
                }
            } else {
                if (originFileSize > 20) {
                    toast.error("선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다.")
                    setLoadingFile(false)
                    return
                }
            }

            // let bucketName = "flowing-web-resources"
            let fileSize = originFileSize.toFixed(2)
            let fileName = fileUploaded?.files[0]?.name
            formData.append("upload", fileUploaded.files[0])

            if (fileSize < 1) {
                fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + "KB"
            } else {
                fileSize += "MB"
            }

            // await imageUpload(formData, fileSize, fileName)
            await uploadFiles(formData, fileType, fileSize, fileName)
        }
    }

    function tog_Category() {
        setmodalCategory(true)
    }

    function tog_District() {
        setmodalDistrict(true)
    }

    const onChangeFunction = value => {
        setSelcategories(value)
        setCategoryIds(value.map(item => item.id))
    }
    //passing district category datas to parent
    const onChangeDistrictFunction = value => {
        setSelDisCategories(value)
        setDistCategoryIds(value.map(item => item.id))
    }

    const removeFiles = async idx => {
        // console.log('index', idx);

        const removeFilePath = userImg[idx]
        // TODO : 파일이 저장된 S3 버킷에서 저장된 파일으 삭제하는 기능 구현이 필요함.
        // 일단은 파일 업로드 과정에서 resourceList에서만 제외함
        const newSrcList = resourceList.slice();
        const removeResourceIndex = newSrcList.findIndex(item => {
            return item.thumbnailUrl === removeFilePath;
        })

        newSrcList.splice(removeResourceIndex,1);
        setResourceList(newSrcList);


        const newUserImg = [...userImg]
        newUserImg.splice(idx, 1)
        // console.log('newUserImg', newUserImg);
        setUserImg(newUserImg)
        if (newUserImg.length < 4) setFileBox(false)
        return false
    }

    const registerRoom = async () => {
        if (!title) {
            toast.error("제목을 입력해 주세요.")
            document.getElementsByName("title")[0].focus()
            return
        }
        if (!content) {
            toast.error("내용을 입력해 주세요.")
            document.getElementsByName("content")[0].focus()
            return
        }
        if (!rewardType) {
            toast.error("리워드 타입을 선택해 주세요.")
            document.getElementsByName("rewardType")[0].focus()
            return
        }
        if (!rewardCount) {
            toast.error("리워드 수량을 입력해 주세요.")
            document.getElementById("rewardCount").focus()
            return
        }
        const body = {
            name: title,
            introduction: content,
            ownerId: userInfo.id,
            //rewardPoints: adoptionPoint,
            rewardType: rewardType,
            rewardCount: rewardCount,
            relCategory: selCategories,
            relRegion: selDisCategories,
            // representativeImg: userImg[0],
            // representativeRatio: "0.56",
            resourceList,
        }
        // console.log("body:::", body); //return;
        const response = await createRoom(body)
        if (response && response?.itemList[0]?.roomId) {
            toast.success("공개톡 질문방이 생성 되었습니다")

            // todo : 일괄생성 완료 후 Enter API 호출을 통해 방에 입장해 주어야 한다.
            const result = await enterRoom(
                {
                    userId: userInfo.id,
                    userName: userInfo.nickname,
                    userImg: userInfo.image_url || "https://d1vb419ubgm46b.cloudfront.net/userbg.jpg",
                },
                response?.itemList[0]?.roomId
            )

            args.history.push("/main")
        }
    }

    const validateRewardPoints = async (rewardType, rewardCount) => {
        const userId = userInfo.id
        if (rewardType === "point" && userId) {
            const response = await fetchEstimatedPoints(userId)
            if (response && response.point) {
                if (Number(response.point) < Number(rewardCount)) {
                    console.log("포인트 부족")
                    document.getElementById("rewardCount").value = response.point
                }
                setAlertMessage(`보유하신 포인트(${response.point} 포인트) 한도 내에서 사용 가능합니다.`)
                setIsAlert(true)
            }
        } else {
            const response = await fetchEstimatedAssets(userId)
            console.log(response)
            if (
                response &&
                response.hasOwnProperty("itemList") &&
                Array.isArray(response.itemList) &&
                response.itemList.length > 0
            ) {
                if (Number(response.itemList[0][rewardType]) < Number(rewardCount)) {
                    console.log("부족")
                    document.getElementById("rewardCount").value = response.itemList[0][rewardType]
                }
                setAlertMessage(
                    `보유하신 보석(${response.itemList[0][rewardType]} ${rewardType}) 한도 내에서 사용 가능합니다.`
                )
                setIsAlert(true)
            }
        }
    }

    const rewardTypeOptions = [
        {
            label: "리워드타입 선택",
            value: "",
        },
        {
            label: "루비",
            value: "ruby",
        },
        {
            label: "다이아",
            value: "dia",
        },
        {
            label: "포인트",
            value: "point",
        },
    ]
    //meta title
    document.title = "답변톡 플로잉"

    // useEffect(() => {
    //     if (userInfo.id) {
    //         const userData = JSON.parse(localStorage.getItem("user_data"))
    //         setUserInfo(userData)
    //     } else {
    //         args.history.push("/login")
    //     }
    // }, [])


    return (
        <React.Fragment>
            <section className="pt-60px">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="filters pb-4 d-flex align-items-center">
                                <h2 className="listTitle mr-1">
                                    <b>질문하기</b>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="question-area pb-40px">
                <Container>
                    <Row>
                        <Col lg={8}>
                            <Card className="card-item">
                                <div className="card-body">
                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                                            질문 제목
                                            <span className="textmax">{`${title?.length || 0}/20`}</span>
                                        </label>

                                        <div className="form-group">
                                            <input
                                                className="form-control form--control"
                                                type="text"
                                                name="title"
                                                maxLength={20}
                                                placeholder="질문제목을 입력해 주세요."
                                                onChange={e => setTitle(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                                            질문 내용
                                            <span className="textmax">{`${content?.length || 0}/1000`}</span>
                                        </label>

                                        <div className="form-group">
                                            <textarea
                                                className="form-control form--control"
                                                name="content"
                                                rows="4"
                                                maxLength={1000}
                                                placeholder="질문내용을 입력해 주세요."
                                                onChange={e => setContent(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                                            리워드 타입
                                        </label>

                                        <div className="form-group">
                                            <select
                                                className="form-control form--control"
                                                name="rewardType"
                                                onChange={async e => {
                                                    setRewardType(e.target.value)
                                                    // console.log('rewardType : ', e.target.value);
                                                    if (e.target.value && rewardCount && Number(rewardCount) > 0)
                                                        await validateRewardPoints(e.target.value, rewardCount)
                                                }}
                                            >
                                                {rewardTypeOptions.map((option, index) => (
                                                    <option value={option.value} key={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                                            리워드 수량
                                        </label>

                                        <div className="form-group">
                                            <input
                                                className="form-control form--control"
                                                id="rewardCount"
                                                type="number"
                                                name="rewardCount"
                                                placeholder={
                                                    rewardType
                                                        ? `채택한 사람에게 선물할 ${
                                                            rewardType === "ruby" ? "루비" :
                                                            rewardType === "dia" ? "다이아" : "포인트"
                                                        }의 개수를 입력하세요.`
                                                        : "채택한 사람에게 선물할 리워드 타입을 선택해 주세요."
                                                }
                                                onChange={async e => {
                                                    setRewardCount(e.target.value)
                                                    // console.log('rewardType : ', rewardType);
                                                    if (rewardType && e.target.value && Number(e.target.value) > 0)
                                                        await validateRewardPoints(rewardType, e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {isAlert === true && (
                                        <Alert
                                            color="success"
                                            className="alert alert-icon alert-success fs12"
                                            role={alert}
                                        >
                                            <i className="fe fe-alert-triangle"></i>
                                            <span style={{ marginLeft: 20 }}>{alertMessage}</span>
                                        </Alert>
                                    )}

                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium mb-0">질문 카테고리(선택)</label>

                                        <div className="form-group">
                                            <div
                                                className="input-group"
                                                style={{
                                                    height: "100%",
                                                }}
                                            >
                                                <div className="form-control" onClick={() => tog_Category()}>
                                                    {selCategories &&
                                                        selCategories.map((item, index) => {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                    className="tag tag-gray"
                                                                    style={{ marginLeft: ".5rem" }}
                                                                >
                                                                    {item.name}
                                                                </span>
                                                            )
                                                        })}
                                                </div>
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn theme-btn"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#category"
                                                        onClick={() => tog_Category()}
                                                    >
                                                        검색
                                                    </button>
                                                    {modal_category === true && (
                                                        <CategoryModal
                                                            modal_category={modal_category}
                                                            setModal={() => setmodalCategory()}
                                                            selCategories={selCategories}
                                                            onChange={value => onChangeFunction(value)}
                                                        />
                                                    )}
                                                    {modal_district === true && (
                                                        <DistrictModal
                                                            modal_district={modal_district}
                                                            setModal={() => setmodalDistrict()}
                                                            selDisCategories={selDisCategories}
                                                            onDistChange={value => onChangeDistrictFunction(value)}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium mb-0">질문 관련지역(선택)</label>
                                        <div className="form-group">
                                            <div
                                                className="input-group"
                                                style={{
                                                    height: "100%",
                                                }}
                                            >
                                                <div className="form-control" onClick={() => tog_District()}>
                                                    {selDisCategories &&
                                                        selDisCategories.map((item, index) => {
                                                            return (
                                                                <span
                                                                    key={index}
                                                                    className="tag tag-gray"
                                                                    style={{ marginLeft: ".5rem" }}
                                                                >
                                                                    {item.upmyeondong_name}
                                                                </span>
                                                            )
                                                        })}
                                                </div>
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn theme-btn"
                                                        type="button"
                                                        data-toggle="modal"
                                                        onClick={() => tog_District()}
                                                        data-target="#area"
                                                    >
                                                        검색
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <label className="fs-14 text-black fw-medium">미디어 자료(선택)</label>
                                        <div className="form-group">
                                            <div className="file-upload-wrap file-upload-layout-2">
                                                <input
                                                    type="file"
                                                    name="files[]"
                                                    className="file-upload-input"
                                                    multiple=""
                                                    ref={inputRef}
                                                    onChange={selectFileFunction}
                                                    disabled={fileBox}
                                                />
                                                <span className="file-upload-text d-flex align-items-center justify-content-center">
                                                    <i className="la la-cloud-upload mr-2 fs-24"></i>
                                                    {fileBox
                                                        ? "미디어파일 첨부는 모두 4개까지 가능합니다."
                                                        : "파일첨부를 위해서는 파일을 이곳에 끌어다 놓거나, 이곳을 클릭한 후 원하는 파일을 선택해 주세요."}
                                                </span>
                                            </div>
                                        </div>
                                        {userImg && userImg.length > 0 && (
                                            <div className="photo_update_box">
                                                {userImg.map((item, index) => {
                                                    return (
                                                        <div key={item + index} className="up-file-thumbnails">
                                                            <img src={item} alt="" />
                                                            <button
                                                                onClick={() => removeFiles(index)}
                                                                className="delImage_close"
                                                            ></button>
                                                            {/*<a href="" className="delImage_close"></a>*/}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                    <div className="input-box pt-2">
                                        <div className="btn-box text-center">
                                            <button
                                                type="submit"
                                                className="btn theme-btn"
                                                onClick={() => registerRoom()}
                                            >
                                                질문 전송하기
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        {/* static area */}
                        <Col lg={4}>
                            <div className="sidebar">
                                <div className="card card-item p-4">
                                    <h3 className="fs-17 pb-3">질문 등록을 위한 필수 정보 3가지를 작성하세요. </h3>
                                    <div className="divider">
                                        <span></span>
                                    </div>
                                    <p className="fs-14 lh-22 pb-2 pt-3">
                                        질문 등록을 위해서는 질문 제목, 질문 내용, 채택 포인트 등 3가지를 필수적으로
                                        입력해야 합니다. 질문 내용에 사진이나 영상 등이 필요한 경우 미디어 자료에
                                        입력하세요.
                                    </p>
                                    {/* <p className="fs-14 lh-22">
                                        Avoid asking opinion-based questions.
                                    </p> */}
                                    <div id="accordion" className="generic-accordion pt-4">
                                        <Card>
                                            <div className="card-header" id="heading_one">
                                                <button
                                                    className="btn btn-link collapsed"
                                                    onClick={() => {
                                                        handleToggler("heading_one")
                                                    }}
                                                >
                                                    <span>
                                                        <span className="text-color pr-2 fs-16">1.</span> 질문 제목을
                                                        입력하세요.
                                                    </span>
                                                    <i
                                                        className={
                                                            openEl === "heading_one"
                                                                ? "la collapse-icon la-angle-up "
                                                                : "la collapse-icon la-angle-down "
                                                        }
                                                    ></i>
                                                </button>
                                            </div>
                                            <div className="collapse show" id="collapseOne">
                                                <Collapse isOpen={openEl === "heading_one"} {...args}>
                                                    <CardBody>
                                                        <p className="fs-14 lh-22 text-black-50">
                                                            질문 제목은 최대 20자 이내로 간결하게 입력해 주세요. 질문에
                                                            내용을 잘 들어낼 수 있는 제목이 좋아요.
                                                        </p>
                                                    </CardBody>
                                                </Collapse>
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="card-header" id="heading_two">
                                                <button
                                                    className="btn btn-link collapsed"
                                                    onClick={() => {
                                                        handleToggler("heading_two")
                                                    }}
                                                >
                                                    <span>
                                                        <span className="text-color pr-2 fs-16">2.</span> 질문 내용을
                                                        입력하세요.
                                                    </span>
                                                    <i
                                                        className={
                                                            openEl === "heading_two"
                                                                ? "la collapse-icon la-angle-up "
                                                                : "la collapse-icon la-angle-down "
                                                        }
                                                    ></i>
                                                </button>
                                            </div>
                                            <div className="collapse show" id="collapseTwo">
                                                <Collapse isOpen={openEl === "heading_two"} {...args}>
                                                    <CardBody>
                                                        <p className="fs-14 lh-22 text-black-50">
                                                            질문 내용은 최대 1,000자 이내로 입력해 주세요. 질문 내용에
                                                            개인정보와 관련된 것은 입력하지 마세요.
                                                        </p>
                                                    </CardBody>
                                                </Collapse>
                                            </div>
                                        </Card>
                                        <Card>
                                            <div className="card-header" id="heading_three">
                                                <button
                                                    className="btn btn-link collapsed"
                                                    onClick={() => {
                                                        handleToggler("heading_three")
                                                    }}
                                                >
                                                    <span>
                                                        <span className="text-color pr-2 fs-16">3.</span> 채택 포인트를
                                                        입력하세요.
                                                    </span>
                                                    <i
                                                        className={
                                                            openEl === "heading_three"
                                                                ? "la collapse-icon la-angle-up "
                                                                : "la collapse-icon la-angle-down "
                                                        }
                                                    ></i>
                                                </button>
                                            </div>
                                            <div className="collapse show" id="collapseThree">
                                                <Collapse isOpen={openEl === "heading_three"} {...args}>
                                                    <CardBody>
                                                        <p className="fs-14 lh-22 text-black-50">
                                                            좋은 답변을 해준 사람에게 줄 채택 포인트를 입력하세요. 채택
                                                            포인트는 최소 50 이상을 입력해야 해요.
                                                        </p>
                                                    </CardBody>
                                                </Collapse>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div id="accordion-two" className="generic-accordion">
                                    <Card className="mb-3">
                                        <div className="card-header" id="heading_four">
                                            <button
                                                className="btn btn-link collapsed"
                                                onClick={() => {
                                                    handleToggler("heading_four")
                                                }}
                                            >
                                                <span>질문 내용과 관련된 질문 카테고리를 입력하세요.</span>
                                                <i
                                                    className={
                                                        openEl === "heading_four"
                                                            ? "la collapse-icon la-angle-up "
                                                            : "la collapse-icon la-angle-down "
                                                    }
                                                ></i>
                                            </button>
                                        </div>
                                        <div className="collapse show" id="collapseFour">
                                            <Collapse isOpen={openEl === "heading_four"} {...args}>
                                                <CardBody>
                                                    <p className="fs-14 lh-22 text-black-50">
                                                        질문 내용과 관련된 질문 카테고리를 선택하면 플로잉 시스템에서
                                                        관련 카테고리 답변자들를 찾아서 질문을 전송합니다.
                                                        <br />
                                                        <br />
                                                        질문 카테고리는 검색을 통해서 선택할 수 있고 최대 3개까지 선택이
                                                        가능합니다.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                    </Card>
                                    <Card className="mb-3">
                                        <div className="card-header" id="headingFive">
                                            <button
                                                className="btn btn-link collapsed"
                                                onClick={() => {
                                                    handleToggler("headingFive")
                                                }}
                                            >
                                                <span>지역 질문이라면 관련 지역을 꼭 넣으세요.</span>
                                                <i
                                                    className={
                                                        openEl === "headingFive"
                                                            ? "la collapse-icon la-angle-up "
                                                            : "la collapse-icon la-angle-down "
                                                    }
                                                ></i>
                                            </button>
                                        </div>
                                        <div className="collapse show" id="collapseFive">
                                            <Collapse isOpen={openEl === "headingFive"} {...args}>
                                                <CardBody>
                                                    <p className="fs-14 lh-22 text-black-50">
                                                        지역에 관련된 질문이면 관련 지역도 꼭 등록해 주세요. 플로잉
                                                        시스템에서 관련 지역 답변자들에게 해당 질문을 알려 드려요.
                                                        <br />
                                                        <br />
                                                        관련 지역은 검색을 통해서 선택할 수 있고 최대 5 개까지 선택이
                                                        가능합니다.
                                                    </p>
                                                </CardBody>
                                            </Collapse>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default QuestionsWrite
