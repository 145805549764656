import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import React, { useState } from "react"

import { Alert, Nav, NavItem, NavLink } from "reactstrap"
import "../../assets/css/style.css"
// import "../../assets/css/AddStyle.css"
//redux

import { withRouter, Link } from "react-router-dom"

//import service
import { authService } from "../../services/auth.service"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"

const Login = props => {
    //meta title
    document.title = "답변톡 플로잉"
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    // const [isAlert, setIsAlert] = useState(false);
    // const [alertMessage, setAlertMessage] = useState('');

    const login = async () => {
        props.setIsAlert(() => false)
        if (email.trim()==='' || password.trim()==='') {
            // return props.easyCreate()

            // props.history.push('/register')
            toast.error('이메일 혹은 비밀번호를 입력해주세요')
            props.setAlertMessage(() => '이메일 혹은 비밀번호를 입력해주세요')
            props.setIsAlert(() => true)
            return;
        }

        if (email && password) {
            try {
                const result2 = await authService.newSignIn({email, password})
                console.log(result2)
            } catch(error) {
                console.log(error)
                if(error?.status===400) {
                    toast.error(error?.data?.place_holder)
                    props.setAlertMessage(() => error?.data?.place_holder || '다시 한번 시도해 주세요')
                    props.setIsAlert(() => true)
                    return;
                }
                if(error?.status===302) {
                    console.error(error?.data?.user_id)
                    sessionStorage.setItem('renewUserId', error?.data?.user_id);
                    sessionStorage.setItem('renewUserEmail', email);
                    window.alert("이메일 재인증이 필요하여 재인증 화면으로 이동합니다.");
                    props.history.push("/registerRenew");
                }
                throw error
            }
            // const result = await authService.login(email, password)
            // console.log('result', result)
            // if (result.status === 400) {
            //     console.log('setAlertMessage', result?.data?.place_holder);
            //     setAlertMessage(result?.data?.place_holder);
            //     setIsAlert(true);

            // } else if(result.status === 302) {
            //     sessionStorage.setItem('renewUserId', result?.data?.user_id);
            //     sessionStorage.setItem('renewUserEmail', email);
            //     window.alert("번호재인증이 필요하여 재인증 화면으로 이동합니다.");
            //     props.history.push("/registerRenew");

            // }
        }
    }

    return (
        // <ToastContainer />
        <div className="loginCase02 caseview">
            {/* {isAlert === true && (
                <Alert
                    color="success"
                    className="d-flex p-2 fs12"
                    role={alert}
                >
                    <div className="flex-grow-0 mr-1">
                        <i className="fe fe-alert-triangle" style={{margin: 'unset',}} />
                    </div>
                    <div className="flex-grow-1">
                        {alertMessage}
                    </div>
                </Alert>
            )} */}
            <div className="form-group">
                <input
                    type="email"
                    className="form-control blackBox"
                    placeholder="계정(이메일)을 입력하세요."
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={e => e.key === "Enter" && login()}
                />
            </div>
            <div className="form-group">
                <input
                    type="password"
                    className="form-control blackBox"
                    placeholder="비밀번호를 입력하세요."
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={e => e.key === "Enter" && login()}
                />
            </div>
            {/*<div className="form-group">*/}
            {/*    <label className="custom-control custom-checkbox">*/}
            {/*        <input type="checkbox" className="custom-control-input" />*/}
            {/*        <span className="custom-control-label">로그인 정보 저장하기</span>*/}
            {/*    </label>*/}
            {/*</div>*/}
            <div className="form-footer">
                <button
                    className="btn btn-primary btn-block btn-lg"
                    title=""
                    onClick={login}
                >
                    {/*Log in*/}로그인
                </button>
            </div>
        </div>
    )
}

export default withRouter(withTranslation()(Login))

Login.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any
}
