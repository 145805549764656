import React, { useState, useEffect } from "react"
import { Spinner } from "reactstrap"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"
import { toast } from "react-toastify"
import { map } from "lodash"

import defualt_img from "../../../../assets/images/chat/defualt-image.png"
import {
  getBlockUsers,
  unblockUserFromChatRoomFunction,
} from "../helper/Functions/SupportFunctions"

export default function BlockUsers(props, args) {
  const { roomId, user } = props

  const [targetUser, setTargetUser] = useState()
  const [loading, setLoading] = useState()
  const [blockUsers, setBlockUsers] = useState()
  const [unBlockModal, setUnBlockModal] = useState(false)

  // user unblock
  const unBlockUserFunction = async () => {
    setUnBlockModal(false)
    let data = {
      userId: user.id,
      userName: user.nickname,
      targetId: targetUser.id,
      targetName: targetUser.userName,
    }
    console.log(data, "data")
    const response = await unblockUserFromChatRoomFunction(roomId, data)
    if (response.message === "Updated") {
      props.onChangeMenu("chatContainer")
      // toast.success("unblock user successful")
      toast.success("사용자를 차단해제 했습니다.")
    } else {
      // toast.error("unblock user unsuccessful")
      toast.error("사용자를 차단해제하지 못했습니다.")
    }
  }

  // get block users
  useEffect(() => {
    const getBlockUsersFunction = async () => {
      setLoading(true)
      const result = await getBlockUsers(roomId, user.id)
      setBlockUsers(result.data)
      setLoading(false)
    }

    getBlockUsersFunction()
  }, [roomId, user.id])

  return (
    <div className="room-list">
      <div className="room-item-header d-flex align-items-center">
        <div className="room-item-close-button">
          <span
            className="la la-close"
            onClick={e => {
              props.onChangeMenu("chatContainer")
            }}
          ></span>
        </div>
        <div className="room-item-header-title font-weight-bold">
          내보내기 목록
        </div>
      </div>

      {loading ? (
        <Spinner type="grow" color="warning" />
      ) : (
        <div>
          {blockUsers?.length > 0 ? (
            <div className="room-body ">
              {map(blockUsers, (blockUser, index) => (
                <div key={index} className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-cetner">
                    <div className="ProfileImg">
                      <img
                        src={blockUser.userImg || defualt_img}
                        width={35}
                        className="rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="title"> {blockUser.userName} </div>
                  </div>
                  {/* <span className="owner-title owner-title-button"> */}
                    <Button
                      // className="btn btn-secondary"
                      color="secondary"
                      size="sm"
                      onClick={() => {
                        setTargetUser({
                          id: blockUser.userId,
                          userName: blockUser.userName,
                        })
                        setUnBlockModal(true)
                      }}
                    >
                      해제
                    </Button>
                  {/* </span> */}
                </div>
              ))}
              <Modal
                isOpen={unBlockModal}
                returnFocusAfterClose={true}
                centered={true}
              >
                <ModalBody>
                  해당 톡유저를 내보내기에서 해제하시겠습니까? 해제하시면 해당
                  톡유저가 톡방에 다시 입장할 수 있습니다.
                </ModalBody>
                {/* className="justify-content-center" */}
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={() => setUnBlockModal(false)}
                  >
                    취소
                  </Button>
                  <Button color="primary" onClick={() => unBlockUserFunction()}>
                    해제
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          ) : (
            <div className="room-item-header-title font-weight-bold block-user-content">
              내보낸 유저가 없습니다.
            </div>
          )}
        </div>
      )}
    </div>
  )
}
