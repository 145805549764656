import React, {useEffect, useState, memo, useCallback} from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from 'reactstrap';
import { debounce } from 'lodash';

import { getObjectDiffs } from 'utils/diffs';
import {genderSelection, agesSelection} from '../../constants/userInfoSelections';
import Avatar from '../../components/ChatUI/Avatar';
import './SettingForm.scoped.scss';

import {toast} from 'react-toastify';
import { authService } from 'services/auth.service';

const SettingForm = ({userInfo, changeProfile, resetPass, updateImage, ...props}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [dataLoading, setDataLoading] = useState({});

    // 👇 기존 간편가입 유저 이메일 연동 용 state
    // const [updateEmail, setUpdateEmail] = useState('')
    // const [authCode, setAuthCode] = useState('')
    // const [authResult, setAuthResult] = useState(true)
    // const [password, setPassword] = useState('')
    // const [passwordCheck, setPasswordCheck] = useState('')

    const setData = (data) => {
        if (!data) return;

        setPhoneNumber(data?.phone_number);
        setEmail(data?.email);
        setGender(data?.gender);
        setAge(data?.age_group);
    }

    const setDataLoader = (key, bool) => {
        const loaderSet = Object.assign({}, dataLoading);
        if (!bool) {
            delete loaderSet[key]
        } else {
            loaderSet[key] = bool;
        }
        setDataLoading(loaderSet);
    }

    const profileImageUpload = async (ev) => {
        ev.preventDefault();
        try {
            const files = ev.target.files || [];
            if (files[0] && files[0].type.substring(0, 5) !== 'image') {
                toast.error('지원하지 않는 파일형식입니다.');
                return;
            }

            const size = files[0]?.size / (1024*1024);
            if (size > 20) {
                toast.error('20MB를 초과하는 이미지는 업로드 할 수 없습니다.');
                return;
            }

            const formData = new FormData();

            // let bucketName = 'flowing-web-resources';
            // let fileSize = size.toFixed(2) < 1 ? `${(files[0]?.size/1000).toFixed(2)}KB` : `${size.toFixed(2)}MB`;
            // let fileName = files[0]?.name;
            formData.append('upload', files[0]);
            formData.append('limitedImageSize', 1280);

            if (updateImage) await updateImage(formData);
        } catch (e) {
            throw e;
        }
    }

    const changeInfo = async (currentData = {}) => {
        const propData = userInfo;
        if (!propData) return;

        const diffData = Object.assign({}, propData, currentData);
        const diff = getObjectDiffs(diffData, propData);
        if (diff.length <= 0) return;

        const payload = {};
        diff.forEach(key => {
            payload[key] = diffData[key];
        })

        if (changeProfile) {
            try {
                await changeProfile(payload);
            } catch (e) {
                setData(userInfo);
            }
        }
    }

    const debounceChangeInfo = useCallback(debounce((currentData = {}) => {
        changeInfo(currentData);
    }, 600), [userInfo]);

    useEffect(() => {
        setData(userInfo);
    }, [userInfo]);

    useEffect(() => {
        if (!userInfo) return;
        debounceChangeInfo({
            gender: gender,
            age_group: age,
        });
    }, [gender, age]);

    return (
        <div className="flowing-ui--profile-setting-form">
            <div className="d-flex justify-content-center mb-3">
                {/* {userInfo?.email && ( */}
                    <div>
                        <div className="logo_profile mx-auto">
                            <Avatar src={userInfo?.image_url}
                                    bgColor={'#e8e8e8'}
                                    bordered={false}
                                    size={90}
                                    alt={'avatar'} />
                            <form onSubmit={ev => ev.preventDefault()} onChange={async (ev) => {
                                try {
                                    setDataLoader('profile-image', true);
                                    await profileImageUpload(ev);
                                } finally {
                                    setDataLoader('profile-image', false);
                                }
                            }}>
                                <label className="image-button" htmlFor="file-uploader">
                                    {dataLoading['profile-image'] ? (
                                        <Spinner size={'sm'} color={'primary'} style={{borderWidth: 1}} />
                                    ) : (
                                        <i className="las la-camera" />
                                    )}
                                </label>
                                <input type="file" id="file-uploader" accept={'image/*'} style={{display:'none'}}/>
                            </form>
                        </div>
                        <p className="text-center">{userInfo?.nickname}</p>
                    </div>
                {/* )} */}

            </div>
            {/* {userInfo?.email && ( */}
                <div>
                    <FormGroup onSubmit={ev => ev.preventDefault()}>
                        <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                            휴대폰 번호
                        </Label>
                        <Input placeholder={'휴대폰 번호를 입력해 주세요.'} value={phoneNumber}
                            name={'phone_number'}
                            onChange={ev => setPhoneNumber(ev.target.value)} type={'text'} readOnly={userInfo?.phone_number} />
                    </FormGroup>
                    <FormGroup>
                        <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                            계정 (이메일)
                        </Label>
                        <Input placeholder={'사용하실 계정 (이메일)을 등록해주세요.'} value={email}
                            name={'email'}
                            onChange={ev => setEmail(ev.target.value)} type={'text'} readOnly={userInfo?.email} />
                    </FormGroup>
                    <FormGroup>
                        {!userInfo?.email ? (
                            <Button className="btn theme-btn theme-btn-outline" type="button" outline={true} block disabled={!email || !email?.trim() || dataLoading['account-update']} onClick={async () => {
                                try {
                                    setDataLoader('account-update', true);
                                    await changeInfo({
                                        email: email
                                    })
                                } finally {
                                    setDataLoader('account-update', false);
                                }
                            }}>
                                {dataLoading['account-update'] ? (<Spinner size={'sm'} color={'primary'} />) : '계정 등록하기'}
                            </Button>
                        ) : (
                            <Button className="btn theme-btn theme-btn-outline" type="button" outline={true} block
                                    disabled={dataLoading?.['email-wait']}
                                    onClick={async () => {
                                        try {
                                            setDataLoader('email-wait', true);
                                            if (resetPass) await resetPass(email);
                                        } finally {
                                            setDataLoader('email-wait', false);
                                        }
                                    }}>
                                {dataLoading['email-wait'] ? (<Spinner size={'sm'} color={'primary'} />) : '비밀번호 재설정'}
                            </Button>
                        )}
                    </FormGroup>

                    <FormGroup>
                        <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                            성별
                        </Label>
                        <ButtonGroup className="d-flex row">
                            {genderSelection.map((item, idx) => (
                                <Col lg={6} sm={6} xs={6} key={`${item.label}-${idx}`}>
                                    <Button className="theme-btn theme-btn-outline" type="button" outline block
                                            active={gender === item.value}
                                            onClick={(ev) => {
                                                setGender(item.value);
                                            }} >
                                        {item.label}
                                    </Button>
                                </Col>
                            ))}
                        </ButtonGroup>
                    </FormGroup>

                    <FormGroup>
                        <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                            나이
                        </Label>
                        <ButtonGroup className="d-flex row">
                            {agesSelection.map((item, idx) => (
                                <Col lg={4} sm={6} xs={6} key={`${item.label}-${idx}`}>
                                    <Button className="theme-btn theme-btn-outline" type="button" outline block
                                            active={age === item.value}
                                            onClick={(ev) => {
                                                setAge(item.value);
                                            }} >{item.label}</Button>
                                </Col>
                            ))}
                        </ButtonGroup>
                    </FormGroup>
                </div>
            {/* )} */}
            {/* {!userInfo?.email && (
                <div>
                    <FormGroup onSubmit={ev => ev.preventDefault()}>
                        <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                            이메일을 입력해 주세요
                        </Label>
                        <Input placeholder={'이메일을 입력해 주세요'} value={updateEmail}
                            name={'email'}
                            onChange={ev => setUpdateEmail(ev.target.value)} type={'email'} />
                    </FormGroup>
                    <FormGroup>
                        <Button
                            className="btn theme-btn theme-btn-outline"
                            type="button"
                            outline={true}
                            block
                            disabled={!updateEmail || !updateEmail?.trim() || dataLoading['account-update']}
                            onClick={async () => {
                                try {
                                    setDataLoader('account-update', true);
                                    await authService.activateEmailRequestCode(
                                        userInfo.id,
                                        {email:updateEmail}
                                    ).then(r => r)
                                    // await changeInfo({
                                    //     email: email
                                    // })
                                } finally {
                                    setDataLoader('account-update', false);
                                }
                            }}
                        >
                            {dataLoading['account-update'] ? (<Spinner size={'sm'} color={'primary'} />) : '인증번호 전송'}
                        </Button>
                    </FormGroup>
                    <FormGroup onSubmit={ev => ev.preventDefault()}>
                        <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                            인증번호를 입력해 주세요
                        </Label>
                        <Input placeholder={'인증번호를 입력해 주세요'} value={authCode}
                            name={'auth-code'}
                            onChange={ev => setAuthCode(ev.target.value)} type={'email'} />
                    </FormGroup>
                    <FormGroup>
                        <Button
                            className="btn theme-btn theme-btn-outline"
                            type="button"
                            outline={true}
                            block
                            disabled={!authCode || !authCode?.trim() || dataLoading['account-update']}
                            onClick={async () => {
                                try {
                                    setDataLoader('account-update', true);
                                    await authService.certificateActivateEmailRequestCode(
                                        userInfo.id,
                                        {email:updateEmail, auth_code: authCode}
                                    ).then(r => r)
                                    // await changeInfo({
                                    //     email: email
                                    // })
                                } finally {
                                    setDataLoader('account-update', false);
                                }
                            }}
                        >
                            {dataLoading['account-update'] ? (<Spinner size={'sm'} color={'primary'} />) : '확인'}
                        </Button>
                    </FormGroup>
                    {authResult && (
                        <>
                            <FormGroup onSubmit={ev => ev.preventDefault()}>
                                <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                                    비밀번호를 입력해 주세요
                                </Label>
                                <Input placeholder={'비밀번호를 입력해 주세요'} value={password}
                                    name={'password'}
                                    onChange={ev => setPassword(ev.target.value)} type={'password'} />
                                    {password && (!password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/)) && (<p style={{color:'red', fontSize:"13px"}}>영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자로 입력하세요.</p>)}
                            </FormGroup>
                            <FormGroup onSubmit={ev => ev.preventDefault()}>
                                <Label className="fs-14 text-black fw-medium mb-0 d-flex justify-content-between">
                                    비밀번호를 한번 더 입력해 주세요
                                </Label>
                                <Input placeholder={'비밀번호를 한번 더 입력해 주세요'} value={passwordCheck}
                                    name={'password-check'}
                                    onChange={ev => setPasswordCheck(ev.target.value)} type={'password'} />
                                    {passwordCheck && (password !== passwordCheck) && (<p style={{color:'red', fontSize:"13px"}}>비밀번호가 일치하지 않습니다.</p>)}
                            </FormGroup>
                            <FormGroup>
                                <Button
                                    className="btn theme-btn theme-btn-outline"
                                    type="button"
                                    outline={true}
                                    block
                                    disabled={!authCode || !authCode?.trim() || dataLoading['account-update']}
                                    onClick={async () => {
                                        try {
                                            setDataLoader('account-update', true);
                                            // await authService.certificateActivateEmailRequestCode(
                                            //     userInfo.id,
                                            //     {email:updateEmail, auth_code: authCode}
                                            // ).then(r => r)
                                            await authService.addUserEmail(
                                                    userInfo.id,
                                                    {email: updateEmail, password: password}
                                                )
                                            // await changeInfo({
                                            //     email: email
                                            // })
                                        } finally {
                                            setDataLoader('account-update', false);
                                        }
                                    }}
                                >
                                    {dataLoading['account-update'] ? (<Spinner size={'sm'} color={'primary'} />) : '이메일 등록하기'}
                                </Button>
                            </FormGroup>
                        </>
                    )}
                </div>
            )} */}
        </div>
    )
}

export default memo(SettingForm);