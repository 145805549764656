import _ from 'lodash';

export function diffDeep(obj1, obj2) {
    try {
        return _.isEqual(obj1, obj2)
    } catch (e) {
        throw e;
    }
}

export function getObjectDiffs(obj1, obj2) {
    try {
        return Object.keys(obj1).reduce((result, key) => {
            if (!obj2.hasOwnProperty(key)) {
                result.push(key);
            } else if (_.isEqual(obj1[key], obj2[key])) {
                const resultKeyIndex = result.indexOf(key);
                result.splice(resultKeyIndex, 1);
            }
            return result;
        }, Object.keys(obj2));
    } catch (e) {
        throw (e);
    }
}

export default {
    diffDeep,
    getObjectDiffs,
}