import {memo} from 'react';
import {Button} from 'reactstrap';

import appStoreIcon from 'assets/images/ic_app.png'
import playStoreIcon from 'assets/images/ic_gl.png'

const StoreIcon = (props) => {
    const {os} = props;

    return (
        <div className={props.className ? props.className : ''} style={{
            width: 20,
            height: 20,
        }}>
            <img src={os === 'ios' ? appStoreIcon : playStoreIcon} alt="" style={{
                marginTop: -3,
                width: '100%',
                height: '100%',
                objectFit: 'contain'
            }}/>
        </div>
    )
}


const AppMessageComp = (props) => {
    const {os, url} = props;
    const link = os === 'ios' ?
        'https://apps.apple.com/kr/app/%ED%94%8C%EB%A1%9C%EC%9E%89/id1457920637' :  // apple store link
        'https://play.google.com/store/apps/details?id=tpkorea.com.flowing';  // google store link

    return (
        <section>
            <article>
                <div>
                    앱을 이용하시면 더 간편하게 질문을 주고 받을 수 있어요!<br/><br/>
                    Flowing 앱으로 대화를 계속 하시겠어요?
                </div>
                <div className="d-flex mt-3">
                    {/*<Button color={'white'} size={'sm'} href={link} target={'_blank'} style={{*/}
                    {/*    boxShadow: '0 0 10px rgba(0,0,0,0.15)',*/}
                    {/*    border: '1px solid #ccc',*/}
                    {/*    borderRadius: 7,*/}
                    {/*}}>*/}
                    {/*    <div className="d-flex align-items-center">*/}
                    {/*        <StoreIcon className="mr-1" {...props} />*/}
                    {/*        <div className="mt-1px">*/}
                    {/*            지금 다운로드!*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Button>*/}
                    <Button color={'primary'} size={'sm'} href={url || link} target={'_blank'}>
                        앱으로 대화하기
                    </Button>
                </div>
            </article>
        </section>
    )
}

export default  memo(AppMessageComp);